import React,{ useEffect } from 'react';
import Layout from '../../layout/layout';
import { navigate, graphql, Link, useStaticQuery } from 'gatsby';
import './centres.scss';
import Dropdown from '../../components/common/Dropdown/Dropdown';
import {
  centreDirectionContent,
  centreDirectionContentCn
} from '../../../static-data';
import { FBPixelEventTracker } from '../../components/common/utils/utils';

const Centers = ({
  pageContext: {
    center: { url, label, title },
    centerLocations,
    centerLocations_cn,
    centers,
    language
  }
}) => {
  useEffect(() => {
    FBPixelEventTracker('The Little Skool-House - SG - Centres - IMG');
}, []);
  const metaDescription = {
    allcentres: {
      english: {
        url: 'https://www.littleskoolhouse.com/our-centres/centres/all-centres',
        title: 'Preschool Near Me - Our Centres | Little Skool-House',
        description: 'Looking for a preschool near you? Little Skool-House operates multiple preschool centres islandwide for your convenience. Click here to find a centre near you.'
      },
      chinese: {
        url: 'https://www.littleskoolhouse.com/zh/womendezhongxin/suoyouzhongxin',
        title: '我们的中心 - 所有中心 | 新加坡小小学庭',
        description: '小小学庭高效能的课程促使孩子精通中英文双语，也为他们的升学和人生发展打好基础。立即报名。'
      }
    },
    north: {
      english: {
        url: 'https://www.littleskoolhouse.com/our-centres/centres/north',
        title: 'Preschool in the North of Singapore | The Little Skool-House',
        description:
          'Several of the Little Skool-House preschools in the North are the choice preschool in the top institutions in Singapore. Schedule a centre tour today.'
      },
      chinese: {
        url: 'https://www.littleskoolhouse.com/our-centres/centres/north',
        title: 'Preschool in the North of Singapore | The Little Skool-House',
        description:
          'Several of the Little Skool-House preschools in the North are the choice preschool in the top institutions in Singapore. Schedule a centre tour today.'
      },
    },
    central: {
      english: {
        url: 'https://www.littleskoolhouse.com/our-centres/centres/central',
        title: 'Preschool in Central Singapore | The Little Skool-House',
        description:
          'Several of the Little Skool-House preschools in Central Singapore are the choice preschool in top institutions. Schedule a centre tour and enrol today.'
      },
      chinese: {
        url: 'https://www.littleskoolhouse.com/our-centres/centres/central',
        title: 'Preschool in Central Singapore | The Little Skool-House',
        description:
          'Several of the Little Skool-House preschools in Central Singapore are the choice preschool in top institutions. Schedule a centre tour and enrol today.'
      },
    },
    east: {
      english: {
        url: 'https://www.littleskoolhouse.com/our-centres/centres/east',
        title: 'Preschool in the East of Singapore | The Little Skool-House',
        description:
          '93% of Little Skool-House students a Highly Proficient in both English and Mandarin. Schedule a centre visit to our preschools in the East of Singapore today.'
      },
      chinese: {
        url: 'https://www.littleskoolhouse.com/our-centres/centres/east',
        title: 'Preschool in the East of Singapore | The Little Skool-House',
        description:
          '93% of Little Skool-House students a Highly Proficient in both English and Mandarin. Schedule a centre visit to our preschools in the East of Singapore today.'
      }
    },
    west: {
      english: {
        url: 'https://www.littleskoolhouse.com/our-centres/centres/west',
        title: 'Preschools in Singapore (West) | The Little Skool-House',
        description:
          'There are several Little Skool-House kindergartens in the West coast of Singapore, one of which provides IB curriculum for N2 to K2. Contact us today.'
      },
      chinese: {
        url: 'https://www.littleskoolhouse.com/our-centres/centres/west',
        title: 'Preschools in Singapore (West) | The Little Skool-House',
        description:
          'There are several Little Skool-House kindergartens in the West coast of Singapore, one of which provides IB curriculum for N2 to K2. Contact us today.'
      }
    }
  };
  let metaData;
  let centreZoneContents;
  const centreList = centers.filter(item => item.centre?.language?.toLowerCase() == language);
  if (language === "english") {
    centreZoneContents = centreDirectionContent;
  } else {
    centreZoneContents = centreDirectionContentCn;
  }
  const custom = {
    english: {
      centrePath: '/our-centres/centres',
      heading: `Our Centres ${title !== 'all-centers' && ' - ' + label}`,
      breadCrumb: [
        { label: 'Our Centres', link: '/our-centres/centres/all-centres' },
        { label: title === 'all-centers' ? 'All' : label }
      ],
      centreLocationList: centerLocations
    },
    chinese: {
      centrePath: '/zh/womendezhongxin',
      heading: `我们的中心 ${title !== 'all-centers' && ' - ' + label}`,
      breadCrumb: [
        { label: '我们的中心', link: '/zh/womendezhongxin/suoyouzhongxin' },
        { label: title === 'all-centers' ? '所有中心' : label }
      ],
      centreLocationList: centerLocations_cn
    }
  }
  if (title === 'north') {
    metaData = metaDescription.north[language];
  }
  if (title === 'central') {
    metaData = metaDescription.central[language];
  }
  if (title === 'east') {
    metaData = metaDescription.east[language];
  }
  if (title === 'west') {
    metaData = metaDescription.west[language];
  }

  const subheaders = useStaticQuery(graphql`
  query {
    cmsData: cms {
      pages {
        nodes {
          subheaders_name {
            centresHeader
            curriculumHeaders
          }
        }
      }
    }
  }
  `)

  const subheading = subheaders && subheaders.cmsData && subheaders.cmsData.pages.nodes.length > 0 && subheaders.cmsData.pages.nodes[0].subheaders_name;
  const centerLocationHandler = centerLocation => {
    navigate(`${custom[language].centrePath}/${centerLocation}`);
  }
  
  const navigateToCenter = (slugLink) => {
    if(language == 'english'){
      navigate(`/our-centres/${slugLink}`)
    }else{
      navigate(`/zh/womendezhongxin/${slugLink}`)
    }
  }
  const navigate360handle = (slugLink) => {
    if(language == 'english'){
      navigate(`/our-centres/${slugLink}?true`)
    }else{
      navigate(`/zh/womendezhongxin/${slugLink}?true`)
    }
  }
  

  return (
    <Layout
      bannerClass="admissions"
      heading={custom[language].heading}
      breadCrumbs={custom[language].breadCrumb}
      metaData={metaDescription.allcentres[language]}
    >
      <div className="centers-container">
      <p className="lsh-banner-sub-text lsh-centers-pad">
          <div
          dangerouslySetInnerHTML={{ __html: subheading.centresHeader}}/></p>

          {language == "english" ? 
          <div className='new-section-360-view'>
                  <span className="new-button-styling">
                    <b>NEW </b></span> {' '}
                  <span className='new-center-styling'>
                  : Get a 360° view of {' '}
                    <span className='center-page-360-view'
                       onClick={() => navigate360handle('the-little-skool-house-at-delta-house-alexandra-road')}>
                        Delta House
                    </span> & {' '}
                   <span className='center-page-360-view'
                         onClick={() => navigate360handle('the-little-skool-house-at-tampines-junction')}>
                    Tampines Junction
                     </span>.
                  </span>
            </div> 
            : 
            <div className='new-section-360-view'>
                  <span className="new-button-styling">
                    <b>新 </b></span> {' '}
                  <span className='new-center-styling'>
                  : 透过360°虚拟实境游览 {' '}
                    <span className='center-page-360-view'
                       onClick={() => navigate360handle('lidadashazhongxinyalishandafang')}>
                        立达大厦中心
                    </span> 和 {' '}
                   <span className='center-page-360-view'
                         onClick={() => navigate360handle('danbinnizhizongyingkangzhongxin')}>
                    淡滨尼职总英康中心
                     </span>.
                  </span>
            </div>
          }
        <div className="tablet-and-desktop-only">
          <div className="nav-section">
            {custom[language].centreLocationList.length > 0 &&
              custom[language].centreLocationList.map(location => (
                <div className="category-option" key={location.title}>
                  <input
                    type="radio"
                    id={location.title}
                    name="category"
                    checked={location.title === title}
                    value={location.url}
                    onChange={e => centerLocationHandler(e.target.value)}
                  />
                  <label htmlFor={location.title}>{location.label}</label>
                </div>
              ))}
          </div>
        </div>
        <div className="mobile-only-strict">
          <div className="category-dropdown">
            <Dropdown
              onChange={centerLocationHandler}
              options={custom[language].centreLocationList.map(cl => ({
                key: cl.url,
                label: cl.label,
                value: cl.url,
              }))}
              hideSelectedOption
            />
          </div>
        </div>

        <div className="centers-section">
          {centreList.length > 0 &&
            centreList.map(details => {
              const { centreName, location, centreName2, address, contact, email } = details.centre;
              const slugLink  = details.slug;
              const addressArr = address?.trim()?.split(/\n/);

              return (
                <>
                  {centreName && address && contact && email && (
                    // <Link key={details.slug}
                    //   to={language == 'english' ? `/our-centres/${details.slug}` : `/zh/womendezhongxin/${details.slug}`}
                    // >
                      <div className="center-details">
                        <div className="left-section">
                          <div className="center-name" onClick={() => navigateToCenter(slugLink)}>
                            {centreName}
                            {location && (
                              <>
                                <br />
                                <span className="location">{location}</span>
                              </>
                            )}
                            {centreName2 && (
                              <>
                                <br />
                                <span>{centreName2}</span>
                              </>
                            )}
                          </div>
                          <div className="center-address" >
                            <span onClick={() => navigateToCenter(slugLink)}>
                            {addressArr && addressArr.length > 0 && addressArr.map((addressLine, i) => (
                              <p key={i}>{addressLine}</p>
                            ))}
                            </span>
                            {slugLink == 'the-little-skool-house-at-tampines-junction' && 
                          <span
                          className='center-rotate'
                          onClick={() => navigate360handle(slugLink)}
                          >
                           <span className='new-rotate-big'>NEW:</span> 360° view 
                          </span>
                          }
                          {slugLink == 'the-little-skool-house-at-delta-house-alexandra-road' && 
                          <span
                          className='center-rotate'
                          onClick={() => navigate360handle(slugLink)}
                          >
                           <span className='new-rotate-big'>NEW:</span> 360° view 
                          </span>
                          }
                          {slugLink == 'lidadashazhongxinyalishandafang' && 
                          <span
                          className='center-rotate'
                          onClick={() => navigate360handle(slugLink)}
                          >
                           <span className='new-rotate-big'>新:</span> 360°导览 
                          </span>
                          }
                          {slugLink == 'danbinnizhizongyingkangzhongxin' && 
                          <span
                          className='center-rotate'
                          onClick={() => navigate360handle(slugLink)}
                          >
                           <span className='new-rotate-big'>新:</span> 360°导览 
                          </span>
                          }
                          </div>
                          
                          <div
                            className="center-phone"
                            style={{ visibility: !contact && 'hidden' }}
                            onClick={() => navigateToCenter(slugLink)}
                          >
                            {contact}
                          </div>
                          <div className="center-email"
                            onClick={() => navigateToCenter(slugLink)}
                          >
                                {email}
                              </div>
                         
                          
                        </div>

                        <div className="plus-sign"
                          onClick={() => navigateToCenter(slugLink)}>
                          <span>+</span>
                        </div>
                      </div>
                    // </Link>
                  )}
                </>
              )
            })}
        </div>

        {title != 'all-centers' && centreZoneContents && centreZoneContents[title] && (
          <div style={{ margin: '25px 0px' }}>
            <div className="subtext content-text-left">
              <h2> {centreZoneContents[title].mainSection.heading} </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: centreZoneContents[title].mainSection.description
                }}
              />
              {centreZoneContents[title].subSection.map(item => (
                <div style={{ marginTop: '20px' }}>
                  <h2 style={{ color: 'black' }}>{item.heading}</h2>
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Centers;
