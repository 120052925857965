import React, { useMemo, useState, useEffect} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import MobileMenu from './MobileMenu/MobileMenu';
import DesktopMenu from './DesktopMenu/DesktopMenu';
import Announcement from './DesktopMenu/Announcement/Announcement';
import { menuItems, menuItemsCn } from '../../config/appConfig';
import { getLanguage } from '../common/utils/utils';
import './Header.scss';

const Header = ({ handleClick, openStatus, onLangChange, skinnyBannerDataEn, skinnyBannerDataCn, language}) => {
  const data = useStaticQuery(graphql`
    query {
      allHappening(filter: { category: { eq: "stories" } }) {
        totalCount
      }
      openHouseDetails {
        openHouseDetails {
          active
          details {
            promotion
          }
        }
      }
      cmsData: cms {
        pageBy(uri: "limited-time-promotion") {
          open_house {
            openhousestatus
          }
        }
      }
    }
  `);

  const [activeLang, setActiveLang] = useState(language);
  const menuItemsMemo = useMemo(() => {
    const menuOpt = activeLang == 'english' ? menuItems : menuItemsCn;
    const menuItemsCopy = [...menuOpt];
    if (data?.openHouseDetails?.openHouseDetails?.active) {
      const enquiryMenu = menuItemsCopy.find(el => el.link === 'enquiry');

      // enquiryMenu.subMenu[0] = {
      //   label: 'Schedule A Visit',
      //   link: '/schedule-a-visit'
      // };
    }
    // else {
    //   // Remove the events section if there are no events apart from Open House
    //   if (data?.allHappening?.totalCount < 2) {
    //     const happeningMenu = menuItemsCopy.find(
    //       el => el.link === 'happenings'
    //     );
    //     if (happeningMenu.subMenu) {
    //       happeningMenu.subMenu = happeningMenu.subMenu.filter(
    //         el => el.link !== 'events'
    //       );
    //     }
    //   }
    // }

    return menuItemsCopy;
  }, [data, activeLang]);

  const promoDetails = data?.openHouseDetails?.openHouseDetails;
  const promoActive = promoDetails?.active;

  const custom = {
    english: {
      bannerData: skinnyBannerDataEn,
    },
    chinese: {
      bannerData: skinnyBannerDataCn,
    }
  }

  const langSwitch = val => {
    setActiveLang(val);
    localStorage.setItem("lan", val);
    if(val == 'english') {
      document.location.href = '/';
    } else {
      document.location.href = '/zh';
    }
  }

  return (
    <div className="header-container blue-text">
      {custom[activeLang]?.bannerData?.skinnybannerstatus === 'on' && ( 
        <Announcement
          promoActive={openStatus}
          promoAmount={promoDetails?.details?.promotion}
          handleClick={handleClick}
          language={activeLang}
          skinnyBannerData={custom[activeLang].bannerData}
        />
      )}
      <div className="desktop-only">
      <div className={`lang-switch header-menu-container ${activeLang === 'english' ? 'lang-align' : ''}`}>
        <div onClick={() => langSwitch("english")}>
          <a className={`link-en ${activeLang === 'english' ? 'l-lang-active' : ''}`}>EN</a>
        </div>
        |
        <div className="lang-cn" onClick={() => langSwitch("chinese")}>
          <a className={`link-en ${activeLang === 'chinese' ? 'l-lang-active' : ''}`}>中文</a>
        </div>
      </div>
      </div>
      <div className="desktop-only-special">
        <DesktopMenu menuItems={menuItemsMemo} promoActive={openStatus} activeLang={activeLang} />
      </div>
      <div className="mobile-only-special">
        <MobileMenu menuItems={menuItemsMemo} activeLang={activeLang} promoActive={openStatus} />
      </div>
    </div>
  );
};

export default Header;
