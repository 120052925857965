import React from 'react';
import Layout from '../../layout/layout';
import CertifiedCentersImg from '../../images/objects/about-awards/about-awards-1.jpeg';
import OurHealthyFood from '../../images/objects/about-awards/about-awards-2.jpg';
import SPARK from '../../images/logos/SPARK.png';
import HMPP from '../../images/logos/HMPP.png';
import './our-awards.scss';
import AwardsSection from '../../components/common/AwardsSection/AwardsSection';

const OurVision = () => {
  return (
    <Layout
      bannerClass="about-us"
      heading="Our Awards"
      breadCrumbs={[{ label: 'About Us' }, { label: 'Our Awards' }]}
      metaData={{
        url: 'https://www.littleskoolhouse.com/about-us/our-awards',
        title: 'Awards, Achievements and Recognitions | Little Skool-House',
        description:
          `Enrol your child in a preschool centre that has been recognised by various organisations for its exemplary work. See Little Skool-House's awards here.`
      }}
    >
      <div className="our-awards-container">
        <AwardsSection />
        <div className="award-bottom">
          <div className="photo-section">
            <img
              src={CertifiedCentersImg}
              alt="Our Certified Centers"
              className="children-img"
            />
            <h2>Our Certified Centres</h2>
            <p>
              Our preschools are SPARK-certified. The Singapore Preschool
              Accreditation Framework (SPARK) is a quality assurance framework
              introduced by the Ministry of Education to raise the quality of
              preschools. The framework supports preschool leaders in their
              efforts to enhance their teaching, learning, administration and
              management processes.
            </p>
            <img src={SPARK} alt="SPARK Logo" className="logo-img logo-spark" />
          </div>
          <div className="photo-section">
            <img
              src={OurHealthyFood}
              alt="Our Healthy Food"
              className="children-img"
            />
            <h2>Our Healthy Food</h2>
            <p>
              The in-house food specially cooked with love by our in-house chef
              in all our centres have been certified by the Healthy Meals in
              Preschools Programme (HMPP). Of these, nine have been awarded
              Platinum status.
            </p>
            <img src={HMPP} alt="HMPP Logo" className="logo-img" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OurVision;
