import React, { useState, useEffect, useRef, useCallback } from 'react';
import { navigate, Link } from 'gatsby';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Formik, Form } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import {  mdRegistration } from '../../../api/api';
import Dropdown from '../Dropdown/Dropdown';
import DatePick from '../DatePick/DatePick';
import AlertModal from '../AlertModal/AlertModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { TextField, Checkbox } from '../FormInput/FormInput';
import { aboutUs } from '../../../config/appConfig';
import { gtmEventManager } from "../utils/utils"
import './ScheduleVisitForm.scss';

const errorMsg =
  "Sorry, we can't process your enquiry now. Please try again later";

const FormInput = ({ label, name, type = 'text', required, isOpenHouseForm=false }) => (
  <div className="form-input">
    <div className={isOpenHouseForm ? 'label-wrapper' : 'label-wrapper-en' }>
      <label htmlFor={name}>{label}</label>
      {required && <span className="required-label">*</span>}
    </div>
    <div className={isOpenHouseForm ? 'input-wrapper' : 'input-wrapper-en' }>
      <TextField type={type} id={name} name={name} />
    </div>
  </div>
);

const ChildDetailsComponent = ({
  title,
  nameField,
  dobField,
  errors,
  setFieldValue,
  required,
  isOpenHouseForm,
  passportField,
}) => (
  <>
    <div className="child-controlsx">
        <FormInput
         label="Child's Name"
          name={nameField}
           required={required}
            isOpenHouseForm={isOpenHouseForm}
         />
      <div className="form-input">
          <div className={isOpenHouseForm ? 'label-wrapper' : 'label-wrapper-en' }>
            <label htmlFor={dobField}>Child's DOB</label>
            {required && <span className="required-label">*</span>}
          </div>
          <div className={isOpenHouseForm ? 'input-wrapper' : 'input-wrapper-en' }>
            <DatePick
              changeHandle={date => {
                setFieldValue(dobField, date, true);
              }}
              yearPicker={true}
              name={dobField}
              minDate={new Date(moment().subtract(7, 'years').add(1,'day'))}
              maxDate={new Date(moment().add(9, 'months'))}
            />
            {errors[dobField] && (
              <div className="error">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="icon"
                />
                {errors[dobField]}
              </div>
            )}
          </div>
          
        </div>
        <FormInput
           label="Birth certificate No. / FIN / Passport No. (Last 4 characters)"
           name={passportField}
           isOpenHouseForm={isOpenHouseForm}
          />
    </div>
  </>
);

const MothersDayLandingForm = ({ eventType,type,centerList }) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [contact_options, setContactOptions] = useState({});
  const formRef = useRef();
  const isOpenHouseForm = type === 'open-house';

  const [userToken, setUserToken] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    return (async () => {
      try {
        const token = await executeRecaptcha('submit');
        return token;
      } catch (error) {
        console.error("Something went wrong",error);
      }
    })();
  }, [executeRecaptcha]);


  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);



  const handleForm = async formData => {
    const resultToken = await handleReCaptchaVerify();
    setFormSubmitting(true);

    try {
      const { status } = await  mdRegistration({...formData, recaptcha_response: resultToken});
      setFormSubmitting(false);

      if (status !== 'success') {
        setSubmitError(errorMsg);
        return;
      }
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({'event':'FormSubmit','gaEventLabel': type === "open-house" ? "openhouse" : "schedule-a-visit"})

      navigate(
        type === 'open-house'
          ? '/enquiry/limited-time-promotion/thank-you'
          : type === 'mothers-day' ? '/mothers-day-event-6-may/thank-you' : type === 'mothers-day-20' ? '/mothers-day-event-20-may/thank-you' : type === 'mothers-day-20-2' ? '/mothers-day-event-20-may-2/thank-you' : '/mothers-day-event-6-may-2/thank-you'
      );

      const form = formRef.current;
      if (form) {
        form.resetValidationState(true);
      }
    } catch (e) {
      setSubmitError(errorMsg);
      setFormSubmitting(false);
    }
  };

  const closeModalHandler = () => {
    setShowModal(false);
    navigate('/');
  };

  const setCheckBoxValue = (name) => {
    const data = { ...contact_options };
    data[name] = !data[name];

    if (name == "All" && data[name]) {
      data.Email = true;
      data.SMS = true;
      data.Phone = true;
    }
    else if (name == "All" && !data[name]) {
      data[name] = false;
      data.Email = false;
      data.SMS = false;
      data.Phone = false;
    }
    if (data.Email && data.SMS && data.Phone) {
      data.All = true;
    }
    if (!data.Email || !data.SMS || !data.Phone) {
      data.All = false;
    }

    setContactOptions(data);
  };

  return (
    <>
      <div className="form-wrapper">
        {submitError && (
          <div className="submit-error error">
            <FontAwesomeIcon icon={faExclamationTriangle} className="icon" />
            {submitError}
          </div>
        )}

        <Formik
          initialValues={{
            name: '',
            email: '',
            phone: '',
            centre: '',
            address: '',
            date: '',
            child1_name: '',
            child1_dob: '',
            child2_name: '',
            child2_dob: '',
            child1_pass : '',
            contact_options: '',
          }}
          validationSchema={Yup.object({
            name: Yup.string().trim().required('Please enter your name'),
            email: Yup.string()
              .email('Please enter a valid email address')
              .required('Please enter your email address'),
            phone: Yup.string()
              .matches(/^[0-9]{8}$/, 'Phone number must be 8 digits')
              .required('Please enter your phone number'),
            about_us_others: Yup.string().max(
              200,
              'Value is too long (200 characters max)'
            ),
            message: Yup.string().max(
              4096,
              'Your comment is too long (4096 characters max)'
            ),
            centre: Yup.mixed()
              .required('Please select an option')
              .notOneOf(['Choose centre'], 'Please select an option'),
            date: Yup.string().required('Please enter the enrolment date'),
            child1_name: Yup.string().trim().required('Please enter name of the child'),
            child1_dob: Yup.string().required(`Please enter the child's DOB`),
            // child1_pass: Yup.string().trim().required('Please enter BC/FIN/Passport no'),
            address: Yup.string().matches(/^[0-9]{6}$/, 'Postal code must be 6 digits').required('Please enter postal code'),
          })}
          onSubmit={(formData, actions) => {
            formData.date = moment(formData.date).format('YYYY-MM-DD');
            if (moment(formData.date).diff(moment(formData.child1_dob), 'months') < 2) {
              actions.setFieldError('date', 'Enrolment date should be 2 months away from DOB');
              actions.setSubmitting(false);
              return;
            }
            if(formData.child1_pass){
              let childPass = formData.child1_pass;
              if(childPass.length == 4){
                let validateRegex = new RegExp('^([0-9]{3}[aA-zZ]|[0-9]{4})');
                let result = validateRegex.test(childPass);
                if(result == false){
                  actions.setFieldError('child1_pass', 'Please enter a valid format (i.e.: 4 digits or 3 digits followed by 1 alphabet)');
                  actions.setSubmitting(false);
                  return;
                }                
              }else{
                actions.setFieldError('child1_pass', 'Please enter a valid format (i.e.: 4 digits or 3 digits followed by 1 alphabet)');
                actions.setSubmitting(false);
                return
              }
            }
            formData.child_name = formData.child1_name;
            formData.child_dob = moment(formData.child1_dob).format('YYYY-MM-DD');
            formData.child_pass = formData.child1_pass;
            formData.event_type = eventType;
            let date1 = moment(formData.child1_dob).format('YYYY-MM-DD');
            let date2 = moment().format('YYYY-MM-DD')
            if(!formData.child1_pass){
                if(moment(date1).isBefore(date2)){
                    actions.setFieldError('child1_pass', 'Please enter BC/FIN/Passport no');
                    actions.setSubmitting(false);
                    return;
                }
            }

              formData.contact_options = ''          
              let finalString = Object.keys(contact_options).filter(key => contact_options[key])
              if(finalString.includes('All')){
                formData.contact_options = finalString.filter(ele => ele != 'All').toString()
              }
              else{
                formData.contact_options = finalString.toString()
              }
            handleForm(formData).then(() => {
              actions.setSubmitting(false);
              actions.resetForm();
              setContactOptions({})
            });
          }}
        >
          {({ errors, setFieldValue, values }) => (
            <Form>
              <div className={`form-controls-en ${type == "open-house" ? "form-controls-open-house" : "form-controls-normal" }`}>
                <div className="left-col-en">
                  <div className="form-input">
                    <div className={isOpenHouseForm ? 'label-wrapper' : 'label-wrapper-en' }>
                      <label htmlFor="name">Preferred Centre</label>
                      <span className="required-label">*</span>
                    </div>
                    <div className={isOpenHouseForm ? 'input-wrapper' : 'input-wrapper-en' }>
                      <Dropdown
                        resetValue={!values.centre && 'Choose centre'}
                        onChange={centre => {
                          setFieldValue('centre', centre, true);
                        }}
                        options={centerList.map(item => ({
                          key: item.centreName
                            ? item.centreName
                            : item.location,
                          value: item.centreName
                            ? item.centreName
                            : item.location,
                          notClickable: item.location && !item.centreName,
                          selectedCenter:
                            item.isParticipating === 'true' || false
                        }))}
                        theme="white"
                        bottomLine={true}
                        type={type}
                      />
                      {errors.centre && (
                        <div className="error">
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="icon"
                          />
                          {errors.centre}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-input">
                    <div className={isOpenHouseForm ? 'label-wrapper' : 'label-wrapper-en' }>
                      <label htmlFor="date">Preferred Date of Enrolment</label>
                      <span className="required-label">*</span>
                    </div>
                    <div className={isOpenHouseForm ? 'input-wrapper' : 'input-wrapper-en' }>
                      <DatePick
                        changeHandle={date => {
                          setFieldValue('date', date, true);
                        }}
                        name="date"
                        minDate={new Date(moment().add(1, 'day'))}
                      />
                      {errors.date && (
                        <div className="error">
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="icon"
                          />
                          {errors.date}
                        </div>
                      )}
                    </div>
                  </div>
                  <FormInput
                    label="Parent's Name"
                    name="name"
                    required={true}
                    isOpenHouseForm={isOpenHouseForm}
                  />
                  <FormInput
                    label="Email"
                    name="email"
                    type="email"
                    required={true}
                    isOpenHouseForm={isOpenHouseForm}
                  />
                  <FormInput label="Contact No." name="phone" required={true} isOpenHouseForm={isOpenHouseForm}/>
                  <FormInput label="Postal Code" name="address" required={true} isOpenHouseForm={isOpenHouseForm}/>
                  <ChildDetailsComponent
                    title=""
                    nameField="child1_name"
                    dobField="child1_dob"
                    passportField="child1_pass"
                    errors={errors}
                    setFieldValue={setFieldValue}
                    required={true}
                    isOpenHouseForm={isOpenHouseForm}
                  />
                </div>
              </div>
              
              {
                (<div className="agreement-wrapper agreement-col" style={{ display: 'block' }}>
                  <label style={{ color: '#333333' }}>
                By submitting this form, you acknowledge and agree to our&nbsp;
                <a href="https://www.littleskoolhouse.com/others/privacy-policy" target="_blank" style={{ textDecoration: 'underline', color: '#4ec7e2' }}>
                    Privacy Policy
                </a>
                , and that The Little Skool-House International and My First Skool, 
                preschool businesses of NTUC First Campus Co-operative Limited, 
                will contact you regarding registration matters and/or update you 
                in relation to childcare services.
                <br /><br />
                Yes, I would like to receive recommendations for childcare services, parenting tips from experts, 
                promotions and new product
                 information from The Little Skool-House International and My First Skool, 
                 preschool businesses of NTUC First Campus Co-operative Limited, our affiliated 
                 organisations and partners via:
                <br /><br />
                <div className="checkbox">
                  <input
                    type="checkbox"
                    value="All"
                    name="contact_options"
                    checked={contact_options.All}
                    onClick={() => setCheckBoxValue("All")} />All
                </div>
                <div className="checkbox" style={{ paddingLeft: '2%' }}>
                  <input
                    type="checkbox"
                    value="Email"
                    name="contact_options"
                    checked={contact_options.Email}
                    onClick={() => setCheckBoxValue("Email")} />Email
                </div>
                <div className="checkbox" style={{ paddingLeft: '2%' }}>
                  <input
                    type="checkbox"
                    value="SMS"
                    name="contact_options"
                    checked={contact_options.SMS}
                    onClick={() => setCheckBoxValue("SMS")} />SMS
                </div>
                <div className="checkbox" style={{ paddingLeft: '2%' }}>
                  <input
                    type="checkbox"
                    value="Phone"
                    name="contact_options"
                    checked={contact_options.Phone}
                    onClick={() => setCheckBoxValue("Phone")} />Phone
                </div>
                <br />
                </label>
              </div>)
              }
              <div className="error-field">*Required Fields</div>
              <div className="form-input">
                <div
                  className="input-wrapper full"
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <button
                    type="submit"
                    className="yellow-funky-button"
                    disabled={formSubmitting}
                  >
                    {formSubmitting ? 'Submitting...' : 'Submit Now'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default MothersDayLandingForm;
