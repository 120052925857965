import React from 'react';
import '../../pages/about-us/our-successes.scss';
import KidWithMom from '../../images/objects/our-successes-kid-with-mom.jpg';
import LeftQuote from '../../images/objects/left-quote.png';
import RightQuote from '../../images/objects/right-quote.png';

const OurSuccessesCn = () => {
  return (
      <div className="our-successes-container">
        <div className="top-section">
          <div className="left-section">
            <img src={KidWithMom} alt="Kid with mom" draggable={false} />
          </div>
          <div className="right-section">
            <img src={LeftQuote} alt="Left quote" className="quote" />
            <div className="hightlight">
              自1994年起，
              <span>
                数以千计的孩子在小小学庭幼儿中心学习，有效建构知识、探索、成长。
              </span>
            </div>
            <p>
              从创作英语和华语文学作品，到数学技能及探索科学。从培养健康的饮食习惯、规律的日常作息到独立自主，这些成果都表现出小小学庭幼儿中心不仅仅是一所学校。我们的全方为综合课程，专业的教师团队，创造了一个鼓励、支持、发掘、创意和多元性的学习环境。
            </p>
            <div className="right-quote">
              <img src={RightQuote} alt="Right quote" />
            </div>
          </div>
        </div>

        <div className="section-two">
          <p className="info low-top-padding text-align-center">
            <span className="percent">
              小小学庭不同层面的成功
            </span>
          </p>
          <div className="stats">
            <div className="stat">
              <div className="content">
                27
                <br />
                <span>年的历史</span>
              </div>
            </div>
            <div className="stat stat-right">
              <div className="content">
                6,600
                <br />
                <span>名学童</span>
              </div>
            </div>
            <div className="stat">
              <div className="content">
                4.5<div className="million-text-cn">百万</div>
                <br />
                <span>的成就</span>
              </div>
            </div>
          </div>
          <p className="info low-top-padding text-align-center">
            <span className="percent">
              我们27年的传承见证了6,600多名儿童取得超过450万项的成果。
            </span>
          </p>
        </div>
      </div>
  );
};
export default OurSuccessesCn;