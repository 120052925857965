import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../layout/layout';
import './thankyou.scss';

import totGif from '../../images/tot/P1-Readiness-Thank-You-Page-GIF.gif'

const getThankYouCopy = (path, language) => {
  switch (language) {
    case 'cn':
      if (path === 'path1') {
        return '感谢您的预约。';
      } else if (path === 'path2') {
        return '感谢您的关注';
      }
      else {
        return '感谢您的查询';
      }
    default:
      if (path === 'path1' || path === 'path2') {
        return 'Thank you for your interest';
      } else {
        return 'Thank you for your enquiry'
      }
  }
};

const ThankYou = ({ pageContext: { pathurl, language } }) => {
  return (
    <Layout
      bannerClass="schedule"
      heading={language === 'cn' ? (pathurl === 'path1' ? '谢谢' : '谢谢您') : "Thank You"}
      breadCrumbs={language === 'cn' ?
        [
          { label: '询问' },
          {
            label:
              ((pathurl === 'path1') && ('预约参观')) || ((pathurl === 'path2') && ('限时促销')) || ((pathurl === 'path3') && ('联络我们'))
          },
          { label: '谢谢您' }
        ]
        : [
          { label: 'Enquiry' },
          {
            label:
              ((pathurl === 'path1') && ('Schedule A Visit')) || ((pathurl === 'path2') && ('limited-time-promotion')) || ((pathurl === 'path3') && ('Contact Us'))
          },
          { label: 'Thank You' }
        ]}
      noFooter={true}
      noBanner={true}
      metaData={{
        url: 'https://www.littleskoolhouse.com/enquiry/thank-you',
        title: 'Schedule a Centre Visit | The Little Skool-House',
        description:
          'Visit one of our Little Skool-House centres to see how 93% of our graduates are Highly Proficient in Both English and Mandarin. Schedule a visit today.'
      }}
    >
      <div className="thank-you-container">
        <h2>
          {getThankYouCopy(pathurl, language)}
        </h2>
        <p>
          {language === 'cn'
            ? (pathurl === 'path1' ? '我们将在两个工作日内与您联系。' : '我们的代表将在接下来的 2 个工作日内与您联系。')
            : ' Our representative will be in touch with you within the next 2 working days.'}
        </p>

        {language === 'cn' ? (pathurl === 'path1' ?
          (
            <p>
              欢迎查看我们的特色课程，并了解被众多
              <a
                className="top-inst"
                href="/zh/womendeyuzhongbutong/dingjianjigouxuanzedeyoueryuan"
                target="_blank"
              >
                享誉盛名的机构
              </a>
              所优选的原因
            </p>
          ) :
          (
            <p>
              了解我们的专业课程以及为什么我们被
              <a
                className="top-inst"
                href="/zh/womendeyuzhongbutong/dingjianjigouxuanzedeyoueryuan"
                target="_blank"
              >
                顶级机构选中的学前班
              </a>
              。
            </p>
          )
        ) : (
          <p>
            Discover our specialised curriculum and why we are the chosen
            preschool by{' '}
            <a
              className="top-inst"
              href="/our-difference/chosen-preschool-by-top-institutions"
              target="_blank"
            >
              top institutions.
            </a>
          </p>
        )}

        <div className="back-to-list">
          <Link to={language === 'cn' ? '/zh/womendekecheng/kechengkuangjiagaishu' : 
            '/our-curriculum/curriculum-overview'}
          >
            <button className="yellow-funky-button back-btn">
              {language === 'cn' ? (pathurl === 'path1' ? '了解课程详情' : '查看课程') : 'View Curriculum'}
            </button>
          </Link>
        </div>
        {language !== 'cn' && (
          <div className="ty-page-banner">
            <Link to='/happenings/videos/primary-school-readiness-graduation' className="banner-center">
              <img
                src={totGif}
                alt="tot-leader-banner"
                
              />
            </Link>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ThankYou;
