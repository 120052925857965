import React from 'react';
import GwlForm from '../../components/common/GwlForm/GwlForm';
import GWlFormEnglish from '../../components/common/GwlForm/GwlEnglishForm';
import GwlMandarinForm from '../../components/common/GwlForm/GwlMandarinForm';
import { GWL_BILINGUAL_SLUG, GWL_EN_SLUG, GWL_MN_SLUG, inCentreList } from '../../config/appConfig';
import GwlLayout from '../../components/common/GwlLayout/gwlLayout'
import './gwl.scss';
import GwlLogo from '../../images/logos/gwl-logo-new.png';
import { graphql, useStaticQuery } from 'gatsby';
import { date } from 'yup';

const Gwl = ({ pageContext: { form, classgw, metaData }, data }) => {
  const getFormDataBasedOnLanguage = () => {
    let englishData;
    let mandarinData;
    let bilingualData;
    if (data && data.cms && data.cms.pages && data.cms.pages.nodes && data.cms.pages.nodes.length) {
      englishData = data.cms.pages.nodes.find(item => item.slug === GWL_EN_SLUG);
      mandarinData = data.cms.pages.nodes.find(item => item.slug === GWL_MN_SLUG);
      bilingualData = data.cms.pages.nodes.find(item => item.slug === GWL_BILINGUAL_SLUG);
    }
    let cmsDataTemp = [];
    switch (form) {
      case 'english':
        cmsDataTemp = englishData;
        break;
      case 'mandarin':
        cmsDataTemp = mandarinData;
        break;
      case 'bilingual':
        cmsDataTemp = bilingualData;
        break;
      default:
        cmsDataTemp = englishData;
    }
    return cmsDataTemp;
  }

  const cmsData = getFormDataBasedOnLanguage();
  const primaryHeading = cmsData.gwlFields.primaryHeading;
  const subTitle = cmsData.gwlFields.subTitle;
  const blueLabelCta = cmsData.gwlFields.blueLabelCta;

  return (
    <GwlLayout
      metaData={metaData}
    >
      <div className="gwl-container">
        {/* <div className="gwl-logo-wrap">
      <div className="gwl-logo">
        <img className="gwl-img" src={GwlLogo} alt="gwl-logo" />
      </div>
      </div> */}
        <div className="gwl-title">
          <h2>{primaryHeading}</h2>
          <h4 className={classgw}>{blueLabelCta}</h4>
          {/* <p className="gwl-sub-title">{subTitle}</p> */}
        </div>
        {form && form === 'bilingual' ? (
          <GwlForm type="both" inCentreList={inCentreList} data={cmsData} />
        ) : form === 'english' ? (
          <GWlFormEnglish type="english" inCentreList={inCentreList} data={cmsData} />
        ) : (
          <GwlMandarinForm type="mandarin" inCentreList={inCentreList} data={cmsData} />
        )}
      </div>
    </GwlLayout>
  );
};

export const pageQuery = graphql`
  query {
    cms {
      pages(where: {search: "gwl"}) {
        nodes {
          slug
          gwlFields {
            blueLabelCta
            fieldGroupName
            primaryHeading
            subTitle
            yourDetailsLabel
            slotsLabel {
              ... on cms_Page_Gwlfields_SlotsLabel_SlotData {
                isfull
                english
              }
            }
            slotsLabelMn {
              ... on cms_Page_Gwlfields_SlotsLabelMn_SlotData {
                isfull
                mandarin
              }
            }
            slotsLabelEn {
              ... on cms_Page_Gwlfields_SlotsLabelEn_SlotData {
                english
                isfull
              }
            }
            childDob {
              dobErrorCopy
              dobLabel
              dobPlaceholder
            }
            childName {
              childnameErrorCopy
              childnameLabel
              childnamePlaceholder
            }
            email {
              emailErrorCopy
              emailFieldLabel
              emailFieldsPlaceholder
              emailInstruction
              emailInvalidError
            }
            mobileNumber {
              mobileFieldErrorCopy
              mobileFieldLabel
              mobileFieldPlaceholder
              subError
            }
            notes {
              ... on cms_Page_Gwlfields_Notes_NotesContent {
                noteCopy
              }
            }
            liveClassLabel {
              liveClassSubTitle
              liveClassTitle
            }
            scheduleSubTitle
            thanksCopy {
              copyContent
              subtitle
            }
            submitButtonLabel
            noteTitle
            englishDropLabel {
              englishDropLabel
              englishDropdownErrorCopy
              englishDropdownPlaceholder
            }
            mandBiliangualDropLabelCopy {
              mandBiliangualDropLabel
              mandBiliangualDropdownErrorCopy
              mandBiliangualDropdownPlaceholder
            }
            holidayTable {
              table {
                columnA
                columnB
              }
              holidayTableStatus
            }
          }
        }
      }
    }
  }
`;

export default Gwl;
