import React from 'react';
import Layout from '../../layout/layout';
import YoutubeVideo from '../../components/common/YoutubeVideo/YoutubeVideo';
import CareersFamily from '../../images/objects/careers-family.png';
import CareersBuddy from '../../images/objects/careers-buddy.png';
import CareersAchieve from '../../images/objects/careers-achieve.png';
import './careers.scss';

const JobButton = ({ text, link }) => {
  return (
    <div className="job-button">
      <div className="yellow-funky-button">
        <a href={link} target="_blank" rel="nofollow">
          {text}
        </a>
      </div>
    </div>
  );
};

const Careers = () => {
  return (
    <Layout
      bannerClass="enquiry"
      heading="Careers"
      breadCrumbs={[{ label: 'Enquiry' }, { label: 'Careers' }]}
      metaData={{
        url: 'https://www.littleskoolhouse.com/enquiry/careers',
        title: 'Join a Team of Enthusiastic Educators | Little Skool-House',
        description: `Children are amazing to work with. Do you want to be part of a dedicated team of early childhood educators? Check out some of our current job openings here.`
      }}
    >
      <div className="careers-container">
        <div className="banner-video">
          <YoutubeVideo
            title="LSH Kids"
            youtubeVideoId="aQL61A_7oLw"
            fullscreen={false}
            autoPlay={0}
          />
        </div>
        <p>
          Are you bubbly, energetic and passionate about doing extra little bits
          for our little ones, putting every effort towards providing them with
          high quality care and attention to nourish their little minds, so that
          they may make a big difference to our world someday?
        </p>
        <br />
        <h4>If that sounds like you,</h4>
        <h1>We want you!</h1>
        <br />
        <p>
          Here at The Little Skool-House, we are not only extra devoted to the
          little ones but are also committed to making a difference in your
          career.
        </p>
        <br />
        <p>
          From arranging a buddy to help you assimilate better at work, to
          opportunities that will fast-track your career growth, start your
          career with us.
        </p>
        <br />
        <h4>Here's why you should join us:</h4>
        <br />
        <div className="benefit-box">
          <img src={CareersFamily} alt="careers-family" />

          <div className="benefit-content">
            <h2>WE ARE MORE THAN JUST COLLEAGUES!</h2>
            <p>
              It's all about support and friendship here at The Little
              Skool-House! We are an organisation that cares. Experience the
              close-knitted family culture that we have at The Little
              Skool-House where your views are always heard.
            </p>
          </div>
        </div>

        <div className="benefit-box reverse">
          <img src={CareersBuddy} alt="careers-buddy" />

          <div className="benefit-content">
            <h2>PAIR UP, LEVEL UP!</h2>
            <p>
              Find a mentor with our buddy system in place. Learn from
              experienced peers to not only manage challenges, but also
              assimilate better at work!
            </p>
          </div>
        </div>

        <div className="benefit-box">
          <img src={CareersAchieve} alt="careers-achieve" />

          <div className="benefit-content">
            <h2>FAST-TRACK YOUR CAREER GROWTH!</h2>
            <p>
              At The Little Skool-House, we provide you with plenty of career
              advancement opportunities for your growth and development in the
              industry. And with an open culture, our management team adds to
              the personal touch when they spend time with all employees through
              planned activities. With the support to help you succeed, you can
              possibly reach leadership position in 5 years.
            </p>
          </div>
        </div>
        <br />
        <h4>CURRENT OPENINGS</h4>
        <br />
        <p>Click on the job openings below to find out more!</p>
        <div className="jobs-wrapper">
          <JobButton
            link="https://careers.ntucfirstcampus.com/cw/en/job/496365/english-childcare-teacher-islandwide-little-skool-house"
            text="English Childcare Teacher"
          />
          <JobButton
            link="https://careers.ntucfirstcampus.com/cw/en/job/496366/chinese-childcare-teacher-islandwide-little-skool-house"
            text="Chinese Childcare Teacher"
          />
          <JobButton
            link="https://careers.ntucfirstcampus.com/cw/en/job/496367/infant-care-teacher-islandwide-little-skool-house"
            text="Infant Care Teacher"
          />
          <JobButton
            link="https://careers.ntucfirstcampus.com/cw/en/job/496368/assistant-childcare-teacher-islandwide-little-skool-house"
            text="Assistant Childcare Teacher"
          />
        </div>
        <br />
        <p>
          Alternatively, you can drop us an email at{' '}
          <a href="mailto:recruitment-lsh@littleskoolhouse.com" target="_blank">
            recruitment-lsh@littleskoolhouse.com
          </a>
        </p>
        <br />
      </div>
    </Layout>
  );
};

export default Careers;
