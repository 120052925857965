import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './OurDifferenceTilesSlider.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const tiles = [1, 2, 3, 4, 5, 6].map(tile => (
  <div className="tile" key={tile}>
    <div className={`tile-content tile-${tile}`}></div>
  </div>
));

const OurDifferenceTilesSlider = ({ slide }) => {
  let bannerSlider = null;
  const [modal, setModal] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(null);
  const [sliderTrigger, setsliderTrigger] = useState(null);

  useEffect(() => {
    currentSlide && bannerSlider.slickGoTo(currentSlide - 1);
  }, [currentSlide, sliderTrigger]);

  const temps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(tile => (
    <div className="tile" key={tile}>
      <div
        onClick={() => {
          setModal(true);
          setCurrentSlide(tile);
          setsliderTrigger(!sliderTrigger);
        }}
        className={`tile-content temp-${tile}`}
        style={{ cursor: 'pointer' }}
      ></div>
    </div>
  ));

  const SliderPhotos = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true
    };

    return (
      <Slider ref={c => (bannerSlider = c)} {...settings}>
        {[...Array(12).keys()].map(i => (
          <div className="img-holder" key={i + 1}>
            <img
              src={`../../../happenings-photos/event-details-photos/The-Little-Skool-House-25th-Anniversary_${i +
                1}.jpg`}
              alt={`The-Little-Skool-House-25th-Anniversary_${i + 1}`}
            />
          </div>
        ))}
      </Slider>
    );
  };

  function click(e) {
    e.stopPropagation();
  }

  return (
    <div className="our-diff-tiles-slider-container">
      {modal && (
        <div className="tile-modal" onClick={() => setModal(null)}>
          <div className="tile-modal-container" onClick={e => click(e)}>
            <div
              className="close-btn"
              role="button"
              onClick={() => setModal(null)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="slider-div">
              <SliderPhotos />
            </div>
          </div>
        </div>
      )}
      {slide ? (
        <div className="tiles tablet-and-desktop-only">{temps}</div>
      ) : (
        <div className="tiles tablet-and-desktop-only">{tiles}</div>
      )}

      <div className="clearfix"></div>
      <Slider
        className="mobile-slider mobile-only-strict"
        dots={true}
        arrows={false}
      >
        {slide ? temps : tiles}
      </Slider>
    </div>
  );
};

export default OurDifferenceTilesSlider;
