import React, { useState, useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Helicopter from '../../../../images/objects/helicopter.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  getWithExpiry,
  setWithExpiry
} from '../../../../services/commonService';
import './Announcement.scss';
import { ANNOUNCEMENT_CLOSE_EXPIRY_TIME } from '../../../../config/appConfig';

const Announcement = ({ promoActive, promoAmount, handleClick, language, skinnyBannerData }) => {
  const bannerContent = skinnyBannerData.content;
  const [show, setShow] = useState(false);
 
  let scheduleVisit = '';
  if(language == 'english'){
    scheduleVisit = promoActive === 'on' ? 'enquiry/limited-time-promotion' : 'enquiry/schedule-a-visit';
  } else {
    scheduleVisit = promoActive === 'on' ? 'zh/xunwen/xianshicuxiao' : 'zh/xunwen/yuyuecanguan';
  }
  
  useEffect(() => {
    if (!getWithExpiry('IS_ANNOUNCEMENT_CLOSED')) {
      setShow(true);
    }
  }, []);

  const handleShow = val => {
    if (val === false) {
      setWithExpiry(
        'IS_ANNOUNCEMENT_CLOSED',
        true,
        ANNOUNCEMENT_CLOSE_EXPIRY_TIME
      );
      setShow(false);
      handleClick();
    }
  };

  return (
    show && (
      <div className="announcement-container">
        <div className="announcement-wrapper">
          <Link
            to={scheduleVisit}
            id="header-banner"
          >
            <span className="text">
              {bannerContent ? bannerContent
              : '93% of our students are Highly Proficient in English and Mandarin. Schedule a visit today to learn more.'}
              <img src={Helicopter} alt="Toy Helicopter" />
            </span>
          </Link>
          <img src={Helicopter} className="heli" alt="Toy Helicopter" />
          <div className="close-btn-holder" onClick={() => handleShow(false)}>
            <span>{language == 'english' ? 'Close' : '关闭'}</span>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      </div>
    )
  );
};

export default Announcement;
