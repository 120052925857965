import React from 'react';
import { Helmet } from 'react-helmet-async';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Link } from 'gatsby';
import Layout from '../../layout/layout';
import MothersDayLandingForm from '../../components/common/ScheduleVisitForm/MothersDayLandingForm';
import '../../templates/schedule-a-visit/schedule-a-visit.scss';
import { MlCentres20may2, RECAPTCHA_KEY } from '../../config/appConfig';
import MdLandingImg from '../../images/mothers-day-celebration-2.png';

const MothersDayEvent = () => {
  return (
    <Layout
      bannerClass="schedule"
      heading="Events"
      pageLn="en"
      breadCrumbs={[{ label: 'Events' }]}
      noBanner={true}
      metaData={{
        url: 'https://www.littleskoolhouse.com/mothers-day-event-20-may-2/',
        title: 'Mother’s Day Celebration | The Little Skool-House',
        description:
          'Visit one of our Little Skool-House centres to see how 93% of our graduates are Highly Proficient in Both English and Mandarin. Schedule a visit today.'
      }}
    >
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="schedule-container">
        <div>
          <img src={MdLandingImg} className="md-landing-img" />
        </div>
        <div className="md-landing-desc">
          <div className="md-landing-desc-child">
            ❤️ Create unforgettable memories with your little ones through our specially curated parent-child activities, including storytelling, sensory play experiences, and more.</div>
          <div className="md-landing-desc-child">
            ✨  Take home a sustainable 'Pot of Love' craft as a keepsake of your time with us.
          </div>
          <div className="md-landing-desc-child">
            ⚽️ Explore our engaging Science, Music & Sports activities in partnership with our learning partners!
          </div>
        </div>
        <div className="md-landing-list-ul">
          <div className="md-landing-list-desc">
            RSVP for your preferred centre:
          </div>
          <ul>
            <li>At-KK Women’s And Children’s Hospital (Featuring “carnival style” obstacle course for you and your little ones)</li>
            <li>At-Ministry-Of-Education (MOE HQ. Featuring interactive English and Mandarin storytelling sessions)</li>
            <li>At-OCBC-Tampines (Featuring talk by Nestle at 10.30am on “Nutrition tips for your preschooler")</li>
            <li>At-Outram (SGH. Featuring creative hand painting which you can even “twin” with your child)</li>
            <li>At-Republic-Polytechnic (Featuring sports stations to hone shooting and scoring skills)</li>
            <li>At-Temasek-Polytechnic (Featuring creative art experience for you and your little ones)</li>
            <li>At-Thomson (MSF Building. Featuring interactive storytelling sessions)</li>
            <li>By-The-Vista (Ulu Pandan CC. Featuring a Music and Movement experience for you and your little ones)</li>
            <li>On-The-Green (Orchid Country Club. Featuring sports stations to hone shooting and scoring skills and bring home creative “balloon sculptures”)</li>
          </ul>
        </div>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
          <MothersDayLandingForm
            type="mothers-day-20-2"
            eventType="event_may_20_2"
            centerList={MlCentres20may2}
          />
        </GoogleReCaptchaProvider>
      </div>
    </Layout>
  );
};

export default MothersDayEvent;
