import React from 'react';
import PropTypes from 'prop-types';
import { JsonLd } from 'gatsby-plugin-next-seo';

const WebSite = ({ name, url }) => {
  return (
    <JsonLd
      json={{
        '@context': 'https://schema.org/',
        '@type': 'WebSite',
        name,
        url
      }}
    />
  );
};

WebSite.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

WebSite.defaultProps = {};

export default WebSite;
