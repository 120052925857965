import React from "react";
import "./our-award-winning-educators.scss";
import Layout from "../../layout/layout";
import ChineseLetter from "../../images/objects/our-educators/chinese-letter.png";
import Teacher1 from "../../images/objects/our-educators/teacher-1.jpg";
import Teacher2 from "../../images/objects/our-educators/teacher-2.jpg";
import Bottom1 from "../../images/objects/our-educators/bottom-1.png";
import Bottom2 from "../../images/objects/our-educators/bottom-2.png";
import Bottom3 from "../../images/objects/our-educators/bottom-3.png";
import Bottom4 from "../../images/objects/our-educators/bottom-4.png";
import LeftQuote from "../../images/objects/left-quote.png";
import RightQuote from "../../images/objects/right-quote.png";
import MOE from "../../images/logos/MOE.png";
import ECDA from "../../images/logos/ECDA.png";
import Middle1 from "../../images/objects/our-educators/middle-1.png";
import Middle2 from "../../images/objects/our-educators/middle-2.png";
import Middle3 from "../../images/objects/our-educators/middle-3.png";

const bottomShowcase = [
  {
    icon: Bottom1,
    label: (
      <>
        课程开发、
        <br />
        审查和更新
      </>
    ),
  },
  {
    icon: Bottom2,
    label: (
      <>
        质量保证与发展
      </>
    ),
  },
  {
    icon: Bottom3,
    label: (
      <>
        培训、
        <br />
        专业发展与支持
      </>
    ),
  },
  {
    icon: Bottom4,
    label: (
      <>
        儿童学习评估与
        <br />
        支持计划
      </>
    ),
  },
];

const OurEducatorsCn = () => {
  return (
    <Layout
      bannerClass="our-difference"
      heading="我们的获奖教师"
      breadCrumbs={[{ label: "我们的与众不同" }, { label: "我们的获奖教师" }]}
      metaData={{
        url:
          "https://www.littleskoolhouse.com/zh/womendeyuzhongbutong/womendehuojiangjiaoshi",
        title: "我们获奖无数的教育者 | 小小学庭",
        description:
          "小小学庭里的教育者都是教育领域里的专业人士。参观我们其中一所中心以了解更多。",
      }}
    >
      <div className="our-educators-container">
        <div className="curriculum-details-container">
          <div className="center-section">
            <h3>自<span className="lsh-cn-font">2012</span>年开始，小小学庭的教师多次获奖，我们为此感到自豪。</h3>
          </div>
        </div>
        <div className="ministry-section">
          <div className="display-section top">
            <div className="item">
              <img src={MOE} alt="MOE" />
              <div className="text">
                <h2>教育部杰出学前母语教师奖</h2>
              </div>
            </div>
            <div className="line tablet-and-desktop-only" />
            <div className="item">
              <img src={ECDA} alt="MOE" />
              <div className="text">
                <h2>幼儿培育署杰出学前教师奖</h2>
              </div>
            </div>
          </div>
          <h4>我们的教师享有独特的专业发展计画包括</h4>
          <div className="display-section">
            <div className="item">
              <img src={Middle1} alt="Middle 1" />
              <div className="text">
                <p>
                  深入的教学法和课程训练，以及一对一的专业教学指导，以推动教学方法的掌握。
                </p>
              </div>
            </div>
            <div className="line tablet-and-desktop-only" />
            <div className="item">
              <img src={Middle2} alt="Middle 2" />
              <div className="text">
                <p>
                  由新加坡专业剧团（Singapore Reparatory
                  Theatre，简称SRT）及来自台湾陈富仁教授的指导，额外进行戏剧教学法的培训。
                </p>
              </div>
            </div>
            <div className="line tablet-and-desktop-only" />
            <div className="item">
              <img src={Middle3} alt="Middle 3" />
              <div className="text">
                <p>
                  组织、邀请我们的教师前往芬兰、中国等地进行交流学习，体验不同的教学方法和活动实践。
                </p>
              </div>
            </div>
          </div>
        </div>
        <h2 className="center-text">幼儿早期教育的引领着</h2>
        <div className="little-skool-house-container">
          <div className="left-section">
            <img src={Teacher1} draggable={false} alt="Dr Geraldine" />
          </div>
          <div className="right-section">
            <p className="intro">
              小小学庭的课程是由幼儿早期教育领域的引领者专门设计的。
              <br />
              <img src={LeftQuote} alt="Left quote" className="quote" />
            </p>
            <p className="text">
              我们的课程是由幼儿培育署的研究员设计的，具有很强的研究重点，反映了早期幼儿领域的最新发现，特别我们的关系为本课程。该课程注重培养孩子的信任感与安全感，提出以建立信心并推动我们的幼儿学习。此课程乃基于Ebbeck教授（南澳大学幼儿教育榮休教授）的研究。
            </p>
            <div className="right-quote">
              <img src={RightQuote} alt="Right quote" />
            </div>
          </div>
        </div>
        <div className="little-skool-house-container">
          <div className="left-section mobile-only-strict">
            <img src={Teacher2} draggable={false} alt="Dr Connie" />
          </div>
          <div className="right-section">
            <p className="intro">
              我们的母语教学组由林美莲博士（西澳大学教育博士）带领。
              <br />
              <img src={LeftQuote} alt="Left quote" className="quote" />
            </p>
            <p className="text">
              林美莲博士是李光耀基金双语提案评审委员会的委员及新加坡推广华文学习委员会学前华文教育支援组的主要委员。在幼儿教育领域有20多年的经验，是知名的教育家，享誉世界。林博士撰写语文教学相关的书籍超过30本，曾多次在中国幼儿教育学术研讨会、香港大学学术研讨会，以及世界幼儿教育会议等研讨会议上发表演讲。
            </p>
            <div className="right-quote">
              <img src={RightQuote} alt="Right quote" />
            </div>
          </div>
          <div className="left-section tablet-and-desktop-only">
            <img src={Teacher2} draggable={false} alt="Dr Connie" />
          </div>
        </div>
        <div className="bottom-section">
          <h4>
            我们拥有强大的教育学家团队，监督指导小小学庭的英语和华语课程，以及我们的教师在四大关键领域的专业发展。
          </h4>
          <div className="showcase">
            {bottomShowcase.map((item, i) => (
              <div className="item" key={i}>
                <img src={item.icon} alt={item.label} />
                <p className="label">{item.label}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="learning-environment-container">
          <div className="top-section">
            <div className="img-section">
              <img src={ChineseLetter} alt="A" />
            </div>
            <div className="text">
              <h3>
                我们拥有专业的课程团队为后盾，师资队伍稳定且高水平。专业知识的⽀持下，小小学庭提供的课程，可以推动幼儿更好的建构知识一直到⼩学。
              </h3>
              <p>事实上，我们93%的学⽣都精通英语和华语。</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OurEducatorsCn;
