import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Tile from './Tile';
import './HomeTiles.scss';
import { mandarinAcquisitionPageLink, mandarinAcquisitionPageLinkCn } from '../../../config/appConfig';
import { getLanguage } from '../../common/utils/utils';

const tileDetails = [
  {
    heading: (
      <>
        <h3 className="huge">93%</h3>
        <h2>HIGHLY PROFICIENT IN ENGLISH AND MANDARIN</h2>
      </>
    ),
    content: (
      <>
        <p>
          A Nanyang Technological University study shows that 93% of Little
          Skool-House children are Highly Proficient in both English and
          Mandarin.
        </p>
        <div className="btn-section">
          <Link to={mandarinAcquisitionPageLink}>
            <button className="white-funky-button">Read more</button>
          </Link>
        </div>
      </>
    )
  },
  {
    heading: (
      <>
        <h3>AWARD-WINNING TEACHERS</h3>
        <h2>BY MINISTRY OF EDUCATION AND ECDA</h2>
      </>
    ),
    content: (
      <>
        <p>
          Recognised by Ministry of Education and the Early Childhood
          Development Agency for teaching excellence for 9 consecutive years.
        </p>
        <div className="btn-section">
          <Link to="/our-difference/our-award-winning-educators">
            <button className="white-funky-button">Read more</button>
          </Link>
        </div>
      </>
    )
  },
  {
    heading: (
      <>
        <h3>CHOSEN PRESCHOOL</h3>
        <h2>by SINGAPORE’S MOST PRESTIGIOUS INSTITUTIONS</h2>
      </>
    ),
    content: (
      <>
        <p>
          The Little Skool-House is the chosen preschool for the children at the
          Ministry of Education, Ministry of Social and Family Development, and
          many others.
        </p>
        <div className="btn-section">
          <Link to="/our-difference/chosen-preschool-by-top-institutions">
            <button className="white-funky-button">Read more</button>
          </Link>
        </div>
      </>
    )
  },
  {
    heading: (
      <>
        <h3>READY FOR PRIMARY SCHOOL</h3>
        <h2>READY FOR LIFE</h2>
      </>
    ),
    content: (
      <>
        <p>
          Our Relationships-Based Curriculum focuses on building a child’s
          confidence. Our Literacy-Based Curriculum enables success in school
          and life.
        </p>
        <div className="btn-section">
          <Link to="/our-curriculum/curriculum-overview">
            <button className="white-funky-button">Read more</button>
          </Link>
        </div>
      </>
    )
  }
];


const tileDetailsCn = [
  {
    heading: (
      <>
        <h3 className="huge">93%</h3>
        <div style={{ fontSize: '28px' }}>精通英语和华语</div>
      </>
    ),
    content: (
      <>
        <p>
          根据一项南洋理工大学的研究显示，93％小小学庭的孩子精通英语和华语两种语言
        </p>
        <div className="btn-section-cn">
          <Link to={mandarinAcquisitionPageLinkCn}>
            <button className="white-funky-button">阅读更多</button>
          </Link>
        </div>
      </>
    )
  },
  {
    heading: (
      <>
        <h3>荣获教育部及幼儿培育署奖项肯定的教师</h3>
        {/* <h2>BY MINISTRY OF EDUCATION AND ECDA</h2> */}
      </>
    ),
    content: (
      <>
        <p>
          我们卓越的教学连续八年获得教育部及幼儿培育署的肯定
        </p>
        <div className="btn-section-cn">
          <Link to="/zh/womendeyuzhongbutong/womendehuojiangjiaoshi">
            <button className="white-funky-button">阅读更多</button>
          </Link>
        </div>
      </>
    )
  },
  {
    heading: (
      <>
        <h3>新加坡最负盛名机构的首选幼儿园</h3>
        {/* <h2>by SINGAPORE’S MOST PRESTIGIOUS INSTITUTIONS</h2> */}
      </>
    ),
    content: (
      <>
        <p>
          小小学庭是教育部、社会及家庭发展部，以及其他机构选择的幼儿园
        </p>
        <div className="btn-section-cn">
          <Link to="/zh/womendeyuzhongbutong/dingjianjigouxuanzedeyoueryuan">
            <button className="white-funky-button">阅读更多</button>
          </Link>
        </div>
      </>
    )
  },
  {
    heading: (
      <>
        <h3>为小学和生活做准备</h3>
        {/* <h2>READY FOR LIFE</h2> */}
      </>
    ),
    content: (
      <>
        <p>
          我们以关系为本的课程重点是建立孩子的信心；文学化课程促进孩子在学校和生活中取得成功
        </p>
        <div className="btn-section-cn">
          <Link to="/zh/womendekecheng/kechengkuangjiagaishu">
            <button className="white-funky-button">阅读更多</button>
          </Link>
        </div>
      </>
    )
  }
];

const tilesMatrix = {
  desktop: [
    [tileDetails[0], tileDetails[1], null],
    [null, null, tileDetails[2]],
    [tileDetails[3], null, null]
  ],
  mobile: [
    [tileDetails[0], tileDetails[1]],
    [tileDetails[2], null],
    [null, tileDetails[3]]
  ]
};

const tilesMatrixCn = {
  desktop: [
    [tileDetailsCn[0], tileDetailsCn[1], null],
    [null, null, tileDetailsCn[2]],
    [tileDetailsCn[3], null, null]
  ],
  mobile: [
    [tileDetailsCn[0], tileDetailsCn[1]],
    [tileDetailsCn[2], null],
    [null, tileDetailsCn[3]]
  ]
};

const HomeTiles = ({ mobile }) => {
  const [matrixItems, setMatrixItems] = useState([]);
  useEffect(() => {
    const lang = getLanguage();
    let arr = [];
    if(lang == "english"){
      arr = tilesMatrix[mobile ? 'mobile' : 'desktop'];
    }
    else{
      arr = tilesMatrixCn[mobile ? 'mobile' : 'desktop'];
    }
    setMatrixItems(arr);
  }, []);

  return (
    <div className="home-tiles-container">
      <div className={`tiles-wrapper-${mobile ? 'mobile' : 'desktop'}`}>
        {matrixItems?.map?.((row, i) =>
          row.map((col, j) => (
            <Tile
              data={col}
              tileClass={`tile-${i + 1}-${j + 1}`}
              key={i + '' + j}
            />
          ))
        )}
      </div>
      <div className="clearfix" />
    </div>
  );
};

export default HomeTiles;
