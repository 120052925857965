import React, { useState } from 'react';
import Layout from '../../layout/layout';
import { graphql, useStaticQuery } from 'gatsby';
import './curriculum-details.scss';
import WheelNav from '../../components/common/WheelNav/WheelNav';
import Kids from '../../images/objects/curriculum-details/teacher-with-kids.jpg';
import Kids2 from '../../images/objects/our-educators/kid-4.jpg';
import LeftQuote from '../../images/objects/left-quote.png';
import RightQuote from '../../images/objects/right-quote.png';
import { Link } from 'gatsby';
import ScheduleVisitForm from '../../components/common/ScheduleVisitForm/ScheduleVisitForm';
import { RECAPTCHA_KEY } from '../../config/appConfig';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PromoSection from '../../components/common/PromoSection/promoSection';

import Graphic1 from '../../images/objects/curriculum-details/graphic-1.png';
import Graphic2 from '../../images/objects/curriculum-details/graphic-2.png';
import Graphic3 from '../../images/objects/curriculum-details/graphic-3.png';
import Graphic4 from '../../images/objects/curriculum-details/graphic-4.png';
import Graphic8 from '../../images/objects/curriculum-details/graphic-8.png';
import { mandarinAcquisitionPageLink } from '../../config/appConfig';

const wheelOptions = [
  [
    `Visiting a Primary School`,
    `Experiencing Classroom Learning`,
    `Buying Food during Lunch & Tea Break`
  ],
  [
    `Asking for Help`,
    `Making New Friends`,
    `Asking Questions to Clarify Learning`
  ],
  [
    `Advanced Math`,
    `Advanced Science`,
    `Social & Community Knowledge`,
    `Managing Money`,
    `Telling Time`
  ],
  [
    `Concept of Timetabling`,
    `Packing of Personal Belongings`,
    `Getting Food At the Canteen`,
    `Preparing Your Child and Yourself Emotionally`
  ]
];

const sections = [
  {
    text: {
      heading: `Holistic Development`,
      content: (
        <p>
          To enable a child to be ready for primary school, and ready for life,
           we believe in providing a strong academic foundation in kindergarten,
          as well as confident social and emotional development.
           Our kindergarten in Singapore offers integrated learning across six 
           subject domains: Language & Literacy, Maths,
          Science, Creativity, Physical Self and Social Development.
        </p>
      )
    },
    graphic: Graphic1
  },
  {
    text: {
      heading: `English and Chinese Proficiency`,
      content: (
        <>
          <p>
          The Little Skool-House curriculum addresses the holistic developmental needs of children in kindergarten in Singapore.
            One of our focus elements is to enable a child to be{' '}
            <a
              href="/our-difference/high-proficiency-in-english-and-mandarin"
              className="text-link"
            >
              highly proficient in both English and Mandarin
            </a>
             .{' '}Languages are made fun at the Little Skool-House by sparking genuine interest through books, play,
            specialised learning and application,
             leading to 93% of our children achieving High Proficiency
             in both English and Mandarin.
          </p>
          <h3>
            The Little Skool-House Pedagogy for Enhanced Mandarin Acquisition{' '}
          </h3>
          <p>
            Our{' '}
            <a
              href="/our-difference/our-award-winning-educators"
              target="blank"
              style={{ color: '#4ec7e2', textDecoration: 'underline' }}
            >
              highly trained educators
            </a>{' '}
            are well versed in the methods and strategies of implementing
            Language and Literacy activities for preschoolers. Understand our
            unique approach to enable strong Mandarin Acquisition.
          </p>
        </>
      ),
      link: { url: mandarinAcquisitionPageLink }
    },
    graphic: Graphic2
  },
  {
    text: {
      heading: `Builders and Innovation Project `,
      content: (
        <p>
        Our kindergarten programme instills critical thinking
        and problem-solving skills in Science and Math learning.
        Together with the Literacy skills acquired through the
        various fun activities, our curriculum builds confidence
        in language skills as children discuss, investigate,
        research, and report on various topics. Our kindergarten
        students are given the freedom to inquire about subject
        matters that intrigue them, such as ‘How the digestive system
        works’ and conduct an in-depth analysis of their queries with
        their classmates.
        </p>
      )
    },
    graphic: Graphic3
  },
  {
    text: {
      heading: `Pedagogy for Enhanced Mandarin Acquisition`,
      content: (
        <p>
          This is the key driver behind our bilingual
          kindergarten programme and preschoolers’ High Proficiency
          in Mandarin. The pedagogy is built on three pillars: solid
          language foundation (for example vocabulary, grammar, sentence
          structure), strong teacher training and mastery, and pedagogy
          for daily usage by the children to convey their own complex ideas.
        </p>
      ),
      link: { url: mandarinAcquisitionPageLink }
    },
    graphic: Graphic2
  },
  {
    text: {
      heading: `Structured Reading and Literacy Assessments`,
      content: (
        <p>
          This kindergarten programme drives independent
          reading in line with the child's pace and capabilities.
          The child is first assessed individually on their Language
          and Literacy skills. The programme will then be adjusted
          accordingly to maximise the potential of our kindergarten children.
        </p>
      )
    },
    graphic: Graphic4
  },
  {
    text: {
      heading: `Primary School Preparedness`,
      content: (
        <p>
          As the leading kindergarten in Singapore,
          The Little Skool-House plan for our 4-6-year olds to
          thrive beyond kindergarten. They learn language, numeracy
          and literacy through various lessons and activities focusing
          on academic preparedness, confident bilingual communication,
          behavioural maturity, and developing strong social and emotional
          skills necessary to transition to primary school.
        </p>
      )
    },
    graphic: Graphic8
  }
];

export const EPSection = ({
  section: {
    text: { heading, content, link },
    graphic,
    left
  },
  right = false
}) => (
  <div className={`top-section ${right ? 'top-section-right' : ''} ${left ? 'justify-content-start' : ''}`}>
    <div className={`img-section${right ? '-mobile' : ''}`}>
      <img src={graphic} alt={heading} />
    </div>
    <div className="text">
      <h3>{heading}</h3>
      {content}
      {link && (
        <Link to={link.url}>
          <button className="yellow-funky-button">
            {link.label || 'Find out more'}
          </button>
        </Link>
      )}
    </div>
    {right && (
      <div className="img-section">
        <img src={graphic} alt={heading} />
      </div>
    )}
  </div>
);

const LiteracyBased = () => {
  const [wheelPosition, setWheelPosition] = useState(1);

  const data = useStaticQuery(graphql`
  query {
    cmsData: cms {
      pageBy(uri: "limited-time-promotion") {
        open_house {
          openhousestatus
          privacyPolicy
          promotionPrice
          promotionTitle
          subTitle
          title
          centres {
            centreName
            centreNameEn
            isParticipating
            location
          }
        }
      }
      pages {
        nodes {
          subheaders_name {
            centresHeader
            curriculumHeaders
          }
        }
      }
    }
  }
`);
const { centres } = data.cmsData.pageBy.open_house;
let north = centres.filter(item => item.location === 'North');
north.unshift({ location: 'North' });
let central = centres.filter(item => item.location === 'Central');
central.unshift({ location: 'Central' });
let east = centres.filter(item => item.location === 'East');
east.unshift({ location: 'East' });
let west = centres.filter(item => item.location === 'West');
west.unshift({ location: 'West' });

const centersList = north.concat(central, east, west);
const subheading = data && data.cmsData && data.cmsData.pages.nodes.length > 0 && data.cmsData.pages.nodes[0].subheaders_name;

  return (
    <Layout
      bannerClass="curriculum-details"
      heading="Our Curriculum"
      breadCrumbs={[
        { label: 'Our Curriculum' },
        { label: '4-6 years Literacy-Based Curriculum' }
      ]}
      metaData={{
        url:
          'https://www.littleskoolhouse.com/our-curriculum/4-6-years-literacy-based-curriculum',
        title:
          'Top Kindergarten in Singapore | Little Skool-House',
        description:
         'As the top kindergarten in Singapore, Little Skool-House offers quality education for kids. Enrol your child and let them experience fun-filled learning today.'
      }}
    >
      <div className="curriculum-details-container">
        <h2>
          4-6 years
          <br />
          Literacy-Based Curriculum
        </h2>
      </div>

      <div className='lsh-banner-sub-text-wrap'>
         <p className="lsh-banner-sub-text lsh-centers-pad"> 
         <div dangerouslySetInnerHTML={{ __html: subheading.curriculumHeaders }}/>
         </p>
      </div>

      <div className="little-skool-house-container">
        <div className="left-section">
          <img src={Kids} draggable={false} alt="LSH Kids" />
        </div>
        <div className="right-section">
          <p className="intro">
            Our 4-6 year olds are focused on preparing for Primary School
            <img src={LeftQuote} alt="Left quote" className="quote" />
          </p>
          <p className="text">
            We focus on areas of Academic Preparedness, Confident Bilingual
            Communication, Behaviour Maturity and eventually exposure to Big
            School Environment.
          </p>
          <div className="right-quote">
            <img src={RightQuote} alt="Right quote" />
          </div>
        </div>
      </div>
      <div className="curriculum-details-master-container">
        <div className="learning-environment-container">
          {sections.map(
            (section, i) =>
              i < 2 && (
                <EPSection section={section} right={i % 2 !== 0} key={i} />
              )
          )}
        </div>

        <div className="curriculum-details-container">
          <h1>
          Kindergarten, a stepping stone to Primary School
          </h1>
          <p className='page-text mt-20-bottom text-center'>
          At Little Skool-House, our kindergarten programmes provide a strong curriculum and experiential learning opportunities to prepare children to transition to primary school. Our children are independent and confident when they graduate and enter primary school.
          </p>
          <h3>Primary School Exposure and Preparedness</h3>
          <div className="wheel-nav-section">
            <WheelNav sections={4} onChange={setWheelPosition} />
            <div className="wheelnav-description">
              {wheelOptions[wheelPosition - 1].map((text, i) => (
                <div key={i}>
                  <div className="line-number">
                    <span>{i + 1}</span>
                  </div>
                  <div className="text">{text}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="little-skool-house-container">
          <div className="right-section">
            <p className="intro">
              Primary School Formation Programme
              <img src={LeftQuote} alt="Left quote" className="quote" />
            </p>
            <p className="text">
              A hallmark in our academic foundation is to cement the child’s
              learning in maths, sciences, and their ability to communicate
              through the following Primary School Formation Programme.
            </p>
            <div className="right-quote">
              <img src={RightQuote} alt="Right quote" />
            </div>
          </div>
          <div className="left-section">
            <img src={Kids2} draggable={false} alt="LSH Kids" />
          </div>
        </div>

        <div className="learning-environment-container">
          {sections.map(
            (section, i) =>
              i > 1 && (
                <EPSection section={section} right={i % 2 !== 0} key={i} />
              )
          )}
        </div>
        {/* <div className="promo-wrap">
          <PromoSection />
        <div className="promo-right-section">
        <h2>SCHEDULE A ONE-TO-ONE CENTRE VISIT TODAY</h2>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
              <ScheduleVisitForm type="open-house" centerList={centersList} />
        </GoogleReCaptchaProvider>
        </div>
        </div> */}
      </div>
    </Layout>
  );
};

export default LiteracyBased;
