import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import ImageSlider from '../OurDifferenceTilesSlider/ImageSlider';
import YoutubeVideo from '../YoutubeVideo/YoutubeVideo';
import './FlexibleContent.scss';
import Arrow from '../../../images/objects/open-house/arrow-new.png';
import Off from '../../../images/objects/Off.png';
import { Link } from 'gatsby';
import gfm from 'remark-gfm';

const PromoSection = ({ promoImg, altText, off, offerDescription,	description }) => (
  <div className="promo-section">
    <div className="promo-head">
      <div className="promo-column">
        <div>
          <p className="off-para">${off} {offerDescription}</p>
          <p>{description}</p>
        </div>
        <div className="promo-img-div">
          <div className="  promo-img-container ">
            <img src={promoImg} alt={altText} />
            {off && <span className="off">${off}</span>}
          </div>
          <img className="promo-arrow" src={Arrow} alt={altText} />
        </div>
      </div>
    </div>
  </div>
);

const FlexibleContent = ({ data, ctaBtnStyle }) => {
  const renederData = data.map((item, index) => {
    if (item && item.fieldGroupName) {
      if (item.fieldGroupName.includes('FlexibleContent_Paragraph')) {
        return (
          <div className="fx-content">
            <ReactMarkdown
              source={item.content}
              key={`FlexibleContent_Paragraph${index}`}
              plugins={[gfm]}
            />
          </div>
        );
      }
      if (item.fieldGroupName.includes('FlexibleContent_Gallery')) {
        return (
          <ImageSlider
            images={item.images}
            key={`FlexibleContent_Gallery${index}`}
          />
        );
      }
      if (item.fieldGroupName.includes('FlexibleContent_YtVideo')) {
        return (
          <div className="ytb-video" key={`FlexibleContent_YtVideo${index}`}>
            <YoutubeVideo
              title="Proficiency In English And Mandarin"
              youtubeVideoId={item.ytVideoLink.substr(32)}
              fullscreen={false}
              autoPlay={1}
            />
          </div>
        );
      }
      if (item.fieldGroupName.includes('FlexibleContent_FullWidthImage')) {
        return (
          <div
            key={`FlexibleContent_FullWidthImage${index}`}
            className="img-container"
          >
            <img src={item.image.sourceUrl} width="100%" />
          </div>
        );
      }
      if (item.fieldGroupName.includes('FlexibleContent_PromotionCard')) {
        return (
          <div className="promo-section-container">
            {item.promotionDetailCard.map(card => (
              <PromoSection
                promoImg={Off}
                altText="OFF"
                off={card.price}
                description={card.title}
                offerDescription={card.offerDescription}
              ></PromoSection>
            ))}
          </div>
        );
      }
      if (item.fieldGroupName.includes('FlexibleContent_CtaButton')) {
        return (
          <div className={`button-div ${ctaBtnStyle ? ctaBtnStyle : ''}`}>
            <Link
              to={new URL(item.ctaButton.link).pathname}
              className="btn-section"
            >
              <button className="yellow-funky-button">
                {item.ctaButton.label}
              </button>
            </Link>
          </div>
        );
      }
    }
  });

  return <div>{renederData}</div>;
};
export default FlexibleContent;
