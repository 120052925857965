import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { RECAPTCHA_KEY } from '../../config/appConfig';
import Layout from '../../layout/layout';
import ContactUsCNForm from '../../components/common/ContactUsForm/ContactUsCnForm';


const ContactUsCN = () => {
    return (<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
       <Layout
            bannerClass="about-us"
            heading="联络我们"
            breadCrumbs={[{ label: '询问' }, { label: '联络我们' }]}
            noBanner={true}
            metaData={{
                url: 'https://www.littleskoolhouse.com/zh/xunwen/lianluowomen',
                title: '联系我们 | 小小学庭',
                description:
                    '我们会尽我们最大的努力在两个工作日内回复您。若您有任何疑问或是想提出建议，请致电 +65 65097880 或电邮至 info@littleskoolhouse.com。'
            }}
        >  
        <ContactUsCNForm />
      </Layout>
    </GoogleReCaptchaProvider>)
  };
  
  export default ContactUsCN;
