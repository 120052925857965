import React, { useState } from 'react';
import { Link } from 'gatsby';
import FAQPage from '../../components/common/jsonld/FAQPage';
import Layout from '../../layout/layout';
import Accordion from '../../components/common/Accordion/Accordion';
import RadioButton from '../../components/common/RadioButton/RadioButton';
import Dropdown from '../../components/common/Dropdown/Dropdown';
import '../../pages/enquiry/faqs.scss';

const guardianFAQs = [
    {
        title: 'Where can I download the Guardian App?',
        content: (
            <>
                <p>
                    You can download the Guardian App from the following links:
                    <br />Apple App Store: <a href="https://bit.ly/LSHGuardianApp" target="_blank">https://bit.ly/LSHGuardianApp</a>
                    <br /> Android Play Store: <a href="https://bit.ly/LSHGuardianApp" target="_blank">https://bit.ly/LSHGuardianApp</a>
                    <br /> Huawei: <a href="https://bit.ly/LSHGuardianApp-Huawei" target="_blank">https://bit.ly/LSHGuardianApp-Huawei</a>
                </p>
            </>
        )
    },
    {
        title: 'What do I need to do for my guardian after they have downloaded the Guardian App?',
        content: (
            <>
                <p>
                    For existing authorised guardians, you will have to do a one-time reset of the password using the
                    Parent App. You may then proceed to share this password with the authorised guardians so that they
                    can log in to the Guardian App.
                </p>

                <p>
                    For new guardians, you will need to take the following steps to register them in the Parent
                    App:
                </p>
                <p>
                    1. Tap the ellipsis (...) on the menu bar at the bottom of the screen.
                    <br />
                    2. Select ‘Parents &amp; Guardians’.
                    <br />
                    3. Under the ‘Parents &amp; Guardians’ tab, scroll over ‘+’ at the bottom right corner of
                    the screen.
                    <br />
                    4. Please read the ‘Important Note’ before proceeding to click ‘I Understand’.
                    <br />
                    5. Fill in the guardian’s details and click on ‘Add Guardian’.
                    <br />
                    6. Upload a photo of the guardian by clicking on the ‘Upload Image’ button, click
                    ‘Next’ to proceed.
                    <br />
                    7. Fill in the relevant information for the guardian and click on ‘Next’.
                    <br />
                    8. Set up a login password for the guardian to log in and click on ‘Review’.
                    <br />
                    9. Review and confirm the guardian’s details. Click on ‘Submit’.
                    <br />
                    10. Please read the ‘Important Note’ before proceeding to click ‘I Understand’ to
                    proceed.
                    <br />
                    11. Once this is done, you should see a ‘Completion’ screen and you can click on
                    ‘Share Login Information’ to share the login details with the authorised guardian.
                    <br />
                </p>
            </>
        )
    },
    {
        title: 'I will need my relative/neighbour/friend to pick my child up just for one occasion, do I still need to add him/her in the Parent App?',
        content: (
            <>
                <p>
                    Yes, you will have to add the guardian even if it is a one-time arrangement. Please do
                    indicate the validity date when adding a new guardian in the Parent App.
                </p>
            </>
        )
    },

    {
        title: 'I am unable to view a guardian whom I have previously added in my Parent App. What should I do?',
        content: (
            <>
                <p>
                    Please re-register the guardian by following the steps below:
                </p>
                <p>
                    1. Tap the ellipsis (...) on the menu bar at the bottom of the screen.
                    <br />
                    2. Select ‘Parents &amp; Guardians’.
                    <br />
                    3. Under the ‘Parents &amp; Guardians’ tab, scroll over ‘+’ at the bottom right corner of
                    the screen.
                    <br />
                    4. Please read the ‘Important Note’ before proceeding to click ‘I Understand’.
                    <br />
                    5. Fill in the guardian’s details and click on ‘Add Guardian’.
                    <br />
                    6. Upload a photo of the guardian by clicking on the ‘Upload Image’ button, click
                    ‘Next’ to proceed.
                    <br />
                    7. Fill in the relevant information for the guardian and click on ‘Next’.
                    <br />
                    8. Set up a login password for the guardian to log in and click on ‘Review’.
                    <br />
                    9. Review and confirm the guardian’s details. Click on ‘Submit’.
                    <br />
                    10. Please read the ‘Important Note’ before proceeding to click ‘I Understand’ to
                    proceed.
                    <br />
                    11. Once this is done, you should see a ‘Completion’ screen and you can click on
                    ‘Share Login Information’ to share the login details with the authorised guardian.
                    <br />
                </p>
            </>
        )
    },

    {
        title: 'I do not need my child’s/children’s authorised guardian to check my child in and out of school anymore. What should I do?',
        content: (
            <>
                <p>
                    Parents can remove the authorised guardian in the Parent App by following the steps below:
                </p>
                <p>
                    1. Tap the ellipsis (...) on the menu bar at the bottom of the screen.
                    <br />
                    2. Select ‘Parents &amp; Guardians’ to view the registered guardians.
                    <br />
                    3. Click on the guardian to see the guardian’s profile.
                    <br />
                    4. Click on ‘Remove Guardian’.
                    <br />
                    5. The ‘Remove Guardian Account’ pop-up will appear.
                    <br />
                    6. Click on ‘Confirm’.
                    <br />
                    7. Once the process is complete, you should see a pop-up that reads ‘Guardian
                    Removed Successfully’.
                    <br />
                </p>
            </>
        )
    },

    {
        title: 'What is the login ID and password for my child’s/children’s authorised guardian?',
        content: (
            <>
                <p>
                    Authorised guardians can log in using their NRIC/FIN/Passport Number and the password
                    set by you in the Parent App. The password is case sensitive and has to consist of 6
                    characters:
                    <br />i.e.
                </p>
                <ul>
                    <li>letters or</li>
                    <li>numbers or</li>
                    <li>a combination of letters and numbers</li>
                </ul>
            </>
        )

    },

    {
        title: 'My child’s/children’s authorised guardian has forgotten his/her password. What should I do?',
        content: (
            <>
                <p>
                    Parents can reset the authorised guardian’s password by following the steps below:
                </p>
                <p>
                    1. Tap the ellipsis (...) on the menu bar at the bottom of the screen.
                    <br />
                    2. Select ‘Parents &amp; Guardians’ to view the registered guardians.
                    <br />
                    3. Click on the guardian to see the guardian’s profile.
                    <br />
                    4. Click on ‘Reset Password’.
                    <br />
                    5. There will be a ‘Reset Password’ pop-up.
                    <br />
                    6. Key in the new password and click on ‘Submit’.
                    <br />
                    7. Once the process is complete, you should see a screen that reads ‘Successful
                    Password Reset’.
                    <br />
                </p>
            </>
        )
    },

    {
        title: 'My child’s/children’s guardian is unable to log in with his/her NRIC/FIN/Passport Number. What should I do?',
        content: (
            <>
                <p>
                    Please check that the NRIC/FIN/Passport Number and password entered are correct. If
                    the guardian is still unable to log in, parents can reach out to our friendly agents via the live
                    chat in the Parent App here:
                </p>
                <p>
                    <img
                        src="https://cms-staging.littleskoolhouse.com/wp-content/uploads/2021/06/FAQ-Image.png"
                        width="250px"
                    />
                </p>
            </>
        )
    },

    {
        title: 'What is the next step after I add a new guardian?',
        content: (
            <>
                <p>
                    Please inform your child/children’s authorised guardian to bring his/her original NRIC/FIN/Passport
                    that you have used for registration for a one-time verification by our centre staff when they
                    check your child out of school.
                </p>
            </>
        )
    },

    {
        title: 'How long does it take for the centre to verify a new guardian once I add them via the Parent App?',
        content: (
            <>
                <p>
                    The authorised guardian's identity will be verified by our centre staff on their first visit to pick up and check out your child from school. The process should take less than three minutes if all the required information and documentation are available and matches the information submitted in the Parent App.
                </p>
            </>
        )
    },

    {
        title: 'Do I need to download the Guardian App on my authorised guardian’s smartphone to check my child in and out of school on Monday, 4 October 2021?',
        content: (
            <>
                <p>
                    Yes, having the Guardian App will enable authorised guardians to enjoy a more convenient and
                    seamless check in and check out process for your child/children.
                </p>
            </>
        )
    },

    {
        title: 'Besides using the Guardian App, are there any other options for authorised guardians to check my child/children in and out of school?',
        content: (
            <>
                <p>
                    Yes, authorised guardians can use the centre kiosk to check your child/children in and out of school. Authorised guardians can log in to the centre kiosk using their NRIC/FIN/Passport Number to complete the process.
                </p>
            </>
        )
    },

    {
        title: 'Can authorised guardians still use the centre kiosk to check my child/children in and out of school?',
        content: (
            <>
                <p>
                    Yes, authorised guardians can still use the centre kiosk to check your child/children in and out of school. Authorised guardians can log in to the centre kiosk using their NRIC/FIN/Passport Number to complete the process.
                </p>
            </>
        )
    },

    {
        title: 'Why do I need to switch to a new Guardian App?',
        content: (
            <>
                <p>
                    The new Guardian App is a dedicated mobile app developed to provide greater
                    convenience and security for your family.
                </p>
                <p>
                    Authorised guardians will be able to enjoy greater convenience as they can check your child/children in
                    and out of school seamlessly with this dedicated app. We have also launched a new feature
                    in the Parent App where you can include a guardian validity period for greater security. For
                    instance, you can set up a one-week validity period to authorise a guardian to pick your child
                    up during a specific week when you are away.
                </p>
            </>
        )
    },

];

const parentFAQs = [
    {
        title: 'What is my login ID to the Little Skool-House Parent App?',
        content: (
            <>
                <p>
                    It is your registered email address with The Little Skool-House.
                </p>
            </>
        )
    },
    {
        title: 'How do I verify/amend my registered email address?',
        content: (
            <>
                <p>Please get in touch with your centre’s Relations & Marketing Officer if you need to verify/amend your registered email address.</p>
            </>
        )
    },
    {
        title: 'What is my password?',
        content: (
            <>
                <p>
                    Please use this default password: Last 4 alphanumeric characters of parent’s NRIC and first 4 characters of parent’s full name in NRIC (lowercase).
                </p>
                <p>E.g. if NRIC is S1234567Z and name Tan Bee Ling, password is: 567ztanb</p>
            </>
        )
    },

    {
        title: 'I have forgotten my password / I need to update my password. How do I reset my password?',
        content: (
            <>
                <p>
                    Parents can tap on the Forgot Password tab in the Parent App and follow the steps on-screen to reset your password.
                </p>
            </>
        )
    },

    {
        title: 'How do I check my child in and out of school using the Little Skool-House Parent App?',
        content: (
            <>
                <p>
                    Once you have logged into your account, select your child’s profile that you would like to view, then tap the “Check-in”/“Check-out” button at the top of the page and scan the QR Code via the centre kiosk to enjoy a seamless process.
                </p>
            </>
        )
    },

    {
        title: 'I would like my sibling to pick my child up today, what should I do?',
        content: (
            <>
                <p>
                    You will have to add the guardian even if it is a one-time arrangement. Please do indicate the validity date when adding a new guardian in the Parent App.
                </p>
            </>
        )

    },

    {
        title: 'I need to remove my child’s/children’s authorised guardian from the list, how do I go about doing that?',
        content: (
            <>
                <p>
                    Parents can remove the authorised guardian in the Parent App by following the steps below:
                </p>
                <p>
                    1. Tap the ellipsis (...) on the menu bar at the bottom of the screen.
                    <br />
                    2. Select ‘Parents & Guardians’.
                    <br />
                    3. Click on the guardian to see the guardian’s profile.
                    <br />
                    4. Click on ‘Remove Guardian’.
                    <br />
                    5. The ‘Remove Guardian Account’ pop-up will appear.
                    <br />
                    6. Click on ‘Confirm’.
                    <br />
                    7. Once the process is complete, you should see a pop-up that reads ‘Guardian Removed Successfully’.
                    <br />
                </p>
            </>
        )
    },

    {
        title: 'My child’s/children’s authorised guardian has forgotten to bring his/her mobile phone. What can he/she do?',
        content: (
            <>
                <p>
                    Authorised guardians can log in to the centre kiosk using their own NRIC/FIN/Passport Number to check your child in and out of school.
                </p>
            </>
        )
    },

    {
        title: 'How do I view the details of the fees payment for my child?',
        content: (
            <>
                <p>
                    Please refer to the “Statement of Account” icon in the Little Skool-House Parent App where you will be able to view the invoice details for your child’s school fees. Fee payable would be the nett fee after any approved government subsidy.
                </p>
            </>
        )
    },

    {
        title: 'How do I know the mode of payment for the original deposit?',
        content: (
            <>
                <p>
                    You may refer to the receipt created upon payment of your child’s enrollment registration, in the Little Skool-House Parent App under the “Statement of Account” icon.
                </p>
            </>
        )
    },

    {
        title: 'How do I check on the outstanding amount of fee payable?',
        content: (
            <>
                <p>
                    You can check your outstanding balance in the Little Skool House-Parent App by following the steps below:
                </p>
                <p>
                    1. Tap the ellipsis (...) on the menu bar at the bottom of the screen.
                    <br />
                    2. Select ‘Statement of Account’ and “Outstanding” tab will appear
                    <br />
                    3. You should be able to see a list of outstanding balance, if any
                </p>
            </>
        )
    },

    {
        title: 'How do I check on the breakdown of approved subsidy/subsidies for my child/children? ',
        content: (
            <>
                <p>
                    If a subsidy/subsidies has/have been approved for your child/children, the amount will be reflected in the monthly school fees invoice within the Little Skool-House Parent App.
                </p>
            </>
        )
    },

    {
        title: 'How do I check my GIRO account details?',
        content: (
            <>
                <p>
                    Your GIRO account details can be found in the Little Skool-House Parent App under the “GIRO Payment” icon.
                </p>
            </>
        )
    },

    {
        title: 'How do I know if my GIRO application has been approved?',
        content: (
            <>
                <p>
                    You may refer to the Little Skool-House Parent App for your GIRO application status under the “GIRO Payment” icon. A green tick will be shown if the account has been approved.
                </p>
            </>
        )
    },

    {
        title: 'I would like to check on the status of my withdrawal refund.',
        content: (
            <>
                <p>
                    The withdrawal refund will be credited to the account which you have verified upon withdrawal within 4 to 6 weeks.
                </p>
            </>
        )
    },

    {
        title: 'If I have other questions about the Little Skool-House Parent App after logging in, who should I approach for more information?',
        content: (
            <>
                <p>
                    If you have further questions, reach out to us via the chat in the Little Skool-House Parent App and our friendly customer support team will get back to you as soon as we can.
                </p>
            </>
        )
    },

];

const faqs = {
    一般问题: [
        {
            title:
                '小小学庭幼儿中心和小小学庭伙伴业者计划幼儿中心有何差别？',
            content: (
                <>
                    <p>
                    在幼儿培育署的管理下，伙伴业者计划支持指定的幼儿中心以改善其托儿及育婴服务。家长们请安心，我们所有小小学庭幼儿中心的计划与课程都是相同的。{' '}
                    </p>
                    <p>
                    在小小学庭幼儿中心的网络中，有14所伙伴业者计划幼儿中心。特选的小小学庭伙伴业者计划幼儿中心与我们的合作伙伴共同营运，并成为他们员工可为子女选择的幼儿园。我们的目标是让在职父母能安心，确保子女在自己工作时能获得妥善的照顾及优质的幼儿教育。我们将这些中心的学额优先提供给工作场所的员工。
                    </p>
                    <p>
                    尽管如此，如果您并非我们伙伴业者计划幼儿中心的员工，但希望申请就读，您可以加入候补名单。   
                    </p>
                    <p>
                    如果您想邀请我们成为您的伙伴业者计划幼儿中心，请电邮 {' '}
                        <a href="mailto:info@littleskoolhouse.com">
                            info@littleskoolhouse.com
                        </a>{' '}
                        以了解更多的详情。
                    </p>
                </>
            )
        },
        {
            title: '小小学庭幼儿中心的地点在哪里？',
            content: (
                <p>
                    新加坡共有20间小小学庭幼儿中心。请点击 {' '}
                    <Link to="/zh/womendezhongxin/suoyouzhongxin">
                        <strong>我们的中心</strong>
                    </Link>{' '}
                    阅览各中心列表、各别中心的特色、校长介绍，以及相关中心的照片等。
                </p>
            )
        },
        {
            title: '小小学庭每个月的学费是多少？',
            content: (
                <>
                    <p>
                    小小学庭幼儿中心学前和育婴服务方案每月学费介于$1,230 至 $1,630之间（职业母亲津贴后及未含消费税)。幼儿培育署指南，伙伴业者计划幼儿中心的每月学费则根据幼儿培育署指南，介于$460 至$730之间（职业母亲津贴后及未含消费税)。{' '}
                    </p>
                </>
            )
        },
        {
            title: '教师的资格是什么？',
            content: (
                <p>
                    我们的教师都是拥有幼儿教育学士学位或大专文凭的专业人士，并且训练有素。他们秉持正确的态度，积累了丰富培育幼儿的经验。我们所有的教师都通过专业发展计画的培训，该计画设置也符合小小学庭的品质与标准。
                </p>
            )
        },
        {
            title: '是否所有幼儿中心都设有育婴服务？',
            content: (
                <p>
                    只有特定中心提供育婴服务。请点击 {' '}
                    <Link to="/zh/womendezhongxin/suoyouzhongxin">
                        <strong>我们的中心</strong>
                    </Link>
                    了解更多详情。
                </p>
            )
        },
        {
            title:
                '小小学庭不同班级的幼儿年龄是怎样划分的？',
            content: (
                <>
                    <p>
                        <strong>截至12月31日儿童的年龄</strong>
                    </p>
                    <p>
                        育婴班：2-17个月 <br />
                        学步班：2岁 <br />
                        幼儿低班：3岁 <br />
                        托儿高班：4岁 <br />
                        幼稚低班：5岁 <br />
                        幼稚高班：6岁
                    </p>
                </>
            )
        },
        {
            title:
                '我该何时为子女申请入学？是否有固定的注册时间？',
            content: (
                <p>
                    全年都可以申请入学，取决于是否有学额。最早可以在孩子预定入学日期前的12个月，办理入学注册手续。
                </p>
            )
        },
        {
            title: '小小学庭的入学政策有哪些？',
            content: (
                <>
                    <p>
                        入学程序的3个基本步骤：
                        <br />
                        1)<Link to="/zh/xunwen/yuyuecanguan">
                            <strong>预约参观</strong>
                        </Link>
                        了解小小学庭是否为完全适合您的孩子；
                        <br />
                        2)缴交订金（一个月学费）和注册费，并提交入学申请书及相关文件，即完成注册手续；
                        <br />
                        3)收到欢迎信及入学邀请，信中将详细说明孩子入学首日所需准备的事物。
                        <br />
                    </p>
                    <p>
                        欲知更多详情，请阅览我们的 入学政策。{' '}
                    </p>
                </>
            )
        },
        {
            title:
                '移居新加坡的外国人可以为孩子申请就读小小学庭吗？',
            content: (
                <p>
                    可以，移居新加坡的外国人可以为孩子申请入学。若有需要，小小学庭会向新加坡的移民与关卡局申请学生准证。申请者将于事后通过电邮收到线上申请的注册确认信函。{' '}
                </p>
            )
        },
        {
            title: '幼儿中心的开放时间？',
            content: (
                <>
                    <p>
                        我们中心的开放时间为周一至周五，早上七点至晚上七点；周六则为早上七点至下午两点。
                    </p>
                    <p>
                        我们位于中央商务区的中心开放时间为早上七点至晚上七点半，另有部分中心提供全日制及半日制课程。欲知更多关于各中心营运时间的详情，请参阅{' '}
                        <Link to="/zh/womendezhongxin/suoyouzhongxin">
                            <strong>我们的中心</strong>
                        </Link>
                        个别中心的网页。
                    </p>
                    <p>
                        除了公共假期外，每年中心另有六天关闭日。
                    </p>
                </>
            )
        },
        {
            title: '中心有提供增益课程吗？',
            content: (
                <p>
                    我们策划了由外部合作伙伴提供的各种选修{' '}
                    <Link to="/zh/womendekecheng/zengyikecheng">
                        <strong>增益课程。</strong>
                    </Link>{' '}
                    我们相信，在培养终身学习的习惯如专注、纪律和自信的同时，这些课程能进一步发展孩子的学习兴趣。课程皆为额外收费的现场课程。请与您心仪的中心询问课程详情。
                </p>
            )
        },
        {
            title: '中心是否提供膳食？',
            content: (
                <>
                    <p>
                        我们的全日制课程提供早餐、午餐级下午茶点。所有的膳食皆遵循保健促进局健康膳食计画（ Healthy Meals in Pre-schools Programme，简称HMPP）的标准。{' '}
                    </p>
                    <p>
                        一个月内有四周轮替菜单，食物内不含猪肉、猪油和牛肉。{' '}
                    </p>
                    <p>
                        若有任何宗信或饮食限制，建议家长于入学注册时告知我们中心的同事。
                    </p>
                </>
            )
        },
        {
            title: '学校如何处理儿童的过敏问题？',
            content: (
                <p>
                    家长应该在注册入学时声明孩子所有已知的过敏症状。为了安全起见，我们会在用餐区清楚展示儿童及其过敏症状的完整清单。此外，校方会发放烫印过敏标签，将标签熨烫在制服上以便识别。我们的同事都接受过辨识及处理过敏症状的训练。{' '}
                </p>
            )
        },
        {
            title: '我该如何购买制服？',
            content: (
                <p>
                    可直接向注册就读的中心购买制服。我们强烈建议家长在订购前带孩子先进行样品试穿。制服将于订购后两到三周送达。
                </p>
            )
        }
    ],
    课程: [
        {
            title:
                '小小学庭所提供的是怎样的课程？与其他幼儿中心有何区别？',
            content: (
                <>
                    <p>
                        我们提供连贯两套的独特课程：{' '}
                        <Link to="/zh/womendekecheng/guanxiweibenkechengchushengzhi3sui">
                            <strong>关系为本课程</strong>
                        </Link>{' '}
                        （2个月月至3岁）{' '}
                        <Link to="/zh/womendekecheng/wenxuehuakecheng4zhi6sui">
                            <strong>及文学化课程</strong>
                        </Link>{' '}
                        （4岁至6岁），让孩子在我们的照顾下发展身体、社会、情感、语言，以及认知的潜能。
                    </p>
                    <p>
                        我们以关系为本课程的核心信念：孩子的信心是学习的起点。幼儿需要良好、正向及安全的关系，以发展终身积极学习的态度。我们专注于培养孩子的自信心，在日常生活中支持他们发展独立的自助技能。我们为孩子们提供优质的体验及探索机会，让他们建构自己的知识并学以致用。
                    </p>
                    <p>
                        我们的文学化课程相信：故事帮助孩子如何看待世界，向他们介绍遥不可及的地方、与众不同的人、大开眼界的情境。通过指导阅读、写作、听力、口语，以及问题解决等活动，我们为孩子提供他们所需的知识与技能，培养积极的终身学习习惯。{' '}
                    </p>
                </>
            )
        },
        {
            title: '小小学庭如何帮助幼儿过渡、适应的学习生活？',
            content: (
                <p>
                    小小学庭专注建立密切的亲子关系，并培养积极的学习倾向。因此，我们制定了一个为期三天的活动，邀请家长与孩子一起参与入学的前三天的活动，因为父母的陪伴总能安抚孩子的心灵。家长与教师一起互动，也让孩子确信教师是值得信赖的人。{' '}
                </p>
            )
        },
        {
            title:
                '小小学庭如何开展英语和华语的双语教学？',
            content: (
                <>
                    <p>
                        小小学庭的课程及教学法启发语言的学习与信心。我们课程是英语和华语并重的双语课程。
                    </p>
                    <p>
                        <Link to="/zh/womendeyuzhongbutong/jīngtongyingyuhehuayu">
                            <strong>强化华语习得的教学法</strong>
                        </Link>{' '}
                        不仅专注于语言的学习，也重视语言相关的知识。我们的华语课程有三大目标：
                    </p>
                    <p>
                        1. 幼儿会对学习华语产生兴趣。
                        <br />
                        2. 培养幼儿华语的基础语言及识字技能。
                        <br />
                        3. 幼儿了解本地的族群文化
                    </p>
                    <p>
                        事实上，<a className="lsh-anchor-common" href="https://research.littleskoolhouse.com/" target="_blank">一项南洋理工大学</a>
                        的研究发现，小小学庭 93% 的学童被检测为精通双语。{' '}
                    </p>
                </>
            )
        },
        {
            title: '小小学庭如何开展华语教学？',
            content: (
                <>
                    <p>我们通过以下方式使华语课变得生动有趣：</p>
                    <ol>
                        <li>
                            结构式阅读及识字课程专注于以渐进式及系统化的方法，培养孩子的听力、口语、阅读，以及写作技能。这为学龄前儿童提供不同的策略，让他们能独立阅读与写作。教师则根据每个孩子能力评估后的要求和指示，在孩子学习上担任协助引导的角色。
                        </li>
                        <li>
                            <Link to="/zh/womendeyuzhongbutong/jīngtongyingyuhehuayu">
                                <strong>深化的华语习得教学法</strong>
                            </Link>{' '}
                            让孩子们内化华语并同时培养创造力、团体合作，以及协作技能。 孩子们投票决定他们的戏剧主题，编写自己的剧本、为制作分配角色、排练、制作宣传海报，最终呈现演出。上述所有活动都以华语进行。
                        </li>
                    </ol>
                    <p>
                        我们相信孩子通过学习获得乐趣，能让学习记忆保持更长久。我们希望孩子喜欢这个语言，进而享受学习它。{' '}
                    </p>
                </>
            )
        },
        {
            title:
                '小小学庭如何为幼儿做好上小学的准备？',
            content: (
                <p>
                    我们4至6岁的孩子专注于为小学做准备，包括学业准备、自信的双语沟通、良好行为和接触更大的学校环境。小小学庭的课程具有明确的成果，旨在培养幼儿的全方位发展，促进他们快乐学习，同时让他们为上小学做好准备。
                </p>
            )
        },
        {
            title: '小小学庭提供国际文凭®（IB）课程吗？',
            content: (
                <p>
                    目前，只有小小学庭乌鲁班丹社区俱乐部中心提供国际文凭®课程。
                </p>
            )
        }
    ]
};

const categories = Object.keys(faqs);

const FaqCn = ({ pageContext }) => {

    const { type } = pageContext;
    const defaultCategory = (type === 'guardianFaq') ? guardianFAQs : faqs[categories[0]];
    const parentCategory = (type === 'guardianFaq') ? parentFAQs : '';
    const [category, setCategory] = useState(categories[0]);
    const [items, setItems] = useState(defaultCategory);
    const [parentItems, setParentItems] = useState(parentCategory);
    const questions = Object.values((type === 'guardianFaq') ? guardianFAQs : faqs)
        .flat()
        .map(({ title, content }) => ({ q: title, a: content }));


    const categoryHandler = cat => {
        setCategory(cat);
        setItems(faqs[cat]);
    };

    const pageTitle = (type === 'guardianFaq') ? 'Parent and Guardian Apps FAQ' : '常见问题';
    const breadCrumbs = [{ label: '询问' }, { label: pageTitle }];
    if ((type === 'guardianFaq')) {
        breadCrumbs.shift();
    };

    return (
        <Layout
            bannerClass="enquiry"
            heading={pageTitle}
            breadCrumbs={breadCrumbs}
            metaData={{
                url: 'https://www.littleskoolhouse.com/enquiry/faqs',
                title: `${pageTitle} | The Little Skool-House`,
                description:
                    'Here are the list of Frequently Asked Questions (FAQ) about Little Skool-House preschools. Read about our curriculum, locations and more.'
            }}
        >
            <FAQPage faqs={questions} />
            <div className={`faq-container ${type !== "guardianFaq" && 'd-faq-flex'}`} >
                {(type !== 'guardianFaq') &&
                    (<div className="category-selection">
                        <div className="desktop-only">
                            {categories.map((cat, i) => (
                                <RadioButton
                                    key={`${cat}-${i}`}
                                    name="category"
                                    value={cat}
                                    checked={cat === category}
                                    handler={e => {
                                        categoryHandler(e.target.value);
                                    }}
                                />
                            ))}
                        </div>
                        <div className="mobile-only">
                            <div className="category-dropdown">
                                <Dropdown
                                    defaultValue={category}
                                    onChange={categoryHandler}
                                    options={categories.map(cat => ({ key: cat, value: cat }))}
                                    hideSelectedOption
                                />
                            </div>
                        </div>
                    </div>)
                }
                {
                    parentItems && parentItems.length > 0 &&
                    <div className="catalog-container">
                        {
                            parentItems && parentItems.length > 0 && <h4 className="faq-sub-head">Parent App FAQ</h4>
                        }
                        <div className="faq-catalog">
                            {parentItems &&
                                parentItems.map(({ title, content }, i) => (
                                    <Accordion key={`${title}-${i}`} title={title} type={category} activeLang="chinese">
                                        {content}
                                    </Accordion>
                                ))}
                        </div>
                    </div>
                }
                <div className="catalog-container">
                    {
                        items && items.length > 0 && type === 'guardianFaq' && <h4 className="faq-sub-head">Guardian App FAQ</h4>
                    }
                    <div className="faq-catalog">
                        {items &&
                            items.map(({ title, content }, i) => (
                                <Accordion key={`${title}-${i}`} title={title} type={category} activeLang="chinese">
                                    {content}
                                </Accordion>
                            ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default FaqCn;
