import React from 'react';
import Layout from '../layout/layout';

const GWLThanks = () => {
    return (
        <Layout noBanner noHeader noFooter>
            <div className="gwl-container" style={{ paddingTop: '30px' }}>
                <p style={{ fontSize: '18px', textAlign: 'center' }}>
                Please click on the Golden Window of Learning Logo on the top left to access your 'Happenings of the Month' page to access your videos.
                </p>
            </div>
        </Layout>
    );
}

export default GWLThanks;