import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import PropTypes from 'prop-types';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import './layout.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';
import favIcon from '../images/new-favicon.png';
import Banner from '../components/Footer/Banner';
import { getWithExpiry } from '../services/commonService';
import { getLanguage } from '../components/common/utils/utils';
import BannerCn from '../components/Footer/Banner-cn';
import FooterCn from '../components/Footer/Footer-cn';

const Layout = ({
  heading,
  subHeading,
  path,
  metaTags,
  openGraph,
  children,
  noFooter,
  noHeader,
  noBanner,
  pageLn,
  bannerClass,
  breadCrumbs,
  schemaMarkup,
  metaData,
  language,
}) => {
  const { site, openGraphLogo, cmsData, skinnyBannerStatus, bannerData } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
      openGraphLogo: file(relativePath: { eq: "open-graph.jpg" }) {
        childImageSharp {
          fixed(width: 3595, height: 2397) {
            src
          }
        }
      }
      cmsData: cms {
        pageBy(uri: "limited-time-promotion") {
          open_house {
            openhousestatus
          }
        }
      }
      skinnyBannerStatus: cms {
        pageBy(uri: "skinny-banner") {
          skinny_banner {
            closeButtonStatus
            content
            skinnybannerstatus
          }
        }
      }
      bannerData: cms {
        page(id: "skinny-banner-mandarin", idType: URI) {
          skinny_banner {
            closeButtonStatus
            content
            skinnybannerstatus
          }
        }
      }
    }
  `);
  const openStatus = cmsData.pageBy.open_house.openhousestatus;
  const skinnyDataEn = skinnyBannerStatus.pageBy.skinny_banner;
  const skinnyDataCn = bannerData.page.skinny_banner;
  const [announcement, setAnnouncement] = useState('');

  const { description, siteUrl } = site.siteMetadata;
  const absoluteUrl = relativeURL => new URL(relativeURL, siteUrl).toString();
  let { title } = site.siteMetadata;

  const custom = {
    english: {
      home: 'Home',
      homeLink: '/'
    },
    chinese: {
      home: '首页',
      homeLink: '/zh'
    }
  }
  const isBrowser = () => typeof window !== "undefined";

  const [isCookiePolicyAgreed, setCookiePolicyAgreed] = useState(isBrowser() && window.localStorage.getItem('cookieConsentAgreed'));
  const [activeLang, setActiveLang] = useState('');
  
  useEffect(() => {
    const lang = getLanguage();
    const link = document.location.href.split('/');
    if(lang != link[3]) {
      if(link[3] == 'zh') {
        localStorage.setItem("lan", "chinese");
        setActiveLang("chinese");
      } else {
        localStorage.setItem("lan", "english");
        setActiveLang("english");
      }
    } else {
      setActiveLang(lang);
    }
  },[1]);

  if (heading) {
    if (subHeading) {
      subHeading = ` ${subHeading}`;
    }

    title = `${heading instanceof Array
        ? heading.filter(el => typeof el === 'string').join(' ')
        : heading
      }${subHeading} | ${title}`;
  }

  const url = absoluteUrl(path);
  const imageUrl = absoluteUrl(openGraphLogo.childImageSharp.fixed.src);

  openGraph = openGraph || {
    type: 'website',
    url: metaData ? metaData.url : url,
    title: metaData ? metaData.title : title,
    description: metaData ? metaData.description : description,
    images:
      metaData && metaData.image
        ? [
          {
            url: metaData.image,
            alt: metaData ? metaData.title : title
          }
        ]
        : [
          {
            url: imageUrl,
            width: 3595,
            height: 2397,
            alt: metaData ? metaData.title : title
          }
        ]
  };

  useEffect(() => {
    if (getWithExpiry('IS_ANNOUNCEMENT_CLOSED')) {
      setAnnouncement(true);
    }
  }, []);

  // useEffect(() => {
  //   !function (f, b, e, v, n, t, s) {
  //     if (f.fbq) return; n = f.fbq = function () {
  //       n.callMethod ?
  //         n.callMethod.apply(n, arguments) : n.queue.push(arguments)
  //     };
  //     if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
  //     n.queue = []; t = b.createElement(e); t.async = !0;
  //     t.src = v; s = b.getElementsByTagName(e)[0];
  //     s.parentNode.insertBefore(t, s)
  //   }(window, document, 'script',
  //     'https://connect.facebook.net/en_US/fbevents.js');
  //   fbq('init', '619202212095870');
  //   fbq('track', 'PageView');
  // })

  const handleClick = () => {
    setAnnouncement(true);
  };
  if(!activeLang || activeLang == '') {
    return '';
  }

  return (
    <div className="layout-container">
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <meta name="google-site-verification" content="ooaFFf4uBVTLYrFkeOKxzlRYatdW4ZapYRiKrSeE1YM" />
        <meta name="msvalidate.01" content="A46ED5F72FBE26236E09086CEE7552E5" />
        <link rel="shortcut icon" href={favIcon} />
        <link rel="icon" type="image/png" href={favIcon} />
        {schemaMarkup && (
          <script type="application/ld+json">
            {JSON.stringify(schemaMarkup)}
          </script>
        )}
        {/* <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/21698896.js?businessUnitId=314040"></script> */}
        <script charset="utf-8" src="https://js.hscta.net/cta/current.js"></script>
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/22078937.js"></script>
      </Helmet>

      <GatsbySeo
        title={metaData ? metaData.title : title}
        description={metaData ? metaData.description : description}
        canonical={metaData ? metaData.url : url}
        metaTags={metaTags}
        openGraph={openGraph}
      />

      <div className="main-container">
        {!noHeader && 
          <Header 
            openStatus={openStatus}
            skinnyBannerDataEn={skinnyDataEn}
            skinnyBannerDataCn={skinnyDataCn}
            handleClick={handleClick}
            language={activeLang}
          />
        }
        <div
          className={`${!noHeader ? 'middle-section' : ''} ${!announcement ? 'banner-new-top' : 'banner-top' }`}
        >
          {heading && (
            <div
              className={`top-banner${bannerClass ? ' ' + bannerClass + '-banner' : ''
                }`}
            >
              <div className="overlay-header" />
              <div className="heading-text">
                <h1>{heading}</h1>
                {subHeading && <h4>{subHeading}</h4>}
              </div>
            </div>
          )}
          {breadCrumbs && (
            <div className="bread-crumbs">
              <Link to={language ? custom[language].homeLink : custom[activeLang].homeLink}>
                {language ? custom[language].home : custom[activeLang].home}
              </Link>
              {breadCrumbs.map((bc, i) => (
                <React.Fragment key={i}>
                  {` / `}
                  {bc.link ? (
                    <Link to={bc.link}>{bc.label}</Link>
                  ) : (
                    <span>{bc.label}</span>
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
          <main>{children}</main>
          {!noBanner && activeLang && activeLang === 'english' ? <Banner openStatus={openStatus} />
          : !noBanner && activeLang === 'chinese' ? <BannerCn openStatus={openStatus} /> : ''
          }

          {!noFooter && activeLang && activeLang === 'english' ? <Footer openStatus={openStatus} />
          : !noFooter && activeLang === 'chinese' ? <FooterCn openStatus={openStatus} /> : ''
          }

        </div>
      </div>
      {
        isCookiePolicyAgreed !== 'true' &&
        <div className="cookie-popup" style={{ display: 'block' }}>
          <div className="cookie-container">
          <div className="close-button" onClick={() => {
                  isBrowser() && window.localStorage.setItem('cookieConsentAgreed', 'true');
                  return setCookiePolicyAgreed('true');
                }}>
                  ×
                </div>
          <div className="row">
          <div>
            <p>
           { activeLang === 'english' ? <span className="cookie-text"> This website uses cookies to personalize your experience and to analyze site traffic. By continuing to browse the site, you are agreeing to our terms. Please refer to our privacy policy&nbsp;<a href="/others/privacy-policy">here.</a> </span> 
           : <span className="cookie-text">  此网站使用Cookie来个性化您的体验以及分析网站流量。如果您继续浏览该网站，即表示您同意我们的Cookie条款。请点击<a href="/others/privacy-policy">此处</a>参阅我们的隐私权政策。 </span> 
           }
            </p>
          </div>
          </div>
          </div>
        </div>}
    </div>
  );
};

Layout.propTypes = {
  heading: PropTypes.any,
  subHeading: PropTypes.string,
  path: PropTypes.string,
  additionalMetaTags: PropTypes.arrayOf(PropTypes.object),
  openGraph: PropTypes.object,
  children: PropTypes.any,
  noFooter: PropTypes.bool,
  bannerClass: PropTypes.string,
  pageLn: PropTypes.string,
  metaData: PropTypes.object,
  noBanner: PropTypes.bool
};

Layout.defaultProps = {
  heading: '',
  subHeading: '',
  path: '',
  pageLn:'en',
  metaTags: [],
  noFooter: false,
  noBanner: false,
  schemaMarkup: {}
};

export default Layout;
