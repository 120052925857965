import React from 'react';
import Layout from '../../layout/layout';
import GirlBalloon from '../../images/objects/partnership-girl-balloon.png';
import './partnership.scss';

const Partnership = () => {
  return (
    <Layout
      bannerClass="enquiry"
      heading="Partnership"
      breadCrumbs={[{ label: 'Enquiry' }, { label: 'Partnership' }]}
      metaData={{
        url: 'https://www.littleskoolhouse.com/enquiry/partnership',
        title: 'Check Out Our Partnership Network | Little Skool-House',
        description: `Thinking about adding reliable childcare services to your list of employee benefits? Visit this page to learn more about partnering with Little-Skool House.`
      }}
    >
      <div className="partnership-container">
        <h4>Add childcare services to your list of employee benefits</h4>
        <br />
        <div className="content-wrapper">
          <div className="content">
            <p>
            At The Little Skool-House, we believe that the bond between parent and child is an important one. That’s why we partner government ministries, tertiary institutions, hospitals and private corporations to provide childcare services in the workplace. Currently, we operate twelve POP workplace centres.
            </p>
            <br />
            <p>
            Through these centres, we give working parents peace of mind in knowing that their little wonders are well taken care of during working hours and are receiving a quality early childhood education.{' '}
            </p>
            <br />
            <p>
              Simply email{' '}
              <a href="mailto:info@littleskoolhouse.com" target="_blank">
                info@littleskoolhouse.com
              </a>{' '}
              to find out how to be our workplace partner.
            </p>
            <br />
          </div>
          <div className="image">
            <img src={GirlBalloon} alt="girl with balloon" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Partnership;
