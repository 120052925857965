import React from 'react';
import PropTypes from 'prop-types';
import { JsonLd } from 'gatsby-plugin-next-seo';
import { normalizePhoneNumber } from '../utils/utils';

const Preschool = ({
  name,
  url,
  logo,
  image,
  streetAddress,
  addressLocality,
  addressCountry,
  postalCode,
  telephone,
  email,
  openingHours,
  hasMap,
  sameAs
}) => {
  return (
    <JsonLd
      json={{
        '@context': 'https://schema.org',
        '@type': 'Preschool',
        name,
        url,
        logo,
        image,
        address: {
          '@type': 'PostalAddress',
          streetAddress,
          addressLocality,
          addressCountry,
          postalCode
        },
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: normalizePhoneNumber(telephone),
          email
        },
        openingHours,
        hasMap,
        sameAs
      }}
    />
  );
};

Preschool.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  streetAddress: PropTypes.string.isRequired,
  addressLocality: PropTypes.string.isRequired,
  addressCountry: PropTypes.string,
  postalCode: PropTypes.string.isRequired,
  telephone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  openingHours: PropTypes.string,
  hasMap: PropTypes.string.isRequired,
  sameAs: PropTypes.arrayOf(PropTypes.string).isRequired
};

Preschool.defaultProps = {
  addressCountry: 'Singapore'
};

export default Preschool;
