import { Link } from 'gatsby';
import React from 'react';
import { siteMapObj } from '../config/appConfig';
import Layout from '../layout/layout';

const LSHSiteMap = () => {
    
    return (
        <Layout
            heading="Sitemap"
            breadCrumbs={[{ label: 'Sitemap' }]}
            bannerClass="about-us"
        >
            <div className="cards">
                {
                    siteMapObj.map(({label,link,subMenu}) => {
                        if (!subMenu) return;
                        return (
                        <div className='card'>
                            <div><h4>{label}</h4></div>
                            {
                                subMenu.map(({ label, link: subMenuLink }) => (
                                    <div>
                                        <p>
                                        <Link to={ subMenuLink === 'others/privacy-policy' ? `/${subMenuLink}` : `/${link}/${subMenuLink}`}>
                                            {label}
                                        </Link>
                                    </p>
                                  </div>
                                  ))
                            }
                        </div>
                    )})
                }
            </div>
        </Layout>
    );
}

export default LSHSiteMap;