import React, { useRef, useEffect, useState } from 'react';

const TextHolder = ({ text, tilePositionClass, tileClass }) => (
  <div className={`text-holder ${tileClass} ${tilePositionClass}`}>
    {text && <div className={`text ${tilePositionClass}`}>{text}</div>}
  </div>
);

const Tile = ({ data, tileClass, timeOutDuration = 4500 }) => {
  const timerInstance = useRef(null);
  const [flipped, setFlipped] = useState(false);

  useEffect(() => {
    const timerTick = () => {
      setFlipped(current => !current);
      timerInstance.current = setTimeout(
        timerTick,
        timeOutDuration + Math.random() * 1000
      );
    };

    setTimeout(timerTick, timeOutDuration + Math.random() * 1000);

    return () => {
      if (clearTimeout) clearTimeout(timerInstance.current);
    };
  }, [timeOutDuration]);

  return (
    <div className="tile-wrapper">
      <div className={`tile ${flipped ? 'flipped' : ''}`}>
        {data ? (
          <>
            {data.heading && (
              <TextHolder
                text={data.heading}
                tilePositionClass="front"
                tileClass={tileClass}
              />
            )}
            {data.content && (
              <TextHolder
                text={data.content}
                tilePositionClass="back"
                tileClass={tileClass}
              />
            )}
          </>
        ) : (
          <TextHolder tileClass={tileClass} />
        )}
      </div>
    </div>
  );
};

export default Tile;
