import React from 'react';
import ECDA from '../../../images/logos/ECDA.png';
import MOE from '../../../images/logos/MOE.png';
import Trophy from '../../../images/objects/trophy.png';
import './AwardsSection.scss';

const AwardsSectionCn = ({ educatorsPage }) => {
  return (
    <div className={`awards-section ${educatorsPage ? 'educators-page' : ''}`}>
      <h3>
        {educatorsPage
          ? `The Little Skool-House is proud of our award-winning teachers`
          : `我们的获奖教师`}
      </h3>
      <div className="award-section-cn">
        <div className="award-section">
          <div className="img-section">
            <img src={MOE} alt="MOE" />
          </div>
          <div className="text-section">
            <div className="trophy-section">
              <img src={Trophy} alt="Trophy" />
              <h5>
                {educatorsPage
                  ? `教育部杰出学前母语教师奖`
                  : `教育部`}
              </h5>
            </div>
            {!educatorsPage && (
              <h3>
                教育部杰出学前母语教师奖
              </h3>
            )}
          </div>
        </div>
        <div className="award-section">
          <div className="img-section">
            <img src={ECDA} alt="ECDA" />
          </div>
          <div className="text-section">
            <div className="trophy-section">
              <img src={Trophy} alt="Trophy" />
              <h5>
                {educatorsPage
                  ? `新加坡幼儿培育署杰出及潜力幼儿教师奖`
                  : `新加坡幼儿培育署`}
              </h5>
            </div>
            {!educatorsPage && (
              <h3>
                新加坡幼儿培育署杰出及潜力幼儿教师奖
              </h3>
            )}
          </div>
        </div>
      </div>
      <p>
        小小学庭教师都通过严谨的训练课程。这些课程由幼儿保育和教育的开拓者及幼儿培育署的杰出伙伴设计，同时也为我们设计独特和优质的教学课程。我们的教师赴海外学习，接受深入的指导让专业更为精通。优秀的教师让我们获奖无数，更是您孩子获得最高标准保育和教育的保证。
      </p>
    </div>
  );
};

export default AwardsSectionCn;
