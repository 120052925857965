import React from 'react';
import Layout from '../../layout/layout';
import Graphic1 from '../../images/objects/curriculum-overview/graphic-1.png';
import Graphic2 from '../../images/objects/curriculum-overview/graphic-2.png';
import Graphic3 from '../../images/objects/curriculum-overview/graphic-3.png';
import Graphic4 from '../../images/objects/curriculum-overview/graphic-4.png';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { mandarinAcquisitionPageLink } from '../../config/appConfig';

const CurriculumOverview = () => {
  const images = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativePath: {
            in: [
              "objects/curriculum-overview/photo-1.jpg"
              "objects/curriculum-overview/photo-2.jpg"
            ]
          }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const [image1, image2] = images.allFile.edges.map(
    ({ node: { childImageSharp } }) => childImageSharp.fluid
  );

  return (
    <Layout
      bannerClass="curriculum-details"
      heading="Curriculum Framework Overview"
      breadCrumbs={[
        { label: 'Our Curriculum' },
        { label: 'Curriculum Overview' }
      ]}
      metaData={{
        url:
          'https://www.littleskoolhouse.com/our-curriculum/curriculum-overview',
        title: 'Best Preschool Curriculum in Singapore | Little Skool-House',
        description:
          'Our preschool curriculum encompasses the domains stipulated by MOE and ECDA framework, enabling us to offer the best preschool education in Singapore.'
      }}
    >
      <div className="learning-environment-container">
        <div className="top-section">
          <div className="img-section">
            <img src={Graphic1} alt="Graphics 1" />
          </div>
          <div className="text">
            <h3>Trendsetters in Early Childhood Education</h3>
            <p>
              As trendsetters in Early Childhood Education in Singapore, our
              curriculum is designed by pioneering and established ECDA fellows.
              Our pedagogy is based on clinical research on child development
              and education from top universities of the world.
            </p>
          </div>
        </div>
        <div className="top-section top-section-right">
          <div className="img-section-mobile">
            <img src={Graphic2} alt="Meals" />
          </div>
          <div className="text">
            <h3>
              Holistic Development and High Proficiency in both English and
              Mandarin
            </h3>
            <p>
              The Little Skool-House curriculum addresses the holistic
              developmental needs of children and provides globally accepted
              learning experiences for all preschoolers. With uniquely developed
              pedagogies that coincide with educational needs from infancy to
              kindergarten, our curriculum incorporates various
              <a
                href="enrichment-programme"
                target="blank"
                style={{ color: '#4ec7e2', textDecoration: 'underline' }}
              >
                {' '}
                programmes{' '}
              </a>{' '}
              into its education framework. We focus on:
            </p>

            <ul className="right-sided">
              <li>
                High proficiency in both English and Mandarin through our
                Pedagogy for Enhanced Mandarin Acquisition
              </li>
              <p>
                <Link to={mandarinAcquisitionPageLink}>
                  <button className="yellow-funky-button">Find out more</button>
                </Link>
              </p>
              <li>
                Creating a love and interest in academic areas such as math and
                science
              </li>
              <li>
                Building self-confidence, and developing social and emotional
                strength
              </li>
              <li>Preparing every child for Primary School</li>
            </ul>
          </div>
          <div className="img-section">
            <img src={Graphic2} alt="Meals" />
          </div>
        </div>
        <div className="top-section">
          <div className="img-section">
            <img src={Graphic3} alt="Graphics 1" />
          </div>
          <div className="text">
            <h3>Primary School Formation Programme</h3>
            <p>
              Our Primary School Formation Programme has hallmark pillars such
              as:
            </p>
            <ul>
              <li>Builders and Innovation Project</li>
              <li>Pedagogy for Enhanced Mandarin Acquisition</li>
              <li>Structured Reading and Literacy Assessments</li>
            </ul>
            <p>
              Our objective is to enable children to be ready for primary
              school, ready for life.
            </p>
          </div>
        </div>
        <div className="top-section top-section-right">
          <div className="img-section-mobile">
            <img src={Graphic4} alt="Meals" />
          </div>
          <div className="text">
            <h3>Chosen Preschool of Singapore’s Top Institutions</h3>
            <p>
              With all this in mind, we are proud to be the
              <a
                href="/our-difference/chosen-preschool-by-top-institutions"
                style={{ color: '#4ec7e2', textDecoration: 'underline' }}
                target="blank"
              >
                {' '}
                chosen preschool of Singapore’s most prestigious institutions{' '}
              </a>
              for their own children, such as the Ministry of Education (MOE),
              the Ministry of Social and Family Development (MSF), the National
              University of Singapore (NUS), and many more.
            </p>
          </div>
          <div className="img-section">
            <img src={Graphic2} alt="Meals" />
          </div>
        </div>
        <div className="photo-section">
          <div className="img-section">
            <Img fluid={image1} alt="Picture 1" />
          </div>
          <div className="text">
            <h2>Infant-3 years</h2>
            <h4>Relationships-Based Curriculum</h4>
            <p>
              The cornerstone of our Relationships-Based curriculum is the
              belief that building your child’s confidence is the starting point
              for every child’s learning journey in Singapore.
            </p>
            <Link
              to="/our-curriculum/infant-3-years-relationships-based-curriculum"
              className="btn-section"
            >
              <button className="yellow-funky-button">Find out more</button>
            </Link>
          </div>
        </div>
        <div className="photo-section photo-section-right">
          <div className="img-section">
            <Img fluid={image2} alt="Picture 2" />
          </div>
          <div className="text">
            <h2>4-6 years</h2>
            <h4>Literacy-Based Curriculum</h4>
            <p>
              The objective of our Literacy-Based Curriculum is holistic
              development to enable success in school, and success in life.
            </p>
            <Link
              to="/our-curriculum/4-6-years-literacy-based-curriculum"
              className="btn-section"
            >
              <button className="yellow-funky-button">Find out more</button>
            </Link>
          </div>
        </div>
        <div className="subtext text-center content-text-center text-head">
          <h2> The Little Skool-House Curriculum Framework </h2>
          <p>
            <br />
            <a href="/">The Little Skool-House</a> places importance in building
            an unshakeable foundation for your child. In preparation for
            lifelong learning amongst kids in kindergarten, our trained
            preschool educators follow a curriculum framework that reflects the
            desired outcomes of education set out by the Singapore Ministry of
            Education and ECDA. Our preschool curriculum framework aims to
            develop a love for learning and ensure retention from early years
            through to kindergarten.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default CurriculumOverview;
