import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../../layout/layout';
import './promotion-detail.scss';
import FlexibleContent from '../../../components/common/FlexibleContent/FlexibleContent';

const PromotionDetailsCms = ({ pageContext }) => {
  const { title, data, slug, language } = pageContext;
  const metaTitle =
    data && data.metaTag && data.metaTag.metaTitle
      ? `${data.metaTag.metaTitle}`
      : 'Promotions | The Little Skool-House';
  const metaDescription =
    data && data.metaTag && data.metaTag.metaDescription
      ? data.metaTag.metaDescription
      : 'The Little Skool-House is a bilingual preschool that fosters proficiency in English & Mandarin. Learn about upcoming events & speak to our educators today.';
  const custom = {
    english: {
      heading: "Happenings",
      breadCrumb: [
        { label: 'happenings' },
        { label: 'promotions', link: '/happenings/promotions' },
        { label: title }
      ],
      backBtn: "Back to list",
      backBtnLink: '/happenings/promotions'
    },
    chinese: {
      heading: "促销活动",
      breadCrumb: [
        { label: '传媒与活动' },
        { label: '促销活动', link: '/zh/chuanmeiyuhuodong/cuxiaohuodong' },
        { label: title }
      ],
      backBtn: "返回目录",
      backBtnLink: '/zh/chuanmeiyuhuodong/cuxiaohuodong'
    },
  }

  return (
    <Layout
      bannerClass="happenings"
      noBanner={true}
      heading={custom[language].heading}
      breadCrumbs={custom[language].breadCrumb}
      metaData={{
        url: `https://www.littleskoolhouse.com/promotions/${slug}`,
        title: metaTitle,
        description: metaDescription
      }}
    >
      <div className="promotion-detail-container">
        <div className="top-section">
          <Link
            to={custom[language].backBtnLink}
            style={{ position: 'absolute', top: '30', right: '15px' }}
          >
            <button
              className="yellow-funky-button"
              style={{ padding: '10px 30px' }}
            >
              {custom[language].backBtn}
            </button>
          </Link>
        </div>
        <div>
          <div className="heading">
            {data.title && data.title.line1 && <h1>{data.title.line1}</h1>}
            {data.title && data.title.line2 && <h1>{data.title.line2}</h1>}
          </div>
          {data.flexibleContent && (
            <FlexibleContent data={data.flexibleContent} />
          )}
        </div>
      </div>
    </Layout>
  );
};
export default PromotionDetailsCms;
