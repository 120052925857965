import React from 'react';
import './our-award-winning-educators.scss';
import Layout from '../../layout/layout';
import ChineseLetter from '../../images/objects/our-educators/chinese-letter.png';
import Teacher1 from '../../images/objects/our-educators/teacher-1.jpg';
import Teacher2 from '../../images/objects/our-educators/teacher-2.jpg';
import Bottom1 from '../../images/objects/our-educators/bottom-1.png';
import Bottom2 from '../../images/objects/our-educators/bottom-2.png';
import Bottom3 from '../../images/objects/our-educators/bottom-3.png';
import Bottom4 from '../../images/objects/our-educators/bottom-4.png';
import LeftQuote from '../../images/objects/left-quote.png';
import RightQuote from '../../images/objects/right-quote.png';
import MOE from '../../images/logos/MOE.png';
import ECDA from '../../images/logos/ECDA.png';
import Middle1 from '../../images/objects/our-educators/middle-1.png';
import Middle2 from '../../images/objects/our-educators/middle-2.png';
import Middle3 from '../../images/objects/our-educators/middle-3.png';

const bottomShowcase = [
  {
    icon: Bottom1,
    label: (
      <>
        Curriculum development,
        <br />
        review and renewal
      </>
    )
  },
  {
    icon: Bottom2,
    label: (
      <>
        Quality assurance
        <br />
        and development
      </>
    )
  },
  {
    icon: Bottom3,
    label: (
      <>
        Training, professional
        <br />
        development and support
      </>
    )
  },
  {
    icon: Bottom4,
    label: (
      <>
        Children’s learning assessment
        <br />
        and support programmes
      </>
    )
  }
];

const OurEducators = () => {
  return (
    <Layout
      bannerClass="our-difference"
      heading="Our Award-Winning Educators"
      breadCrumbs={[
        { label: 'Our Difference' },
        { label: 'Our Award-Winning Educators' }
      ]}
      metaData={{
        url:
          'https://www.littleskoolhouse.com/our-difference/our-award-winning-educators',
        title: 'Award Winning Early Childhood Educators | Little Skool-House',
        description: 'Little Skool-House has a team of dedicated and award-winning teachers who are committed to their craft and to our children\'s growth. Get to know our teachers.'
      }}
    >
      <div className="our-educators-container">
        <div className="curriculum-details-container">
          <div className="center-section">
            <h3>
              The Little Skool-House is proud of our teachers for winning awards
              consecutively since 2012
            </h3>
          </div>
        </div>
        <div className="ministry-section">
          <div className="display-section top">
            <div className="item">
              <img src={MOE} alt="MOE" />
              <div className="text">
                <h2>
                  MOE Outstanding Mother
                  <br />
                  Tongue Language Award
                </h2>
              </div>
            </div>
            <div className="line tablet-and-desktop-only" />
            <div className="item">
              <img src={ECDA} alt="MOE" />
              <div className="text">
                <h2>
                  ECDA Outstanding
                  <br />
                  Preschool Teacher Award
                </h2>
              </div>
            </div>
          </div>
          <h4>
            Our teachers enjoy the advantage of a unique professional
            development programme which includes
          </h4>
          <div className="display-section">
            <div className="item">
              <img src={Middle1} alt="Middle 1" />
              <div className="text">
                <p>
                  Deep pedagogical and curriculum training and one-to-one
                  mentoring to drive teaching mastery
                </p>
              </div>
            </div>
            <div className="line tablet-and-desktop-only" />
            <div className="item">
              <img src={Middle2} alt="Middle 2" />
              <div className="text">
                <p>
                  Additional training by professionals from The Singapore
                  Reparatory (SRT), and Professor Chen Ren Fu from Taiwan to
                  teach drama pedagogy
                </p>
              </div>
            </div>
            <div className="line tablet-and-desktop-only" />
            <div className="item">
              <img src={Middle3} alt="Middle 3" />
              <div className="text">
                <p>
                  Sending our teachers for learning journeys to places like
                  Finland and China to immerse in the different teaching
                  methodology and practices
                </p>
              </div>
            </div>
          </div>
        </div>
        <h2 className="center-text">
          Trendsetters in Early Childhood Education
        </h2>
        <div className="little-skool-house-container">
          <div className="left-section">
            <img src={Teacher1} draggable={false} alt="Dr Geraldine" />
          </div>
          <div className="right-section">
            <p className="intro">
            The Little Skool-House’s curriculum is exclusively designed by trendsetters in the Early Childhood Education sector
              <br />
              <img src={LeftQuote} alt="Left quote" className="quote" />
            </p>
            <p className="text">
            Designed by ECDA fellows and with a strong emphasis and passion in research excellence,
            our curriculum reflects the latest discoveries in the Early Childhood sector. 
            In particular, our Relationships-based curriculum, which fosters a sense of trust 
            and security to build confidence and drive learning in our young children, 
            is based on the research by Professor Ebbeck (Emeritus Professor of Early Childhood Education, 
            University of South Australia).
            </p>
            <div className="right-quote">
              <img src={RightQuote} alt="Right quote" />
            </div>
          </div>
        </div>
        <div className="little-skool-house-container">
          <div className="left-section mobile-only-strict">
            <img src={Teacher2} draggable={false} alt="Dr Connie" />
          </div>
          <div className="right-section">
            <p className="intro">
              Our Mother Tongue Language Group is headed by Dr. Connie Lum (EdD,
              The University of Western Australia)
              <br />
              <img src={LeftQuote} alt="Left quote" className="quote" />
            </p>
            <p className="text">
            A member of the Lee Kuan Yew Fund Bilingualism Proposal Evaluation 
            Committee and Promote Chinese Language Learning Pre-School Education Support Group, 
            Dr. Lum is a world-renowned educator with over 20 years in the early childhood field. 
            Having written over 30 books on teaching Chinese, 
            Dr. Lum has been an esteemed speaker in multiple symposia such as The China Early Childhood Education Conference, 
            The Hong Kong University Conference as well as the OMEP World Conference.
            </p>
            <div className="right-quote">
              <img src={RightQuote} alt="Right quote" />
            </div>
          </div>
          <div className="left-section tablet-and-desktop-only">
            <img src={Teacher2} draggable={false} alt="Dr Connie" />
          </div>
        </div>
        <div className="bottom-section">
          <h4>
          A strong team of pedagogists oversees the school’s English and Mandarin 
          curriculum as well as the professional development of our teachers in four key areas
          </h4>
          <div className="showcase">
            {bottomShowcase.map((item, i) => (
              <div className="item" key={i}>
                <img src={item.icon} alt={item.label} />
                <p className="label">{item.label}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="learning-environment-container">
          <div className="top-section">
            <div className="img-section">
              <img src={ChineseLetter} alt="A" />
            </div>
            <div className="text">
              <h3>
                Backed by such expertise, The Little Skool-House offers a
                curriculum that drives discoveries and builds knowledge better,
                all the way through to primary school
              </h3>
              <p>
                In fact, 93% of our students are Highly Proficient in English
                and Mandarin.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OurEducators;
