import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';
import Layout from '../layout/layout';
import './giftredemption.scss';
import LSHLogo from '../images/New-lsh-logo-updated.png';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { TextField } from '../components/common/FormInput/FormInput';
import AlertModal from '../components/common/AlertModal/AlertModal';
import { saveGiftRedemption } from '../api/api';
import DatePick from '../components/common/DatePick/DatePick';

const errorMsg =
  "Sorry, we can't process your request now. Please try again later";

const giftDetailsPDFUrl = 'https://littleskoolhouse-prod-cms.s3-ap-southeast-1.amazonaws.com/wp-content/uploads/pdf/Newborn+%26+1st+Birthday+Programme.pdf';

const GiftRedemption = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef();
  const [contact_options, setContactOptions] = useState({});

  const handleForm = async formData => {
    setFormSubmitting(true);

    try {
      const { status } = await saveGiftRedemption(formData);
      setFormSubmitting(false);

      if (status !== 'success') {
        setSubmitError(errorMsg);
        return;
      }

      setShowModal(true);

      const form = formRef.current;
      if (form) {
        form.resetValidationState(true);
      }
    } catch (e) {
      setSubmitError(errorMsg);
      setFormSubmitting(false);
    }
  };

  const setCheckBoxValue = (name) => {
    const data = { ...contact_options };
    data[name] = !data[name];

    if (name == "All" && data[name]) {
      data.Email = true;
      data.SMS = true;
      data.Phone = true;
    }
    else if (name == "All" && !data[name]) {
      data[name] = false;
      data.Email = false;
      data.SMS = false;
      data.Phone = false;
    }
    if (data.Email && data.SMS && data.Phone) {
      data.All = true;
    }
    if (!data.Email || !data.SMS || !data.Phone) {
      data.All = false;
    }

    setContactOptions(data);
  };

  const closeModalHandler = () => {
    setShowModal(false);
  };

  return (
    <Layout
      noHeader
      metaData={{
        url: 'https://www.littleskoolhouse.com/giftredemption',
        title: 'Gift Redemption for Partners',
        description:
          'Redeem a gift for your newborn as our incentives for business partners. Contact us and learn how your workplace can be a part of our business network today.'
      }}
    >
      <div className="gift-redemtion-container">
        {showModal && (
          <AlertModal handleClose={closeModalHandler}>
            <div className="success-alert">
              <div className="badge">
                <FontAwesomeIcon icon={faCheck} className="icon" />
                Thank You!
              </div>
              Your request for gift redemption has been submitted successfully.
              Our representative will be in touch via email soon. Do check your
              inbox for updates!
            </div>
          </AlertModal>
        )}
        <header>
          <Link to="/">
            <div className="logo-section">
              <img src={LSHLogo} alt="LSH Logo" />
            </div>
          </Link>
          <h1>NEWBORN &amp; FIRST BIRTHDAY GIFT PROGRAMME</h1>
          <h2>exclusive for Partners</h2>
          <p style={{textAlign: 'left', paddingTop: '15px'}}>
          At The Little Skool-House, we cherish and care about each child’s precious moments.
          <br/>
          <br/>
          To celebrate your child’s birth and first birthday with you, we are happy to 
          extend two beautiful gifts to you.
          <br/><br/>Find out more <a href={giftDetailsPDFUrl} target="_blank">here.</a>
          </p>
        </header>
        <div className="main-container">
          <h4>Gift Redemption Form</h4>
          <p>
            Please fill in the below information and we will be in touch with
            you on the collection arrangements soon.
          </p>

          <div className="form-wrapper">
            {submitError && (
              <div className="submit-error error">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="icon"
                />
                {submitError}
              </div>
            )}
            <Formik
              initialValues={{
                name: '',
                companyName: '',
                email: '',
                childName: '',
                mobileNumber: '',
                dob: ''
              }}
              validationSchema={Yup.object({
                name: Yup.string().required('Please enter your name'),
                companyName: Yup.string().required(
                  'Please enter your company name'
                ),
                mobileNumber: Yup.string()
                  .matches(/^[0-9]{8}$/, 'Mobile number must be 8 digits')
                  .required('Please enter your Mobile number'),
                childName: Yup.string().required('Please enter the child’s name'),
                dob: Yup.string().required('Please enter the DOB'),
                email: Yup.string()
                  .email('Please enter a valid email address')
                  .required('Please enter your email address')
              })}
              onSubmit={(formData, actions) => {
                formData.contact_options = '';          
                let finalString = Object.keys(contact_options).filter(key => contact_options[key]);
                if(finalString.includes('All')){
                  formData.contact_options = finalString.filter(ele => ele != 'All').toString();
                }
                else{
                  formData.contact_options = finalString.toString()
                }
                handleForm(formData).then(() => {
                  actions.setSubmitting(false);
                  actions.resetForm();
                });
              }
              }
            >
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <div className="form-controls">
                    <div className="colum-flex-wrap">
                      <div className="section-subtitle"><span>Parent’s Particulars</span></div>
                      <div className="form-controls">
                        <div className="left-col">
                          <div className="form-input">
                            <div className="label-wrapper">
                              <label htmlFor="name">Name</label>
                            </div>
                            <div className="input-wrapper">
                              <TextField type="text" id="name" name="name" />
                            </div>
                          </div>
                          <div className="form-input">
                            <div className="label-wrapper">
                              <label htmlFor="mobileNumber">Mobile No.</label>
                            </div>
                            <div className="input-wrapper">
                              <TextField type="text" id="mobileNumber" name="mobileNumber" />
                            </div>
                          </div>
                        </div>
                        <div className="right-col">
                          <div className="form-input">
                            <div className="label-wrapper">
                              <label htmlFor="companyName">Organisation Name</label>
                            </div>
                            <div className="input-wrapper">
                              <TextField
                                type="text"
                                id="companyName"
                                name="companyName"
                              />
                            </div>
                          </div>
                          <div className="form-input">
                            <div className="label-wrapper">
                              <label htmlFor="email">Email</label>
                            </div>
                            <div className="input-wrapper">
                              <TextField type="email" id="email" name="email" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-controls">
                    <div className="colum-flex-wrap">
                      <div className="section-subtitle"><span>Child’s Particulars</span></div>
                      <div className="form-controls">
                        <div className="left-col">
                          <div
                            className="form-input"
                            style={{
                              minHeight: '43px'
                            }}
                          >
                            <div className="label-wrapper">
                              <label htmlFor="childName">Name</label>
                            </div>
                            <div className="input-wrapper">
                              <TextField
                                type="text"
                                id="childName"
                                name="childName"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="right-col">
                          <div
                            className="form-input"
                            style={{
                              minHeight: '43px'
                            }}
                          >
                            <div className="label-wrapper">
                              <label htmlFor="dob">Date of Birth</label>
                            </div>
                            <div className="input-wrapper">
                              <DatePick
                                changeHandle={dob => {
                                  setFieldValue('dob', dob, true);
                                }}
                                name="dob"
                                maxDate={new Date()}
                                minDate={new Date('11/01/2019')}
                                yearPicker
                              />
                              {errors.dob && (
                                <div className="error">
                                  <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    className="icon"
                                  />
                                  {errors.dob}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>
                    By submitting this form, you acknowledge and agree to our&nbsp;
                      <a href="https://www.littleskoolhouse.com/others/privacy-policy" target="_blank" style={{ textDecoration: 'underline', color: '#4ec7e2' }}>
                      Privacy Policy
                      </a>, and that The Little Skool-House International and My First Skool, pre-school businesses of NTUC First Campus Co-operative Limited, will contact you regarding redemption matters and/or update you in relation to childcare services.
                    </p>
                    <p>
                    Yes, I would like to receive recommendations for childcare services, parenting 
                    tips from experts, promotions and new product information from The Little Skool-House 
                    International and My First Skool, pre-school businesses of NTUC 
                    First Campus Co-operative Limited, our affiliated organisations and partners via:
                    </p>
                    <div className="agreement-wrapper agreement-col">
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        value="All"
                        name="contact_options"
                        checked={contact_options.All}
                        onClick={() => setCheckBoxValue("All")} />All
                    </div>
                    <div className="checkbox" style={{ paddingLeft: '2%' }}>
                      <input
                        type="checkbox"
                        value="Email"
                        name="contact_options"
                        checked={contact_options.Email}
                        onClick={() => setCheckBoxValue("Email")} />Email
                    </div>
                    <div className="checkbox" style={{ paddingLeft: '2%' }}>
                      <input
                        type="checkbox"
                        value="SMS"
                        name="contact_options"
                        checked={contact_options.SMS}
                        onClick={() => setCheckBoxValue("SMS")} />SMS
                    </div>
                    <div className="checkbox" style={{ paddingLeft: '2%' }}>
                      <input
                        type="checkbox"
                        value="Phone"
                        name="contact_options"
                        checked={contact_options.Phone}
                        onClick={() => setCheckBoxValue("Phone")} />Phone
                    </div>
                  </div>
                  <div className="submit-bttn">
                    <button
                      type="submit"
                      className="yellow-funky-button"
                      disabled={formSubmitting}
                    >
                      Submit Now
                    </button>
                  </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <footer>
          <h2>Terms &amp; Conditions</h2>
          <div className="content">
            <p>
              1. Gift redemption is valid for 
              current employees of partners of 
              The Little Skool-House whose child 
              is not currently enrolled or registered with The Little Skool-House. 
              The employee’s staff pass will be requested at the point of redemption
               for verification purposes.
            </p>
            <p>
              2. Each eligible employee of the Little Skool-House's partners will be
               able to redeem up to two gifts per child, depending on the child’s age 
               at the point of registration on the Gift Redemption Form. The newborn gift (premium bamboo fibre swaddle) 
               is for children aged 0 to 6 months, while the first birthday 
               gift (felt letter board) is for children aged 12 to 18 months.
            </p>
            <p>
              3. The Little Skool-House reserves the sole
              and absolute right to withdraw, amend
              and/or alter any part or the whole terms
              and conditions of this Newborn and First
              Birthday Gift Programme at any time without
              giving any prior notice.
            </p>
          </div>
        </footer>
      </div>
    </Layout>
  );
};

export default GiftRedemption;
