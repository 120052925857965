import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Link } from 'gatsby';
import Layout from '../../layout/layout';
import ScheduleVisitFormCn from '../../components/common/ScheduleVisitForm/ScheduleVisitFormCn';
import './schedule-a-visit.scss';
import ScheduleLocation from '../../images/objects/schedule-location.png';
import icon from '../../images/icons/icon-cn.png'
import { RECAPTCHA_KEY } from '../../config/appConfig';
const Schedule = () => {
  return (
    <Layout
      bannerClass="schedule"
      heading="预约参观"
      pageLn="cn"
      noBanner={true}
      metaData={{
        url: 'https://www.littleskoolhouse.com/zh/xunwen/yuyuecanguan',
        title: '预约参观时段 | 小小学庭',
        description: '在寻找新加坡最好的学前教育中心？我们小小学庭的中心遍布全国，欢迎参观其中一所或填写以下表格。'
      }}
    >
      <div className="schedule-container">
        <div className="benefit-box reverse">
          <img src={ScheduleLocation} alt="schedule-location" />
          <div className="divider" />
          <div className="benefit-content">
            <h4>
              在小小学庭，我们有93%的幼儿双语能力强。您想知道我们是如何培养他们的吗？
              今天就来参观我们的中心以了解更多！
            </h4>
            <p>
              我们的文学化课程适合不同年龄段婴幼儿的发展。填写以下表格后，我们中心的行政人员将会与您联系以进行预约。
            </p>

            {/* <div className="cn-promo-wrapper">
            <p className="cn-promo-txt"><span className="cn-promo-price">的折扣*,&nbsp; </span><span className="cn-promo-price">并可获赠价值$200的精美礼盒与特制迎新礼包。</span></p>
            </div> */}

            <div className="btn-wrapper ">
              <Link
                className="yellow-funky-button"
                to="/zh/womendezhongxin/suoyouzhongxin"
              >
                中心位置
              </Link>
            </div>
            {/* <p style={{color: '#2d3a3d', fontWeight: 'bold'}}></p> */}
            <p>
            To switch to English web form, please click&nbsp;
              <a style={{ color: '#4ec7e2', textDecoration: 'underline' }} href="/enquiry/schedule-a-visit">
              here.
              </a>
            </p>
          </div>
        </div>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
          <ScheduleVisitFormCn type="normal-cn" />
        </GoogleReCaptchaProvider>

        <h2 className="t-c-head">附加条款与细则</h2>
         
        <div className="cn-t-c-content">
            <p>
              1. 此促销仅限在以下参与活动的中心使用，有效期限至21年12月31日
            </p>
            <p>
              2. 在促销活动期间，报名全日制课程可获得总额$1600的学费折扣。这将在自孩童入学后的一年内的每个季度末分批从学费中扣除（$400/每季度直到扣完$1600为止）。此促销活动仅适用于以下中心：
            </p>
            <ul>
              <li>小小学庭（立达大厦中心）</li>
              <li>小小学庭（蒂凡娜学院中心）</li>
              <li>小小学庭（白沙乐怡度假村中心）</li>
              <li>小小学庭（滨海林荫道中心）</li>
              <li>小小学庭（蒙巴登广场中心）</li>
              <li>小小学庭（淡滨尼职总英康中心）</li>
              <li>小小学庭（乌鲁班丹社区俱乐部中心）</li>
              <li>小小学庭（胡姬乡村俱乐部中心）</li>
            </ul>
            <p>
              3. 在促销活动期间，报名Prime课程可获得总额$800的学费折扣。这将在自孩童入学后的一年内的每个季度末分批从学费中扣除（$200/每季度直到扣完$800为止）。此促销活动仅适用于以下中心：
            </p>
            <ul>
              <li>小小学庭（立达大厦中心）</li>
              <li>小小学庭（白沙乐怡度假村中心）</li>
            </ul>
            <p>
              4. 在促销活动期间，报名半日制课程可获得总额$400的学费折扣。这将在自孩童入学后的一年内的每个季度末分批从学费中扣除（$100/每季度直到扣完$400为止）。此促销活动仅适用于以下中心：
            </p>
            <ul>
              <li>小小学庭（立达大厦中心）</li>
            </ul>
            <p>
              5. 符合条件且在2021年11月1日至2022年1月31日优惠期间入园的幼儿，将获得价值超过200元的精美礼盒和迎新礼包。
            </p>
            <p>
              6. 以上促销不适用于在21年5月1日之前已经在小小学庭报名的家长。
            </p>
            <p>7. 如果孩童在折扣全部返还之前退学，小小学庭将没收所有剩余返款。</p>
            <p>
              8. 上述活动优惠仅限于指定参与的中心，并且幼儿须在优惠活动期间入园，也不可与其他优惠同时使用。如该幼儿在折扣未完全返还期间转去其他小小学庭中心，该优惠活动将自动终止。
            </p>
          </div>
      </div>
    </Layout>
  );
};

export default Schedule;
