import React from 'react';
import { Link } from 'gatsby';
import Banner from '../../../images/backgrounds/banners/schedule/banner-oh.jpg';

const Promotion = () => {
  return (
    <div className="promo-section">
      <div>
        <img src={Banner} width="200" height="256" />
      </div>
      <div className="text-section">
        <h3>EXCLUSIVE REBATE</h3>
        <h4>
          <span>Enjoy up to $1,600 in rebates*</span>
        </h4>
        <Link to="/enquiry/schedule-a-visit">
          <button className="yellow-funky-button blue-text">
            Schedule A Visit
          </button>
        </Link>
      </div>
    </div>
  );
};
export default Promotion;
