import React from 'react';
import Layout from '../../layout/layout';
import { graphql, useStaticQuery } from 'gatsby';
import Graphic1 from '../../images/objects/high-proficiency/graphic-1.png';
import Graphic2 from '../../images/objects/high-proficiency/graphic-2.png';
import Graphic3 from '../../images/objects/high-proficiency/graphic-3.png';
import Kids from '../../images/objects/high-proficiency.jpg';
import LeftQuote from '../../images/objects/left-quote.png';
import RightQuote from '../../images/objects/right-quote.png';
import { EPSection } from '../our-curriculum/4-6-years-literacy-based-curriculum';
import './high-proficiency-in-english-and-mandarin.scss';
import YoutubeVideo from '../../components/common/YoutubeVideo/YoutubeVideo';
import ScheduleVisitForm from '../../components/common/ScheduleVisitForm/ScheduleVisitForm';
import { RECAPTCHA_KEY } from '../../config/appConfig';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PromoSection from '../../components/common/PromoSection/promoSection';


const sections = [
  {
    text: {
      content: (
        <>
          <h3>Pillar 1</h3>
          <h4>Systematic Language Formation</h4>
          <div className="points">
            <h4>• Comprehension versus Memorisation</h4>
            <p>
              Methodical building of vocabulary
              <br />
              Focuses on understanding concept versus rote learning.
            </p>
            <h4>
              • Scaffolding on Grammar, Sentence Structure and Pronunciation
            </h4>
            <p>
              Ensures correct foundation is built for primary school readiness
            </p>
            <h4>• Progressive Complexity in Reading and Writing</h4>
            <p>Goal: Primary 1 readiness</p>
          </div>
        </>
      )
    },
    graphic: Graphic1
  },
  {
    text: {
      content: (
        <>
          <h3>Pillar 2</h3>
          <h4>Highly Trained, Multi Awarded Teachers</h4>
          <div className="points">
            <h4>• Curriculum developed by Mandarin Pedagogy Expert</h4>
            <p>
              Dr. Connie Lum, EdD, Committee member of Lee Kuan Yew Fund for
              Bilingualism,
              <br />
              author of 30 published books on teaching Chinese
            </p>
            <h4>• Rigorous Mandarin Teacher training programme</h4>
            <p>
              Includes training by Taiwan's Prof. Chen Ren Fu,  learning
              journeys to Beijing, Taiwan and Hong Kong, mentorship and on the
              job coaching <br />
              Drives skill progression for all of Little Skool-House’s teachers
              from the Beginning to Developing to Mastery level of teaching
            </p>
          </div>
        </>
      )
    },
    graphic: Graphic2
  },
  {
    text: {
      content: (
        <>
          <h3>Pillar 3</h3>
          <h4>Progressive Usage: From Everyday Topics to Complex Ideas</h4>

          <div className="points">
            <h4>• Builders and Innovation Project</h4>
            <p>
              Ability to think, analyse and reason in Mandarin (e.g. which
              fruits can conduct electricity)
            </p>
            <h4>• Converse and Write Complex Ideas </h4>
            <p>
              Ability to express own ideas and carry more complex conversations{' '}
              <br />
              Weekly journal writing based on children’s interest
            </p>
            <h4>• Full Cycle Chinese Theatre Pedagogy</h4>
            <p>
              An immersive experience where children advocate and influence
              their peers, express complex ideas, plan production, solve
              problems and do self assessments, all in Mandarin
            </p>
          </div>
        </>
      )
    },
    graphic: Graphic3
  }
];

const chinesePedgogyWheel = [
  {
    label: 'Advocacy',
    text: [`"我们选这本书`, `吧！里面的十个小`, `精灵很可爱的!"`],
    color: 'orange',
    position: {
      top: '-7%',
      left: '50%'
    }
  },
  {
    label: 'Negotiation',
    text: [`"我想做场记`, `因为我可`, `帮助别人"`],
    color: 'blue',
    position: {
      top: '27%',
      right: '0%'
    }
  },
  {
    label: 'Write Own Script',
    text: [`"我们有太多人了,`, `可以加多几个`, `角色进来吗"`],
    color: 'orange',
    position: {
      top: '70%',
      right: '0%'
    }
  },
  {
    label: 'Problem Solving',
    text: [
      `"如果要让舞台站`,
      `起来，我们应该`,
      `一个人扶着，一`,
      `个人来粘胶带"`
    ],
    color: 'blue',
    position: {
      bottom: '-7%',
      left: '50%'
    }
  },
  {
    label: 'Feedback',
    text: [`"你刚刚的动作`, `是这样的,`, `好像在行礼"`],
    color: 'orange',
    position: {
      top: '70%',
      left: '0%'
    }
  },
  {
    label: 'Self Reflection',
    text: [`"我记住`, `台词啦"`],
    color: 'blue',
    position: {
      top: '27%',
      left: '0%'
    }
  }
];

const ChineseTheatrePedagogyWheel = () => {
  return (
    <div className="chinese-pedgogy-circle">
      <div className="centre-text">
        Full cycle
        <br className="mobile-only-strict" />
        <br className="tablet-and-desktop-only" /> chinese
        <br className="tablet-and-desktop-only" /> theatre
        <br className="tablet-and-desktop-only" /> pedagogy
      </div>
      {chinesePedgogyWheel.map((item, i) => (
        <div
          className={`circle-container ${
            i === 0 || i === 3 ? 'transform-x' : 'transform-y'
          }`}
          key={i}
          style={{ ...item.position }}
        >
          <p className={`label label-top ${item.color}`}>{item.label}</p>
          <div className={`circle ${item.color}`}>
            <div className="text">
              {item.text.map((el, i) => (
                <p key={i}>{el}</p>
              ))}
            </div>
          </div>
          <p className={`label label-bottom ${item.color}`}>{item.label}</p>
        </div>
      ))}
      <div className="inner-circle" />
    </div>
  );
};

const HighProficiency = () => {
  const data = useStaticQuery(graphql`
  query {
    cmsData: cms {
      pageBy(uri: "limited-time-promotion") {
        open_house {
          openhousestatus
          privacyPolicy
          promotionPrice
          promotionTitle
          subTitle
          title
          centres {
            centreName
            centreNameEn
            isParticipating
            location
          }
        }
      }
    }
  }
`);
const { centres } = data.cmsData.pageBy.open_house;
let north = centres.filter(item => item.location === 'North');
north.unshift({ location: 'North' });
let central = centres.filter(item => item.location === 'Central');
central.unshift({ location: 'Central' });
let east = centres.filter(item => item.location === 'East');
east.unshift({ location: 'East' });
let west = centres.filter(item => item.location === 'West');
west.unshift({ location: 'West' });

const centersList = north.concat(central, east, west);
  return (
    <Layout
      heading="High Proficiency In English And Mandarin"
      bannerClass="about-us"
      breadCrumbs={[
        { label: 'Our Difference' },
        { label: 'High Proficiency In English And Mandarin' }
      ]}
      metaData={{
        url:
          'https://www.littleskoolhouse.com/our-difference/high-proficiency-in-english-and-mandarin',
        title:
          'Bilingual Preschool, Kindergarten and Programme | Little Skool-House',
        description:
          'Our Bilingual preschool and kindergarten programmes in Singapore has led to 93% of our children achieving High Proficiency in English and Mandarin. Enrol now!'
      }}
    >
      <div className="high-proficiency-container">
        <div className="little-skool-house-container">
          <div className="left-section">
            <img src={Kids} draggable={false} alt="LSH Kids" />
          </div>
          <div className="right-section">
            <p className="intro">
              At The Little Skool-House we are <span>proud</span>
              <br className="tablet-and-desktop-only" /> that our curriculum not
              only builds <span>holistic</span>
              <br className="tablet-and-desktop-only" /> development for
              children, but also has led to
              <br />
              <span>
                93% of our children achieving High Proficiency in both English
                and Mandarin
              </span>
              <img src={LeftQuote} alt="Left quote" className="quote" />
            </p>
            <p className="text">
              This has been achieved in a <a className="lsh-anchor-common" href="https://research.littleskoolhouse.com/" target="_blank">study</a> by Nanyang Technological
              University in 2019 by Dr. Setoh (PhD, The University of Illinois)
              and is the result of our robust Pedagogy for Enhanced Mandarin
              Acquisition.
            </p>
            <div className="right-quote">
              <img src={RightQuote} alt="Right quote" />
            </div>
          </div>
        </div>
        <div className="banner-video">
          <YoutubeVideo
            title="Proficiency In English And Mandarin"
            youtubeVideoId="OhOl30SHKRE"
            fullscreen={false}
            autoPlay={1}
          />
        </div>
        <div className="learning-environment-container">
          <h2 className="blue">Pedagogy for Enhanced Mandarin Acquisition</h2>
          {sections.map((section, i) => (
            <EPSection section={section} right={i % 2 !== 0} key={i} />
          ))}
        </div>
        <ChineseTheatrePedagogyWheel />
        <div className="foot-notes">
          <span>
            93<span>%</span>
          </span>{' '}
          of Little Skool-House Children
          <br className="tablet-and-desktop-only" /> are Highly Proficient in
          English and Mandarin
        </div>
        {/* <div className="promo-wrap">
          <PromoSection />
        <div className="promo-right-section">
        <h2>SCHEDULE A ONE-TO-ONE CENTRE VISIT TODAY</h2>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
              <ScheduleVisitForm type="open-house" centerList={centersList} />
        </GoogleReCaptchaProvider>
        </div>
        </div> */}
        <div className="subtext text-center content-text-center text-head">
          <h2>
            {' '}
            Attaining High Bilingual Proficiency at The Little Skool-House
            Preschool{' '}
          </h2>
          <p>
            <br />
            Establishing a strong bilingual foundation for preschool students is
            vital for their future language learning in Singapore and globally.{' '}
            <a href="/">The Little Skool-House</a> is one of the leading
            bilingual kindergarten, preschool and childcare learning
            centres with a bilingual pedagogy for Enhanced Mandarin
            Acquisition that remains unrivalled by other immersion
            preschools in Singapore. With activities conducted entirely
            in Mandarin, the correct foundation is built in kindergarten,
            promising readiness for primary school in Singapore.
            The Little Skool-House offers a 
            literacy-based approach to our bilingual programme.
             Our children learn English and Mandarin through
            fun and interactive activities that enhances their interest
             in learning these languages. Being in a bilingual
             preschool also encourages the use of English and Mandarin,
             providing adequate practice to elevate their bilingual proficiency
             and confidence in both languages.

            <br />
            <br />
            The Little Skool-House is proud to be a bilingual preschool
            offering a comprehensive curriculum, including English
            enrichments and Mandarin Immersion lessons.
            Our bilingual programme meets the Singapore Education 
            Ministry's learning goals and 93% of Little Skool-House
            children are highly proficient in English and Mandarin.
            Graduates from our bilingual kindergarten remain highly
             skilled learners beyond years and throughout their lives.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default HighProficiency;
