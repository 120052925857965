import React from 'react';
import './article.scss';
import Layout from '../../../layout/layout';
import { Link, graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet-async';

const ArticleNew = ({
  pageContext: { slug, title, data, categoryDetails, fullData }
}) => {
  let category = {};
  let subCategory = {};
  const cat = categoryDetails.nodes;

  cat.map(item => {
    if (item && item.parent != null) {
      let temp = item && item?.slug.split('-');
      subCategory = {
        name: item.name,
        slug:
          temp.length > 3 ? `${temp[3]}-${temp[4]}` : `${temp[1]}-${temp[2]}`
      };
    } else {
      category = item;
    }
  });
  const { author, articleDetails, metaTag, description } = data.article;
  const { image, topSection, middleSection, bottomSection } = articleDetails;
  
  const articleSchema = {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `https://www.littleskoolhouse.com/articles/${slug}`
    },
    headline: title,
    description: description,
    image: image.sourceUrl,  
    author: {
      "@type": "Person",
       name: author
    },  
    publisher: {
      "@type": "Organization",
      name: "",
      logo: {
        "@type": "ImageObject",
         url: ""
      }
    },
    datePublished: ""
  }
  return (
    <Layout
      bannerClass="expert-opinion"
      heading="Expert Opinion"
      subHeading={category.name}
      breadCrumbs={[
        { label: 'Articles', link: `/articles` },
        {
          label: category.name,
          link: `/articles/${category.slug}/${subCategory.slug}`
        },
        {
          label: subCategory.name,
          link: `/articles/${category.slug}/${subCategory.slug}`
        },
        {
          label: title
        }
      ]}
      metaData={{
        url: `https://www.littleskoolhouse.com/articles/${slug}`,
        title: `${metaTag.title} | The Little Skool-House` || '',
        description: metaTag.description || ''
      }}
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <script type="application/ld+json">
          {JSON.stringify(articleSchema)}
      </script>
    </Helmet>
      <div className="article-container">
        <div className="title-wrapper">
          <div className="back-btn">
            <Link to={`/articles/${category.slug}/${subCategory.slug}`}>
              <button className="yellow-funky-button">Back to list</button>
            </Link>
          </div>
          <div className="title">
            <div className={`category ${category.slug}`}>
              {subCategory.name}
            </div>
            <h5>{title}</h5>
            {author && (
              <>
                By <span className="author">{author}</span>
              </>
            )}
          </div>
        </div>
        <div className="banner-wrapper">
          <img src={image.sourceUrl} />
        </div>
        <div className="overview">
          <ReactMarkdown source={topSection} className="markdown-styling" />
          {middleSection && (
            <div className="article-banner">
              <h2> {middleSection.title} </h2>
              <div className="button-container">
                <Link to="/enquiry/contact-us">
                  <button className="button-primary">
                    {' '}
                    {middleSection.ctaButton1.buttonLabel}{' '}
                  </button>
                </Link>
                <Link to="/enquiry/enquiry/schedule-a-visit">
                  <button className="button-secondary">
                    {middleSection.ctaButton2.buttonLabel}
                  </button>
                </Link>
              </div>
              <p style={{ color: '#333' }}>{middleSection.description}</p>
              <br />
            </div>
          )}
          {bottomSection && <ReactMarkdown source={bottomSection} className="markdown-styling" />}
        </div>
        <div className="back-btn">
          <Link to={`/articles/${category}/${subCategory}`}>
            <button className="yellow-funky-button">Back to list</button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default ArticleNew;
