import React from 'react';
import Layout from '../../layout/layout';
import Graphic1 from '../../images/objects/enrichment-programme/graphic-1.png';
import Graphic2 from '../../images/objects/enrichment-programme/graphic-2.png';
import Graphic3 from '../../images/objects/enrichment-programme/graphic-3.png';
import Graphic4 from '../../images/objects/enrichment-programme/graphic-4.png';
import Graphic5 from '../../images/objects/enrichment-programme/graphic-5.png';
import Graphic6 from '../../images/objects/enrichment-programme/graphic-6.png';

const sections = [
  {
    text: {
      heading: `艺术欣赏`,
      subHeading: `透过引介不同的艺术形式，发挥孩子的想像力。`,
      content: [
        '我们提供各种艺术与工艺课程，从素描、绘画、马赛克，以及拼贴画等等。体验艺术与手工艺让幼儿学习珍惜和欣赏不同的文化与时代，也是他们探索、创造和实验过程中表达自己的一种方式。透过艺术欣赏，幼儿学会更自发、更自信、更有创意。'
      ]
    },
    graphic: Graphic1
  },
  {
    text: {
      heading: `创造性律动和舞蹈技巧`,
      subHeading: `透过各种创造性律动，加强幼儿的认知发展、粗大与精细动作技能。`,
      content: [
        `创造性律动与各种舞蹈形式刺激五感的其中三种：触觉、视觉和听觉，提供幼儿一个快乐地发展身体技能、消耗体力、刺激想像，以及激发创意的方式。透过创意性律动，幼儿将学会接受对音乐和律动因人而异的诠释，进而发展更丰富的社交技能与接纳异己。`
      ]
    },
    graphic: Graphic2
  },
  {
    text: {
      heading: `剑术`,
      subHeading: `一个良好的身心锻炼让幼儿养成成功的习惯。`,
      content: [
        `剑术经常被称为"身体象棋"并有助于培养幼儿的专注力，这专注力可延续于学业和其他生活方面。剑术是一项有助于发展手眼腿协调、平衡及快速反映的体能活动。这项运动的专注、纪律和决心，再加上推广荣誉、优雅以及骑士精神的品格为本课程，剑术会让幼儿掌握应对未来生活挑战的技能。`
      ]
    },
    graphic: Graphic3
  },
  {
    text: {
      heading: `演讲与戏剧课程`,
      subHeading: `建立自信的同时，也培养幼儿对文学的喜爱。`,
      content: [
        `演讲与戏剧课程让幼儿运用声音、演讲和语言的技巧，是学习有效沟通最好的方法。让他们从中发展自信、创造性想像、同理心，以及成熟的人际互动技巧。`
      ]
    },
    graphic: Graphic4
  },
  {
    text: {
      heading: `幼儿运动（运动球/ 桌球）`,
      subHeading: `鼓励幼儿培养无竞争压力但积极和健康的生活方式。`,
      content: [
        `从事体育活动对幼儿的身体发育以外的发展非常重要，对他们的心智、情感及社交都有影响。`,
        `运动球是一项国际知名的非竞争性体育教学课程，适合20个月以上的幼儿，目的在促进动作、社交及身体素质的发展。通过有创意的教学方法，为幼儿提供八大运动的概念与技巧：足球、桌球、篮球、排球、棒球、高尔夫球、橄榄球，以及曲棍球。`,
        `我们与通过教育部认证的教练们合作，为4岁以上的幼儿提供以华语教授课的桌球课程。这项运动不仅改善大脑健康，还能强化手眼协调、机动性和灵活性。`
      ]
    },
    graphic: Graphic5
  },
  {
    text: {
      heading: `STEAM课程`,
      subHeading: `STEAM教育为幼儿面对2023年教育部的应用框架做好准备。`,
      content: [
        `STEAM是一种应用科学（Science）、科技（Technology）、工程（Engineering）、艺术（Art），以及数学（Mathematics）的教育方法。基于过程导向学习—强调、探究及合作，我们旨在培养小小学庭的幼儿接受深思熟虑的风险、参与实验性学习，并通过有创意的解决问题流程以培养抗压性。所有的活动能让幼儿亲自动手做并参与学习流程的每一步。`
      ]
    },
    graphic: Graphic6
  }
];

const EPSection = ({
  section: {
    text: { heading, subHeading, content },
    graphic
  },
  right = false
}) => (
  <div className={`top-section ${right ? 'top-section-right' : ''}`}>
    <div className={`img-section${right ? '-mobile' : ''}`}>
      <img src={graphic} alt={heading} />
    </div>
    <div className="text">
      <h3>{heading}</h3>
      <h4>{subHeading}</h4>

      {content &&
        content.map(item => {
          return <p>{item}</p>;
        })}
    </div>
    {right && (
      <div className="img-section">
        <img src={graphic} alt={heading} />
      </div>
    )}
  </div>
);

const EnrichmentProgrammeCn = () => {
  return (
    <Layout
      bannerClass="curriculum-details"
      heading="增益课程"
      breadCrumbs={[
        { label: '我们的课程' },
        { label: '增益课程' }
      ]}
      metaData={{
        url:
          'https://www.littleskoolhouse.com/zh/womendekecheng/zengyikecheng',
        title: '增益课程 | 小小学庭',
        description:
          '我们增益课程有助于促进您孩子的全面发展，使他/她在课业和生活上都能有一番成就。今天就来了解我们是如何办到的'
      }}
    >
      <div className="learning-environment-container">
        {sections.map((section, i) => (
          <EPSection section={section} right={i % 2 !== 0} key={i} />
        ))}
      </div>
    </Layout>
  );
};

export default EnrichmentProgrammeCn;
