import React, { Fragment, useState } from 'react';
import Layout from '../../layout/layout';
import './articles.scss';
import { Link } from 'gatsby';
import Dropdown from '../../components/common/Dropdown/Dropdown';
import Learning from '../../images/objects/expert-opinion/learning.png';
import Behaviour from '../../images/objects/expert-opinion/behavior.png';
import Play from '../../images/objects/expert-opinion/play.png';
import HealthAndNutrition from '../../images/objects/expert-opinion/health-nutrition.png';
import Parenting from '../../images/objects/expert-opinion/parenting.png';
import { graphql, useStaticQuery } from 'gatsby';

const ArticleList = ({ pageContext: { articles, allCategories} }) => {

  const data = useStaticQuery(graphql`
    query {
      cms {
        expertOpinionArticles(first: 100) {
          nodes {
            expert_opinion {
              article {
                author
                description
              }
            }
            slug
            title
          }
        }
      }
    }
  `);
  const [subCategory, setSubCategory] = useState('');
  const [active, setActive] = useState();
  const [filteredArticle, setFilteredArticle] = useState(articles);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filterKey, setFilterKey]= useState(false);
  const subCatList = allCategories
    .find(cat => cat?.url === selectedCategory)
    ?.subCategories.map(sub => ({
      key: sub.key,
      value: sub.label
    }));
  const mapIcon = icon => {
    if (icon == 'parenting') return Parenting;
    if (icon == 'learning') return Learning;
    if (icon == 'behaviour') return Behaviour;
    if (icon == 'play') return Play;
    if (icon == 'health-and-nutrition') return HealthAndNutrition;
  };

  const handleChange = key => {
    const data = articles.filter(item => item.key === key);
    setFilteredArticle(data);
    setFilterKey(true);

  };

  return (
    <Layout
      bannerClass="expert-opinion"
      heading="Expert Opinion"
      breadCrumbs={[{ label: 'Articles' }]}
      metaData ={{
        url:`https://www.littleskoolhouse.com/articles`,
        title: 'Find Our Blog Articles On This Page | Little Skool-House',
        description: 'Childhood development is a tough topic to navigate. Gain more insights into effective parenting, early childhood education, and more on our blog page.'
      }}
    >
      <div className="expert-opinion-container">
        <p>
          Little Skool-House’s team of award-winning educators providing expert
          opinion on preschool education, parenting tips, health and nutrition,
          and family relationships.
        </p>
        <div className="article-selection-section">
          <div className="selector align-text tablet-and-desktop-only">
            {allCategories.map(item => {
              return (
                <Fragment key={item?.url}>
                  <div
                    className="category-option"
                    onClick={() => {
                      active === item?.url
                        ? setActive(null)
                        : setActive(item?.url);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <img
                      src={mapIcon(item?.icon)}
                      style={{ width: '30px', margin: '0px 4px -8px' }}
                    />
                    <label htmlFor={item?.url} style={{ cursor: 'pointer' }}>
                      {item?.label}
                    </label>
                  </div>
                  <div
                    className="sub-menu-container"
                    style={{
                      marginLeft: '30px',
                      display: `${item?.url !== active ? 'none' : ''}`
                    }}
                  >
                    {item &&
                      item.subCategories.map(subCat => {
                        return (
                          <div className="sub-category-option" key={subCat.key}>
                            <input
                              type="radio"
                              id={subCat.key}
                              name="subCategorys"
                              checked={subCategory === subCat.key}
                              onChange={() => {
                                handleChange(subCat.key);
                                setSubCategory(subCat.key);
                                setSelectedCategory(null);
                              }}
                            />
                            <label htmlFor={subCat.key}>{subCat.label}</label>
                          </div>
                        );
                      })}
                  </div>
                </Fragment>
              );
            })}
          </div>
          <div className="mobile-only-strict">
            <div
              className="category-dropdown"
              style={{
                marginBottom: '10px'
              }}
            >
              <Dropdown
                defaultValue={
                  selectedCategory ? selectedCategory : 'Please Choose Category'
                }
                onChange={cat => {
                  setSelectedCategory(cat);
                }}
                options={allCategories.map(item => ({
                  key: item?.url,
                  value: item?.label
                }))}
                category={true}
              />
            </div>
            {selectedCategory && (
              <div className="category-dropdown">
                <Dropdown
                  defaultValue={
                    subCategory ? subCategory : 'Please Choose Age Group'
                  }
                  onChange={subCat => {
                    handleChange(subCat);
                    setSubCategory(subCat);
                  }}
                  options={subCatList}
                />
              </div>
            )}
          </div>
          <div className="articles-list">
        {filterKey  ? filteredArticle.length > 0 &&
              filteredArticle.map(art => {
                const { slug, title, article } = art.content;
                const { author, description } = article;
                return (
                  <Link to={`/articles/${slug}`} key={slug}>
                    <div className={`article-card`}>
                      <div className="text">
                        <h4>{title}</h4>
                        {author && (
                          <p className="author">
                            <span>By </span>
                            {author}
                          </p>
                        )}
                        {description && (
                          <p className="subtitle">{description}</p>
                        )}
                      </div>
                      <div className="plus-sign">
                        <span>+</span>
                      </div>
                    </div>
                  </Link>
                );
              })
              :
               data && data.cms.expertOpinionArticles.nodes.length > 0 &&
               data.cms.expertOpinionArticles.nodes.map(art => {
                const { slug, title} = art;
                const { author, description } = art.expert_opinion.article;
                return (
                  <Link to={`/articles/${slug}`} key={slug}>
                    <div className={`article-card`}>
                      <div className="text">
                        <h4>{title}</h4>
                        {author && (
                          <p className="author">
                            <span>By </span>
                            {author}
                          </p>
                        )}
                        {description && (
                          <p className="subtitle">{description}</p>
                        )}
                      </div>
                      <div className="plus-sign">
                        <span>+</span>
                      </div>
                    </div>
                  </Link>
                );
              }) }
           </div>
        </div>
      </div>
    </Layout>
  );
};

export default ArticleList;
