import React, { useState } from 'react';
import Layout from '../../layout/layout';
import './tot-leader.scss';
import BgLsh from '../../images/tot/lsh-bg.jpg';
import LshLogo from '../../images/icons/lsh-logo-n.png';
import LshLogoW from '../../images/tot/LSH-log-white.jpg'
import Chimp from '../../images/tot/chimpanzee.png';
import Linyuzheng from '../../images/tot/linyuzheng.png';
import YuXuan from '../../images/tot/yu-xuan.png';
import arrow from "../../images/icons/left-arrow-blue.png"
import Mathieu from '../../images/tot/mathieu.png';
import Megan from '../../images/tot/megan.png';
import { navigate, Link } from 'gatsby';
import FB from '../../images/tot/FB.png';
import ytThumbnail from '../../images/tot/tot-video-li.png'
import VideoModal from '../../components/common/VideoModal/videoModal'
import playIcon from '../../images/icons/play.png'
import { FacebookButton, FacebookCount } from 'react-social';
import { APP_ID } from '../../config/appConfig';


const url = 'http://lsh-staging.firebaseapp.com/tot-talks/tot-leader-lyz';

const Experiences = [
  {
    image: LshLogoW,
    heading: [
      'Athlete',
      'Kindergarten 2 Student'
    ],
    title: ['Little Skool-House - Full Time', 'Singapore, Singapore'],
    description1:
      'The Little Skool-House is one of the leading preschools with a highly effective curriculum that not only develops children holistically, but has led to 93% of their students being highly proficient bilinguals in English and Mandarin.',
    description2:
      'Their educators are well versed in the methods and strategies of implementing literacy-based activities for preschoolers, setting the correct foundation and ensuring readiness for primary school and beyond.'
  },
  {
    image: Chimp,
    heading: [
      'Chimpanzee'
    ],
    title: ['Wizard of Oz', '2019'],
    description1:
      'The Wizard of Oz tells the heart-warming story of friendship and loyalty brought to life by the team at Little Skool-House starring a talented cast of Nursery 2 children. It is a story about a girl named Dorothy and her pet dog,',
    description2:
      'Toto who are taken far away with their home and their journey along the Yellow Brick Road to the Emerald City in search of the Wizard for his help.'
  }
];

const Tot = () => {
  const [readMore, setReadMore] = useState('');
  const [isOpen, setOpen] = useState(false)
  const closeModalHandler = () => {
    setOpen(false);
  };
  return (
    <Layout
      breadCrumbs={[{ label: 'Tot Talks', link: '/tot-talks' }, { label: 'Tot Leader' }]}
      noBanner={true}
      noFooter={true}
      metaData={{
        url: 'https://www.littleskoolhouse.com/tot-talks/tot-leader-lyz',
        title:
          ' Tot Talks - Tot Leader - Athlete | The Little Skool-House Singapore',
        description:
          '93% of our children are highly proficient in English and Mandarin. Discover just how articulate they are!',
        image: Linyuzheng
      }}
    >
      <div className="tot-container">
      {isOpen && (
         <VideoModal
         Open = {isOpen}
          videoId="U2KcCBAbHf8"
           handleClose={closeModalHandler}
         />
        )}
        <div>
          <img src={arrow} alt="logo"
          onClick={() =>
            navigate(
              '/tot-talks'
            )
          } />
        </div>
        <div className="t-row">
          <div className="t-col">
            <div className="inner-div">
              <div className="top-bg">
                <img src={BgLsh} />
                <div className="profile-pic">
                  <img src={Linyuzheng} className="tot-person-logo" />
                </div>
              </div>
              <div className="no-logo">
                <div className="mid-div">
                  <img src={LshLogo} style={{ width: '62px', margin: '5px' }} />
                  <p style={{ margin: '5px' }}>Little Skool-House</p>
                </div>
              </div>
              <div className="text-div d-only">
                <strong>Lin Yuzheng 林语正</strong>
                <p>Athlete at Little Skool-House Singapore</p>
                <p>
                  Singapore <span className="b-color">500+ connections</span>{' '}
                </p>
                <button
                  className="f-button"
                  onClick={() =>
                    navigate(
                      '/enquiry/schedule-a-visit'
                    )
                  }
                >
                  <u>Visit our Organisation</u>
                </button>
              </div>
              <div className="m-only" style={{ height: '140px' }}>
                <div
                  className="text-div profile-desc-mob"
                  style={{ padding: '10px 0px' }}
                >
                  <strong>Lin Yuzheng 林语正</strong>
                  <p>
                    Athlete at Little Skool-House
                    <br /> Singapore
                  </p>
                  <p>
                    Singapore <span className="b-color">500+ connections</span>{' '}
                  </p>
                  <button
                  className="f-button"
                  onClick={() =>
                    navigate(
                      '/enquiry/schedule-a-visit'
                    )
                  }
                >
                  <u>Visit our Organisation</u>
                </button>
                </div>
              </div>
              <div className="text-div">
                <strong>About Me</strong>
                <p>
                  I am an aspiring author whose favourite books are the 'The
                  Mornings of the Solar System' and '叫我小可爱'. Being both
                  creative and analytic, I excel at Math Art, creating
                  masterpieces with a flourish.
                </p>
              </div>
              <div className="text-div">
                <strong>Status Update</strong>
                <p>"Working is when you are doing something important.💻"</p>
              </div>
            </div>
            <div className="inner-div mobile-only">
              <div className="text-div">
                <strong>Most recent video</strong>
                <div className="tot-yt-section">
                 <img  src= {ytThumbnail} width="100%" alt="yt-thumbnail" />
                 <img src={playIcon} className="play-icon" onClick={()=> setOpen(true)}  alt="play-icon" />
                </div>
              </div>
            </div>
            <div className="inner-div">
              <div className="text-div">
                <strong>Experience</strong>
                <div>
                  {Experiences.map((item, i) => (
                    <div style={{ display: 'flex', margin: '20px 0px' }}>
                      <div>
                        <img src={item.image} width="62px" />
                      </div>
                      <div className="exp">
                        {item.heading.map(txt => (
                          <p className="tot-exp-head">{txt}</p>
                        ))}
                        {item.title.map(txt => (
                          <p>{txt}</p>
                        ))}
                        <div style={{ margin: '15px 0px' }}>
                          <p>
                            {item.description1}
                            {item.description2 && readMore !==i && <span className="tot-more">...</span>}
                            { item.description2 && readMore !==i && (
                              <button
                                className="t-r-more-btn"
                                onClick={() => setReadMore(i)}
                              >
                                [Read more...]
                              </button>
                            )}
                            {item.description2 && readMore === i && (
                              <span className="tot-more">
                                {item.description2}
                              </span>
                            )}
                            {item.description2 && readMore === i && (
                              <span
                                className="t-r-more-btn"
                                onClick={() => setReadMore(null)}
                              >
                                [Read less...]
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="t-col">
            <div className="inner-div desktop-only">
              <div className="text-div">
                <strong>Most recent video</strong>
                <div className="tot-yt-section">
                 <img  src= {ytThumbnail} width="100%" alt="yt-thumbnail" />
                 <img src={playIcon} className="play-icon" onClick={()=> setOpen(true)}  alt="play-icon" />
                </div>
              </div>
            </div>
            <div className="inner-div">
              <div className="text-div">
                <strong>Similar Professional Profiles</strong>
              </div>
              <div className="child-profile">
                <div style={{ width: 'fit-content' }}>
                  <img src={Mathieu} style={{ width: '120px' }} />
                </div>
                <div className="text-section">
                  <p className="y-color">Environmental Activist</p>
                  <h4>MATHIEU GOH</h4>
                  <p>Little Skool-House Singapore</p>
                  <p>
                    Singapore <span className="b-color"> 500+ connections</span>
                  </p>
                  <button
                    onClick={() => navigate('/tot-talks/tot-leader-mg')}
                    className="v-button"
                  >
                    View Profile
                  </button>
                </div>
              </div>
              <div className="child-profile">
                <div style={{ width: 'fit-content' }}>
                  <img src={Megan} style={{ width: '120px' }} />
                </div>
                <div className="text-section">
                  <p className="y-color">Life Coach</p>
                  <h4>MEGAN NG</h4>
                  <p>Little Skool-House Singapore</p>
                  <p>
                    Singapore <span className="b-color"> 500+ connections</span>
                  </p>
                    <button
                      onClick={() => navigate('/tot-talks/tot-leader-mn')}
                      className="v-button"
                    >
                      View Profile
                    </button>
                </div>
              </div>
              <div className="child-profile">
                <div style={{ width: 'fit-content' }}>
                  <img src={YuXuan} style={{ width: '120px' }} />
                </div>
                <div className="text-section">
                  <p className="y-color">Lawyer</p>
                  <h4>TEO YU XUAN 张予賓</h4>
                  <p>Little Skool-House Singapore</p>
                  <p>
                    Singapore <span className="b-color"> 500+ connections</span>
                  </p>
                  <button
                      onClick={() => navigate('/tot-talks/tot-leader-tyx')}
                      className="v-button"
                    >
                      View Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ margin: '30px auto', textAlign: 'center' }}>
          <h3 style={{ color: '#666', fontWeight: '600', fontSize: '1.5rem' }}>
          Find out how we create Tot Leaders such as Yuzheng through our unique curriculum.
          </h3>
          <div style={{ margin: '20px auto' }}>
            <Link to='/enquiry/limited-time-promotion'>
              <button className="yellow-funky-button button-tot">
                Schedule a Visit
              </button>
            </Link>
          </div>
          <div className="fb-share">
            <FacebookButton url={url} appId={APP_ID}>
              <FacebookCount url={url} />
              <button className="tot-fb-btn">
                <img src={FB} width="100" />
              </button>
            </FacebookButton>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Tot;
