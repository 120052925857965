import React, { useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet-async';
import Modal from 'react-modal';
import Layout from '../../layout/layout';
import './home.scss';
import HomeTiles from '../../components/home/HomeTiles/HomeTiles';
import CentersCarousel from '../../components/home/CentersCarousel/CentersCarousel';
import TestimonialsCarousel from '../../components/home/TestimonialsCarousel/TestimonialsCarousel';
import FlipBook from '../../components/home/FlipBook/FlipBook';
import FlipBookMobile from '../../components/home/FlipBookMobile/FlipBookMobile';
import Promotion from '../../components/home/Promotion/Promotion';
import EducationalOrganization from '../../components/common/jsonld/EducationalOrganization';
import WebSite from '../../components/common/jsonld/WebSite';
import { getWithExpiry, setWithExpiry } from '../../services/commonService';
import { ANNOUNCEMENT_CLOSE_EXPIRY_TIME } from '../../config/appConfig';
import { getLanguage } from '../../components/common/utils/utils';

const homeContentEn = {
  "section1_header": "The Little Skool-House Story",
  "section1_content1": "Step into a world of learning and adventure at The Little Skool-House. Here, we lead your little ones on the road to discovery so they can build their knowledge and grow.",
  "section1_link1": "Highly Proficient bilingually",
  "section1_link1_url": "/our-difference/high-proficiency-in-english-and-mandarin",
  "section1_content2": "Our award-winning teachers have received the Ministry of Education and Early Childhood Development Agency Awards consecutively for the past 9 years, establishing our position as one of the top preschools in Singapore.",
  "section1_link2": "We are also the chosen preschool for some of Singapore’s most prestigious institutions to provide preschool education for their own personnel and children.",
  "section1_link2_url": "/our-difference/chosen-preschool-by-top-institutions",
  "section1_content3": "Little Skool-House embarks on a learning journey that prepares your children, not only for school but also for life.",
  "section1_button_text": "Find out more",
  "section1_button_text_url": "/about-us/the-little-skool-house",
  "section2_header": "Top Preschool in Singapore since 1994",
  "section2_content1": "Little Skool-House is an established early childhood education provider with over 27 years of experience in Singapore. We have provided premium infant and childcare services since 1994 that cater to the needs of little ones from infancy to six years old.",
  "section2_content2" : "Our preschools offer a strong curriculum that effectively builds intrinsic love for learning, confidence, and academic skills necessary for primary school and life in Singapore.",
  "section2_content3": "Embark on the ideal education journey for your little ones with our preschools centres nearest to you. Our holistic preschool education curriculums and childcare services will build a strong foundation that shapes your child’s future.",

  section3_header: 'What Parents Say'
};
const homeContentCn = {
  "section1_header": "小小学庭的故事",
  "section1_content1": "走进我们的故事，了解孩子在小小学庭的学习与探险之旅。在小小学庭，我们引领孩子走上发现之路，驱使他们建构知识。根据南洋理工大学的研究调查，我们乐见93％的孩子",
  "section1_link1": "精通英语和华语",
  "section1_link1_url": "/zh/womendeyuzhongbutong/jīngtongyingyuhehuayu",
  "section1_content2": "我们的教师连续八年获得教育部及幼儿培育署颁授的奖项肯定。",
  "section1_link2": "我们更是新加坡最负盛名的权威机构的幼儿园首选",
  "section1_link2_url": "/zh/womendeyuzhongbutong/dingjianjigouxuanzedeyoueryuan",
  "section1_content3": "我们开启学习之旅，让孩子不仅为小学，也为生活做好准备。",
  "section1_button_text": "更多详情",
  "section1_button_text_url": "/zh/guanyuwomen",
  "section2_header": "新加坡知名的幼儿园",
  "section2_content1": "小小学庭成立于1994年，拥有超过25年经验，肩负为满足婴儿期至6岁孩童优质教育的需求及使命。我们幼儿园提供扎实的课程，培养学生学习的热情、掌握小学必要的学术技能，以及在新加坡生活的热情与信心。",
  "section2_content2": "我们提供全面性的幼儿教育课程及育儿服务。请带您的孩子到住家附近或您工作场所的幼儿中心，一起展开理想的教育旅程。",

  section3_header: '家长的话'
};

const HomePage = ({ pageContext: { centreDetails, language } }) => {
  const {
    site,
    logo,
    cms: { pages }
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
          telephone
          links
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        publicURL
      }
      cms {
        pages {
          nodes {
            home_page {
              flipBook {
                language
                flipBookPage {
                  flipBookTile {
                    buttonText
                    description
                    linkTo
                    image {
                      mediaItemUrl
                    }
                    bgimage {
                      mediaItemUrl
                    }
                    mobileImage {
                      mediaItemUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const [sectionContent, setSectionContent] = useState([]);
  const [activeLang, setLanguage] = useState([]);

  useEffect(() => {
    const lang = getLanguage();
    setLanguage(lang);
    if (lang == 'english') {
      setSectionContent(homeContentEn);
    } else {
      setSectionContent(homeContentCn);
    }
  }, []);

  const customMobile = {
    top: '20%',
    left: '10%',
    right: '10%',
    bottom: '20%',
    border: 'none'
  };
  const customWeb = {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    height: 'fit-content'
  };
  const style = {
    zIndex: 1010,
    background: 'rgb(87 85 85 / 75%)'
  };

  const { siteUrl, title, telephone, links } = site.siteMetadata;

  const absoluteLogoUrl = new URL(logo.publicURL, siteUrl).toString();
  const [show, setShow] = useState(false);
  const [width, setWidth] = useState(
    typeof window !== 'undefined' && window.innerWidth
  );
  const breakpoint = 500;
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', () => setWidth(window.innerWidth));
    }
  }, []);

  // useEffect(() => {
  //   if (!getWithExpiry('IS_PROMOTION_CLOSED')) {
  //     setShow(true);
  //   }
  // }, []);

  const handleShow = val => {
    if (val === false) {
      setWithExpiry(
        'IS_PROMOTION_CLOSED',
        true,
        ANNOUNCEMENT_CLOSE_EXPIRY_TIME
      );
      setShow(false);
    }
  };

  const lshHomeSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Little Skool House",
    image: "",
    "@id": "",
    url: "https://www.littleskoolhouse.com/",
    telephone: "65 6509 7880",
    address: {
      "@type": "PostalAddress",
      streetAddress: "1 Orchid Club Road",
      addressLocality: "",
      postalCode: "769162",
      addressCountry: "SG"
    } ,
    sameAs: [
      "https://www.facebook.com/littleskoolhouse/",
      "https://www.instagram.com/littleskoolhousesg/?igshid=xmhx42c453e6",
      "https://www.youtube.com/channel/UChJdWcrtM5zRGKDUFLdb5hQ"
    ] 
  }
  return (
    <Layout>
      {/* SEO */}
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <meta name="msvalidate.01" content="A46ED5F72FBE26236E09086CEE7552E5" />
        <script type="application/ld+json">
            {JSON.stringify(lshHomeSchema)}
        </script>
      </Helmet>
      <EducationalOrganization
        name={title}
        url={siteUrl}
        logo={absoluteLogoUrl}
        telephone={telephone}
        sameAs={links}
      />
      <Modal
        isOpen={show}
        style={{
          overlay: style,
          content: width > breakpoint ? customWeb : customMobile
        }}
        contentLabel="corporate-modal"
        onRequestClose={() => {
          setShow(false);
        }}
        ariaHideApp={false}
      >
        <div style={{ position: 'relative' }}>
          <Promotion />
          <button
            onClick={() => handleShow(false)}
            style={{
              position: 'absolute',
              top: '-18px',
              right: '-14px',
              width: '20px',
              borderRadius: '50%',
              cursor: 'pointer'
            }}
          >
            x
          </button>
        </div>
      </Modal>
      <WebSite url={siteUrl} name={title} />

      <div className="mobile-only-strict">
        <FlipBookMobile data={pages} />
      </div>
      <div className="home-container">
        <div className="tablet-and-desktop-only">
          <FlipBook data={pages} />
        </div>

        <div className="subtext text-center">
          <h1>{sectionContent.section1_header}</h1>
          <p>
            {sectionContent.section1_content1}
            {/* <a
              href={sectionContent.section1_link1_url}
              target="blank"
              color="yellow"
            >
              {' '}
              {sectionContent.section1_link1}
            </a> */}
            { ' ' }
            {sectionContent.section1_content2}
            {
              activeLang === "english" ? (
                <span>
                {' '}93% of our children have high proficiency in both English and Mandarin as assessed in a Nanyang Technological University study.
                <a
                href="our-difference/chosen-preschool-by-top-institutions"
                target="blank"
              >
                {' '}
                {sectionContent.section1_link2}
              </a>
              </span>
              ) : (
                <a
                href="/zh/womendeyuzhongbutong/dingjianjigouxuanzedeyoueryuan"
                target="blank"
              >
                {' '}
                {sectionContent.section1_link2}
              </a>
              )
            }
            {/* <a
              href={sectionContent.section1_link2_url}
              target="blank"
            >
              {' '}
              {sectionContent.section1_link2}
            </a> */}
          </p>
          <br/>
          <p>
            {sectionContent.section1_content3}
          </p>
          <span className='new-find-out-button'>
            <Link
              to={sectionContent.section1_button_text_url}
              className="find-out-more-btn-holder"
            >
              <button className="yellow-funky-button">
                {sectionContent.section1_button_text}
              </button>
            </Link>
            </span>
        </div>
        <div className="desktop-only">
          <HomeTiles />
        </div>
        <div className="mobile-only">
          <HomeTiles mobile={true} />
        </div>
        <div className="subtext text-center">
          <h1> {sectionContent.section2_header} </h1>
          <div>
            <p>{sectionContent.section2_content1} {sectionContent.section2_content2}</p>
            <br/>
            <p>{sectionContent.section2_content3}</p>
          </div>
        </div>
        <CentersCarousel centreDetails={centreDetails} />

        <div className="subtext text-center">
          <h2>{sectionContent.section3_header}</h2>
        </div>
        <TestimonialsCarousel />
      </div>
    </Layout>
  );
};

export default HomePage;
