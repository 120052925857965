import React from 'react';
import ResponsiveEmbed from 'react-responsive-embed';
import PropTypes from 'prop-types';
import './YoutubeVideo.scss';

const YoutubeVideo = ({
  title,
  youtubeVideoId,
  fullscreen = true,
  autoPlay,
  startTime='0'
}) => {
  const videoUrl1 = `https://www.youtube.com/embed/${youtubeVideoId}?controls=1&rel=0&start=${startTime}`;
  const videoUrl2 = `https://www.youtube.com/embed/${youtubeVideoId}?controls=1&rel=0&mute=1&autoplay=1&loop=1&playlist=${youtubeVideoId}`;
  return (
    <div className="video-container">
      <ResponsiveEmbed
        src={autoPlay ? videoUrl2 : videoUrl1}
        allowFullScreen={fullscreen}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
      />
    </div>
  );
};

YoutubeVideo.propTypes = {
  title: PropTypes.string.isRequired,
  youtubeVideoId: PropTypes.string.isRequired
};

export default YoutubeVideo;
