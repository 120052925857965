import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { navigate } from 'gatsby';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { saveEnquiry } from '../../../api/api';
import Dropdown from '../../../components/common/Dropdown/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import {
    TextField,
    TextArea
} from '../../../components/common/FormInput/FormInput';
import './ContactUsforms.scss'
import { centres, aboutUsNewCn, AllowedDomians } from '../../../config/appConfig';


const errorMsg =
    "Sorry, we can't process your enquiry now. Please try again later";

const ContactUsCnForm = () => {
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const formRef = useRef();
    const [centreData, setCentreData] = useState([]);
    const [contact_options, setContactOptions] = useState({});

    const [userToken, setUserToken] = useState('');
    const { executeRecaptcha } = useGoogleReCaptcha();
  
    const handleReCaptchaVerify = useCallback(() => {
      if (!executeRecaptcha) {
        return;
      }
      (async () => {
        try {
          const token = await executeRecaptcha('submit');
          setUserToken(token);
        } catch (error) {
            console.error("Something went wrong",error);        }
      })();
    }, [executeRecaptcha]);
  
  
    useEffect(() => {
      handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);


    const handleForm = async formData => {
        handleReCaptchaVerify();
        setFormSubmitting(true);

        try {
            const { status } = await saveEnquiry({...formData, recaptcha_response: userToken});
            setFormSubmitting(false);

            if (status !== 'success') {
                setSubmitError(errorMsg);
                return;
            }

            navigate('/zh/xunwen/lianluowomen/xiexie');

            const form = formRef.current;
            if (form) {
                form.resetValidationState(true);
            }
        } catch (e) {
            setSubmitError(errorMsg);
            setFormSubmitting(false);
        }
    };

    const setCheckBoxValue = (name) => {
        const data = { ...contact_options };
        data[name] = !data[name];

        if (name == "All" && data[name]) {
            data.Email = true;
            data.SMS = true;
            data.Phone = true;
        }
        else if (name == "All" && !data[name]) {
            data[name] = false;
            data.Email = false;
            data.SMS = false;
            data.Phone = false;
        }
        if (data.Email && data.SMS && data.Phone) {
            data.All = true;
        }
        if (!data.Email || !data.SMS || !data.Phone) {
            data.All = false;
        }

        setContactOptions(data);
    };

    return (
            <div className="contact-container">
                <h4>联络我们</h4>
                <p className="contact-numbers">
                    请填写我们的在线表格以进行中心相关查询。如需反馈和一般查询，请致电 +65 6509 7880 或发送电子邮件至
                    {' '}
                    <a href="mailto:info@littleskoolhouse.com" target="_blank">
                        info@littleskoolhouse.com
                    </a>
                </p>

                <div className="form-wrapper-contact">
                    {submitError && (
                        <div className="submit-error error">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="icon" />
                            {submitError}
                        </div>
                    )}

                    <Formik
                        initialValues={{
                            name: '',
                            email: '',
                            phone: '',
                            subject: 'Contact Us',
                            about_us: 'N/A',
                            about_us_others: '',
                            centre: '',
                            message: '',
                            contact_options: '',
                            channel: 'Website - CN'
                        }}
                        validationSchema={Yup.object({
                            name: Yup.string().trim().required('请输入您的姓名'),
                            email: Yup.string()
                                .email('请输入有效的电子邮件地址')
                                .required('请输入您的电子邮箱地址'),
                            phone: Yup.string()
                                .matches(/^[0-9]{8}$/, '电话号码必须为 8 位数字')
                                .required('请输入您的联系电话'),
                            about_us_others: Yup.string().max(
                                200,
                                '选项太长（最多 200 个字符）'
                            ),
                            message: Yup.string()
                                .trim()
                                .required('请写下您的评论')
                                .max(4096, '您的评论太长（最多 4096 个字符）')
                            // about_us: Yup.mixed()
                            //     .required('请选择一个选项')
                            //     .notOneOf([aboutUsNewCn[0]], '请选择一个选项'),
                            // centre: Yup.mixed()
                            //     .required('请选择一个选项')
                            //     .notOneOf(['选择中心'], '请选择一个选项'),
                        })}
                        onSubmit={(formData, actions) => {
                            if (
                                (formData.about_us === 'Others' || formData.about_us === '其他') &&
                                formData.about_us_others.trim() === ''
                            ) {
                                actions.setFieldError(
                                    'about_us_others',
                                    '请明确说明'
                                );
                                actions.setSubmitting(false);

                                return;
                            }
                            let domain = formData.email.split('.')
                            let charactersAfterDot = domain[domain.length - 1];                      
                            if (!AllowedDomians?.includes(charactersAfterDot)) {
                                actions.setFieldError('email', '请输入有效的电子邮件地址');
                                actions.setSubmitting(false);
                                return;
                            }
                            formData.contact_options = ''
                            let finalString = Object.keys(contact_options).filter(key => contact_options[key])
                            if (finalString.includes('All')) {
                                formData.contact_options = finalString.filter(ele => ele != 'All').toString()
                            }
                            else {
                                formData.contact_options = finalString.toString()
                            }

                            if (!formData.centre) {
                                formData.centre = 'HQ';
                              }

                            handleForm(formData).then(() => {
                                actions.setSubmitting(false);
                                actions.resetForm();
                                setContactOptions({})
                            });
                        }}
                    >
                        {({ errors, setFieldValue, values }) => (
                            <Form>
                                <div className="form-controls-en">
                                    <div className="left-col-en">
                                        <div className="form-input-contact">
                                            <div className="label-wrapper-en">
                                                <label htmlFor="name">姓名</label>
                                                <span className="required-label">*</span>
                                            </div>
                                            <div className="input-wrapper-en">
                                                <TextField type="text" id="name" name="name" />
                                            </div>
                                        </div>
                                        <div className="form-input-contact">
                                            <div className="label-wrapper-en">
                                                <label htmlFor="email">电子邮箱地址</label>
                                                <span className="required-label">*</span>
                                            </div>
                                            <div className="input-wrapper-en">
                                                <TextField type="email" id="email" name="email" />
                                            </div>
                                        </div>

                                        <div className="form-input-contact">
                                            <div className="label-wrapper-en">
                                                <label htmlFor="phone">联系电话</label>
                                                <span className="required-label">*</span>
                                            </div>
                                            <div className="input-wrapper-en">
                                                <TextField type="text" id="phone" name="phone" />
                                            </div>
                                        </div>
                                       
                                        <div className="form-input-contact">
                                            <div className="label-wrapper-en">
                                                <label htmlFor="about">如有首选中心</label>
                                            </div>
                                            <div className="input-wrapper-en">
                                                {/* {centreData[0] && */}
                                                <Dropdown
                                                    resetValue={!values.centre && '选择中心'}
                                                    onChange={centre => {
                                                        setFieldValue('centre', centre, true);
                                                    }}
                                                    options={centres.map(item => ({
                                                        key: item.location || item.centreName,
                                                        value: item.location || item.centreName,
                                                        label: item.chinese,
                                                        notClickable: item.location && !item.centreName
                                                    }))}
                                                    theme="white"
                                                />
                                                {/* } */}
                                                {errors.centre && (
                                                    <div className="error">
                                                        <FontAwesomeIcon
                                                            icon={faExclamationTriangle}
                                                            className="icon"
                                                        />
                                                        {errors.centre}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="form-input-contact a-top">
                                        <div className="label-wrapper-en">
                                           <label htmlFor="message">询问</label>
                                           <span className="required-label">*</span>
                                        </div>
                                        <div className="input-wrapper-en">
                                            <TextArea className="input" id="message" name="message" />
                                        </div>
                                      </div> 

                                        <div className="form-input-contact">
                                            <div className="label-wrapper-en">
                                                <label htmlFor="about">
                                                    您是通过哪些渠道知道小小学庭的？
                                                </label>
                                            </div>
                                            <div className="input-wrapper-en">
                                                <Dropdown
                                                    resetValue={!values.about_us && aboutUsNewCn[0]}
                                                    onChange={about => {
                                                        setFieldValue('about_us', about, true);
                                                    }}
                                                    options={aboutUsNewCn.map(item => ({
                                                        key: item,
                                                        value: item
                                                      }))}
                                                    theme="white"
                                                />
                                                {errors.about_us && (
                                                    <div className="error">
                                                        <FontAwesomeIcon
                                                            icon={faExclamationTriangle}
                                                            className="icon"
                                                        />
                                                        {errors.about_us}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-input-contact">
                                            <div className="label-wrapper-en">
                                                <label htmlFor="about_us_others">
                                                    如有其他，请注明
                                                </label>
                                            </div>
                                            <div className="input-wrapper-en">
                                                <TextField
                                                    type="text"
                                                    id="about_us_others"
                                                    name="about_us_others"
                                                />
                                            </div>
                                        </div>
                                     
                                    </div>
                                    <div className="right-col">
                                        <div className="form-input-contact">
                                            <div className="label-wrapper" />
                                            <div className="input-wrapper" />
                                        </div>
                                    </div>
                                </div>

                                <br />
                                <div className="agreement-wrapper-contact agreement-col-contact">
                                    提交此表格,即表示您认可并同意我们的
                                    <a href="https://www.littleskoolhouse.com/others/privacy-policy" target="_blank" style={{ textDecoration: 'underline', color: '#4ec7e2' }}>
                                        隐私政策
                                    </a>
                                    。职总优儿学府合作社旗下的学前教育机构—小小学庭国际及职总优儿学府幼乐园,
                                    将就注册事项及/或更新托儿服务相关信息,与您联系。
                                    <br /><br />
                                    是的，我愿意透过以下方式，收到小小学庭国际及职总幼儿学府幼乐园
                                    (职总优儿学府合作社旗下的学前教育机构，皆为其附属机构和合作伙伴) 的托儿服务建议、
                                    专家提供的育儿技巧、新产品信息及促销方案。
                                    <br /><br />
                                    <div className="checkbox">
                                        <input
                                            type="checkbox"
                                            value="All"
                                            name="contact_options"
                                            checked={contact_options.All}
                                            onClick={() => setCheckBoxValue("All")} />全部
                                    </div>
                                    <div className="checkbox" style={{ paddingLeft: '2%' }}>
                                        <input
                                            type="checkbox"
                                            value="Email"
                                            name="contact_options"
                                            checked={contact_options.Email}
                                            onClick={() => setCheckBoxValue("Email")} />电邮
                                    </div>
                                    <div className="checkbox" style={{ paddingLeft: '2%' }}>
                                        <input
                                            type="checkbox"
                                            value="SMS"
                                            name="contact_options"
                                            checked={contact_options.SMS}
                                            onClick={() => setCheckBoxValue("SMS")} />短信
                                    </div>
                                    <div className="checkbox" style={{ paddingLeft: '2%' }}>
                                        <input
                                            type="checkbox"
                                            value="Phone"
                                            name="contact_options"
                                            checked={contact_options.Phone}
                                            onClick={() => setCheckBoxValue("Phone")} />电话
                                    </div>
                                </div>

                                <div className="form-input-contact" style={{ paddingTop: '15px', display: 'flex', justifyContent: 'center' }}>
                                    <button
                                        type="submit"
                                        className="yellow-funky-button button-pad"
                                        disabled={formSubmitting}
                                    >
                                        {formSubmitting ? '提交中...' : ' 提交 '}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
    );
};
  
export default ContactUsCnForm;
