import React from 'react';
import Layout from '../../layout/layout';
import Graphic1 from '../../images/objects/93percent.png';
import Graphic2 from '../../images/objects/award-winning.png';
import Graphic3 from '../../images/objects/choice.png';
import { EPSection } from '../our-curriculum/4-6-years-literacy-based-curriculum';
import './why-choose-the-little-skool-house.scss';

const sections = [
  {
    text: {
      content: (
        <>
          <div className="heading">
            <h3>
              Our highly effective curriculum not only{' '}
              <br className="tablet-and-desktop-only" />
              develops children holistically, but has led to
              <span className="percent mobile-only-strict">
                93<span>%</span>
              </span>
            </h3>
            <div className="percent tablet-and-desktop-only">
              93<span>%</span>
            </div>
          </div>
          <p>
            of our children being Highly Proficient bilinguals in English and
            Mandarin as documented in a <a className="lsh-anchor-common" href="https://research.littleskoolhouse.com/" target="_blank">study</a> by Dr Setoh Pei Pei (PhD, The
            University of Illinois) Nanyang Technological University in 2019.
          </p>
        </>
      )
    },
    graphic: Graphic1
  },
  {
    text: {
      content: (
        <>
          <div className="heading">
            <div>
              <p>Our award-winning teachers have received</p>
              <h3>
                Ministry of Education and ECDA awards
                <br className="tablet-and-desktop-only" /> consecutively for the
                past{' '}
                <span className="percent mobile-only-strict">
                  9 <span>years</span>
                </span>
              </h3>
            </div>
            <div className="percent tablet-and-desktop-only">
              9 <span>years</span>
            </div>
          </div>
        </>
      )
    },
    graphic: Graphic2
  },
  {
    text: {
      content: (
        <>
          <div className="heading">
            <h3>
              We are the chosen preschool for some of Singapore’s most
              prestigious institutions{' '}
              <span className="lighter">
                to provide preschool education for their own personnel and
                children.
              </span>
            </h3>
          </div>
        </>
      )
    },
    graphic: Graphic3
  }
];

const WhyChooseLSH = () => {
  return (
    <Layout
      bannerClass="our-difference"
      heading={[
        'Why Choose ',
        <br className="mobile-only-strict" key={1} />,
        'The Little Skool-House'
      ]}
      breadCrumbs={[
        { label: 'Our Difference' },
        { label: 'Why Choose The Little Skool-House' }
      ]}
      metaData={{
        url:
          'https://www.littleskoolhouse.com/our-difference/why-choose-the-little-skool-house',
        title:
          'Best Childcare in Singapore | Little Skool-House',
        description:
          'Offering the best childcare in Singapore, our curriculum has led to 93% of our children to be highly proficient in English and Mandarin. Learn more here.'
      }}
    >
      <div className="learning-environment-container why-lsh-container">
        <h2 className="intro">
          We have guided thousands of children in achieving our many learning
          goals over the past 27 years
        </h2>
        {sections.map((section, i) => (
          <EPSection section={section} right={i % 2 !== 0} key={i} />
        ))}
        <h1>
        High Quality Childcare For Every Child
        </h1>
        <p className='text-center'>
        The best childcares in Singapore recognises the importance of each child’s well-being and growth. Little Skool-House goes above and beyond, shaping the child’s growth in a safe and nurturing environment while building healthy relationships. 
        </p>
      </div>
    </Layout>
  );
};

export default WhyChooseLSH;
