import React from 'react';
import { Helmet } from 'react-helmet-async';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Link } from 'gatsby';
import Layout from '../../layout/layout';
import MothersDayLandingForm from '../../components/common/ScheduleVisitForm/MothersDayLandingForm';
import '../../templates/schedule-a-visit/schedule-a-visit.scss';
import { MlCentres20may, RECAPTCHA_KEY } from '../../config/appConfig';
import MdLandingImg from '../../images/mothers-day-celebration-2.png';

const MothersDayEvent = () => {
  return (
    <Layout
      bannerClass="schedule"
      heading="Events"
      pageLn="en"
      breadCrumbs={[{ label: 'Events' }]}
      noBanner={true}
      metaData={{
        url: 'https://www.littleskoolhouse.com/mothers-day-event-20-may/',
        title: 'Mother’s Day Celebration | The Little Skool-House',
        description:
          'Visit one of our Little Skool-House centres to see how 93% of our graduates are Highly Proficient in Both English and Mandarin. Schedule a visit today.'
      }}
    >
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="schedule-container">
        <div>
          <img src={MdLandingImg} className="md-landing-img" />
        </div>
        <div className="md-landing-desc">
          <div className="md-landing-desc-child">
            ❤️ Create unforgettable memories with your little ones through our
            specially curated parent-child activities, including storytelling,
            sensory play experiences, and more.
          </div>
          <div className="md-landing-desc-child">
            ✨ Take home a sustainable 'pot of love' craft as a keepsake of your
            time with us and an exclusive goodie bag
          </div>
          <div className="md-landing-desc-child">
            ⚽️ Explore our engaging Science, Music & Sports activities in
            partnership with our learning partners!
          </div>
        </div>
        <div className="md-landing-list-ul">
          <div className="md-landing-list-desc">
            RSVP for your preferred centre:
          </div>
          <ul>
            <li>
              At-Marina-Boulevard (Raffles Place)
            </li>
            <div className="md-padding-10">Featuring talk by Flip for Joy at 11am on “Chinese language for our children - living and loving it!” by founder Jiang Meiru. Meiru is a trained Chinese language teacher with experience teaching in a local junior college and is a mother of two. She is passionate about reading, the learning of the Chinese language and raising bilingual children.</div>
          </ul>
        </div>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
          <MothersDayLandingForm
            type="mothers-day-20"
            eventType="event_may_20"
            centerList={MlCentres20may}
          />
        </GoogleReCaptchaProvider>
      </div>
    </Layout>
  );
};

export default MothersDayEvent;
