import React, { useState, useEffect } from "react";
import "./happenings.scss";
import Layout from "../../layout/layout";
import { navigate, graphql, Link, useStaticQuery } from "gatsby";
import Dropdown from "../../components/common/Dropdown/Dropdown";
// import { promotions } from '../../../static-data';
import PlayIcon from "../../images/icons/play.png";
import {
  BUILDERS_INNOVATION_PROJECT_VIDEO,
  FULL_CYCLE_CHINESE_THEATRE_PEDAGOGY,
} from "../../constants";
import { getLanguage } from "../../components/common/utils/utils";

const moment = require("moment");

const DateBox = ({ date }) => {
  if (!date) return null;
  var day = moment(date, "DD/MM/YYYY").format("DD");
  var month = moment(date, "DD/MM/YYYY").format("MMM YY");
  return (
    <div className="date">
      <div className="dd">{day}</div>
      <div className="my">{month}</div>
    </div>
  );
};

const CatalogImage = ({ image, altText, press, play }) => {
  return (
    <div className="image-wrapper">
      {play ? (
        <div style={{ position: "relative" }}>
          <img
            className="image"
            src={image}
            alt={altText}
            imgStyle={{
              objectFit: press ? "contain" : "cover",
            }}
          />
          <img
            src={PlayIcon}
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      ) : (
        <img
          className="image"
          src={image}
          alt={altText}
          imgStyle={{
            objectFit: press ? "contain" : "cover",
          }}
        />
      )}
    </div>
  );
};

const Story = ({ slug, happening_our_stories, title }) => {
  const { thumbnail, shortDescription, storyDate } = happening_our_stories;
  const story = (
    <div className="happenings-catalog-item">
      <CatalogImage image={thumbnail.sourceUrl} altText={title} />

      <div className="details-wrapper">
        {storyDate && <DateBox date={storyDate} />}
        <div className="title-wrapper">
          <div className="title">
            <h5>{title}</h5>
          </div>
          <div className="description">{shortDescription}</div>
        </div>
      </div>
    </div>
  );

  return <Link to={`/happenings/ourstories/${slug}`}>{story}</Link>;
};

const MediaCoverageLink = ({ happening_media_cov }) => {
  const { thumbnail, title, shortDescription, mediaCoverageLink } = happening_media_cov;
  return (
    <a href={mediaCoverageLink} rel="nofollow" target="blank">
      <div className="happenings-catalog-item press">
        <CatalogImage
          image={thumbnail.sourceUrl}
          className="press-image"
          press={true}
        />
        <div className="details-wrapper">
          <div className="title-wrapper">
            <div className="title">
              {title && title.title2 ? (
                <>
                  <h5>{title.title1}</h5>
                  <h5>{title.title2}</h5>
                </>
              ) : (
                <h5>{title.title1}</h5>
              )}
              <>
                By <span className="author">{shortDescription}</span>
              </>
            </div>
          </div>
        </div>

        <div className="tab">+</div>
      </div>
    </a>
  );
};
const Promotions = ({ slug, title, happening_promotions, lan }) => {
  const { thumbnail, promotionLink } = happening_promotions;
  const custom = {
    english: {
      path: `/happenings/promotions/${slug}`
    },
    chinese: {
      path: `/zh/chuanmeiyuhuodong/cuxiaohuodong/${slug}`
    },
  }

  const promotion = (
    <div className="happenings-catalog-item promotion">
      <CatalogImage image={thumbnail.sourceUrl} altText={title.line} />
      <div className="details-wrapper">
        <div className="title-wrapper">
          <div className="title">
            <h5>{title}</h5>
          </div>
        </div>
      </div>
      <div className="tab">+</div>
    </div>
  );
  return promotionLink ? (
    <Link to={new URL(promotionLink).pathname}> {promotion} </Link>
  ) : (
    <Link to={custom[lan].path}>{promotion}</Link>
  );
};

const VideoGallery = ({ slug, happening_videos, lan }) => {
  const { thumbnail, title, shortDescription } = happening_videos;
  const customLink = {
    english: {
      videoLink: `/happenings/videos/${slug}`
    },
    chinese: {
      videoLink: `/zh/chuanmeiyuhuodong/shipin/${slug}`
    }
  }
  if (
    slug === BUILDERS_INNOVATION_PROJECT_VIDEO ||
    slug === FULL_CYCLE_CHINESE_THEATRE_PEDAGOGY
  )
    return null;
  return (
    <Link to={customLink[lan].videoLink}>
      <div className="happenings-catalog-item">
        <CatalogImage
          image={thumbnail.sourceUrl}
          play={true}
          altText={title.line1}
        />

        <div className="details-wrapper">
          <div className="title-wrapper">
            <div className="title">
              {title && title.line2 ? (
                <>
                  <h5>{title.line1}</h5>
                  {/* <h5>{title.line2}</h5> */}
                </>
              ) : (
                <h5>{title.line1}</h5>
              )}
            </div>
            <div className="description">
              {shortDescription && shortDescription}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const HappeningsCatalog = ({ category, items, lan }) => {
  const { stories, mediacoverage, promotions, videos } = items;
  if (category === "mediacoverage") {
    return (
      <div className="happenings-catalog">
        {mediacoverage
          .map((item, i) => (
            <MediaCoverageLink key={i} {...item} />
          ))}
      </div>
    );
  }
  if (category === "promotions") {
    let filteredPromotions = promotions.filter(d => d.slug !=="ntuc-health-staff" & d.slug !=="ntuc-income-staff" & d.slug !=="fairprice-group-staff");
    return (
      <div className="happenings-catalog">
        {filteredPromotions
          .filter((x) => x.happening_promotions.language?.toLowerCase() == lan)
          .map((item, i) => (
            <Promotions key={i} {...item} lan={lan} />
          ))}
      </div>
    );
  }
  if (category === "videos") {
    return (
      <div className="happenings-catalog">
        {videos
          .filter((k) => k.happening_videos.language?.toLowerCase() == lan)
          .map((item, i) => (
            <VideoGallery key={i} {...item} lan={lan} />
          ))}
      </div>
    );
  }

  return (
    <div className="happenings-catalog">
      {stories.map((item, i) => (
        <Story key={i} {...item} />
      ))}
    </div>
  );
};

const Happenings = ({ pageContext: { category, categoryUrl }, language }) => {
  const {
    cms: {
      happeningOurStories: { nodes: happeningOurStories },
      happeningPromotions: { nodes: happeningPromotions },
      happeningVideos: { nodes: happeningVideos },
      happeningMediaCoverages: { nodes: happeningMediaCoverages },
    },
  } = useStaticQuery(graphql`
    {
      cms {
        happeningOurStories(first: 100) {
          nodes {
            slug
            happening_our_stories {
              thumbnail {
                sourceUrl
              }
              storyDate
              shortDescription
            }
            title
          }
        }
        happeningPromotions(first: 100) {
          nodes {
            title
            slug
            happening_promotions {
              language
              thumbnail {
                sourceUrl
              }
              metaTag {
                metaDescription
                metaTitle
              }
              promotionLink
            }
          }
        }
        happeningVideos(first: 100) {
          nodes {
            slug
            happening_videos {
              language
              title {
                line1
                line2
              }
              metaTag {
                metaDescription
                metaTitle
              }
              shortDescription
              thumbnail {
                sourceUrl
              }
            }
          }
        }
        happeningMediaCoverages(first: 100) {
          nodes {
            happening_media_cov {
              language
              title {
                title1
                title2
              }
              shortDescription
              thumbnail {
                sourceUrl
              }
              mediaCoverageLink
            }
          }
        }
      }
    }
  `);

  const [activeLang, setActiveLang] = useState("english");
  const [menu, setMenu] = useState([]);
  useEffect(() => {
    const lang = getLanguage();
    if (lang === "english") {
      setMenu(categories);
    } else {
      setMenu(categoriesCn);
    }
    setActiveLang(lang);
  }, [1]);

  const categoryHandler = (category) => {
    if (activeLang === "chinese") {
      navigate(`/zh/chuanmeiyuhuodong/${category}`);
    } else {
      navigate(`/happenings/${category}`);
    }
  };

  const happeningsList = {
    stories: happeningOurStories,
    promotions: happeningPromotions,
    mediacoverage: happeningMediaCoverages,
    videos: happeningVideos,
  };
  const categories = [
    {
      title: "ourstories",
      label: "our stories",
      url: "ourstories"
    },
    {
      title: "promotions",
      label: "promotions",
      url: "promotions"
    },
    {
      title: "videos",
      label: "videos",
      url: "videos"
    },
    {
      title: "mediacoverage",
      label: "media coverage",
      url: "mediacoverage"
    },
  ];
  const categoriesCn = [
    {
      title: "mediacoverage",
      label: "新闻稿",
      url: 'xinwengao'
    },
    {
      title: "promotions",
      label: "促销活动",
      url: 'cuxiaohuodong'
    },
    {
      title: "videos",
      label: "视频",
      url: 'shipin'
    },
  ];

  const metaDataStory = {
    url: "https://www.littleskoolhouse.com/happenings/ourstories",
    title: "Read Our Stories On This Page | Little Skool-House",
    description: `Get to know Little Skool-House. Find the latest headlines, happenings, and news stories featuring Little Skool-House, our teachers, and our students here.`
    };
  const metaDataPromotion = {
    english: {
      url: "https://www.littleskoolhouse.com/happenings/promotions",
      title: "Visit This Page to See Our Promotions | Little Skool-House",
      description: `Want to kickstart your child's learning journey with Little Skool-House? Visit this page for more information about all our ongoing enrolment promotions.`
    },
    chinese: {
      url: "https://www.littleskoolhouse.com/zh/chuanmeiyuhuodong/cuxiaohuodong",
      title: "促销活动 | 小小学庭",
      description:
        "小小学庭和我们获奖无数的教育者致力于建立不同凡响的学术基础。查看我们的学杂费减免优惠。",
    }
  };
  const metaDataVideo = {
    english: {
      url: "https://www.littleskoolhouse.com/happenings/videos",
      title: "Watch Our School Videos On This Page | Little Skool-House",
      description: `Get a glimpse into our relationships-based curriculums, teaching methods, and our children's school life. Find the latest videos about Little Skool-House here.`
    },
    chinese: {
      url: "https://www.littleskoolhouse.com/zh/chuanmeiyuhuodong/shipin",
      title: "视频 | 小小学庭",
      description:
        "小小学庭是一所实施双语教学的学前教育中心，致力于培育孩子掌握中英文双语的能力。今天就联系我们的教育者以了解我们即将举办的活动。",
    }
  };
  const metaDataMediaCoverage = {
    english: {
      url: "https://www.littleskoolhouse.com/happenings/mediacoverage",
      title: "Check Out Our Media Coverages | Little Skool-House",
      description: `Little Skool-House has been featured multiple times across various media platforms over the years. Visit this page to view some of our media coverages.`
    },
    chinese: {
      url: "https://www.littleskoolhouse.com/zh/chuanmeiyuhuodong/xinwengao",
      title: "新闻稿 | 小小学庭",
      description:
        "翻阅新加坡小小学庭的最新动态。",
    }
  };
  let metaData;
  if (category === "ourstories") {
    metaData = metaDataStory;
  } else if (category === "mediacoverage") {
    metaData = metaDataMediaCoverage[activeLang];
  } else if (category === "promotions") {
    metaData = metaDataPromotion[activeLang];
  } else if (category === "videos") {
    metaData = metaDataVideo[activeLang];
  }
  const customCat = {
    promotions: "促销活动",
    videos: "视频",
    mediacoverage: "新闻稿",
  };

  const custom = {
    english: {
      heading: "Happenings",
      breadCrumb: [{ label: "happenings" }, { label: category === "mediacoverage" ? "Media Coverage" : category === "ourstories" ? "Our Stories" : category }],
    },
    chinese: {
      heading: "传媒与活动",
      breadCrumb: [{ label: "传媒与活动" }, { label: customCat[category] }],
    },
  };
  
  return (
    <Layout
      bannerClass="happenings"
      heading={custom[activeLang].heading}
      breadCrumbs={custom[activeLang].breadCrumb}
      metaData={metaData}
    >
      <div className="happenings-container">
        <div className="category-selection">
          <div className="desktop-only">
            <div className="nav-section">
              {menu.length > 0 &&
                menu.map((cat, i) => (
                  <div className="category-option" key={`${cat}-${i}`}>
                    <input
                      type="radio"
                      id={cat.title}
                      name="category"
                      checked={cat.title === category}
                      value={cat.url}
                      onChange={(e) => categoryHandler(e.target.value)}
                    />
                    <label htmlFor={cat.title}>{cat.label}</label>
                  </div>
                ))}
            </div>
          </div>
          <div className="mobile-only">
            <div className="category-dropdown">
            {menu.length > 0 && (
              <Dropdown
                onChange={categoryHandler}
                options={menu.length > 0 && menu.map((cat) => ({
                  key: cat.url,
                  label: cat.label,
                  value: cat.url
                }))}
                hideSelectedOption
              />)}
            </div>
          </div>
        </div>

        <div className="catalog-container">
          <HappeningsCatalog
            lan={activeLang}
            category={category}
            items={happeningsList}
          // openHouseDetails={openHouseDetails}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Happenings;
