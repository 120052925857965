import React, { useState } from 'react';
import { Link } from 'gatsby';
import FAQPage from '../../components/common/jsonld/FAQPage';
import Layout from '../../layout/layout';
import Accordion from '../../components/common/Accordion/Accordion';
import RadioButton from '../../components/common/RadioButton/RadioButton';
import Dropdown from '../../components/common/Dropdown/Dropdown';
import './faqs.scss';
import parentAppScreenshot from '../../images/appfaq.jpeg';
import { useEffect } from 'react';

const guardianFAQs = [
  {
    title: 'Where can I download the Guardian App?',
    content: (
      <>
        <p>
          You can download the Guardian App from the following links:
          <br />Apple App Store: <a href="https://bit.ly/LSHGuardianApp" target="_blank">https://bit.ly/LSHGuardianApp</a>
          <br /> Android Play Store: <a href="https://bit.ly/LSHGuardianApp" target="_blank">https://bit.ly/LSHGuardianApp</a>
          <br /> Huawei: <a href="https://bit.ly/LSHGuardianApp-Huawei" target="_blank">https://bit.ly/LSHGuardianApp-Huawei</a>
        </p>
      </>
    )
  },
  {
    title: 'What do I need to do for my guardian after they have downloaded the Guardian App?',
    content: (
      <>
        <p>
          For existing authorised guardians, you will have to do a one-time reset of the password using the
          Parent App. You may then proceed to share this password with the authorised guardians so that they
          can log in to the Guardian App.
        </p>

        <p>
          For new guardians, you will need to take the following steps to register them in the Parent
          App:
        </p>
        <p>
          1. Tap the ellipsis (...) on the menu bar at the bottom of the screen.
          <br />
          2. Select ‘Parents &amp; Guardians’.
          <br />
          3. Under the ‘Parents &amp; Guardians’ tab, scroll over ‘+’ at the bottom right corner of
          the screen.
          <br />
          4. Please read the ‘Important Note’ before proceeding to click ‘I Understand’.
          <br />
          5. Fill in the guardian’s details and click on ‘Add Guardian’.
          <br />
          6. Upload a photo of the guardian by clicking on the ‘Upload Image’ button, click
          ‘Next’ to proceed.
          <br />
          7. Fill in the relevant information for the guardian and click on ‘Next’.
          <br />
          8. Set up a login password for the guardian to log in and click on ‘Review’.
          <br />
          9. Review and confirm the guardian’s details. Click on ‘Submit’.
          <br />
          10. Please read the ‘Important Note’ before proceeding to click ‘I Understand’ to
          proceed.
          <br />
          11. Once this is done, you should see a ‘Completion’ screen and you can click on
          ‘Share Login Information’ to share the login details with the authorised guardian.
          <br />
        </p>
      </>
    )
  },
  {
    title: 'I will need my relative/neighbour/friend to pick my child up just for one occasion, do I still need to add him/her in the Parent App?',
    content: (
      <>
        <p>
          Yes, you will have to add the guardian even if it is a one-time arrangement. Please do
          indicate the validity date when adding a new guardian in the Parent App.
        </p>
      </>
    )
  },

  {
    title: 'I am unable to view a guardian whom I have previously added in my Parent App. What should I do?',
    content: (
      <>
        <p>
          Please re-register the guardian by following the steps below:
        </p>
        <p>
          1. Tap the ellipsis (...) on the menu bar at the bottom of the screen.
          <br />
          2. Select ‘Parents &amp; Guardians’.
          <br />
          3. Under the ‘Parents &amp; Guardians’ tab, scroll over ‘+’ at the bottom right corner of
          the screen.
          <br />
          4. Please read the ‘Important Note’ before proceeding to click ‘I Understand’.
          <br />
          5. Fill in the guardian’s details and click on ‘Add Guardian’.
          <br />
          6. Upload a photo of the guardian by clicking on the ‘Upload Image’ button, click
          ‘Next’ to proceed.
          <br />
          7. Fill in the relevant information for the guardian and click on ‘Next’.
          <br />
          8. Set up a login password for the guardian to log in and click on ‘Review’.
          <br />
          9. Review and confirm the guardian’s details. Click on ‘Submit’.
          <br />
          10. Please read the ‘Important Note’ before proceeding to click ‘I Understand’ to
          proceed.
          <br />
          11. Once this is done, you should see a ‘Completion’ screen and you can click on
          ‘Share Login Information’ to share the login details with the authorised guardian.
          <br />
        </p>
      </>
    )
  },

  {
    title: 'I do not need my child’s/children’s authorised guardian to check my child in and out of school anymore. What should I do?',
    content: (
      <>
        <p>
          Parents can remove the authorised guardian in the Parent App by following the steps below:
        </p>
        <p>
          1. Tap the ellipsis (...) on the menu bar at the bottom of the screen.
          <br />
          2. Select ‘Parents &amp; Guardians’ to view the registered guardians.
          <br />
          3. Click on the guardian to see the guardian’s profile.
          <br />
          4. Click on ‘Remove Guardian’.
          <br />
          5. The ‘Remove Guardian Account’ pop-up will appear.
          <br />
          6. Click on ‘Confirm’.
          <br />
          7. Once the process is complete, you should see a pop-up that reads ‘Guardian
          Removed Successfully’.
          <br />
        </p>
      </>
    )
  },

  {
    title: 'What is the login ID and password for my child’s/children’s authorised guardian?',
    content: (
      <>
        <p>
          Authorised guardians can log in using their NRIC/FIN/Passport Number and the password
          set by you in the Parent App. The password is case sensitive and has to consist of 6
          characters:
          <br />i.e.
        </p>
        <ul>
          <li>letters or</li>
          <li>numbers or</li>
          <li>a combination of letters and numbers</li>
        </ul>
      </>
    )

  },

  {
    title: 'My child’s/children’s authorised guardian has forgotten his/her password. What should I do?',
    content: (
      <>
        <p>
          Parents can reset the authorised guardian’s password by following the steps below:
        </p>
        <p>
          1. Tap the ellipsis (...) on the menu bar at the bottom of the screen.
          <br />
          2. Select ‘Parents &amp; Guardians’ to view the registered guardians.
          <br />
          3. Click on the guardian to see the guardian’s profile.
          <br />
          4. Click on ‘Reset Password’.
          <br />
          5. There will be a ‘Reset Password’ pop-up.
          <br />
          6. Key in the new password and click on ‘Submit’.
          <br />
          7. Once the process is complete, you should see a screen that reads ‘Successful
          Password Reset’.
          <br />
        </p>
      </>
    )
  },

  {
    title: 'My child’s/children’s guardian is unable to log in with his/her NRIC/FIN/Passport Number. What should I do?',
    content: (
      <>
        <p>
          Please check that the NRIC/FIN/Passport Number and password entered are correct. If
          the guardian is still unable to log in, parents can reach out to our friendly agents via the live
          chat in the Parent App here:
        </p>
        <p>
          <img
            src="https://cms-staging.littleskoolhouse.com/wp-content/uploads/2021/06/FAQ-Image.png"
            width="250px"
          />
        </p>
      </>
    )
  },

  {
    title: 'What is the next step after I add a new guardian?',
    content: (
      <>
        <p>
          Please inform your child/children’s authorised guardian to bring his/her original NRIC/FIN/Passport
          that you have used for registration for a one-time verification by our centre staff when they
          check your child out of school.
        </p>
      </>
    )
  },

  {
    title: 'How long does it take for the centre to verify a new guardian once I add them via the Parent App?',
    content: (
      <>
        <p>
          The authorised guardian's identity will be verified by our centre staff on their first visit to pick up and check out your child from school. The process should take less than three minutes if all the required information and documentation are available and matches the information submitted in the Parent App.
        </p>
      </>
    )
  },

  {
    title: 'Do I need to download the Guardian App on my authorised guardian’s smartphone to check my child in and out of school on Monday, 4 October 2021?',
    content: (
      <>
        <p>
          Yes, having the Guardian App will enable authorised guardians to enjoy a more convenient and
          seamless check in and check out process for your child/children.
        </p>
      </>
    )
  },

  {
    title: 'Besides using the Guardian App, are there any other options for authorised guardians to check my child/children in and out of school?',
    content: (
      <>
        <p>
          Yes, authorised guardians can use the centre kiosk to check your child/children in and out of school. Authorised guardians can log in to the centre kiosk using their NRIC/FIN/Passport Number to complete the process.
        </p>
      </>
    )
  },

  {
    title: 'Can authorised guardians still use the centre kiosk to check my child/children in and out of school?',
    content: (
      <>
        <p>
          Yes, authorised guardians can still use the centre kiosk to check your child/children in and out of school. Authorised guardians can log in to the centre kiosk using their NRIC/FIN/Passport Number to complete the process.
        </p>
      </>
    )
  },

  {
    title: 'Why do I need to switch to a new Guardian App?',
    content: (
      <>
        <p>
          The new Guardian App is a dedicated mobile app developed to provide greater
          convenience and security for your family.
        </p>
        <p>
          Authorised guardians will be able to enjoy greater convenience as they can check your child/children in
          and out of school seamlessly with this dedicated app. We have also launched a new feature
          in the Parent App where you can include a guardian validity period for greater security. For
          instance, you can set up a one-week validity period to authorise a guardian to pick your child
          up during a specific week when you are away.
        </p>
      </>
    )
  },

];

const parentFAQs = [
  {
    title: 'What is my login ID to the Little Skool-House Parent App?',
    content: (
      <>
        <p>
          It is your registered email address with The Little Skool-House.
        </p>
      </>
    )
  },
  {
    title: 'How do I verify/amend my registered email address?',
    content: (
      <>
        <p>Please get in touch with your centre’s Relations & Marketing Officer if you need to verify/amend your registered email address.</p>
      </>
    )
  },
  {
    title: 'What is my password?',
    content: (
      <>
        <p>
          Please use this default password: Last 4 alphanumeric characters of parent’s NRIC and first 4 characters of parent’s full name in NRIC (lowercase).
        </p>
        <p>E.g. if NRIC is S1234567Z and name Tan Bee Ling, password is: 567ztanb</p>
      </>
    )
  },

  {
    title: 'I have forgotten my password / I need to update my password. How do I reset my password?',
    content: (
      <>
        <p>
          Parents can tap on the Forgot Password tab in the Parent App and follow the steps on-screen to reset your password.
        </p>
      </>
    )
  },

  {
    title: 'How do I check my child in and out of school using the Little Skool-House Parent App?',
    content: (
      <>
        <p>
          Once you have logged into your account, select your child’s profile that you would like to view, then tap the “Check-in”/“Check-out” button at the top of the page and scan the QR Code via the centre kiosk to enjoy a seamless process.
        </p>
      </>
    )
  },

  {
    title: 'I would like my sibling to pick my child up today, what should I do?',
    content: (
      <>
        <p>
          You will have to add the guardian even if it is a one-time arrangement. Please do indicate the validity date when adding a new guardian in the Parent App.
        </p>
      </>
    )

  },

  {
    title: 'I need to remove my child’s/children’s authorised guardian from the list, how do I go about doing that?',
    content: (
      <>
        <p>
          Parents can remove the authorised guardian in the Parent App by following the steps below:
        </p>
        <p>
          1. Tap the ellipsis (...) on the menu bar at the bottom of the screen.
          <br />
          2. Select ‘Parents & Guardians’.
          <br />
          3. Click on the guardian to see the guardian’s profile.
          <br />
          4. Click on ‘Remove Guardian’.
          <br />
          5. The ‘Remove Guardian Account’ pop-up will appear.
          <br />
          6. Click on ‘Confirm’.
          <br />
          7. Once the process is complete, you should see a pop-up that reads ‘Guardian Removed Successfully’.
          <br />
        </p>
      </>
    )
  },

  {
    title: 'My child’s/children’s authorised guardian has forgotten to bring his/her mobile phone. What can he/she do?',
    content: (
      <>
        <p>
          Authorised guardians can log in to the centre kiosk using their own NRIC/FIN/Passport Number to check your child in and out of school.
        </p>
      </>
    )
  },

  {
    title: 'How do I view the details of the fees payment for my child?',
    content: (
      <>
        <p>
          Please refer to the “Statement of Account” icon in the Little Skool-House Parent App where you will be able to view the invoice details for your child’s school fees. Fee payable would be the nett fee after any approved government subsidy.
        </p>
      </>
    )
  },

  {
    title: 'How do I know the mode of payment for the original deposit?',
    content: (
      <>
        <p>
          You may refer to the receipt created upon payment of your child’s enrollment registration, in the Little Skool-House Parent App under the “Statement of Account” icon.
        </p>
      </>
    )
  },

  {
    title: 'How do I check on the outstanding amount of fee payable?',
    content: (
      <>
        <p>
          You can check your outstanding balance in the Little Skool House-Parent App by following the steps below:
        </p>
        <p>
          1. Tap the ellipsis (...) on the menu bar at the bottom of the screen.
          <br />
          2. Select ‘Statement of Account’ and “Outstanding” tab will appear
          <br />
          3. You should be able to see a list of outstanding balance, if any
        </p>
      </>
    )
  },

  {
    title: 'How do I check on the breakdown of approved subsidy/subsidies for my child/children? ',
    content: (
      <>
        <p>
          If a subsidy/subsidies has/have been approved for your child/children, the amount will be reflected in the monthly school fees invoice within the Little Skool-House Parent App.
        </p>
      </>
    )
  },

  {
    title: 'How do I check my GIRO account details?',
    content: (
      <>
        <p>
          Your GIRO account details can be found in the Little Skool-House Parent App under the “GIRO Payment” icon.
        </p>
      </>
    )
  },

  {
    title: 'How do I know if my GIRO application has been approved?',
    content: (
      <>
        <p>
          You may refer to the Little Skool-House Parent App for your GIRO application status under the “GIRO Payment” icon. A green tick will be shown if the account has been approved.
        </p>
      </>
    )
  },

  {
    title: 'I would like to check on the status of my withdrawal refund.',
    content: (
      <>
        <p>
          The withdrawal refund will be credited to the account which you have verified upon withdrawal within 4 to 6 weeks.
        </p>
      </>
    )
  },

  {
    title: 'If I have other questions about the Little Skool-House Parent App after logging in, who should I approach for more information?',
    content: (
      <>
        <p>
          If you have further questions, reach out to us via the chat in the Little Skool-House Parent App and our friendly customer support team will get back to you as soon as we can.
        </p>
      </>
    )
  },

];

const faqs = {
  general: [
    {
      title:
        'What is the difference between the Little Skool-House centres that are under the Partner Operator (POP) scheme and those that aren’t?',
      content: (
        <>
          <p>
          Governed by ECDA, the Partner Operator (POP) scheme supports appointed centres to improve the accessibility, affordability and quality of childcare and infant care services. Rest assured that our programmes and curriculum are the same across all Little Skool-House centres.{' '}
          </p>
          <p>
          Within the Little Skool-House network of centres, 14 are POP centres. Selected Little Skool-House POP centres also also operating in collaboration with our workplace partners as their preschool of choice for their employees. Our goal is to give working parents peace of mind by making sure their little ones are well taken care of during working hours and are receiving quality early childhood education. For these centres, vacancy priority is given to workplace employees.
          </p>
          <p>
          Nonetheless, if you are not an employee of our POP workplace centres and would like to be considered for enrolment, you will be invited to join our waitlist. 
          </p>
          <p>
            If you would like to invite us to be your workplace preschool
            partner, please drop us an email at{' '}
            <a href="mailto:info@littleskoolhouse.com" target="_blank">
              info@littleskoolhouse.com
            </a>{' '}
            to find out more.
          </p>
        </>
      )
    },
    {
      title: 'Which of the Little Skool-House centres are POP centres?',
      content: (
        <>
        <p><b>Our POP centres are: </b></p>
        <p>
          At Kent Vale&nbsp;<span className='workplace-anchor-span'>POP workplace centre</span><br />
          At Ngee Ann Polytechnic&nbsp;<span className='workplace-anchor-span'>POP workplace centre</span><br />
          At OCBC Centre&nbsp;<span className='workplace-anchor-span'>POP workplace centre</span><br />
          At OCBC Tampines&nbsp;<span className='workplace-anchor-span'>POP workplace centre</span><br />
          At Outram (Outram Community Hospital)&nbsp;<span className='workplace-anchor-span'>POP workplace centre</span><br />
          At Temasek Polytechnic&nbsp;<span className='workplace-anchor-span'>POP workplace centre</span><br />
          At Thomson (MSF)&nbsp;<span className='workplace-anchor-span'>POP workplace centre</span><br />
          By The Vista (Ulu Pandan Community Club)<br />
          At Alexandra POP workplace centre)&nbsp;<span className='workplace-anchor-span'>POP workplace centre</span><br />
          At KK Hospital POP workplace centre&nbsp;<span className='workplace-anchor-span'>POP workplace centre</span><br />
          At Ministry of Education POP workplace centre&nbsp;<span className='workplace-anchor-span'>POP workplace centre</span><br />
          At Republic Polytechnic&nbsp;<span className='workplace-anchor-span'>POP workplace centre</span><br />
          By The Lake (Khoo Teck Puat Hospital)&nbsp;<span className='workplace-anchor-span'>POP workplace centre</span><br />
          On The Green (Orchid Country Club)
        </p>
        </>
      )
    },
    {
      title: 'Where are your centres located?',
      content: (
        <p>
          Find The Little Skool-House centres in 20 locations across Singapore.
          Get a comprehensive list of our centres, along with individual centre
          offerings, principal introductions and centre photos by clicking{' '}
          <a href="/our-centres/centres/all-centres">
            Our Centres
          </a>{' '}
          tab.
        </p>
      )
    },
    {
      title: 'How much is your monthly school fees?',
      content: (
        <>
          <p>
          Our monthly fee for preschool and infant care programmes at our centres ranges from $1,230 to $1,630 per month (after working mother subsidy and before GST). Our monthly fee for POP centres is aligned with ECDA guidelines, and ranges from $460 to $730 (after working mother subsidy and before GST).
          </p>
          <p>
          All parents with Singapore Citizen children enrolled in child care centres licensed by ECDA will continue to be eligible for a Basic Subsidy. A Basic Subsidy of $300 is given to working mothers/single fathers who work a minimum of 56 hours per month and a subsidy of $150 for non-working mothers/single fathers. For Infant care, a Basic Subsidy of $600 is given to working mothers/single fathers who work a minimum of 56 hours per month and a subsidy of $150 for non-working mothers/single fathers.
          </p>
        </>
      )
    },
    {
      title: 'What are the qualifications of your teachers?',
      content: (
        <p>
          Our teachers are highly skilled early childhood professionals holding
          Degrees or Diplomas in the Early Childhood discipline. They have the
          right attitude honed over years of experience in nurturing young
          children. All our teachers go through our Professional Development
          Programme which sets the quality standard of The Little Skool-House.
        </p>
      )
    },
    {
      title: 'Do all centres offer infant care?',
      content: (
        <p>
          Only certain centres offer Infant care. For details, please click on{' '}
          <a href="/our-centres/centres/all-centres">
            Our Centres.
          </a>
        </p>
      )
    },
    {
      title:
        'What is the age range for the different classes at The Little Skool-House?',
      content: (
        <>
          <p>
            <strong>Age of child as at 31st December</strong>
          </p>
          <p>
            Infant Care: 2 – 17 months <br />
            Toddler: 2 years <br />
            Nursery 1: 3 years <br />
            Nursery 2: 4 years <br />
            Kindergarten 1: 5 years <br />
            Kindergarten 2: 6 years
          </p>
        </>
      )
    },
    {
      title:
        'When should I apply for my child’s enrolment? Is there a fixed period for registration?',
      content: (
        <p>
          Enrolment is open all year round, depending on availability.
          Registration may be done up to 12 months before your child’s intended
          enrolment date.
        </p>
      )
    },
    {
      title : 'What is the online registration process like?',
      content : (
      <>
          <p>Thank you for enrolling with The Little Skool-House and we look forward to partnering on this learning journey with you and your child.
            <br/>
            <br/>
            The online registration process is as follows.
            <br/>
            <br/>
            <ul>
              <b> 1. Pre-registration: </b>
              <br/>
               <li className='faq-bullet-points-padding'> Parent(s) are required to fill in their child's details.</li>
              <b> 2. Pending Form Completion: </b>
              <br/>
              <li className='faq-bullet-points-padding'> Parent(s) must fill in their details to assist in the application process, such as working statuses, household income, etc.</li>
              <b> 3. Pending Document Submission: </b>
              <br/>
              <li className='faq-bullet-points-padding'>Parent(s) to upload the required supporting documents to substantiate the declared information for ECDA to assess your child’s subsidy application (when your child enrols). </li>
              <b> 4. Pending Document Verification: </b>
              <br/>
              <li className='faq-bullet-points-padding'> To help smoothen the application process for your child’s subsidy, we will verify the documents and details you have submitted and will contact you should we require any clarification. We do this to help smoothen the application process for your child’s subsidy. </li>
              <li className='faq-bullet-points-padding'><b> E-consent (e-signature via PDF): </b></li>
              <li className='faq-bullet-points-padding'>Both parents (if applicable) will be prompted to sign the form(s) online.</li> 
              <li className='faq-bullet-points-padding'>It will be directed to the main applicant first.</li>
              <li className='faq-bullet-points-padding'>Finally, it will be routed to the Centre Principal for his/her final consent.</li>
              <b> 5. GIRO Set-up: </b>
              <br/>
              <li className='faq-bullet-points-padding'>Setting up a Non-CDA GIRO account for future school fees payment is mandatory for all children.</li>
          
              <li className='faq-bullet-points-padding'>  For a Singapore Citizen child, parents have the option of adding your CDA account too. This is to ensure the timely deduction of GIRO payments done every 1st of the calendar month once the child is enrolled.</li>
                    </ul>

</p>
      </>),
      accordianId : 'register-faq'
    },
    {
      title: 'What is the Admissions Policy at The Little Skool-House ?',
      content: (
        <>
          <p>
            Admission procedure involves 3 basic steps: 
            <br />
            1) Book a Centre Tour to
            find out if The Little Skool-House is the perfect fit for your
            child; 
            <br />
            2) Complete the registration by making a deposit equivalent
            to one month school fees, pay registration fee and submit enrolment
            form and required documents; 
            <br />
            3) Receive a welcome letter and
            invitation detailing things you need to prepare for your child’s
            first day in school.
            <br />
          </p>
          <p>
            For details, please see our{' '}
            <a href="/our-centres/admissions">
              Admissions Policy.
            </a>
          </p>
        </>
      )
    },
    {
      title:
        'Can foreigners relocating to Singapore enroll their child at The Little Skool-House?',
      content: (
        <p>
          Yes, foreigners relocating to Singapore may enroll their child. If
          required, The Little Skool-House will initiate the process with the
          Immigration and Checkpoint Authority of Singapore for the Student Pass
          application. Thereafter, a Registration Acknowledgement Letter will be
          emailed to the applicant for online application.{' '}
        </p>
      )
    },
    {
      title: 'What are the school hours? ',
      content: (
        <>
          <p>
            Our centres are open from Mondays to Fridays, from 7am to 7pm; and
            on Saturdays, from 7am to 2pm.
          </p>
          <p>
            We have some centres in the CBD area that operates from 7am to
            7.30pm, and some centres offering Full-Day as well as Half-Day
            Programmes. For more information of your preferred centre’s
            operating hours, please refer the respective centre's page in{' '}
            <a href="/our-centres/centres/all-centres">
              Our Centres
            </a>
          </p>
          <p>
            Our centres are closed for 6 days in addition to public holidays.
          </p>
        </>
      )
    },
    {
      title: 'Does the school provide any enrichment programmes?  ',
      content: (
        <p>
          We curated a variety of optional{' '}
          <a href="/our-curriculum/enrichment-programme">
            Enrichment Programmes
          </a>{' '}
          offered by external providers which we believe could further develop
          your child’s interests, while shaping lifelong learning habits such as
          focus, discipline and confidence. All are on-site programmes,
          available at an additional cost. Please enquire at your preferred
          centre to see what they offer.
        </p>
      )
    },
    {
      title: 'Are meals provided in your school?  ',
      content: (
        <>
          <p>
            Breakfast, lunch and tea break are provided for our full-day
            programme. All our meals abide by the Healthy Meals in Pre-schools
            Programme (HMPP) criteria by the Health Promotion Board.{' '}
          </p>
          <p>
            Four menus are rotated on a weekly basis over a period of one month.
            Our food does not contain pork, lard and beef.{' '}
          </p>
          <p>
            Parents are advised to inform the centre staff of any religious or
            dietary restrictions upon registration.
          </p>
        </>
      )
    },
    {
      title: 'How will the school handle children with allergies?',
      content: (
        <p>
          Parents should declare all known allergies of the child upon
          enrolment. As a safety precaution, a complete list of children and
          their allergies will be prominently displayed at our dining areas. In
          addition, the school will issue iron-on allergy tags to be ironed onto
          the child’s uniform as an allergy identification. Our staff are
          trained in identifying and managing allergic reactions.{' '}
        </p>
      )
    },
    {
      title: 'How can I purchase uniforms?',
      content: (
        <p>
          Uniforms can be purchased directly at the centre where your child is
          enrolled. We highly encourage parents to bring their child for
          fittings of sample uniforms before making their orders. Uniforms will
          take 2 to 3 weeks to arrive after the order is placed.
        </p>
      ),
    }
  ],
  curriculum: [
    {
      title:
        'What is the curriculum offered in your school and how is it different to other preschools?',
      content: (
        <>
          <p>
            We offer a unique programme with a lineup of 2 curriculums –{' '}
            <a href="/our-curriculum/infant–3-years-relationships-based-curriculum">
              Relationships-Based Curriculum
            </a>{' '}
            (2 months to 3 years old) and{' '}
            <a href="/our-curriculum/4-6-years-literacy-based-curriculum">
              Literacy-Based Curriculum
            </a>{' '}
            (4 to 6 year olds) - which allows the children in our care to reach
            their physical, social and emotional, language and cognitive
            potential.
          </p>
          <p>
            In our Relationships-based curriculum, our Core belief is a child’s
            confidence is the starting point for learning. We believe that our
            youngest children need good, positive and secure attachments and
            relationships in order to develop life-long positive learning
            dispositions. We focus in nurturing children’s confidence by
            supporting them in routines for the development of independent
            self-help skills. We provide quality experiences and exploration
            opportunities for children to construction of their own knowledge
            and applying their learning.
          </p>
          <p>
            In our Literacy-based curriculum, we believe that stories shape how
            children see the world, introducing them to far-flung places,
            extraordinary people and eye-opening situations. Through bilingual
            guided reading, writing, listening, speaking and problem-solving
            activities, we equip your child with the skills and knowledge they
            need to develop positive learning habits that will last a lifetime.{' '}
          </p>
        </>
      )
    },
    {
      title: 'What is the settling-in programme at The Little Skool-House?',
      content: (
        <p>
          At The Little Skool-House, we focus on building strong relationships
          with children and parents and aims to develop positive learning
          dispositions. That is why we have in place a 3-day settling-in
          programme where parents are invited to join their child during the
          first 3 days of school as the presence of the parent always gives
          comfort to the child. The parent’s joint presence with the child’s
          teacher also gives affirmation to the child that the teacher is a
          trusted person.{' '}
        </p>
      )
    },
    {
      title:
        'How does The Little Skool-House teach bilingually in English and Chinese?',
      content: (
        <>
          <p>
            The Little Skool House curriculum and teaching pedagogy inspires
            both learning and confidence in language. Our curriculum is a
            bilingual programme with equal emphasis to both English and
            Mandarin.
          </p>
          <p>
            Our{' '}
            <a href="/our-difference/high-proficiency-in-english-and-mandarin">
              Pedagogy for Enhanced Mandarin Acquisition
            </a>{' '}
            focuses not just on learning the language but also on learning about
            the language. The Mandarin curriculum is aimed towards 3 main goals:
          </p>
          <p>
            1. Children will have an interest in learning Mandarin.
            <br />
            2. Children will develop foundational language and literacy skills
            in Mandarin.
            <br />
            3. Children will be aware of the local ethnic culture.
          </p>
          <p>
            In fact, A <a className="lsh-anchor-common" href="https://research.littleskoolhouse.com/" target="_blank">Nanyang Technological University (NTU) study </a> found that
            93% of Little Skool-House children are assessed to be highly
            proficient bilingually.{' '}
          </p>
        </>
      )
    },
    {
      title: 'How is Mandarin taught in focus at The Little Skool-House?',
      content: (
        <>
          <p>Our Mandarin lessons are made fun through:</p>
          <ol>
            <li>
              Structured Reading and Literacy programme which focuses on
              developing a child’s listening, speaking, reading and writing
              skills in a progressive and systematic manner. This equips
              preschoolers with different strategies to enable them to read and
              write independently. Teachers act as facilitators of learning,
              matching requirements and instruction based on the assessed
              capability of each child.
            </li>
            <li>
              <a href="/our-difference/high-proficiency-in-english-and-mandarin">
                Pedagogy for Enhanced Mandarin Acquisition
              </a>{' '}
              where children internalise the Mandarin language and build
              creativity, teamwork and collaboration skills concurrently.
              Children vote on the theme of their play, write their own script,
              assign roles for production, rehearse, create marketing materials
              and eventually perform to an audience. All in Mandarin.
            </li>
          </ol>
          <p>
            We believe that children learn while having fun which makes
            retention longer. We want children to enjoy the language and
            therefore enjoy learning it.{' '}
          </p>
        </>
      )
    },
    {
      title:
        'How does The Little Skool-House Curriculum prepare my child for Primary school?',
      content: (
        <p>
          Our 4 to 6 year olds are focused on preparing for Primary School, in
          the areas of Academic Preparedness, Confident Bilingual Communication,
          Behavior Maturity and Exposure to a Bigger School Environment. The
          Little Skool-House programme is designed with clear outcomes to
          develop children holistically and promotes happy learners while
          preparing them for school readiness.
        </p>
      )
    },
    {
      title: 'Do you offer the International Baccalaureate® (IB) Curriculum? ',
      content: (
        <p>
          The IB Curriculum is currently only offered at The Little Skool-House
          By-The-Vista (located at Ulu Pandan Community Club).
        </p>
      )
    }
  ]
};

const categories = Object.keys(faqs);

  const getId = typeof window !== 'undefined' && window?.location?.hash.substring(1);


const Faq = ({ pageContext }) => {

  const { type } = pageContext;
  const defaultCategory = (type === 'guardianFaq') ? guardianFAQs : faqs[categories[0]];
  const parentCategory = (type === 'guardianFaq') ? parentFAQs : '';
  const [category, setCategory] = useState(categories[0]);
  const [items, setItems] = useState(defaultCategory);
  const [parentItems, setParentItems] = useState(parentCategory);
  const questions = Object.values((type === 'guardianFaq') ? guardianFAQs : faqs)
    .flat()
    .map(({ title, content }) => ({ q: title, a: content }));
    const [linkId, setLinkId] = useState('');


  const categoryHandler = cat => {
    setCategory(cat);
    setItems(faqs[cat]);
  };

  const pageTitle = (type === 'guardianFaq') ? 'Parent and Guardian Apps FAQ' : 'FAQs';
  const breadCrumbs = [{ label: 'Enquiry' }, { label: pageTitle }];
  if ((type === 'guardianFaq')) {
    breadCrumbs.shift();
  };

  useEffect(() => {
    window.onclick = e => {
      let clickedElement = e.srcElement.innerText;
      if(clickedElement === 'POP workplace centre') {
          setLinkId(clickedElement);
      } else {
        setLinkId('');
      }
    };
  }, []);

  return (
    <Layout
      bannerClass="enquiry"
      heading={pageTitle}
      breadCrumbs={breadCrumbs}
      metaData={{
        url: 'https://www.littleskoolhouse.com/enquiry/faqs',
        title: `${pageTitle} | The Little Skool-House`,
        description:
          'Here are the list of Frequently Asked Questions (FAQ) about Little Skool-House preschools. Read about our curriculum, locations and more.'
      }}
    >
      <FAQPage faqs={questions} />

      <div className={`faq-container ${type !== "guardianFaq" && 'd-faq-flex'}`} >
        {(type !== 'guardianFaq') && (
          <div className="category-selection">
            <div className="desktop-only">
              {categories.map((cat, i) => (
                <RadioButton
                  key={`${cat}-${i}`}
                  name="category"
                  value={cat}
                  checked={cat === category}
                  handler={e => {
                    categoryHandler(e.target.value);
                  }}
                />
              ))}
            </div>
            <div className="mobile-only faq-mobile">
              <div className="category-dropdown">
                <Dropdown
                  defaultValue={category}
                  onChange={categoryHandler}
                  options={categories.map(cat => ({ key: cat, value: cat }))}
                  hideSelectedOption
                />
              </div>
            </div>
          </div>
        )}
        {
          parentItems && parentItems.length > 0 &&
          <div className="catalog-container">
            {
              parentItems && parentItems.length > 0 && <h4 className="faq-sub-head">Parent App FAQ</h4>
            }
            <div className="faq-catalog">
              {parentItems &&
                parentItems.map(({ title, content }, i) => (
                  <Accordion key={`${title}-${i}`} title={title} type={category} activeLang="english">
                    {content}
                  </Accordion>
                ))}
            </div>
          </div>
        }
        <div className="catalog-container">
          {
            items && items.length > 0 && type === 'guardianFaq' && <h4 className="faq-sub-head">Guardian App FAQ</h4>
          }
          <div className="faq-catalog">
            {getId && getId == 'register-faq' ? 
            <span>
            {items &&
              items.map(({ title, content, accordianId }, i) => (
                <Accordion 
                key={`${title}-${i}`}
                 title={title}
                 type={category}
                 activeLang="english"
                 linkId={"register-faq"}
                 isOpen={(accordianId == 'register-faq') ? true : false}
                  // isOpen={(title==='Which of the Little Skool-House centres are POP centres?' || title==='What is the difference between the Little Skool-House centres that are under the Partner Operator (POP) scheme and those that aren’t?') && (linkId === 'POP workplace centre')}
                  >
                  {content}
                </Accordion>
              ))}
              </span> 
              :
              <span>
               {items &&
                items.map(({ title, content }, i) => (
                  <Accordion 
                  key={`${title}-${i}`}
                   title={title}
                   type={category}
                   activeLang="english"
                    isOpen={(title==='Which of the Little Skool-House centres are POP centres?' || title==='What is the difference between the Little Skool-House centres that are under the Partner Operator (POP) scheme and those that aren’t?') && (linkId === 'POP workplace centre')}
                    >
                    {content}
                  </Accordion>
                ))}
                </span>
               }
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Faq;

