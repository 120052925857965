import React from 'react';
import Layout from '../../layout/layout';
import Kids from '../../images/objects/about-lsh-kids.jpg';
import LeftQuote from '../../images/objects/left-quote.png';
import RightQuote from '../../images/objects/right-quote.png';
import './the-little-skool-house.scss';

const TheLittleSkoolHouse = () => {
  return (
    <Layout
      heading="The Little Skool-House"
      bannerClass="about-us"
      breadCrumbs={[{ label: 'About Us' }, { label: 'The Little Skool-House' }]}
      metaData={{
        url: 'https://www.littleskoolhouse.com/about-us/the-little-skool-house',
        title: 'About Our Childcare Centre in Singapore | Little Skool-House',
        description:
          ' At The Little Skool-House, we have been driving discoveries and building knowledge better for 27 years. Learn more about our childcare centre in Singapore here.'
      }}
    >
      <div className="little-skool-house-container">
        <div className="left-section">
          <img src={Kids} draggable={false} alt="LSH Kids" />
        </div>
        <div className="right-section">
          <p className="intro">
            At The Little Skool-House, we <br className="mobile-only" />
            <span>unlock</span>
            <br />
            your child’s natural curiosity to <br className="mobile-only" />
            <span>learn &amp; discover</span>
            <br />
            by propelling them to <br className="mobile-only" />
            <span>investigate, create &amp; innovate</span>
            <img src={LeftQuote} alt="Left quote" className="quote" />
          </p>
          <p className="text">
          Our immersive bilingual curriculum enables High Proficiency in both English and Mandarin. The expertise of our teachers has enabled us to win numerous Outstanding Preschool Teacher and Outstanding Preschool Mother Tongue Language Teacher awards for the past 9 years.
          </p>
          <p className="text">
          Do you know the reason behind our 'Skool" spelling?  As children learn to write, they commonly use what they know about letters, sounds &amp; spelling patterns to invent spellings for words. Studies show invented spelling helps children understand the relationships between speech sounds and alphabets.
          </p>
          <p className="text">
          The invented 'Skool' reflects our belief that absolute correctness, mechanics and memorisation should not be the focus when teaching preschoolers to write. 
          </p>
          <p className="text">
           Little Skool-House has been driving discoveries and building knowledge better for over 25 years. Our vision is to enable our children to be ready for primary school, and ready for life! 
          </p>
          <div className="right-quote">
            <img src={RightQuote} alt="Right quote" />
          </div>
          <p className="text new-para-margin">
          Little Skool-House is part of NTUC First Campus, a social enterprise dedicated to enabling bright futures for every child and their family.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default TheLittleSkoolHouse;
