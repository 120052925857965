import React from 'react';
import Layout from '../layout/layout';
import './404.scss';
import { Link } from 'gatsby';
import '../components/Header/DesktopMenu/DesktopMenu.scss';
import Icon from '../images/icons/404_icon.png';

const NotFound = () => {
  return (
    <Layout noBanner>
      <div className="not-found-container">
        <div className="container">
          <img src={Icon} style={{ marginRight: '10px' }} />
          <span style={{ marginLeft: '10px' }}>
            <h1>Page Not Found</h1>
          </span>
        </div>
        <p>
          Sorry we cannot find the page you are looking for! It might be an old
          link or may have moved to a new location.
        </p>

        <div>
          <Link to="/" className="yellow-border-btn-holder">
            <button className="yellow-funky-button">Back To Home</button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
