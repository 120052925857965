import React from 'react';
import ECDA from '../../../images/logos/ECDA.png';
import MOE from '../../../images/logos/MOE.png';
import Trophy from '../../../images/objects/trophy.png';
import './AwardsSection.scss';

const AwardsSection = ({ educatorsPage }) => {
  return (
    <div className={`awards-section ${educatorsPage ? 'educators-page' : ''}`}>
      <h3>
        {educatorsPage
          ? `The Little Skool-House is proud of our award-winning teachers`
          : `Our Award-Winning Teachers`}
      </h3>
      <div className="award-section">
        <div className="img-section">
          <img src={MOE} alt="MOE" />
        </div>
        <div className="text-section">
          <div className="trophy-section">
            <img src={Trophy} alt="Trophy" />
            <h5>
              {educatorsPage
                ? `MOE Outstanding Preschool Mother Tongue Language Teacher Awards`
                : `Ministry of Education`}
            </h5>
          </div>
          {!educatorsPage && (
            <h3>
              MOE Outstanding Preschool Mother Tongue Language Teacher Awards
            </h3>
          )}
        </div>
      </div>
      <div className="award-section">
        <div className="img-section">
          <img src={ECDA} alt="ECDA" />
        </div>
        <div className="text-section">
          <div className="trophy-section">
            <img src={Trophy} alt="Trophy" />
            <h5>
              {educatorsPage
                ? `ECDA Outstanding & Promising Early Childhood Educator Awards`
                : `Singapore's Early Childhood Development Agency`}
            </h5>
          </div>
          {!educatorsPage && (
            <h3>
              ECDA Outstanding &amp; Promising Early Childhood Educator Awards
            </h3>
          )}
        </div>
      </div>
      <p>
        The Little Skool-House teachers go through rigorous training programmes
        designed by ECCE pioneers and established ECDA fellows, who also design
        our unique and outstanding curriculum. They embark on learning journeys
        overseas, and receive in-depth mentorship to achieve mastery. This has
        resulted in our multiple awards and is the reason why you can be sure
        that your child is getting the highest standard of care.
      </p>
    </div>
  );
};

export default AwardsSection;
