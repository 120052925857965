import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../../layout/layout';
import KidPlaying from '../../images/objects/difference-le/kid-playing.png';
import './our-learning-environment.scss';
import OurDifferenceTilesSlider from '../../components/common/OurDifferenceTilesSlider/OurDifferenceTilesSlider';

export const query = graphql`
query ($slugId: ID!) {
  cms {
    page(id: $slugId, idType: URI) {
      our_learning_environment {
        description
        language
        metaDescription
        metaTitle
        metaUrl
        pageHeader
        title
      }
    }
  }
}`
const LearningEnvironment = ({ pageContext: { language, preview }, data }) => {

  const custom = {
    english: {
      breadCrumb: [
        { label: 'Our Centres' },
        { label: 'Our Learning Environment' }
      ],
    },
    chinese: {
      breadCrumb: [
        { label: '我们的中心' },
        { label: '我们的学习环境' }
      ],

    }
  }
  let dataList = {};
  if(!preview) {
    dataList = data?.cms?.page?.our_learning_environment;
  } else {
    dataList = data?.our_learning_environment;
  }
  if(!dataList) {
    return '';
  }

  return (
    <Layout
      bannerClass="our-difference"
      heading={dataList.pageHeader}
      breadCrumbs={custom[language].breadCrumb}
      language={language}
      metaData={{
        url: dataList.metaUrl,
        title: dataList.metaTitle,
        description:
          dataList.metaDescription
      }}
      noFooter={preview}
      noBanner={preview}
      noHeader={preview}
    >
      <div className="learning-environment-container">
        <div className="top-section">
          <div className="img-section">
            <img src={KidPlaying} alt="Kid Playing" />
          </div>

          <div className="text">
            <h2>
              {dataList.title}
            </h2>
            <p>
              {dataList.description}
            </p>
          </div>
        </div>
        <div className="bottom-section">
          <OurDifferenceTilesSlider />
        </div>
      </div>
    </Layout>
  );
};

export default LearningEnvironment;
