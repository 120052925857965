const countryCode = '+65';

export function normalizePhoneNumber(phoneNumber) {
  if(!phoneNumber) {
    return;
  }
  const phone = phoneNumber?.replace(/\s/g, '');

  return phone.startsWith(countryCode) ? phone : countryCode + phone;
}

export function getLanguage() {
  if(localStorage) {
    const value = localStorage.getItem("lan");
    const lang = value ? value : 'english';
    return lang;
  }
  return 'english';
}

// export const LSH_LOGO = "https://s3-ap-southeast-1.amazonaws.com/littleskoolhouse-prod-cms/wp-content/uploads/2022/06/01102304/littleskoolhouse.png";
export const LSH_LOGO = "https://s3-ap-southeast-1.amazonaws.com/littleskoolhouse-prod-cms/wp-content/uploads/2022/06/01102304/New-lsh-logo-updated.png";

export const gtmEventManager = (category, action, label) => {
  console.log('label: ', label);
  console.log('action: ', action);
  console.log('category: ', category);
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", action, {
      "event_category": category,
      "event_label": label
    })
  }
};

export const FBPixelEventTracker = (eventName) => {
  if (typeof window !== "undefined" && window.fbq) {
      window.fbq('track', eventName);
  }
}
