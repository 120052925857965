import React from 'react';
import './Banner.scss';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { gtmEventManager } from "../common/utils/utils"

const BannerCn = ({openStatus}) => {
  const scheduleVisit = openStatus === 'on'? 'xianshicuxiao'
   : 'yuyuecanguan';
  return (
    <Link to={`/zh/xunwen/${scheduleVisit}`} id="footer-banner">
      <div onClick={() => gtmEventManager(`Footer`, 'Click', 'Footer Banner')} className="background">
        <h2>
          我们93%的毕业生精通英语和华语{' '}
          <br />
          欲知详情，请立即预约参观
        </h2>
      </div>
    </Link>
  );
};
export default BannerCn;
