import React from 'react';
import Layout from '../../layout/layout';
import './privacy-policy.scss';

const PrivacyPolicy = () => {
  return (
    <Layout bannerClass="our-difference" heading="Privacy Policy"   metaData={{
      url: `https://www.littleskoolhouse.com/others/privacy-policy`,
    }}>
      <div className="privacy-policy-container">
        <h5 className="privacy-h">
          NTUC First Campus Co-operative Limited <br />
          Personal Data Protection Policy
        </h5>
        <h3>1. Policy</h3>
        <p>
          This is the privacy policy of{' '}
          <span className="privacy-font-w">
            NTUC First Campus Co-operative Limited
          </span>
          , a co-operative society registered in Singapore (“
          <span className="privacy-font-w">NFC</span>”), and its affiliated
          organisation(s) as listed on{' '}
          <a href="https://www.ntucfirstcampus.com" target="_blank">
            https://www.ntucfirstcampus.com
          </a>&nbsp; and &nbsp;
          <a href="http://ntucsocialenterprises.sg" target="_blank">
            http://ntucsocialenterprises.sg
          </a>
          &nbsp;(each an “
          <span className="privacy-font-w">affiliated organisation</span>”). The
          terms “we”, and “our” in this Policy refer to NTUC Enterprise, and/or
          any affiliated organisation which has adopted this Policy, as
          appropriate.
        </p>
        <p>
        </p>
        <p>
          1.1 <span className="privacy-font-w">Application.</span> NFC and each
          affiliated organisation have different channels of collecting personal
          data, but each are committed to complying with this Policy in its
          collection, use and disclosure of personal data, to ensure that there
          is accountability and uniformity in the way we protect your personal
          data. Although this Policy is in common use by NFC and the affiliated
          organisations, each is responsible to you to the extent of its own
          collection, use and disclosure of your personal data, and its own
          actions.
        </p>
        <p>
          1.2{' '}
          <span className="privacy-font-w"> Compliance with this Policy.</span>{' '}
          This Policy applies to all personal data you provide to us, or that we
          may collect about you. Please do not provide any personal data to us
          if you do not accept this Policy.
        </p>
        <p>
          We may also require you to accept this Policy when you contact,
          interact, transact or deal with us, or when you access and use our
          websites, applications or services. If you notify us that you do not
          accept this Policy, we may not be able to establish a relationship
          with you or be able to service your requests.
        </p>
        <p>
          1.3{' '}
          <span className="privacy-font-w"> Concerns and Contacting Us.</span>{' '}
          If you have any feedback or issues in relation to your personal data,
          or about this Policy, or wish to make a complaint to us, you may
          contact our Data Protection Officer whose contact details are set out
          below.
        </p>
        <p>
          Data Protection Officer <br />
          Email address:{' '}
          <a href="mailto:dpo@ntucfirstcampus.com" target="_blank">dpo@ntucfirstcampus.com</a>
        </p>
        <p>
          1.4 <span className="privacy-font-w">Amendment to this Policy.</span>{' '}
          We may amend this Policy from time to time without notice to you, to
          comply with applicable laws or as we update our data usage and
          handling processes. The updated Policy will supersede earlier versions
          and will apply to personal data provided to us previously. The amended
          Policy will take effect when made available at{' '}
          <a href="https://www.ntucfirstcampus.com" target="_blank">
            https://www.ntucfirstcampus.com.
          </a>
        </p>
        <h3>2. Personal Data</h3>
        <p>
          2.1{' '}
          <span className="privacy-font-w">What personal data we collect.</span>{' '}
          The Personal data we collect depends on the purposes for which we
          require the personal data and what you have chosen to provide. This
          may include your name, address, contact information (e.g. telephone
          number and email address), identification number, photograph, video
          image and any other information that may identify you or is personal
          to you.
        </p>
        <p>
          2.2{' '}
          <span className="privacy-font-w"> How we collect personal data.</span>{' '}
          We collect personal data relevant to our relationship with you. We may
          collect your personal data directly or indirectly through various
          channels, including when:
          <ul>
            <li>
              you use our services or enter into transactions with us (or
              express interest in doing so);
            </li>
            <li>
              you apply to be a member of any of our loyalty programs, respond
              to our promotions, or subscribe to our mailing lists;
            </li>
            <li>
              you visit our websites, download or use our mobile applications;
            </li>
            <li>
              you register an account with us through our websites or
              applications;
            </li>
            <li>
              you transact with us, contact us or request that we contact you
              through various communication channels, for example, through
              social media platforms, messenger platforms, face-to-face
              meetings, telephone calls, emails, fax and letters;
            </li>
            <li>
              your images are captured via photographs or videos taken by us or
              our representatives when you are within our premises or attend
              events organised by us;
            </li>
            <li>
              you participate in events and programs, competitions, contests or
              games organised by us;
            </li>
            <li>
              we seek information about you and receive your personal data in
              connection with your relationship with us, for example, if you are
              a customer, investor or shareholder; or
            </li>
            <li>you submit your personal data to us for any other reason.</li>
          </ul>
          Depending on your relationship with us, we may also collect your
          personal data from third parties, including:
          <ul>
            <li>
              from other organisations and social enterprises which are part of
              NTUC Social Enterprise (see{' '}
              <a href=" http://ntucsocialenterprises.sg">
                {' '}
                http://ntucsocialenterprises.sg
              </a>
              ) (collectively “
              <span className="privacy-font-w">
                NTUC Social Enterprise Group
              </span>
              ”) ;
            </li>
            <li>
              from your family members or friends who provide your personal data
              to us on your behalf; and
            </li>
            <li>from public agencies or other public sources.</li>
          </ul>
          Our website and applications may also contain or involve certain
          technologies that automate the collection of data (including personal
          data). These technologies include cookies, web beacons and web
          analytics. If you do not wish to have your data collected through such
          technologies you may disable the operation of these technologies on
          your devices (where possible), or you may refrain from using our
          websites and applications.
        </p>
        <p>
          2.3{' '}
          <span className="privacy-font-w">
            Voluntary provision of personal data.
          </span>{' '}
          Your provision of personal data to us is voluntary and you have the
          right to withdraw your consent for us to use your personal data at any
          time by contacting and submitting a request to us. Your withdrawal
          will take effect after your request is processed. However, if you
          choose not to provide us with the personal data we require, it may not
          be possible for us to fulfil the purposes for which we require the
          personal data, including providing products and services which you
          need from us.
        </p>
        <p>
          2.4{' '}
          <span className="privacy-font-w">
            Providing personal data belonging to others.
          </span>{' '}
          In certain circumstances, you may also provide the personal data of
          persons other than yourself (including your family members). If you do
          so, you are responsible for informing him / her of the purposes for
          which we require his/her personal data and warrant that you are
          validly acting on behalf of him / her to consent to our collection,
          use and disclosure of his / her personal data.
        </p>
        <p>
          2.5{' '}
          <span className="privacy-font-w">
            {' '}
            Accuracy and Completeness of personal data.
          </span>{' '}
          You must ensure that all personal data that you provide is true,
          accurate and complete and promptly inform us of any changes to the
          personal data.
        </p>
        <p>
          2.6 <span className="privacy-font-w">Minor.</span> If you are a child,
          minor or not of legal age, please inform and seek the consent of your
          parent or guardian, before you provide your personal data to us. If
          you are a parent or guardian and you have reason to believe your child
          or ward has provided us with their personal data without your consent,
          please contact us to request for erasure of their personal data.
        </p>
        <h3>3. Purposes</h3>
        <p>
          3.1 We collect, use and disclose your personal data where:
          <ul>
            <li>you have given us consent;</li>
            <li>
              necessary to comply with our legal or regulatory obligations;
            </li>
            <li>
              necessary for our legitimate business interests, provided that
              this does not override your interests or rights; and/or
            </li>
            <li>
              necessary to perform a contract or transaction you have entered
              into with us, or provide a service that you have requested or
              require from us.
            </li>
          </ul>
        </p>
        <p>
          3.2 <span className="privacy-font-w">General purposes.</span>{' '}
          Generally, we collect, use and disclose your personal data for
          purposes connected or relevant to our business, including:
          <ul>
            <li>
              processing your transactions with us, or to provide products and
              services to you;
            </li>
            <li> managing your relationship with us;</li>
            <li> facilitating your use of our platforms and services;</li>
            <li> assisting you with your requests, enquiries and feedback;</li>
            <li>
              {' '}
              administrative purposes, e.g. accounting, risk management and
              record keeping, business research, data, planning and statistical
              analysis, and staff training;
            </li>
            <li>
              security and safety purposes, e.g. protecting our platforms from
              unauthorised access or usage and to monitor for security threats,
              and your image may be captured by security cameras;
            </li>
            <li>carrying out research, data and statistical analysis;</li>
            <li>
              {' '}
              compliance with laws and regulations, internal policies and
              procedures, e.g. audit, accounting, risk management and record
              keeping;
            </li>
            <li>
              {' '}
              enforcing legal obligations owed to us, or responding to
              complaints, litigation or investigations concerning us;
            </li>
            <li>
              managing and engaging third parties or data processors that
              provide services to us, e.g. IT services, data analytics,
              marketing, and other professional services;
            </li>
            <li>
              such purposes that may be informed to you when your personal data
              is collected;
            </li>
            <li>
              carrying out our legitimate business interests (listed below);
              and/or
            </li>
            <li>any other reasonable purposes related to the aforesaid</li>
          </ul>
        </p>
        <p>
          3.3 <span className="privacy-font-w"> Marketing purposes.</span> Where
          you give us consent, we collect, use and disclose your personal data
          for purposes of:
          <ul>
            <li>
              Managing and/or administering your request to receive news
              (including events and product launches), promotions and marketing
              information from us (and/or our affiliates or related entities)
              and on our group products;
            </li>
            <li>
              Analyzing and/or profiling your purchases, transactions and/or
              likes or dislikes so as to be better able to send you relevant or
              targeted news (including events and product launches), promotion
              and marketing information from us (and/or our affiliates or
              related entities) and on our group products; and/or
            </li>
            <li>
              Sending you news (including events and product launches) and
              promotions from us (and/or our affiliates or related entities) as
              well as marketing information from us (and/or our affiliates or
              related entities) and on our group products.
            </li>
          </ul>
        </p>
        <p>
          3.4{' '}
          <span className="privacy-font-w">
            {' '}
            Legitimate business interests.
          </span>{' '}
          Our legitimate business interests include:
          <ul>
            <li>
              managing our business and relationship with you, and providing
              services to our users and customers;
            </li>
            <li>
              protecting our rights and interests and those of our users and
              customers;
            </li>
            <li>
              preventing and investigating possible misuse of our websites,
              applications and services;
            </li>
            <li>understanding and responding to inquiries and feedback;</li>
            <li>
              understanding how our users use our websites, applications and
              services;
            </li>
            <li>
              identifying what our users want and improving our websites,
              applications, services and offerings;
            </li>
            <li>
              enforcing obligations owed to us, or protecting ourselves from
              legal liability; and{' '}
            </li>
            <li>
              sharing data in connection with acquisitions and transfers of our
              business.
            </li>
          </ul>
        </p>
        <p>
          3.5{' '}
          <span className="privacy-font-w">
            Purposes involving NTUC Social Enterprise Group.
          </span>{' '}
          NTUC Enterprise Co-operative Limited has responsibility for the NTUC
          Social Enterprise Group as well as the affiliated organisations. For
          administrative efficiencies and to allow NTUC Enterprise Co-operative
          Limited to better serve your needs, your personal data will also be
          collected, used and disclosed to an organisation within the NTUC
          Social Enterprise Group for the following purposes:
          <ul>
            <li>
              faciliating the provision of centralised administrative and
              mangement services;
            </li>
            <li>
              faciliating use of centralised resources e.g. shared information
              technology resources and systems;
            </li>
            <li>
              faciliating internal audits, reporting and management of our
              operations; and/or
            </li>
            <li>
              facilitaing the conduct of centralised business activities and
              functions e.g. data analytics.
            </li>
          </ul>
        </p>
        <p>
          3.6{' '}
          <span className="privacy-font-w">
            {' '}
            Use permitted under applicable laws.
          </span>{' '}
          We may also collect, use, disclose and process your personal data for
          other purposes, without your knowledge or consent, where this is
          required or permitted by law.
        </p>
        <p>
          3.7 <span className="privacy-font-w">Contacting you.</span> When using
          your personal data to contact you for the above purposes, we may
          contact you via mail, e-mail, SMS, telephone, pop-up notifications
          (when you are using our applications), or any other means.
        </p>
        <p>
          {' '}
          We will not contact you for marketing purposes unless with your
          consent, or we are exempted by applicable law from having to obtain
          consent. When contacting you for marketing purposes, we will not
          contact you through your telephone number, unless you have
          specifically consented to such a mode of communication. If you do not
          wish to receive any communication or information from us, or wish to
          restrict the manner by which we may contact or send you information,
          you may contact us to do so.
        </p>
        <h3>4. Disclosure of Personal Data</h3>
        <p>
          4.1{' '}
          <span className="privacy-font-w">
            {' '}
            Disclosure to NTUC Social Enterprise Group.
          </span>{' '}
          We may disclose or share your personal data with the NTUC Social
          Enterprise Group for the purposes described in paragraph 3.2, 3.3, 3.4
          and 3.5 . We do not disclose children’s data that can be used to
          identify any person (including data concerning children’s development,
          academic performance and health records) with the NTUC Social
          Enterprise Group.
        </p>
        <p>
          4.2 <span className="privacy-font-w">Other Disclosures.</span> We may
          also disclose or share your personal data in connection with the
          purposes described in paragraphs 3.2 , 3.3 and 3.4 above, including to
          the following parties:
          <ul>
            <li>
              third parties who are appointed to provide services to us, e.g. IT
              vendors, marketing companies and event organisers;
            </li>
            <li>
              third parties that we conduct joint marketing and cross promotions
              with; and/or
            </li>
            <li>regulatory authorities and public agencies.</li>
          </ul>
        </p>
        <p>
          When disclosing personal data to third parties, we will (where
          appropriate and required by applicable law) enter into contracts with
          these third parties to protect your personal data in a manner that is
          consistent with applicable laws and/or ensure that they only process
          your personal data in accordance with our instructions.
        </p>
        <h3>5. Cross Jurisdiction Transfers of Personal Data</h3>
        <p>
          5.1 <span className="privacy-font-w">Safeguards.</span> We may
          transfer your personal data out of Singapore for the purposes set out
          in paragraph 3 above. When transferring personal data outside
          Singapore, we will require recipients of the personal data to protect
          personal data at a standard comparable to that under the laws of
          Singapore. For example, we may enter into legally enforceable
          agreements with the recipients to ensure that they protect your
          personal data. You may obtain details of these safeguards by
          contacting us.
        </p>
        <h3>6. Protection of Personal Data</h3>
        <p>
          6.1 <span className="privacy-font-w"> Period of retention.</span> We
          keep your personal data only for so long as we need the data to fulfil
          the purposes we collected it for, and to satisfy our business and
          legal purposes, including audit, accounting or reporting requirements.
          How long we keep your personal data depends on the nature of the data,
          e.g. we keep personal data for at least the duration of the limitation
          period for bringing claims if the personal data may be required to
          commence or defend legal proceedings. Certain information may also be
          retained for longer, e.g. where we are required to do so by law.
          Typically, our data retention period is from 6 years upwards,
          depending on the limitation period.
        </p>
        <p>
          6.2 <span className="privacy-font-w">Anonymised data.</span> In some
          circumstances we may anonymise your personal data so that it no longer
          identifies you, in which case we are entitled to retain and use such
          anonymised data without restriction, including for data analytics.
        </p>
        <p>
          6.3{' '}
          <span className="privacy-font-w">
            Unauthorised access and vulnerabilities.
          </span>{' '}
          While we take reasonable precautions to safeguard your personal data
          in our possession or under our control, we cannot be held responsible
          for unauthorised or unintended access that is beyond our control,
          including hacking or cybercrimes. We also do not guarantee that our
          websites and applications are invulnerable to security breaches, or
          that your use of our websites and applications is safe and protected
          from viruses, worms, Trojan horses, and other vulnerabilities.
        </p>
        <h3>7. Your Rights</h3>
        <p>
          7.1 You enjoy certain rights at law in relation to your personal data
          that we hold or control. These rights include:
          <ul>
            <li>
              <span className="privacy-font-w">Withdrawal of consent.</span> you
              may withdraw consent for our use of your personal data.
            </li>
            <li>
              <span className="privacy-font-w">Correction.</span> you may
              request that any incomplete or inaccurate data that we hold or
              control be corrected.
            </li>
            <li>
              <span className="privacy-font-w">Access.</span> you may ask if we
              hold or control your personal data and if we are, you can request
              access or a copy of such data.
            </li>
          </ul>
        </p>
        <p>
          7.2 <span className="privacy-font-w"> Exercising your rights.</span>{' '}
          If you wish to exercise your rights, you may contact us to do so (see
          paragraph 1.4 above for contact details).We may require that you
          submit certain forms or provide certain information to process your
          request. Where permitted by law, we may charge you a fee to process
          your request. We may also be permitted under applicable laws to refuse
          a request.
        </p>
        <p>
          7.3 <span className="privacy-font-w">Response timeline. </span>
          We will respond to your request within thirty (30) business days. 
          Should we not be able to respond to your request within 
          thirty (30) days after receiving your request, we will inform you in writing 
          within thirty (30) days of the time by which 
          we will be able to respond to your request. 
          If we are unable to provide you with any personal data or 
          to make a correction requested by you, we shall generally 
          inform you of the reasons why we are unable to do so (except where
          we are not required to do so under the PDPA).
        </p>
        <p>
          7.4 <span className="privacy-font-w">Limitations.</span> We may be
          permitted under applicable laws to refuse your request to exercise
          your rights, for example, we may refuse (a) a request for erasure
          where the personal data is required for in connection with claims; or
          (b) an objection request and continue processing your personal data
          based on compelling legitimate grounds for the processing.
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
