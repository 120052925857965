import React from 'react';
import Layout from '../../layout/layout';
import Graphic1 from '../../images/objects/difference-ch/graphic-1.png';
import Graphic2 from '../../images/objects/difference-ch/graphic-2.png';
import Graphic3 from '../../images/objects/difference-ch/graphic-3.png';
import Graphic4 from '../../images/objects/difference-ch/graphic-4.png';
import Graphic5 from '../../images/objects/difference-ch/graphic-5.png';
import Picture1 from '../../images/objects/difference-ch/picture-11.jpg';
import Picture2 from '../../images/objects/difference-ch/picture-2.jpg';

const CurriculumHighlights = () => {
  return (
    <Layout
      bannerClass="our-difference"
      heading="Curriculum Highlights"
      breadCrumbs={[
        { label: 'Our Difference' },
        { label: 'Curriculum Highlights' }
      ]}
    >
      <div className="learning-environment-container">
        <div className="photo-section">
          <div className="img-section">
            <img src={Picture1} alt="Meals" />
          </div>
          <div className="text">
            <h2>0-3 years</h2>
            <h4>Relationships-Based Curriculum</h4>
            <p>
              Confidence and independence are the starting point for a child's
              learning.
            </p>
            <p>
              Our Relationships-Based curriculum ensures that learning outcomes
              stipulated by the Early Childhood Development Agency and the
              Ministry of Education are expertly honed in each child by
              fostering the child's interest in learning and self-discovery.
            </p>
            <p>
              To start your child on learning, we focus on building his
              self-confidence. As observed by Zero to Three (National Center for
              Clinical Infant Programs),{' '}
              <strong>
                "Children who are confident are eager to learn new skills and
                face new challenges."
              </strong>
            </p>
          </div>
        </div>
        <div className="top-section">
          <div className="img-section">
            <img src={Graphic1} alt="Graphics 1" />
          </div>
          <div className="text">
            <h3>
              The same study also noted that self-confidence is essential for
              children to learn how to get along with others, particularly in
              school situations where they need to share, compete and make
              friends.
            </h3>
            <p>
              In their first week at The Little Skool-House, we assign a teacher
              to always be with the children to ensure them of help and support,
              and to provide positive reinforcement to give them a sense of
              pride.
            </p>
          </div>
        </div>
        <div className="top-section top-section-right">
          <div className="img-section-mobile">
            <img src={Graphic2} alt="Meals" />
          </div>
          <div className="text">
            <p>
              Establishing routine is also an important part of fostering
              self-confidence. According to Zero to Three, children feel safe,
              secure and confident.
            </p>
            <h3>
              "When events are predictable and when they happen in approximately
              the same way at approximately the same time each day."
            </h3>
            <p>
              Once children gain a sense of familiarity and belonging, we then
              introduce them to storybooks as a springboard for learning.
            </p>
          </div>
          <div className="img-section">
            <img src={Graphic2} alt="Meals" />
          </div>
        </div>
        <div className="photo-section">
          <div className="img-section">
            <img src={Picture2} alt="Literacy-Based Curriculum" />
          </div>
          <div className="text">
            <h2>4-6 years</h2>
            <h4>Literacy-Based Curriculum</h4>
            <p>
              Holistic development to enable success in school and success in
              life.
            </p>
            <p>
              Our 4 to 6 year olds are focussed on preparing for Primary School.
              The Little Skool-House Literacy-Based Curriculum culminates in our
              Formation Programme in order for children to be primary
              school-ready even before they leave preschool.
            </p>
          </div>
        </div>
        <div className="top-section">
          <div className="img-section">
            <img src={Graphic3} alt="Meals" />
          </div>
          <div className="text">
            <h3>The Formation Programme</h3>
            <h3>Builders &amp; Innovation Project</h3>
            <p>
              Instilling critical thinking and problem solving in science and
              math learning. Our children make their own science project,
              applying concepts and skills they have learnt in projects such as
              water filtration and how the digestive system works.
            </p>
          </div>
        </div>
        <div className="top-section top-section-right">
          <div className="img-section-mobile">
            <img src={Graphic4} alt="Meals" />
          </div>
          <div className="text">
            <h3>Drama Pedagogy in Mandarin 戏剧教学</h3>
            <p>
              A fun and engaging theatre production produced by the children.
              From script writing, to costume making and selling tickets, to
              performing their very own play on stage. All in Mandarin.
            </p>
          </div>
          <div className="img-section">
            <img src={Graphic4} alt="Meals" />
          </div>
        </div>
        <div className="top-section">
          <div className="img-section">
            <img src={Graphic5} alt="Meals" />
          </div>
          <div className="text">
            <h3>Structured Reading &amp; Literacy Assessments</h3>
            <p>
              Equips preschoolers with different strategies to enable them to
              read independently and at their own pace. From phonics to Yu Ying
              to weekly journal writing.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CurriculumHighlights;
