import React from 'react';
import Layout from '../../layout/layout';
import Graphic1 from '../../images/objects/chosen-preschool/graphic-1.png';
import Graphic2 from '../../images/objects/chosen-preschool/graphic-2.png';
import Graphic3 from '../../images/objects/chosen-preschool/graphic-3.png';
import Graphic4 from '../../images/objects/chosen-preschool/graphic-4.png';
import Graphic5 from '../../images/objects/chosen-preschool/graphic-5.png';
import { EPSection } from '../our-curriculum/4-6-years-literacy-based-curriculum';
import './chosen-preschool-by-top-institutions.scss';

const sections = [
  {
    text: {
      heading: `Ministries of Singapore`,
      content: (
        <ul>
          <li>Ministry of Education</li>
          <li>Ministry of Social and Family Development</li>
        </ul>
      )
    },
    graphic: Graphic1
  },
  {
    text: {
      heading: `Major Tertiary Institutions`,
      content: (
        <ul className="right-sided">
          <li>National University of Singapore</li>
          <li>Ngee Ann Polytechnic</li>
          <li>Republic Polytechnic</li>
          <li>Temasek Polytechnic</li>
        </ul>
      )
    },
    graphic: Graphic2
  },
  {
    text: {
      heading: `Healthcare Institutions`,
      content: (
        <ul>
          <li>Alexandra Hospital</li>
          <li>Khoo Teck Puat Hospital</li>
          <li>KK Women’s and Children’s Hospital</li>
          <li>Singapore General Hospital</li>
        </ul>
      )
    },
    graphic: Graphic3
  },
  {
    text: {
      heading: `Banks`,
      content: (
        <ul className="right-sided">
          <li>OCBC Bank</li>
        </ul>
      )
    },
    graphic: Graphic4
  },
  {
    text: {
      heading: `Other Corporate Partnerships`,
      content: (
        <ul>
          <li>DBS Bank</li>
          <li>Wildlife Reserves Singapore</li>
        </ul>
      )
    },
    graphic: Graphic5
  }
];

const ChosenPreschool = () => {
  return (
    <Layout
      bannerClass="our-difference"
      heading="Chosen Preschool By Top Institutions"
      breadCrumbs={[
        { label: 'Our Difference' },
        { label: 'Chosen Preschool By Top Institutions' }
      ]}
      metaData={{
        url:
          'https://www.littleskoolhouse.com/our-difference/chosen-preschool-by-top-institutions',
        title: 'Chosen Preschool by Top Institutions | Little Skool-House',
        description: 'Little Skool-House is known for curriculums designed based on clinical research, and is the preferred preschool chosen by many top institutions in Singapore.'
      }}
    >
      <div className="learning-environment-container chosen-ps-container">
        <h2 className="intro">
          We are the preschool chosen by Singapore’s most prestigious
          institutions
          <br className="desktop-only" /> to provide preschool education for
          their own personnel and children
        </h2>

        {sections.map((section, i) => (
          <EPSection section={section} right={i % 2 !== 0} key={i} />
        ))}
      </div>
    </Layout>
  );
};

export default ChosenPreschool;
