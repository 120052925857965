import React from 'react';
import './RadioButton.scss';

const RadioButton = ({ name, value, checked, handler }) => (
  <div className="radio-option">
    <input
      id={value}
      type="radio"
      name={name}
      value={value}
      checked={checked}
      onChange={handler}
    />
    <label htmlFor={value}>{value}</label>
  </div>
);

export default RadioButton;
