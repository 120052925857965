import React, { useEffect, useState } from 'react';
import { Link, useStaticQuery } from 'gatsby';
import GWLCnLogo from '../../../images/GWLCnLogo.png';
import './DesktopMenu.scss';
import Dropdown, {
  DropdownTrigger,
  DropdownContent
} from 'react-simple-dropdown';
import { globalHistory } from '@reach/router';
// import GwlLogo from '../../../images/logos/gwl-text-logo.webp';
import { getLanguage, LSH_LOGO } from '../../common/utils/utils';
import { gtmEventManager } from "../../common/utils/utils"


const getParentUrl = gh => {
  const path = gh && gh.location && gh.location.pathname;
  const lang = getLanguage();
  if (path) {
    const pieces = path.split('/');
    if (pieces && pieces.length > 1) {
      return lang == 'english' ? pieces[1] : pieces[2];
    }
  }
};

const getIconMenu = (link, language) => {
  const custom = {
    english: {
      homeLink: '/'
    },
    chinese: {
      homeLink: '/zh'
    }
  }
  if (link === '/' || link === '/zh') {
    return (
      <div className="link-item-logo">
        <Link to={custom[language].homeLink}>
        <img src={LSH_LOGO} alt="Little Skool House" width="100" height="115" title="Little Skool House" />
        </Link>
      </div>
    )
  }
}

const DesktopMenu = ({ menuItems, promoActive, activeLang }) => {
  const [parentUrl, setParentUrl] = useState();

  useEffect(() => {
    const url = getParentUrl(globalHistory);
    setParentUrl(url);
  }, [1]);

  let scheduleVisit = '';
  if(activeLang == 'english'){
    scheduleVisit = promoActive === 'on' ? 'limited-time-promotion' : 'schedule-a-visit';
  } else {
    scheduleVisit = promoActive === 'on' ? 'xianshicuxiao' : 'yuyuecanguan';
  }
  return (
    <>
      <div className="header-menu-container">
        {menuItems &&
          menuItems.map(({ label, link, subMenu }) => (
            <React.Fragment key={link}>
              {(link === '/') ? getIconMenu(link, activeLang)
                : (
                  <div className="link-item">
                    {!subMenu ? (
                      <Link
                        to={`/${link}`}
                        className={
                          parentUrl && link.includes(parentUrl) ? `active` : ''
                        }
                        partiallyActive={true}
                      >
                        {label}
                      </Link>
                    ) : (
                      <Dropdown>
                        <DropdownTrigger
                          className={
                            parentUrl && link.includes(parentUrl) ? `active` : ''
                          }
                        >
                          {label}
                        </DropdownTrigger>
                        <DropdownContent>
                          <ul className="sub-menu">
                            <div className="white-triangle" />
                            {subMenu.map(({ label, link: subMenuLink }) => (
                              <li key={subMenuLink}>
                                <Link to={`/${link}/${subMenuLink}`}>
                                  {label}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </DropdownContent>
                      </Dropdown>
                    )}
                  </div>
                )}
            </React.Fragment>
          ))}

        <div className="link-item yellow-border-btn-holder">
          {activeLang == 'english' ? (
            <Link to={`enquiry/${scheduleVisit}`} onClick={() => gtmEventManager(`Top Banner`, 'Click', 'Schedule a visit')}> Schedule a Visit </Link>
          ) :
            <Link to={`/zh/xunwen/${scheduleVisit}`} > 预约参观 </Link>
          }
        </div>
      </div>
    </>
  );
};

export default DesktopMenu;
