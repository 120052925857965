import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import './CentersCarousel.scss';
import Dropdown from '../../common/Dropdown/Dropdown';
import { Link } from 'gatsby';
import { getLanguage } from '../../common/utils/utils';
import { centerLocations, centerLocations_cn } from '../../../../static-data';

const settings = {
  speed: 500,
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1
};

const getCenterCarouselContent = (centreList, language) => {
  return (
    centreList &&
    centreList.map(ctr => {
      const {
        centreName,
        centreName2,
        address,
        contact,
        email,
        location,
      } = ctr.centre;
      let centrePic = ctr.centre?.centrePhotos && ctr.centre?.centrePhotos[0]?.mediaItemUrl;
      if (ctr.centre?.defaultPicture) {
        centrePic = ctr.centre?.defaultPicture?.mediaItemUrl;
      }
      const addressArr = address?.split(/\n/);
      const custom = {
        english: {
          "readMoreText": "Read more",
          "readMoreLink": `/our-centres/${ctr.slug}`
        },
        chinese: {
          "readMoreText": "阅读更多",
          "readMoreLink": `/zh/womendezhongxin/${ctr.slug}`
        },
      }
      return (
        <div className="center-details" key={0}>
          <div className="center-img">
            {centrePic && (
              <div
                style={{
                  backgroundImage: `url(${centrePic})`,
                  backgroundSize: 'cover'
                }}
              />)}
          </div>
          <div className="center-text">
            <h2>
              {centreName} { language === "chinese" ? <br /> : ""}
              {centreName2 && <span>- {centreName2}</span>}
            </h2>
            <h3>{location}</h3>
            <p className="address">{addressArr.join(', ')}</p>
            <div className="button-section">
              <a href={`tel:${contact}`}>
                <div className="info-blue">{contact}</div>
              </a>
              <a href={`mailto:${email}`} target="_blank">
                <div className="info-yellow">{email}</div>
              </a>
            </div>
            <Link to={custom[language].readMoreLink}>
              <div className="read-more-btn">
                <button className="yellow-funky-button">{custom[language].readMoreText}</button>
              </div>
            </Link>
          </div>
        </div>
      );
    })
  );
};

const CentersCarousel = ({ centreDetails }) => {
  const [language, setLanguage] = useState('english');
  const [locationList, setLocationList] = useState([]);
  const [selectCentreType, setSelectedCenterType] = useState('north');

  useEffect(() => {
    const lan = getLanguage();
    let loc = [];

    setLanguage(lan);
    if (lan == 'chinese') {
      loc = centerLocations_cn;
    } else {
      loc = centerLocations;
    }
    const newLocationList = loc.filter(item => item.title != 'all-centres');
    setLocationList(newLocationList);
    setSelectedCenterType(newLocationList && newLocationList[0]?.title?.toLowerCase());
  }, [1])
  const customDirection = {
    "北部": 'north',
    "中区": 'central',
    "东部": 'east',
    "西部": 'west',
    "north": 'north',
    "central": 'central',
    "east": 'east',
    "west": 'west',
  }
  const custom = {
    english: {
      "ourCentresTitle": "Our Centres",
      "viewAll": "View All",
      "viewAllLink": "/our-centres/centres/all-centres",
    },
    chinese: {
      "ourCentresTitle": "我们的中心",
      "viewAll": "全部",
      "viewAllLink": "/zh/womendezhongxin/suoyouzhongxin"
    },
  }

  const centreList = language && centreDetails?.filter(item => item.centre?.language?.toLowerCase() == language &&
    customDirection[item.centre.cardinalDirection] == customDirection[selectCentreType]);

  const centreTypeHandler = evt => {
    setSelectedCenterType(evt.target.value);
  };

  return (
    <div className="centers-carousell-container">
      <div className="left-section">
        <h3>{custom[language].ourCentresTitle}</h3>
        <div className="center-selector tablet-and-desktop-only">
          <div className="nav-section">
            {locationList?.map(location => (
              <div className="category-option" key={location.title}>
                <input
                  type="radio"
                  id={location.title}
                  name="category"
                  checked={location.title === selectCentreType}
                  value={location.title}
                  onChange={e => centreTypeHandler(e)}
                />
                <label htmlFor={location.title}>{location.label}</label>
              </div>
            ))}
          </div>
        </div>
        <div className="mobile-only-strict center-selector-mobile">
          {locationList?.length > 0 && (
            <Dropdown
              options={locationList?.map(cl => ({
                key: cl.title,
                value: cl.title,
                label: cl.label
              }))}
              onChange={setSelectedCenterType}
              hideSelectedOption
              defaultValue={locationList && locationList[0]}
            />
          )}
        </div>
        <Link to={custom[language].viewAllLink}>
          <button className="yellow-funky-button">{custom[language].viewAll}</button>
        </Link>
      </div>
      <div className="right-section">
        <div className="tablet-and-desktop-only">
          <Slider {...settings}>
            {getCenterCarouselContent(centreList, language)}
          </Slider>
        </div>
        <div className="mobile-only-strict">
          <Slider {...settings} arrows={false}>
            {getCenterCarouselContent(centreList, language)}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default CentersCarousel;
