import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import './promoSection.scss';
import Rebate from '../../../images/objects/open-house/rebate.png';
import GIFT from '../../../images/objects/gift.png';
import SpecialKit from '../../../images/objects/kit.png';


const PromoSectionPart = ({
    showArrow,
    serial,
    promoImg,
    altText,
    children,
    rebate,
    rebateAmountCls,
    customRebateCls
  }) => (
    <div className="promo-section">
      <div className={`promo-head ${showArrow ? 'show-arrow' : ''}`}>
        <span>
          <div className="text">{serial}</div>
        </span>
        {/* <div className="promo-img-container">
          <img src={promoImg} alt={altText} />
          <div>
          {rebate && <span className={`rebate ${rebateAmountCls} ${customRebateCls}`}>${rebate}</span>}
          </div>
        </div> */}
      </div>
      <div className="promo-body">{children}</div>
    </div>
  );

const PromoSection = () => {

    const data = useStaticQuery(graphql`
    query {
      cmsData: cms {
        pageBy(uri: "limited-time-promotion") {
          open_house {
            openhousestatus
            privacyPolicy
            promotionPrice
            promotionTitle
            subTitle
            title
            worthLabel
            promoSection {
                subHeading
            }
          }
        }
      }
    }
  `);
  const cmsPromo = data.cmsData.pageBy.open_house;

  return (
    <div className="promo-left-section">
    <div className="top-section">
      <h4>{cmsPromo.title}</h4>
      <div className="money-section">
        <span>{cmsPromo.worthLabel || 'worth up to'}</span>
        <div className="money" style={{ width: '59%' }}>{cmsPromo.promotionPrice}</div>
      </div>
    </div> 
   
    <div className="center-section">
      <div className="" />
    
   {cmsPromo.promoSection.map((item, i) => (
    <PromoSectionPart
        key={i}
        serial={i + 1}
        promoImg={Rebate}
        altText="Rebate"
        rebate={cmsPromo.promotionPrice}
    >
     <h4>{item.subHeading}</h4>
    </PromoSectionPart>
    ))}
     
      <div className="terms-section">* Terms and conditions apply.</div>
    </div>
    <div className="under-section">
      <div className="white-triangle" />
      <h2 className="huge">93%</h2>
      <p>
        OF LITTLE SKOOL-HOUSE <br />
        CHILDREN ARE RATED TO
        <br />
        HAVE <b> HIGH PROFICIENCY </b>
        <br />
        IN{' '}
        <b>
          {' '}
          BOTH ENGLISH AND <br />
          MANDARIN{' '}
        </b>
        <br /> <br />
      </p>
      <p style={{ fontSize: '16px' }}>
        <i>
          {' '}
         <a className="op-underline"  href="https://research.littleskoolhouse.com/" target="_blank"> Research </a> done by Nanyang Technological University Professor
          (Dr. Setoh, PhD)
        </i>
      </p>
      <p className="small">
        Register your interest today and learn how we do it!
      </p>
    </div>
  </div>
  );
};


export default PromoSection;
