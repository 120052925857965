import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import LSHLogo from '../../../images/logo.png';
import './WheelNav.scss';

const WheelNav = ({ onChange, sections = 6, timeOutDuration = 2500 }) => {
  const [position, setPosition] = useState(1);
  const timerInstance = useRef(null);

  const setWheelPosition = (pos, slowdownTimer = true) => {
    let newPos;
    if (pos) {
      setPosition(pos);
    } else {
      setPosition(current => {
        newPos = current > 1 ? current - 1 : sections;
        return newPos;
      });
    }
    if (onChange) onChange(pos || newPos);
    if (slowdownTimer) deferTimerTick();
  };

  const timerTick = () => {
    setWheelPosition(null, false);
    timerInstance.current = setTimeout(timerTick, timeOutDuration);
  };

  useEffect(() => {
    timerInstance.current = setTimeout(timerTick, timeOutDuration);
    // Cleanup
    return () => {
      if (clearTimeout) clearTimeout(timerInstance.current);
    };
  }, []);

  const deferTimerTick = () => {
    if (clearTimeout) {
      clearTimeout(timerInstance.current);
      timerInstance.current = setTimeout(timerTick, timeOutDuration * 3);
    }
  };

  return sections === 6 ? (
    <div className="wheel-nav-container">
      <div className={`carret color-${position % 2 === 0 ? 'blue' : 'orange'}`}>
        <FontAwesomeIcon icon={faCaretRight} />
      </div>
      <div className="center-logo">
        <img src={LSHLogo} alt="LSH Logo" />
      </div>
      <div className={`wheel wheel-position-${position}`}>
        <p className="p1" onClick={() => setWheelPosition(1)}>
          Our Teachers
        </p>
        <p className="p2" onClick={() => setWheelPosition(2)}>
          Our Learning Environment
        </p>
        <p className="p3" onClick={() => setWheelPosition(3)}>
          International ECCE Community
        </p>
        <p className="p4" onClick={() => setWheelPosition(4)}>
          Our Parents
        </p>
        <p className="p5" onClick={() => setWheelPosition(5)}>
          Our Principals
        </p>
        <p className="p6" onClick={() => setWheelPosition(6)}>
          Our Curriculum
        </p>
      </div>
    </div>
  ) : (
    <div className="wheel-nav-container-4">
      <div className={`carret color-${position % 2 === 0 ? 'blue' : 'orange'}`}>
        <FontAwesomeIcon icon={faCaretRight} />
      </div>
      <div className="center-logo">
        <img src={LSHLogo} alt="LSH Logo" />
      </div>
      <div className={`wheel wheel-position-${position}`}>
        <p className="p1" onClick={() => setWheelPosition(1)}>
          Exposure
        </p>
        <p className="p2" onClick={() => setWheelPosition(2)}>
          Ability to Communicate
        </p>
        <p className="p3" onClick={() => setWheelPosition(3)}>
          Academic Preparedness
        </p>
        <p className="p4" onClick={() => setWheelPosition(4)}>
          Behavioural Adaptions
        </p>
      </div>
    </div>
  );
};

export default WheelNav;
