import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet-async';
import GwlLogo from '../../../images/logos/gwl-logo-2.png';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import favIcon from '../../../images/gwl-logo.png'
import LshLogo from '../../../images/logo.png'
import './gwlLayout.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';

const GwlLayout = ({
    children,
    metaData,
    openGraph,
    metaTags,
    path,
    schemaMarkup
}) => {

  const { site, openGraphLogo } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
      openGraphLogo: file(relativePath: { eq: "gwl-logo.png" }) {
        childImageSharp {
          fixed(width: 3595, height: 2397) {
            src
          }
        }
      }
    }
    `);
  const { description, siteUrl } = site.siteMetadata;
  // const title = "Schedule Your Classes (Bilingual) | Golden Window of Learning by The Little Skool-House"
  // const description = "Complete your one-time selection of your preferred English and Mandarin class schedules for the Golden Window of Learning by The Little Skool-House."
  const absoluteUrl = relativeURL => new URL(relativeURL, siteUrl).toString();
  const imageUrl = absoluteUrl(openGraphLogo.childImageSharp.fixed.src);
  const url = absoluteUrl(path);

  let { title } = site.siteMetadata;


  openGraph = openGraph || {
    type: 'website',
    url: metaData ? metaData.url : url,
    title: metaData ? metaData.title : title,
    description: metaData ? metaData.description : description,
    images:
      metaData && metaData.image
        ? [
          {
            url: metaData.image,
            alt: metaData ? metaData.title : title
          }
        ]
        : [
          {
            url: imageUrl,
            width: 3595,
            height: 2397,
            alt: metaData ? metaData.title : title
          }
        ]
  };
  
  return (
    <div className="gwl-layout-container">
       <Helmet htmlAttributes={{ lang: 'en' }}>
        <link rel="shortcut icon" href={favIcon} />
        <link rel="icon" type="image/png" href={favIcon} />
        {schemaMarkup && (
          <script type="application/ld+json">
            {JSON.stringify(schemaMarkup)}
          </script>
        )}
      </Helmet>
      <GatsbySeo
        title={metaData ? metaData.title : title}
        description={metaData ? metaData.description : description}
        canonical={metaData ? metaData.url : url}
        metaTags={metaTags}
        openGraph={openGraph}
      />
      <div className="main-container">
         {/* <div className="gwl-layout-banner">
          <div className="gwl-logo-wrap-l">
            <div className="gwl-logo">
            <img className="logo-1" src={GwlLogo} />
            </div>
            <div className="gwl-logo">
            <img className="logo-2" src={LshLogo} />
            </div>
          </div>
         </div>  */}
        <div
          className={`no-top `}
        >
          <main>{children}</main>
           {/* <div className="gwl-footer">
            <p>Copyright © 2021 Golden Window of Learning by The Little Skool-House. All Rights Reserved.</p>
          </div>  */}

        </div>
      </div>
    
    </div>
  );
};

GwlLayout.propTypes = {
    path: PropTypes.string,
    additionalMetaTags: PropTypes.arrayOf(PropTypes.object),
    openGraph: PropTypes.object,
    children: PropTypes.any,
    bannerClass: PropTypes.string,
    pageLn: PropTypes.string,
    metaData: PropTypes.object,
};

GwlLayout.defaultProps = {
  path: '',
  pageLn:'en',
  metaTags: [],
  schemaMarkup: {}
};

export default GwlLayout;
