import React, { useEffect, useState } from 'react';
import { ApolloClient, InMemoryCache, gql, HttpLink } from '@apollo/client';
import fetch from 'isomorphic-fetch';
import OpenHouse from '../../templates/open-house/open-house';
import ArticleNew from '../../templates/articles/article/articleNew';
import VideoDetailsCms from '../../templates/happenings/video-details/video-details-cms';
import PromotionDetailsCms from '../../templates/happenings/promotion-detail/promotion-detail-cms';
import StoryDetails from '../../templates/happenings/story/StoryDetails';
import GWL from '../../templates/gwl/gwl';
import LearningEnvironment from '../../templates/centres/our-learning-env'
import FlipBook from '../../components/home/FlipBook/FlipBook';
import FlipBookMobile from '../../components/home/FlipBookMobile/FlipBookMobile';
import OurMeals from '../../templates/centres/our-nutritious-meal'
import { GWL_BILINGUAL_SLUG, GWL_EN_SLUG, GWL_MN_SLUG, inCentreList ,OLE_CN_SLUG,OLE_EN_SLUG,
        ONM_CN_SLUG, ONM_EN_SLUG, HOME_PAGE_EN_SLUG, HOME_PAGE_CN_SLUG} from '../../config/appConfig';
import CenterDetails from '../../templates/centres/centre-details';
import OpenHouseCn from '../../templates/open-house/open-house-cn';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    fetch,
    uri: process.env.GATSBY_WP_GRAPHQL_URL
  })
});

const Preview = ({ location }) => {
  const urlParams = new URLSearchParams(
    location && location.search && location.search
  );

  const postId = urlParams.get('id');
  const postType = urlParams.get('type');
  const slug = urlParams.get('slug');

  const [post, setPost] = useState(null);

  useEffect(() => {
    if (!postId || !postType) {
      return;
    }
    if (postType == 'page' && slug === GWL_EN_SLUG) {
      return fetchGwlEnglish(postId);
    }
    if (postType == 'page' && slug === GWL_MN_SLUG) {
      return fetchGwlMandarin(postId);
    }
    if (postType == 'page' && slug === GWL_BILINGUAL_SLUG) {
      return fetchGwlBilingual(postId);
    }
    if (postType == 'page' && (slug === HOME_PAGE_EN_SLUG || slug === HOME_PAGE_CN_SLUG)) {
      return fetchFlipBook(slug);
    }
    if (postType == 'page' && (slug === OLE_EN_SLUG || slug === OLE_CN_SLUG)) {
      return fetchOurLearningEnv(postId, slug);
    }
    if (postType == 'page' && (slug === ONM_EN_SLUG || slug === ONM_CN_SLUG)) {
      return fetchNutritiousMeals(postId, slug);
    }
    if (postType == 'page') {
      fetchOpenHouse(postId);
    }
    if (postType == 'happening_our_stories') {
      fetchOurStories(postId);
    }
    if (postType == 'happening_videos') {
      fetchVideos(postId);
    }
    if (postType == 'happening_promotions') {
      fetchPromotions(postId);
    }
    if (postType == 'expert_opinion') {
      fetchArticles(postId);
    }
    if (postType == 'centres') {
      fetchCentres(postId);
    }
  }, []);

  const fetchCentres = async postId => {
    try {
      await client
        .query({
          query: gql`{
            centres(where: {id: ${postId}}) {
              nodes {
                centre {
                  address
                  language
                  cardinalDirection
                  centreName
                  centreName2
                  contact
                  secondaryContact
                  email
                  location
                  map
                  operatingHours
                  scheduleAVisit
                  temporaryLocation
                  awards {
                    name
                    logo {
                      mediaItemUrl
                    }
                  }
                  centrePrincipals {
                    designation
                    name
                    profileSummary
                    qualification
                    profileImage {
                      mediaItemUrl
                    }
                  }
                  contents {
                    description
                    heading
                    programmesOffered
                    programmesOfferedDescription
                  }
                  faq {
                    answer
                    question
                  }
                  metaData {
                    description
                    title
                    url
                  }
                  centrePhotos {
                    mediaItemUrl
                  }
                  defaultPicture {
                    mediaItemUrl
                  }
                }
                slug
              }
            }
          }`
        })
        .then(result => {
          const {
            data: {
              centres: { nodes: pageData }
            }
          } = result;
          if (pageData) {
            const data = pageData[0];
            setPost({
              data: data,
            });
          }
        });
    } catch (ex) {
      console.error('err', ex);
    }
  };

  const fetchOpenHouse = async postId => {
    try {
      await client
        .query({
          query: gql`{
                pages(where: {id: ${postId}}) {
                  nodes {
                    open_house {
                      centres {
                        centreName
                        isParticipating
                        location
                      }
                      privacyPolicy
                      promotionPrice
                      promotionTitle
                      subTitle
                      title
                    }
                    title
                    slug
                  }
                }
              }
            `
        })
        .then(result => {
          const {
            data: {
              pages: { nodes: pageData }
            }
          } = result;
          if (pageData) {
            const data = pageData[0].open_house;
            setPost({
              data,
              title: pageData[0].title,
              slug: pageData[0].slug
            });
          }
        });
    } catch (ex) {
      console.error('error::::', ex);
    }
  };

  const fetchFlipBook = async slug => {
    try {
      await client
        .query({
          query: gql`{
            pages {
              nodes {
                home_page {
                  flipBook {
                    language
                    flipBookPage {
                      flipBookTile {
                        buttonText
                        description
                        linkTo
                        image {
                          mediaItemUrl
                        }
                        bgimage {
                          mediaItemUrl
                        }
                      }
                    }
                  }
                }
              }
            }
          }`
        })
        .then(result => {
          setPost({
            language: slug === HOME_PAGE_EN_SLUG ? 'english' : 'chinese',
            slug: HOME_PAGE_EN_SLUG,
            data: result.data.pages
          });
        });
    } catch (ex) {
      console.error('error::::', ex);
    }
  };

  const fetchOurLearningEnv = async (postId, slug) => {
    try {
      await client
      .query({
        query: gql`{
          pages(where: {id: ${postId}}) {
            nodes {
              our_learning_environment {
                description
                language
                metaDescription
                metaTitle
                metaUrl
                pageHeader
                title
              }
            }
          }
        }
      `
      })
        .then(result => {
          setPost({
            slug: slug,
            data: result?.data?.pages?.nodes[0]
          });
        });
    } catch (ex) {
      console.error('error::::', ex);
    }
  };

  const fetchNutritiousMeals = async (postId, slug) => {
    try {
      await client
      .query({
        query: gql`{
          pages(where: {id: ${postId}}) {
            nodes {
              our_nutritious_meals_cn {
                language
                pageHeader
                metaTitle
                metaUrl
                metaDescription
                detailsSection {
                  sectionDescription
                  sectionTitle
                }
              }
            }
          }
        }
      `
      })
        .then(result => {
          setPost({
            slug: slug,
            data: result?.data?.pages?.nodes[0]
          });
        });
    } catch (ex) {
      console.error('error::::', ex);
    }
  };

  const fetchGwlEnglish = async postId => {
    try {
      await client
        .query({
          query: gql`{
            pages(where: {id: ${postId}}) {
              nodes {
                slug
                gwlFields {
                  blueLabelCta
                  fieldGroupName
                  primaryHeading
                  subTitle
                  yourDetailsLabel
                  slotsLabel {
                    ... on Page_Gwlfields_SlotsLabel_SlotData {
                      isfull
                      english
                    }
                  }
                  childDob {
                    dobErrorCopy
                    dobLabel
                    dobPlaceholder
                  }
                  childName {
                    childnameErrorCopy
                    childnameLabel
                    childnamePlaceholder
                  }
                  email {
                    emailErrorCopy
                    emailFieldLabel
                    emailFieldsPlaceholder
                    emailInstruction
                    emailInvalidError
                  }
                  mobileNumber {
                    mobileFieldErrorCopy
                    mobileFieldLabel
                    mobileFieldPlaceholder
                    subError
                  }
                  notes {
                    ... on Page_Gwlfields_Notes_NotesContent {
                      noteCopy
                    }
                  }
                  liveClassLabel {
                    liveClassSubTitle
                    liveClassTitle
                  }
                  scheduleSubTitle
                  thanksCopy {
                    copyContent
                    subtitle
                  }
                  submitButtonLabel
                  noteTitle
                  englishDropLabel {
                    englishDropLabel
                    englishDropdownErrorCopy
                    englishDropdownPlaceholder
                  }
                  holidayTable {
                    table {
                      columnA
                      columnB
                    }
                    holidayTableStatus
                  }
                }
              }
            }
          }`
        })
        .then(result => {
          setPost({
            form: "english",
            title: "English Only Package",
            classgw: 'gwl-e-h',
            slug: GWL_EN_SLUG,
            metaData: {
              url: 'https://www.littleskoolhouse.com/gwl-scheduling-eng-only',
              title: 'Schedule Your Classes (English) | Golden Window of Learning by The Little Skool-House',
              description: 'Complete your one-time selection of your preferred English class schedule for the Golden Window of Learning by The Little Skool-House.'
            },
            data: { 'cms': result.data }
          });
        });
    } catch (ex) {
      console.error('error::::', ex);
    }
  };

  const fetchGwlMandarin = async postId => {
    try {
      await client
        .query({
          query: gql`{
            pages(where: {id: ${postId}}) {
              nodes {
                slug
                gwlFields {
                  blueLabelCta
                  fieldGroupName
                  primaryHeading
                  subTitle
                  yourDetailsLabel
                  slotsLabel {
                    ... on Page_Gwlfields_SlotsLabel_SlotData {
                      isfull
                      english
                    }
                  }
                  childDob {
                    dobErrorCopy
                    dobLabel
                    dobPlaceholder
                  }
                  childName {
                    childnameErrorCopy
                    childnameLabel
                    childnamePlaceholder
                  }
                  email {
                    emailErrorCopy
                    emailFieldLabel
                    emailFieldsPlaceholder
                    emailInstruction
                    emailInvalidError
                  }
                  mobileNumber {
                    mobileFieldErrorCopy
                    mobileFieldLabel
                    mobileFieldPlaceholder
                    subError
                  }
                  notes {
                    ... on Page_Gwlfields_Notes_NotesContent {
                      noteCopy
                    }
                  }
                  liveClassLabel {
                    liveClassSubTitle
                    liveClassTitle
                  }
                  scheduleSubTitle
                  thanksCopy {
                    copyContent
                    subtitle
                  }
                  submitButtonLabel
                  noteTitle
                  holidayTable {
                    table {
                      columnA
                      columnB
                    }
                    holidayTableStatus
                  }
                  englishDropLabel {
                    englishDropLabel
                    englishDropdownErrorCopy
                    englishDropdownPlaceholder
                  }
                }
              }
            }
          }`
        })
        .then(result => {
          setPost({
            form: "mandarin",
            title: "Mandarin Only Package",
            classgw: 'gwl-m-h',
            slug: GWL_MN_SLUG,
            metaData: {
              url: 'https://www.littleskoolhouse.com/gwl-scheduling-mand-only',
              title: 'Schedule Your Classes (Mandarin) | Golden Window of Learning by The Little Skool-House',
              description: 'Complete your one-time selection of your preferred Mandarin class schedule for the Golden Window of Learning by The Little Skool-House.'
            },
            data: { 'cms': result.data }
          });
        });
    } catch (ex) {
      console.error('error::::', ex);
    }
  };

  const fetchGwlBilingual = async postId => {
    try {
      await client
        .query({
          query: gql`{
            pages(where: {id: ${postId}}) {
              nodes {
                slug
                gwlFields {
                  blueLabelCta
                  fieldGroupName
                  primaryHeading
                  subTitle
                  yourDetailsLabel
                  slotsLabel {
                    ... on Page_Gwlfields_SlotsLabel_SlotData {
                      isfull
                      english
                    }
                  }
                  slotsLabelMn {
                    ... on Page_Gwlfields_SlotsLabelMn_SlotData {
                      isfull
                      mandarin
                    }
                  }
                  slotsLabelEn {
                    ... on Page_Gwlfields_SlotsLabelEn_SlotData {
                      english
                      isfull
                    }
                  }
                  childDob {
                    dobErrorCopy
                    dobLabel
                    dobPlaceholder
                  }
                  childName {
                    childnameErrorCopy
                    childnameLabel
                    childnamePlaceholder
                  }
                  email {
                    emailErrorCopy
                    emailFieldLabel
                    emailFieldsPlaceholder
                    emailInstruction
                    emailInvalidError
                  }
                  mobileNumber {
                    mobileFieldErrorCopy
                    mobileFieldLabel
                    mobileFieldPlaceholder
                    subError
                  }
                  notes {
                    ... on Page_Gwlfields_Notes_NotesContent {
                      noteCopy
                    }
                  }
                  liveClassLabel {
                    liveClassSubTitle
                    liveClassTitle
                  }
                  scheduleSubTitle
                  thanksCopy {
                    copyContent
                    subtitle
                  }
                  submitButtonLabel
                  noteTitle
                  englishDropLabel {
                    englishDropLabel
                    englishDropdownErrorCopy
                    englishDropdownPlaceholder
                  }
                  mandBiliangualDropLabelCopy {
                    mandBiliangualDropLabel
                    mandBiliangualDropdownErrorCopy
                    mandBiliangualDropdownPlaceholder
                  }
                  holidayTable {
                    table {
                      columnA
                      columnB
                    }
                    holidayTableStatus
                  }
                }
              }
            }
          }`
        })
        .then(result => {
          setPost({
            form: "bilingual",
            title: "Bilingual Package (English + Mandarin)",
            classgw: 'gwl-bi-h',
            slug: GWL_BILINGUAL_SLUG,
            metaData: {
              url: 'https://www.littleskoolhouse.com/gwl-scheduling-bilingual',
              title: 'Schedule Your Classes (Bilingual) | Golden Window of Learning by The Little Skool-House',
              description: 'Complete your one-time selection of your preferred English and Mandarin class schedules for the Golden Window of Learning by The Little Skool-House.'
            },
            data: { 'cms': result.data }
          });
        });
    } catch (ex) {
      console.error('err::::', ex);
    }
  };

  const fetchOurStories = async postId => {
    try {
      await client
        .query({
          query: gql`{
              happeningOurStories(where: {id: ${postId}}) {
                nodes {
                  slug
                  title
                  happening_our_stories {
                    storyDate
                    metaTag {
                      metaDescription
                      metaTitle
                      keywords
                    }
                    shortDescription
                    thumbnail {
                      sourceUrl(size: LARGE)
                    }
                    flexibleContent {
                      ... on HappeningOurStory_HappeningOurStories_FlexibleContent_Paragraph {
                        content
                        fieldGroupName
                      }
                      ... on HappeningOurStory_HappeningOurStories_FlexibleContent_Gallery {
                        images {
                          sourceUrl(size: LARGE)
                        }
                        fieldGroupName
                      }
                      ... on HappeningOurStory_HappeningOurStories_FlexibleContent_FullWidthImage {
                        image {
                          sourceUrl(size: LARGE)
                        }
                        fieldGroupName
                      }
                      ... on HappeningOurStory_HappeningOurStories_FlexibleContent_YtVideo {
                        ytVideoLink
                        fieldGroupName
                      }
                      ... on HappeningOurStory_HappeningOurStories_FlexibleContent_CtaButton {
                        fieldGroupName
                        ctaButton {
                          label
                          link
                        }
                      }
                    }
                    title {
                      line1
                      line2
                    }
                  }
                }
              }
            }
          `
        })
        .then(result => {
          const {
            data: {
              happeningOurStories: { nodes: pageData }
            }
          } = result;
          if (pageData) {
            const data = pageData[0].happening_our_stories;
            setPost({
              data,
              title: pageData[0].title,
              slug: pageData[0].slug
            });
          }
        });
    } catch (ex) {
      console.error('error::::', ex);
    }
  };
  const fetchVideos = async postId => {
    try {
      await client
        .query({
          query: gql`{
            happeningVideos(where: {id: ${postId}}) {
                  nodes {
                    title
                    slug
                    happening_videos {
                      flexibleContent {
                        ... on HappeningVideo_HappeningVideos_FlexibleContent_Paragraph {
                          content
                          fieldGroupName
                        }
                        ... on HappeningVideo_HappeningVideos_FlexibleContent_YtVideo {
                          fieldGroupName
                          ytVideoLink
                        }
                      }
                      title {
                        line1
                        line2
                      }
                    }
                  }
                }
              }
            `
        })
        .then(result => {
          const {
            data: {
              happeningVideos: { nodes: pageData }
            }
          } = result;
          if (pageData) {
            const data = pageData[0].happening_videos;
            setPost({
              data,
              title: pageData[0].title,
              slug: pageData[0].slug
            });
          }
        });
    } catch (ex) {
      console.error('error::::', ex);
    }
  };
  const fetchPromotions = async postId => {
    try {
      await client
        .query({
          query: gql`{
            happeningPromotions(where: {id: ${postId}}) {
              nodes {
                title
                slug
                happening_promotions {
                  metaTag {
                    metaDescription
                    metaTitle
                  }
                  flexibleContent {
                    ... on HappeningPromotion_HappeningPromotions_FlexibleContent_Paragraph {
                      content
                      fieldGroupName
                    }
                    ... on HappeningPromotion_HappeningPromotions_FlexibleContent_FullWidthImage {
                      fieldGroupName
                      image {
                        sourceUrl
                      }
                    }
                    ... on HappeningPromotion_HappeningPromotions_FlexibleContent_CtaButton {
                      fieldGroupName
                      ctaButton {
                        label
                        link
                      }
                    }
                    ... on HappeningPromotion_HappeningPromotions_FlexibleContent_PromotionCard {
                      fieldGroupName
                      promotionDetailCard {
                        price
                        title
                      }
                    }
                    ... on HappeningPromotion_HappeningPromotions_FlexibleContent_YtVideo {
                      fieldGroupName
                      ytVideoLink
                    }
                  }
                  title {
                    line1
                    line2
                  }
                }
              }
            }
          }
          `
        })
        .then(result => {
          const {
            data: {
              happeningPromotions: { nodes: pageData }
            }
          } = result;
          if (pageData) {
            const data = pageData[0].happening_promotions;
            setPost({
              data,
              title: pageData[0].title,
              slug: pageData[0].slug
            });
          }
        });
    } catch (ex) {
      console.error('error::::', ex);
    }
  };
  const fetchArticles = async postId => {
    try {
      await client
        .query({
          query: gql`{
            expertOpinionArticles(where: {id: ${postId}}) {
              nodes {
                title
                slug
                expertOpinions {
                  nodes {
                    name
                    parentId
                    slug
                    databaseId
                  }
                }
                expert_opinion {
                  article {
                    author
                    title
                    metaTag {
                      description
                      title
                    }
                    description
                    articleDetails {
                      bottomSection
                      topSection
                      middleSection {
                        title
                        description
                        ctaButton1 {
                          buttonLabel
                          buttonLink
                        }
                        ctaButton2 {
                          buttonLabel
                        }
                      }
                      image {
                        sourceUrl
                      }
                    }
                  }
                }
              }
            }
          }
          `
        })
        .then(result => {
          const {
            data: {
              expertOpinionArticles: { nodes: pageData }
            }
          } = result;
          if (pageData) {
            const data = pageData[0].expert_opinion;
            const categoryDetails = pageData[0].expertOpinions;
            setPost({
              data,
              title: pageData[0].title,
              slug: pageData[0].slug,
              categoryDetails
            });
          }
        });
    } catch (ex) {
      console.error('error::::', ex);
    }
  };
  const getRenderComponent = () => {
    const { title, categoryDetails, slug, data ,language} = post;
    if (slug === OLE_CN_SLUG){
      return <LearningEnvironment pageContext={{language : 'chinese', preview: true}} data={data} />
    }
    if (slug === OLE_EN_SLUG){
      return <LearningEnvironment pageContext={{language : 'english', preview: true}} data={data} />
    }
    if (slug === ONM_CN_SLUG){  
      return <OurMeals pageContext={{language : 'chinese', preview: true}} data={data} />
    }
    if (slug === ONM_EN_SLUG){
      return <OurMeals pageContext={{language : 'english', preview: true}} data={data} />
    }
    if (slug === HOME_PAGE_EN_SLUG || slug === HOME_PAGE_CN_SLUG){
      return <FlipBook pageContext={post} data={data} language={language}/>
    }
    if (slug ===  HOME_PAGE_EN_SLUG || slug === HOME_PAGE_CN_SLUG){
      return <FlipBookMobile pageContext={post} data={data} language={language}/>
    }
    if (slug === GWL_EN_SLUG) {
      return <GWL pageContext={post} data={data} />
    }
    if (slug === GWL_MN_SLUG) {
      return <GWL pageContext={post} data={data} />
    }
    if (slug === GWL_BILINGUAL_SLUG) {
      return <GWL pageContext={post} data={data} />
    }
    switch (postType) {
      case 'page':
        const openHouseData = {
          title,
          slug,
          data
        };
        return openHouseData.slug == 'limited-time-promotion' ? <OpenHouse pageContext={openHouseData} /> : <OpenHouseCn pageContext={openHouseData} />;
      case 'happening_videos':
        const videoData = {
          title,
          slug,
          data
        };
        return <VideoDetailsCms pageContext={videoData} />;
      case 'happening_promotions':
        const promotionData = {
          title,
          slug,
          data
        };
        return <PromotionDetailsCms pageContext={promotionData} />;
      case 'happening_our_stories':
        const storyData = {
          title,
          slug,
          data
        };
        return <StoryDetails pageContext={storyData} />;
      case 'expert_opinion':
        const articleData = {
          title,
          slug,
          data,
          categoryDetails
        };
        return <ArticleNew pageContext={articleData} />;
      case 'centres':
        const centreData = {
          language: data.centre.language.toLowerCase(),
          center: data,
          preview: true
        };
        return <CenterDetails pageContext={centreData} />;
    }
  };

  return (
    <div>
      {post ? (
        getRenderComponent()
      ) : (
        <div style={{ textAlign: 'center', marginTop: '30px' }}>Loading...</div>
      )}
    </div>
  );
};

export default Preview;
