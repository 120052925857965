import React from 'react';
import Layout from '../../layout/layout';
import '../../pages/our-curriculum//curriculum-details.scss';
import Kids from '../../images/objects/about-lsh-kids.jpg';
import LeftQuote from '../../images/objects/left-quote.png';
import RightQuote from '../../images/objects/right-quote.png';
import Graphic1 from '../../images/objects/curriculum-details/graphic-4.png';
import Graphic2 from '../../images/objects/curriculum-details/graphic-5.png';
import Graphic3 from '../../images/objects/curriculum-details/graphic-6.png';
import { EPSection } from './4-6-years-literacy-based-curriculum-cn';
import OpenBook from '../../images/objects/curriculum-details/storybook/open-book.jpg';
import Icon1 from '../../images/objects/curriculum-details/storybook/graphic-1.png';
import Icon2 from '../../images/objects/curriculum-details/storybook/graphic-2.png';
import Icon3 from '../../images/objects/curriculum-details/storybook/graphic-3.png';
import Icon4 from '../../images/objects/curriculum-details/storybook/graphic-4.png';
import Icon5 from '../../images/objects/curriculum-details/storybook/graphic-5.png';
import Icon6 from '../../images/objects/curriculum-details/storybook/graphic-6.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const sections = [
    {
        text: {
            heading:
                '建立自信与独立性是幼儿学习的起',
            content: (
                <p>
                    我们的课程教学法是由Ebbeck教授（南澳大学博士）设计，由幼儿培育署专家Dr Geraldine Zuzarte
                    （南澳大学博士）付诸实践。我们的课程让幼儿能有信心地学习自我意识、感官及动作技能，
                    并能使用英语和华语来进行思考及沟通。这就是我们在新加坡小小学庭幼儿园所实施的关系为本课程，
                    在幼儿早期将他们培养为自信和坚强的学习者。
                </p>
            )
        },
        graphic: Graphic1
    },
    {
        text: {
            heading: `我信任的教师`,
            content: (
                <>
                    <p>
                    从入园的第一天开始，就会有一名教师随时陪同您的孩子，以培养他的安全感和归属感，
                    这对转换到新环境的幼儿尤其重要。我们跟幼儿介绍生活常规的一致性，
                    让他们通过‘预测接下来将要发生的事情’来逐渐建立和增强信心。
                    </p>
                </>
            )
        },
        graphic: Graphic2
    },
    {
        text: {
            heading: `我热爱的学习`,
            content: (
                <p>
                    当幼儿建立信心后，我们将重点放在幼儿"黄金时期"对学习热情的发展，主要有三个领域：
                    心理-社会领域（自我意识及人际关系、社会与情绪智能）；身体动作领域（感官及动作技能）
                    ，思考与沟通领域（英语和华语的理解与沟通能力，早期数学和科学的学术基础，以及道德价值观)。
                </p>
            )
        },
        graphic: Graphic3
    }
];

const storyBookIcons = [
    { icon: Icon1, label: '数学' },
    { icon: Icon2, label: '创意' },
    { icon: Icon3, label: '科学' },
    { icon: Icon4, label: '社交发展' },
    {
        icon: Icon5,
        label: '英语和华语 思考与沟通能力'
    },
    { icon: Icon6, label: '身体动作' }
];

const StoryBookGraphic = () => (
    <div className="story-book-container">
        <div className="top-section">
            <img src={OpenBook} alt="Open Book" />
        </div>

        <div className="vertical-border" />
        <div className="top-border" />
        <div className="mobile-arrow">
            <div className="icon-holder">
                <FontAwesomeIcon icon={faChevronDown} />
            </div>
        </div>
        <div className="bottom-section">
            {storyBookIcons.map(item => (
                <div className="graphic-section" key={item.label}>
                    <div className="icon-holder">
                        <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                    <img src={item.icon} alt={item.label} />
                    <p>{item.label.substring(0,6)}<br/>{item.label.substring(6) && item.label.substring(6)}</p>
                </div>
            ))}
        </div>
    </div>
);

const RelationshipsBasedCn = () => {
    return (
        <Layout
            bannerClass="curriculum-details"
            heading="我们的课程"
            breadCrumbs={[
                { label: '我们的课程' },
                { label: '关系为本课程（出生至3岁）' }
            ]}
            metaData={{
                url: "https://www.littleskoolhouse.com/zh/womendekecheng/guanxiweibenkechengchushengzhi3sui",
                title: "关系为本课程 | 小小学庭",
                description: "以建立关系为主的课程有利于促进学龄前儿童的认知发展。今天就为您的孩子报读我们在新加坡的任何一所学习中心。"
            }}
        >
            <div className="curriculum-details-master-container">
                <div className="curriculum-details-container">
                    <h2>
                        关系为本课程 <br className='mobile-only'/>（出生至3岁）
                    </h2>
                </div>

                <div className="little-skool-house-container">
                    <div className="left-section">
                        <img src={Kids} draggable={false} alt="LSH Kids" />
                    </div>
                    <div className="right-section">
                        <p className="intro">
                            在生命初期积极互动的关系是建立健全大脑构造的最关键因素
                            <span className="author">
                                —哈佛大学儿童发展中心，2017年
                            </span>
                            <img src={LeftQuote} alt="Left quote" className="quote" />
                        </p>
                        <p className="text">
                            该中心的研究也指出幼儿与成人的积极互动能促进脑部的健全发展。对
                            <a
                                href="/zh"
                                style={{ color: '#4ec7e2', textDecoration: 'underline' }}
                            >
                                小小学庭
                            </a>
                            而言，即教师与孩子的关系。我们相信安全的依恋与积极的关系能促进孩子的幸福健康与参与感（Ebbeck &amp; Yim, 2009)。
                        </p>
                        <div className="right-quote">
                            <img src={RightQuote} alt="Right quote" />
                        </div>
                    </div>
                </div>

                <div className="learning-environment-container">
                    {sections.map((section, i) => (
                        <EPSection section={section} right={i % 2 !== 0} key={i} />
                    ))}
                </div>

                <div className="curriculum-details-container">
                    <h3>
                    通过绘本推动学习
                    {/* <br className="mobile-only-strict" />
                        Drive Learning */}
                    </h3>
                    <StoryBookGraphic />
                </div>
                <div className="subtext text-center content-text-center text-head">
                    <h2>
                    幼儿时期关系为本的重要性
                    </h2>
                    <p>
                        <br />
                        我们实施的关系为本课程让婴幼儿与教师之间培养信任及安全感，
                        让学步幼儿在游戏中获得最大的学习机会。培养师生之间的信任能帮助婴幼儿建立信心，
                        从而让幼儿教育无缝融入我们小小学庭幼儿园的环境中。此教学上，
                        从关系的形成到目标教学的的典范转换，在幼儿时期强化核心生活技能，
                        恰好符合公认的最佳实践。小小学庭幼儿园（包括婴幼儿），积极致力于建立完善新加坡幼儿认知发展的课程。
                    </p>
                </div>
            </div>
        </Layout>
    );
};

export default RelationshipsBasedCn;
