import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../../layout/layout';
import './our-successes.scss';
import KidWithMom from '../../images/objects/our-successes-kid-with-mom.jpg';
import LeftQuote from '../../images/objects/left-quote.png';
import RightQuote from '../../images/objects/right-quote.png';
import NinetyThreePer from '../../images/objects/93percent.png';
import AwardWinning from '../../images/objects/award-winning.png';
import ScheduleVisitForm from '../../components/common/ScheduleVisitForm/ScheduleVisitForm';
import { RECAPTCHA_KEY } from '../../config/appConfig';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PromoSection from '../../components/common/PromoSection/promoSection';
import Top from '../../images/objects/top.png';
import Choice from '../../images/objects/choice.png';
import EACWI from '../../images/logos/EACWI.png';
import NUS from '../../images/logos/NUS.png';
import RP from '../../images/logos/RP.png';
import TP from '../../images/logos/TP.png';
import NP from '../../images/logos/NP.png';
import KTPH from '../../images/logos/KTPH.png';
import KKWCH from '../../images/logos/KKWCH.png';
import SGH from '../../images/logos/SGH.png';
import OCBC from '../../images/logos/OCBC.png';

const OurSuccesses = () => {

  const data = useStaticQuery(graphql`
  query {
    cmsData: cms {
      pageBy(uri: "limited-time-promotion") {
        open_house {
          openhousestatus
          privacyPolicy
          promotionPrice
          promotionTitle
          subTitle
          title
          centres {
            centreName
            centreNameEn
            isParticipating
            location
          }
        }
      }
    }
  }
`);
const { centres } = data.cmsData.pageBy.open_house;
let north = centres.filter(item => item.location === 'North');
north.unshift({ location: 'North' });
let central = centres.filter(item => item.location === 'Central');
central.unshift({ location: 'Central' });
let east = centres.filter(item => item.location === 'East');
east.unshift({ location: 'East' });
let west = centres.filter(item => item.location === 'West');
west.unshift({ location: 'West' });

const centersList = north.concat(central, east, west);
  return (
    <Layout
      bannerClass="about-us"
      heading="Our Successes"
      breadCrumbs={[{ label: 'About Us' }, { label: 'Our Successes' }]}
      metaData={{
        url: 'https://www.littleskoolhouse.com/about-us/our-successes',
        title: 'Preschool Registration - Childcare Stories | Little Skool-House',
        description: 'See your child grow with Little Skool-House\'s 20 years of experience in early childhood education. Enrol your child now with our preschool registration form.'
      }}
    >
      <div className="our-successes-container">
        <div className="top-section-success">
          <div className="left-section">
            <img src={KidWithMom} alt="Kid with mom" draggable={false} />
          </div>
          <div className="right-section">
            <img src={LeftQuote} alt="Left quote" className="quote" />
            <div className="hightlight">
              Since 1994, thousands of children at
              <br />
              <span>
                The Little Skool-House have been driving discoveries and
                building knowledge better
              </span>
            </div>
            <p>
              From creating literary works in both English and Mandarin, to math
              skills and investigating science facts, to developing healthy
              eating habits, routine and independence. That’s because The Little
              Skool-House is more than just a ‘skool’. Our comprehensive
              curriculum, along with our teachers’ expertise, create a learning
              environment where discovery, creativity and diversity are
              encouraged and celebrated.
            </p>
            <div className="right-quote">
              <img src={RightQuote} alt="Right quote" />
            </div>
          </div>
        </div>

        <div className="section-two">
          <p className="info low-top-padding">
            <span className="percent">
              The Little Skool-House has seen success on so many levels
            </span>
          </p>
          <div className="stats">
            <div className="stat">
              <div className="content">
                27
                <br />
                <span>year heritage</span>
              </div>
            </div>
            <div className="stat stat-right">
              <div className="content">
                6,600
                <br />
                <span>children</span>
              </div>
            </div>
            <div className="stat">
              <div className="content">
                4.5 million
                <br />
                <span>achievements</span>
              </div>
            </div>
          </div>
          <p className="info low-top-padding">
            <span className="percent">
              Our 27-year heritage has seen over 6,600 children fulfil more than
              4.5 million achievements
            </span>
          </p>
          <h2>Highly Effective Curriculum</h2>
          <div className="info">
            <img src={NinetyThreePer} alt="93%" className="left big-img" />
            <span className="percent">93% High Proficiency</span>
            <p>
              According to a  <a className="lsh-anchor-common" href="https://research.littleskoolhouse.com/" target="_blank">Nanyang Technological University Study</a> that aims to
              measure the children's progress in vocabulary acquisition and
              communication in both English and Chinese, our children are 93%
              Highly Proficient in both English and Mandarin. The research was
              conducted by Cognitive Psychologist Dr. Setoh Pei Pei (PhD, The
              University of Illinois).
            </p>
          </div>
          <h2 className="align-fix">Award-Winning Teachers</h2>
          <div className="info">
            <img src={AwardWinning} alt="93%" className="right" />
            <span className="percent">For 9 Consecutive Years</span>
            <p>
              Our teachers have won{' '}
              <strong>
                Outstanding &amp; Promising Early Childhood Educator Awards
              </strong>{' '}
              from the Early Childhood Development Agency (ECDA). ECDA serves as
              Singapore’s regulatory and development agency for the early
              childhood sector, overseeing key aspects of children’s development
              below the age of 7. Since 2012, The Little Skool-House has won{' '}
              <strong>
                MOE Outstanding Preschool Mother Tongue Language Teacher Awards
              </strong>{' '}
              from the Ministry of Education (MOE).
            </p>
          </div>
          <div className="info">
            <img src={Top} alt="93%" className="left" />
            <span className="percent">
              Training from{' '}
              <span className="larger">Established ECDA Fellows</span>
            </span>
            <p>
              Our teachers go through rigorous training by our in-house
              pioneering and established ECDA fellows, a select group of
              exemplary early childhood professionals with high levels of
              leadership and professional expertise. These experts are also
              behind the success of our unique and outstanding curriculum.
            </p>
            <br />
            <div className="high-padding-left" />
          </div>
          <div className="info">
            <img src={Choice} alt="thumbs up" className="right mt-0" />
            <span className="percent">
              Chosen Preschool by{' '}
              <span className="larger">Singapore's Top Institutions</span>
            </span>
            <p>
              The only preschool chosen by Singapore’s most prestigious
              institutions to provide preschool education for their own
              personnel and children. These include the Ministry of Education
              (MOE), the Ministry of Social and Family Development (MSF), KK
              Women’s and Children’s Hospital (KKH), the National University of
              Singapore (NUS), among many others.
            </p>
          </div>
        </div>

        <div className="bottom-section">
          <div className="logos">
            <h3>Ministries of Singapore</h3>
            <img src={EACWI} alt="EACWI" />
          </div>
          <div className="logos">
            <h3>Major tertiary institutions</h3>
            <img src={NUS} alt="NUS" />
            <img src={RP} alt="RP" />
            <img src={TP} alt="TP" />
            <img src={NP} alt="NP" />
          </div>
          <div className="logos">
            <h3>Esteemed healthcare institutions</h3>
            <img src={KTPH} alt="KTPH" />
            <img src={KKWCH} alt="KKWCH" />
            <img src={SGH} alt="SGH" />
          </div>
          <div className="logos">
            <h3>Banks</h3>
            <img src={OCBC} alt="OCBC" />
          </div>
          {/* <div className="logos">
            <h3>Other Corporate Partnerships</h3>
            <img src={OCBC} alt="OCBC" />
          </div> */}
        </div>
        <div className="promo-wrap success-promo-wrap">
          <PromoSection />
        <div className="promo-right-section">
        <h2>SCHEDULE A ONE-TO-ONE CENTRE VISIT TODAY</h2>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
              <ScheduleVisitForm type="open-house" centerList={centersList} />
        </GoogleReCaptchaProvider>
        </div>
        </div>
      </div>
    </Layout>
  );
};

export default OurSuccesses;
