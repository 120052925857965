import React from 'react';
import Layout from '../../layout/layout';
import Graphic1 from '../../images/objects/93percent.png';
import Graphic2 from '../../images/objects/award-winning.png';
import Graphic3 from '../../images/objects/choice.png';
import { EPSection } from '../../pages/our-curriculum/4-6-years-literacy-based-curriculum';
import OurSuccessesCn from './our-successes-cn';
import '../../pages/our-difference/why-choose-the-little-skool-house.scss';

const sections = [
  {
    text: {
      content: (
        <>
          <div className="heading award-winning-card">
            <div className="text-align-left">
              <div>
                <p>
                  根据Dr Setoh Pei Pei(PhD,The University of Illinois)南洋理工大学2019年的
                  <br className="why-lsh-tablet-and-desktop-only" />
                  <a className="lsh-anchor-common" href="https://research.littleskoolhouse.com/" target="_blank">一项研究</a>结果显示，
                  <span className="h3-black-text">
                    我们的课程不仅培养了孩子全面发展，而且高达
                  </span>
                  <span className="percent percent-cn card-1-percent-cn">
                    93
                    <span>%</span>
                  </span>
                  <br className="card-1-breakpoint" />
                  <span className="h3-black-text">的孩子可以精通英语和华语。</span>
                </p>
              </div>
            </div>
          </div>
        </>
      )
    },
    graphic: Graphic1
  },
  {
    text: {
      content: (
        <>
          <div className="heading award-winning-card">
            <div className="text-align-right">
              <div>
                <p>
                  在过去的
                  <span className="percent">
                    9
                    <span>年里</span>
                  </span>, {' '}
                  我们的教师
                  <br className="card-2-breakpoint" />
                  <span className="h3-black-text">连续荣获教育部及幼儿培育署的奖项。</span>
                </p>
              </div>
            </div>
          </div>
        </>
      )
    },
    graphic: Graphic2
  },
  {
    text: {
      content: (
        <>
          <div className="heading">
            <h3 className="text-align-left">
              我们的幼儿中心受到新加坡最具盛名机构的认可，
              <br className="card-3-breakpoint" />
              <span className="lighter">
                选择我们成为他们的合作伙伴，为其员工的孩子提供优质的学前教育。
              </span>
            </h3>
          </div>
        </>
      )
    },
    graphic: Graphic3,
    left: true
  }
];

const WhyChooseLSHCn = () => {
  return (
    <Layout
      bannerClass="our-difference"
      heading={[
        '为什么选择',
        <br className="mobile-only-strict" key={1} />,
        '小小学庭'
      ]}
      breadCrumbs={[
        { label: '我们的与众不同' },
        { label: '为什么选择小小学庭' }
      ]}
      metaData={{
        url:
          'https://www.littleskoolhouse.com/zh/womendeyuzhongbutong/weishemexuanzexiaoxiaoxueting',
        title:
          "为您的孩子选对学校 | 小小学庭",
        description:
          "为您的孩子选择合适的学前教育中心需要考量很多因素。小小学庭是新加坡最好的学前教育中心，提供优质的学前教育。"
      }}
    >
      <OurSuccessesCn />
      <div className="learning-environment-container why-lsh-container why-lsh-container-cn padding-top-0">
        <h2 className="intro">
          在过去的 <span style={{fontSize: '1.6rem'}}>27</span> 年⾥，我们已经教导数千名的⼉童达到了我们的学习⽬标。
        </h2>
        {sections.map((section, i) => (
          <EPSection section={section} right={i % 2 !== 0} key={i} />
        ))}
      </div>

    </Layout>
  );
};

export default WhyChooseLSHCn;
