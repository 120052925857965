import React from 'react';
import { Helmet } from 'react-helmet-async';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Link } from 'gatsby';
import Layout from '../../layout/layout';
import MothersDayLandingForm from '../../components/common/ScheduleVisitForm/MothersDayLandingForm';
import '../../templates/schedule-a-visit/schedule-a-visit.scss';
import { MlCentres2, RECAPTCHA_KEY } from '../../config/appConfig';
import MdLandingImg from '../../images/mothers-day-celebration.png';

const MothersDayEvent = () => {
  return (
    <Layout
      bannerClass="schedule"
      heading="Events"
      pageLn="en"
      breadCrumbs={[{ label: 'Events' }]}
      noBanner={true}
      metaData={{
        url: 'https://www.littleskoolhouse.com/mothers-day-event-6-may-2/',
        title: 'Mother’s Day Celebration | The Little Skool-House',
        description:
          'Visit one of our Little Skool-House centres to see how 93% of our graduates are Highly Proficient in Both English and Mandarin. Schedule a visit today.'
      }}
    >
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="schedule-container">
        <div>
          <img src={MdLandingImg} className="md-landing-img" />
        </div>
        <div className="md-landing-desc">
          <div className="md-landing-desc-child">
            ❤️ Create unforgettable memories with your little ones through our specially curated parent-child activities, including storytelling, sensory play experiences, and more.
          </div>
          <div className="md-landing-desc-child">
            ✨ Take home a sustainable 'Pot of Love' craft as a keepsake of your time with us.
          </div>
          <div className="md-landing-desc-child">
            ⚽️ Explore our engaging Science, Music & Sports activities in partnership with our learning partners*!
          </div>
          <div style={{padding:"8px 0"}}>*Activities diff between centres</div>
        </div>
        <div className="md-landing-list-ul">
          <div className="md-landing-list-desc">
            RSVP for your preferred centre:
          </div>
          <ul>
            <li>At-Alexandra (Alexandra Hospital)</li>
            <div className="md-padding-10">Learn more about Sustainability, Deforestation and Recycling with your little ones. To honour mothers and in celebration of Mother's Day, our learning partner will be guiding you to create a Tree of Wisdom and Love!</div>
            <li>At-Kent-Vale (NUS Campus)</li>
            <div className="md-padding-10">Let your active preschoolers hone their shooting and scoring skills at our sports stations, or let them have fun bouncing away at our bouncing castle!</div>
            <li>At-Ngee-Ann-Poly</li>
            <div className="md-padding-10">Join us for a fun morning with a mini “obstacle course” and motor skill games!</div>
            <li>At-OCBC-Centre</li>
            <div className="md-padding-10">Let your active preschoolers hone their shooting and scoring skills at our sports stations!</div>
            <li>By-The-Lake (Yishun Community Hospital)</li>
            <div className="md-padding-10">Enjoy your morning with interactive English and Mandarin storytelling sessions by our storytellers where they bring stories to “life”!</div>
          </ul>
        </div>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
          <MothersDayLandingForm
            type="mothers-day-2"
            eventType="event_may_6_2"
            centerList={MlCentres2}
          />
        </GoogleReCaptchaProvider>
      </div>
    </Layout>
  );
};

export default MothersDayEvent;
