import React, { useState } from 'react';
import Layout from '../../layout/layout';
import './our-vision.scss';
import LeftQuote from '../../images/objects/left-quote.png';
import RightQuote from '../../images/objects/right-quote.png';
import WheelNav from '../../components/common/WheelNav/WheelNav';
import LeftImg from '../../images/objects/our-vision-left-image.jpg';

const wheelContent = [
  <>
    <h2>Our Teachers</h2>
    <p>
      Our teachers are our children’s guides, companions and fellow adventurers
      on their journey of discovery.
    </p>
  </>,
  <>
    <h2>Our Learning Environment</h2>
    <p>
      Our environment drives discovery, celebrates curiosity, encourages
      creativity and develops confidence.
    </p>
  </>,
  <>
    <h2>International Early Childhood Care and Education Community</h2>
    <p>Our standards are measured against international benchmarks.</p>
  </>,
  <>
    <h2>Our Parents</h2>
    <p>
      Our parents are our loyal partners and trusted companions in the learning
      journey of our children.
    </p>
  </>,
  <>
    <h2>Our Principals</h2>
    <p>
      Our principals provide the direction and leadership to shape and guide our
      children’s learning journey.
    </p>
  </>,
  <>
    <h2>Our Curriculum</h2>
    <p>
      Through stories and activities, our curriculum nurtures bilingualism in
      English and Mandarin and promotes contextualised learning.
    </p>
  </>
];

const OurVision = () => {
  const [currPos, setCurrPos] = useState(1);

  return (
    <Layout
      bannerClass="about-us"
      heading="Our Vision"
      breadCrumbs={[{ label: 'About Us' }, { label: 'Our Vision' }]}
      metaData={{
        url: 'https://www.littleskoolhouse.com/about-us/our-vision',
        title: `Play-based Learning - Our Vision | Little Skool-House`,
        description: 'Our childcare and early childhood education centre in Singapore is guided by our vision and core values. Learn more about our play-based learning today.'
      }}
    >
      <div className="our-vision-container">
        <div className="little-skool-house-container">
          <div className="left-section">
            <img src={LeftImg} draggable={false} alt="LSH Kids" />
          </div>
          <div className="right-section">
            <p className="intro">
            We nurture and support each child{' '}
              <span>to fulfil his or her potential</span>
              <img src={LeftQuote} alt="Left quote" className="quote" />
            </p>
            <p className="text">
              Focusing on six key areas,
              <br />
              The Little Skool-House strives to ensure we are constantly
              reviewing, assessing and upgrading where required. Our vision is
              to be consistent and innovative across all important domains.
            </p>
            <div className="right-quote">
              <img src={RightQuote} alt="Right quote" />
            </div>
          </div>
        </div>

        <div className="wheelnav-section">
          <WheelNav onChange={setCurrPos} />
          <div className="wheel-nav-details">
            {currPos && wheelContent[currPos - 1]}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OurVision;
