import React from 'react';
import Layout from '../../layout/layout';
import { graphql, useStaticQuery } from 'gatsby';
import './curriculum-details.scss';
import Kids from '../../images/objects/about-lsh-kids.jpg';
import LeftQuote from '../../images/objects/left-quote.png';
import RightQuote from '../../images/objects/right-quote.png';
import Graphic1 from '../../images/objects/curriculum-details/graphic-4.png';
import Graphic2 from '../../images/objects/curriculum-details/graphic-5.png';
import Graphic3 from '../../images/objects/curriculum-details/graphic-6.png';
import { EPSection } from './4-6-years-literacy-based-curriculum';
import OpenBook from '../../images/objects/curriculum-details/storybook/open-book.jpg';
import Icon1 from '../../images/objects/curriculum-details/storybook/graphic-1.png';
import Icon2 from '../../images/objects/curriculum-details/storybook/graphic-2.png';
import Icon3 from '../../images/objects/curriculum-details/storybook/graphic-3.png';
import Icon4 from '../../images/objects/curriculum-details/storybook/graphic-4.png';
import Icon5 from '../../images/objects/curriculum-details/storybook/graphic-5.png';
import Icon6 from '../../images/objects/curriculum-details/storybook/graphic-6.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import ScheduleVisitForm from '../../components/common/ScheduleVisitForm/ScheduleVisitForm';
import { RECAPTCHA_KEY } from '../../config/appConfig';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PromoSection from '../../components/common/PromoSection/promoSection';

const sections = [
  {
    text: {
      heading:
        'Building Self-Confidence and Independence are the Starting Point of a \n Child’s Learning',
      content: (
        <p>
        As the best infant care provider in Singapore,
         our curriculum pedagogy was developed by Professor Ebbeck (PhD, The University of South Australia) 
         and brought to life by top ECDA Fellow Dr Geraldine Zuzarte (PhD, The University of South Australia)
          to raise strong and confident learners. The Relationships-based Care approach is implemented across our 
          infant care and pre-nursery levels to enable the child to learn about the child’s sense of self confidently,
           their sensory & motor skills, thinking & communication skills in both English and Mandarin.
        </p>
      )
    },
    graphic: Graphic1
  },
  {
    text: {
      heading: `The Teacher I trust`,
      content: (
        <>
          <p>
          From day one, we assign a teacher to always be with your child
          to nurture a sense of security and belonging,
          especially as they transition to a new school environment. 
          Providing the best infant care and childcare services,
          we introduce the children to the consistency of a daily routine,
          allowing the child to ‘know what’s next’ thereby strengthening the building blocks of confidence.
          </p>
        </>
      )
    },
    graphic: Graphic2
  },
  {
    text: {
      heading: `The Learning I Love`,
      content: (
        <p>
          Upon building confidence, the next focus of our infant care
          and childcare is to drive a strong sense of learning during
          this ‘golden window’ for a child’s years in 3 areas.
          Their Psychosocial Self (a child’s sense of self and his
          interrelationships, social and emotional intelligence),
          their Physical Self ( the child’s sensory and motor skills); and
          their Thinking and Communication Self, (ability to comprehend and
          communicate concepts in English and Mandarin, as well as an academic
          foundation in early maths, science and moral values).
        </p>
      )
    },
    graphic: Graphic3
  }
];

const storyBookIcons = [
  { icon: Icon1, label: 'Math' },
  { icon: Icon2, label: 'Creativity' },
  { icon: Icon3, label: 'Science' },
  { icon: Icon4, label: 'Social Development' },
  {
    icon: Icon5,
    label: 'Thinking & Communication in English & Mandarin'
  },
  { icon: Icon6, label: 'Physical Self' }
];

const StoryBookGraphic = () => (
  <div className="story-book-container">
    <div className="top-section">
      <img src={OpenBook} alt="Open Book" />
    </div>

    <div className="vertical-border" />
    <div className="top-border" />
    <div className="mobile-arrow">
      <div className="icon-holder">
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    </div>
    <div className="bottom-section">
      {storyBookIcons.map(item => (
        <div className="graphic-section" key={item.label}>
          <div className="icon-holder">
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
          <img src={item.icon} alt={item.label} />
          <p>{item.label}</p>
        </div>
      ))}
    </div>
  </div>
);

const RelationshipsBased = () => {
  const data = useStaticQuery(graphql`
  query {
    cmsData: cms {
      pageBy(uri: "limited-time-promotion") {
        open_house {
          openhousestatus
          privacyPolicy
          promotionPrice
          promotionTitle
          subTitle
          title
          centres {
            centreName
            centreNameEn
            isParticipating
            location
          }
        }
      }
      pages {
        nodes {
          slug
          title
          subheaders_name {
            centresHeader
            curriculumHeaders
            infantHeaders
          }
        }
      }
    }
  }
`);
const { centres } = data.cmsData.pageBy.open_house;
let north = centres.filter(item => item.location === 'North');
north.unshift({ location: 'North' });
let central = centres.filter(item => item.location === 'Central');
central.unshift({ location: 'Central' });
let east = centres.filter(item => item.location === 'East');
east.unshift({ location: 'East' });
let west = centres.filter(item => item.location === 'West');
west.unshift({ location: 'West' });

const subheading = data && data.cmsData && data.cmsData.pages.nodes.length > 0 && data.cmsData.pages.nodes[0].subheaders_name;

const centersList = north.concat(central, east, west);
  return (
    <Layout
      bannerClass="curriculum-details"
      heading="Our Curriculum"
      breadCrumbs={[
        { label: 'Our Curriculum' },
        { label: 'Infant-3 years Relationships-Based Curriculum' }
      ]}
      metaData={{
        url: "https://www.littleskoolhouse.com/our-curriculum/infant-3-years-relationships-based-curriculum",
        title: 'The Best Infant Care in Singapore | Little Skool-House',
        description:
          'Relationships-based curriculum has shown to help in early childhood cognitive development. Provide your child the best infant care in Singapore today.'
      }}
    >
      <div className="curriculum-details-master-container">
        <div className="curriculum-details-container">
          <h2>
            Infant-3 years  
            <br />
            Relationships-Based Curriculum
          </h2>
        </div>

        <div className='lsh-banner-sub-text-wrap'>
         <p className="lsh-banner-sub-text lsh-centers-pad"> 
         <div dangerouslySetInnerHTML={{ __html: subheading.infantHeaders }}/></p>
      </div>

        <div className="little-skool-house-container">
          <div className="left-section">
            <img src={Kids} draggable={false} alt="LSH Kids" />
          </div>
          <div className="right-section">
            <p className="intro">
              Responsive relationships early in life are the most important
              factor in building sturdy brain architecture.
              <span className="author">
                Harvard University Center on the Developing Child, 2017
              </span>
              <img src={LeftQuote} alt="Left quote" className="quote" />
            </p>
            <p className="text">
              It also states how responsive relationships with adults, and in
              our case teachers, promote healthy brain development in a child’s
              early years. At{' '}
              <a
                href="/"
                style={{ color: '#4ec7e2', textDecoration: 'underline' }}
              >
                The Little Skool-House{' '}
              </a>
              , we believe that secure attachment and positive relationships
              promote children’s well-being and involvement (Ebbeck &amp; Yim,
              2009).
            </p>
            <div className="right-quote">
              <img src={RightQuote} alt="Right quote" />
            </div>
          </div>
        </div>

        <div className="learning-environment-container">
          {sections.map((section, i) => (
            <EPSection section={section} right={i % 2 !== 0} key={i} />
          ))}
        </div>

        <div className="curriculum-details-container">
          <h3>
            Story Books <br className="mobile-only-strict" />
            Drive Learning
          </h3>
          <StoryBookGraphic />
        </div>
        <div className="subtext text-center content-text-center text-head">
          <h2>
            {' '}
            The Importance of Relationships-Based Care in Early Childhood{' '}
          </h2>
          <p>
            <br />
            As the best infant care centre, the Little Skool-House
            infant care and early childhood learning centres
            actively work towards building curriculums that optimise
            cognitive development amongst children. We implement
            the Relationships-Based curriculum in our infant care
            and childcare programme to foster a sense of trust and
            security between the child and their educators, maximising
            learning opportunities in our toddler playgroups.
            Nurturing trust builds their confidence, allowing for the
            seamless integration of early childhood education courses
            in our infant and toddler care centres. This paradigm shift
            in education from bond formation to objective pedagogies
            coincides with the recommended best practices to strengthen
            core life skills essential in a child's formative years.
          </p>
        </div>
        {/* <div className="promo-wrap">
          <PromoSection />
        <div className="promo-right-section">
        <h2>SCHEDULE A ONE-TO-ONE CENTRE VISIT TODAY</h2>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
              <ScheduleVisitForm type="open-house" centerList={centersList} />
        </GoogleReCaptchaProvider>
        </div>
        </div> */}
      </div>
    </Layout>
  );
};

export default RelationshipsBased;
