import React, { useEffect } from 'react';
import Logo from '../../images/logo.png';
import { Link, graphql, useStaticQuery } from 'gatsby';
import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faFacebook,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet-async';
import BHFLogo from '../../images/logos/footer-logos/BHF_Logo_Greyscale_RGB.png';
import LSHLogo from '../../images/logos/footer-logos/LSH_Logo_Greyscale_RGB.png';
import MFSLogo from '../../images/logos/footer-logos/MFS_Logo_Greyscale_RGB.png';
import NFCOutdoorLogo from '../../images/logos/footer-logos/NFC_outdoorschool_Logo_Greyscale_RGB.png';
import NFCSeedLogo from '../../images/logos/footer-logos/NFC_seed_Logo_Greyscale_RGB.png';
import FirstCampusLogo from '../../images/logos/footer-logos/NFC_Logo_Greyscale_RGB.png';


const Footer = ({openStatus}) => {

  function loadScript( url, callback ) {
    var script = document.createElement( "script" )
    script.type = "text/javascript";
    if(script.readyState) {  // only required for IE <9
      script.onreadystatechange = function() {
        if ( script.readyState === "loaded" || script.readyState === "complete" ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {  //Others
      script.onload = function() {
        callback();
      };
    }
  
    script.src = url;
    document.getElementsByTagName( "head" )[0].appendChild( script );
  }
  
  
  // call the function...
  loadScript('https://js.hsforms.net/forms/v2.js?pre=1', function() {
    addHubspotFormToPage();
  });


  const addHubspotFormToPage = () => {
    const hbForm = document.getElementById('hs-form-iframe-0');
    if (!hbForm) {
      window && window.hbspt &&  window.hbspt.forms &&  window.hbspt.forms.create({
        region: "na1",
        portalId: process.env.GATSBY_API_BASE_URL == "https://api-staging.littleskoolhouse.com/" ? "22078937" : "21698896",
        formId: process.env.GATSBY_API_BASE_URL == "https://api-staging.littleskoolhouse.com/" ? "84a907e0-5695-457a-b487-e97e89c953a1" : "d4fcc1d0-0080-41cb-ae26-67f5abbffe04",
        target: '#ntuc-logo-container-en',
        submitButtonClass: 'hs-button',
        cssRequired: 'hs-form-required no-list hs-error-msgs hs-input input hs-richtext actions'
      });
    }
  
  }

  const scheduleVisit = openStatus === 'on'? 'limited-time-promotion'
  : 'schedule-a-visit';
  console.log("envv",process.env.GATSBY_API_BASE_URL);
  return (
    <div>
    <div className="footer-container">
      <div className="footer-wrapper" style={{ textAlign: 'left' }}>
        <div className="footer-logo">
          {/* <img src={LSH_LOGO} alt="LSH Logo" /> */}
          <h3>Contact Us</h3>
          <p>
            Tel: <a href="tel:+6565097880">+65 6509 7880</a>
          </p>
          <p style={{marginTop : "8px"}}>
            Email:{' '}
            <a href="mailto:info@littleskoolhouse.com" target="_blank">
              info@littleskoolhouse.com
            </a>
          </p>
        </div>

        <div className="footer-links">
          <div className="social-links">
            <a
              href="https://www.facebook.com/littleskoolhouse/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              href="https://www.instagram.com/littleskoolhousesg/?igshid=xmhx42c453e6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://www.youtube.com/channel/UChJdWcrtM5zRGKDUFLdb5hQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
          <div className="links">
            <Link to="/about-us/the-little-skool-house">About Us</Link>
            <Link to="/our-centres/centres/all-centres">Our Centres</Link>
            <Link to="/our-curriculum/curriculum-overview">Our Curriculum</Link>
            <Link to={`enquiry/${scheduleVisit}`}>Schedule A Visit</Link>
            <Link to="/enquiry/careers">Careers</Link>
            <Link to="/enquiry/contact-us">Contact Us</Link>
          </div>
          <div className="links-privacy-mobile">
            <Link to="/others/privacy-policy">Privacy Policy</Link>
            <Link to="/sitemap">Sitemap</Link>
            <a
              href="https://www.ntucenterprise.sg/contactus/vulnerability-disclosure-programme/"
              target="_blank"
            >
              Report Vulnerability{' '}
            </a>
          </div>
        </div>
        <div className='newsletter-frame-wrap'>
          <div className="ntuc-logo-container-en" id='ntuc-logo-container-en'></div>
        </div>
      </div>

      <div id="nfc-footer">
        <div className="flex-parent-wrapper">
            <div className="main-logo-wrapper">
                <a href="https://www.ntucfirstcampus.com/" target="_blank"><img className="nfc-logo-cus-width" alt="NTUC First Campus" title="NTUC First Campus" width="140" height="40" src={FirstCampusLogo}></img></a> 
            </div>
            <div className="flex-wrapper">
                <div className="flex-item">
                    <a href="https://www.myfirstskool.com/" target="_blank"><img className="nfc-mfs-logo-cus-width" alt="My First Skool" width="100" height="70" title="My First Skool" src={MFSLogo}/></a>
                </div>
                <div className="flex-item">
                    <a href="https://www.littleskoolhouse.com/" target="_blank"><img className="nfc-lsh-logo-cus-width" alt="The Little Skool-House" width="120" height="100" title="The Little Skool-House" src={LSHLogo}/></a>
                </div>
                <div className="flex-item">
                    <a href="https://outdoorschool.sg/" target="_blank"><img className="nfc-outdoor-logo-cus-width" alt="Outdoor School Singapore" width="150" height="40" title="Outdoor School Singapore" src={NFCOutdoorLogo}/></a>
                </div>
                <div className="flex-item">
                    <a><img className="nfc-seed-logo-cus-width" alt="SEED Institute" title="SEED Institute" width="150" height="40" src={NFCSeedLogo}/></a> 
                </div>
                <div className="flex-item">
                    <a href="https://www.ntucfirstcampus.com/do-good/bright-horizons-fund" target="_blank"><img className="nfc-bhf-logo-cus-width" alt="Bright Horizons Fund" width="110" height="40" title="Bright Horizons Fund" src={BHFLogo}/></a>
                </div>
            </div>
        </div>
    </div>

          <div className="links-privacy">
            <Link to="/others/privacy-policy">Privacy Policy</Link>
            <Link to="/sitemap">Sitemap</Link>
            <a
              href="https://www.ntucenterprise.sg/contactus/vulnerability-disclosure-programme/"
              target="_blank"
            >
              Report Vulnerability{' '}
            </a>
          </div>
    </div>
        <div className="copyright-notice">
            Copyright © 2022 The Little Skool-House International | Reg. No. UEN
            199406237Z
          </div>
      </div>
  );
};

export default Footer;
