import React from 'react';
import Layout from '../../layout/layout';
import Graphic1 from '../../images/objects/high-proficiency/graphic-1.png';
import Graphic2 from '../../images/objects/high-proficiency/graphic-2.png';
import Graphic3 from '../../images/objects/high-proficiency/graphic-3.png';
import Kids from '../../images/objects/high-proficiency.jpg';
import LeftQuote from '../../images/objects/left-quote.png';
import RightQuote from '../../images/objects/right-quote.png';
import { EPSection } from '../../pages/our-curriculum/4-6-years-literacy-based-curriculum';
import '../../pages/our-difference/high-proficiency-in-english-and-mandarin.scss';
import YoutubeVideo from '../../components/common/YoutubeVideo/YoutubeVideo';

const sections = [
    {
        text: {
            content: (
                <>
                    <h3>支柱一</h3>
                    <h4>系统性语言形成</h4>
                    <div className="points">
                        <h4>• 理解与记忆</h4>
                        <p>
                            有条理地积累词汇
                            <br />
                            注重理解概念而不是死记硬背
                        </p>
                        <h4>
                            • 语法、句子结构及发音
                        </h4>
                        <p>
                            确保打好良好的基础，为小学入学做好准备。
                        </p>
                        <h4>• 阅读与写作渐进复杂性</h4>
                        <p>目标：为小一做准备</p>
                    </div>
                </>
            )
        },
        graphic: Graphic1
    },
    {
        text: {
            content: (
                <>
                    <h3>支柱二</h3>
                    <h4>训练有素、多次获奖的教师</h4>
                    <div className="points">
                        <h4>• 由华语教学专家开发的课程</h4>
                        <p>
                            林美莲博士，李光耀双语基金的委员，
                            <br />
                            曾出版30本与华语教学相关的著作
                        </p>
                        <h4>• 严格完善的华文教师培训计划</h4>
                        <p>
                            我们的教师培训计划包括，聘请台湾陈仁富教授亲自为教师上课，带领、组织教师赴北京、台湾、香港等地进行交流学习，
                            {/* <br /> */}
                            配备导师在职辅导 推动小小学庭所有教师的技能进步，从初级到发展到精通教学水平的技能进步。
                        </p>
                    </div>
                </>
            )
        },
        graphic: Graphic2
    },
    {
        text: {
            content: (
                <>
                    <h3>支柱三</h3>
                    <h4>进步用法：从日常话题到复杂思想。</h4>

                    <div className="points">
                        <h4>• 方案与创新项目</h4>
                        <p>
                            能够用华语思考、分析和推理（例如：什么水果能导电？）
                        </p>
                        <h4>• 交谈并写出复杂的想法</h4>
                        <p>
                            能够表达自己的想法并进行更复杂的对话；{' '}
                            {/* <br /> */}
                            根据孩子的兴趣写日记
                        </p>
                        <h4>• 全周期华语戏剧教学法</h4>
                        <p>
                            这是⼀种身临其境的体验，整个过程孩⼦们完全以华语与同伴互动，表达自己的想法、计划制作／表演，提出问题并解决问题，以及进⾏⾃我评估。
                        </p>
                    </div>
                </>
            )
        },
        graphic: Graphic3
    }
];

const chinesePedgogyWheel = [
    {
        label: '主张想法',
        text: [`"我们选这本书`, `吧！里面的十个小`, `精灵很可爱的!"`],
        color: 'orange',
        position: {
            top: '-7%',
            left: '50%'
        }
    },
    {
        label: '分工协商',
        text: [`"我想做场记`, `因为我可`, `帮助别人"`],
        color: 'blue',
        position: {
            top: '27%',
            right: '0%'
        }
    },
    {
        label: '剧本写作',
        text: [`"我们有太多人了,`, `可以加多几个`, `角色进来吗"`],
        color: 'orange',
        position: {
            top: '70%',
            right: '0%'
        }
    },
    {
        label: '解决问题',
        text: [
            `"如果要让舞台站`,
            `起来，我们应该`,
            `一个人扶着，一`,
            `个人来粘胶带"`
        ],
        color: 'blue',
        position: {
            bottom: '-7%',
            left: '50%'
        }
    },
    {
        label: '意见反馈',
        text: [`"你刚刚的动作`, `是这样的,`, `好像在行礼"`],
        color: 'orange',
        position: {
            top: '70%',
            left: '0%'
        }
    },
    {
        label: '自我反省',
        text: [`"我记住`, `台词啦"`],
        color: 'blue',
        position: {
            top: '27%',
            left: '0%'
        }
    }
];

const ChineseTheatrePedagogyWheel = () => {
    return (
        <div className="chinese-pedgogy-circle">
            <div className="centre-text">
                整体循环华语戏剧教学法
                {/* <br className="mobile-only-strict" />
                <br className="tablet-and-desktop-only" /> chinese
                <br className="tablet-and-desktop-only" /> theatre
                <br className="tablet-and-desktop-only" /> pedagogy */}
            </div>
            {chinesePedgogyWheel.map((item, i) => (
                <div
                    className={`circle-container ${i === 0 || i === 3 ? 'transform-x' : 'transform-y'
                        }`}
                    key={i}
                    style={{ ...item.position }}
                >
                    <p className={`label label-top ${item.color}`}>{item.label}</p>
                    <div className={`circle ${item.color}`}>
                        <div className="text">
                            {item.text.map((el, i) => (
                                <p key={i}>{el}</p>
                            ))}
                        </div>
                    </div>
                    <p className={`label label-bottom ${item.color}`}>{item.label}</p>
                </div>
            ))}
            <div className="inner-circle" />
        </div>
    );
};

const HighProficiency = () => {
    return (
        <Layout
            heading="精通英语和华语"
            bannerClass="about-us"
            breadCrumbs={[
                { label: '我们的与众不同' },
                { label: '精通英语和华语' }
            ]}
            metaData={{
                url: "https://www.littleskoolhouse.com/zh/womendeyuzhongbutong/jīngtongyingyuhehuayu",
                title: "在新加坡精通双语 | 小小学庭",
                description: "采用双语教学以提高中文程度的小小学庭是最顶尖的学前教育中心和学习中心之一。"
            }}
        >
            <div className="high-proficiency-container">
                <div className="little-skool-house-container">
                    <div className="left-section">
                        <img src={Kids} draggable={false} alt="LSH Kids" />
                    </div>
                    <div className="right-section">
                        <p className="intro">
                            在小小学庭，我们感到<span>自豪</span>
                            ，我们的课程不仅<span>培养孩子的全面发展</span>，更培养了高达
                            <span>
                                93%的孩子都精通英语和华语。
                            </span>
                            <img src={LeftQuote} alt="Left quote" className="quote" />
                        </p>
                        <p className="text">
                            这是南洋理工大学Dr.Setoh(PhD,The University of Illinois) 在2019年的<a className="lsh-anchor-common" href="https://research.littleskoolhouse.com/" target="_blank">一项研究</a>
                            中获得证实，也是我们深化的华语习得教学法的成果。
                        </p>
                        <div className="right-quote">
                            <img src={RightQuote} alt="Right quote" />
                        </div>
                    </div>
                </div>
                <div className="banner-video">
                    <YoutubeVideo
                        title="Proficiency In English And Mandarin"
                        youtubeVideoId="OhOl30SHKRE"
                        fullscreen={false}
                        autoPlay={1}
                    />
                </div>
                <div className="learning-environment-container">
                    <h2 className="blue">深化的华语习得教学法</h2>
                    {sections.map((section, i) => (
                        <EPSection section={section} right={i % 2 !== 0} key={i} />
                    ))}
                </div>
                <ChineseTheatrePedagogyWheel />
                <div className="foot-notes">
                    小小学庭{' '}
                    <span>
                        93<span>%</span>
                    </span>{' '}
                    的孩子精通英语和华语
                </div>
                <div className="subtext text-center content-text-center text-head">
                    <h2>
                        {' '}
                        在小小学庭幼儿园获得高双语能力{' '}
                    </h2>
                    <p>
                        <br />
                        为学前儿童奠定一个扎实的双语基础，对他们未来在新加坡及国际上的语言学习至关重要。{' '}
                        <a href="/zh">小小学庭</a> 是新加坡领先的学前教育和儿童保育学习中心之一。采用双语教学法，实施强化华语学习。
                        这是新加坡其他沉浸式幼儿园所无法比拟的。
                        通过全华语教学活动，幼儿在学前阶段打好扎实的基础，为进入新加坡小学做好准备。{' '}
                        <br />
                        <br />
                        小小学庭双语幼儿园及托儿中心实施的全方为课程，包括英语增益课程及华语沉浸式课程等等。
                        这些课程都符合新加坡教育部制定的学习目标。通过频繁的多语言接触，小小学庭93%的孩子精通英语和华语，
                        并为幼儿园以后的学习和在新加坡的整个生活中，都保持着高超的学习能力。
                    </p>
                </div>
            </div>
        </Layout>
    );
};

export default HighProficiency;
