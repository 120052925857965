import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './MobileMenu.scss';
import { LSH_LOGO } from '../../common/utils/utils';

const getSubMenu = (parentLink, subMenuItems) => {
  return (
    subMenuItems &&
    subMenuItems.length && (
      <ul className="sub-menu">
        {subMenuItems.map(({ label, link }) => (
          <li key={link}>
            <Link to={`/${parentLink}/${link}`}>{label}</Link>
          </li>
        ))}
      </ul>
    )
  );
};

const MobileMenu = ({ menuItems,promoActive, activeLang }) => {

  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [siteLang, setSiteLang] = useState(activeLang);
  const custom = {
    english: {
      "home": 'Home',
      "homeLink": '/',
      "scheduleBtn": 'Schedule a visit'
    },
    chinese: {
      "home": '首页',
      "homeLink": '/zh',
      "scheduleBtn": '预约参观'
    }
  }

  const handleMenu = status => {
    if (typeof document !== `undefined`) {
      document.body.style.overflow = status ? 'hidden' : 'unset';
      setMenuOpen(status);
    }
  };

  useEffect(() => {
    if (typeof document !== `undefined`) {
      document.body.style.overflow = 'unset';
    }
  }, []);

  const handleClick = (link, redirect) => {
    setSelectedItem(link);
    if(redirect) {
      navigate(`/${link}`);
    }
  }
  let scheduleVisit = '';
  if(activeLang == 'english'){
    scheduleVisit = promoActive === 'on' ? 'enquiry/limited-time-promotion' : 'enquiry/schedule-a-visit';
  } else {
    scheduleVisit = promoActive === 'on' ? 'zh/xunwen/xianshicuxiao' : 'zh/xunwen/yuyuecanguan';
  }
  const langSwitch = val => {
    setSiteLang(val);
    localStorage.setItem("lan", val);
    if(val == 'english') {
      document.location.href = '/';
    } else {
      document.location.href = '/zh';
    }
  }
  return (
    <div className="mobile-menu">
      <div className="navbar-wrapper">
        <div className="logo-container">
          <Link to={custom[activeLang].homeLink}>
          <img src={LSH_LOGO} alt="Little Skool House" title="Little Skool House" />
          </Link>
        </div>
        <div className="right-section">
          <Link
            to={scheduleVisit}
          >
            <button className="yellow-funky-button blue-text">
              {custom[activeLang].scheduleBtn}
            </button>
          </Link>
          <FontAwesomeIcon
            icon={menuOpen ? faTimes : faBars}
            onClick={() => handleMenu(current => !current)}
          />
        </div>
      </div>
      <div className={`main-menu ${!menuOpen && 'menu-closed'}`}>
        <div className="close-btn-holder">
        <img src={LSH_LOGO}  alt="Little Skool House" title="Little Skool House" />
          <FontAwesomeIcon icon={faTimes} onClick={() => handleMenu(false)} />
        </div>
        <div className="mobile-only">
        <div className={`lang-switch header-menu-container ${siteLang === 'english' ? 'lang-align' : ''}`}>
        <div onClick={() => langSwitch("english")}>
          <a className={`link-en ${siteLang === 'english' ? 'l-lang-active' : ''}`}>EN</a>
        </div>
        |
        <div className="lang-cn" onClick={() => langSwitch("chinese")}>
          <a className={`link-en ${siteLang === 'chinese' ? 'l-lang-active' : ''}`}>中文</a>
        </div>
      </div>
      </div>
        <ul>
          <li>
            <Link to={custom[activeLang].homeLink}>{custom[activeLang].home}</Link>
          </li>
          {menuItems &&
            menuItems.map(({ noPage, label, link, subMenu, redirect }) => (
              <React.Fragment key={link}>
                {link !== '/' && (
                  <>
                    <li
                      onClick={() => handleClick(link, redirect)}
                      role="button"
                      className={`${noPage && 'high-padding'}`}
                    >
                      {noPage ? label : <Link to={`/${link}`}>{label}</Link>}
                    </li>
                    {subMenu &&
                      link === selectedItem &&
                      getSubMenu(link, subMenu)}
                  </>
                )}
              </React.Fragment>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default MobileMenu;
