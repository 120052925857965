import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../layout/layout';
import { Helmet } from 'react-helmet-async';
import './thankyou.scss';

import totGif from '../../images/tot/P1-Readiness-Thank-You-Page-GIF.gif'

const ThankYouNew = () => {
  return (
    <Layout
      bannerClass="schedule"
      heading="Thank You"
      breadCrumbs={[{label: 'Mothers Day Celebration'},{ label: 'Thank You' }]}
      noFooter={true}
      noBanner={true}
      metaData={{
        url: 'https://www.littleskoolhouse.com/mothers-day-event-6-may/thank-you',
        title: 'Thank You | The Little Skool-House',
        description:
          'Visit one of our Little Skool-House centres to see how 93% of our graduates are Highly Proficient in Both English and Mandarin. Schedule a visit today.'
      }}
    >
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="thank-you-container">
        <h2>
        Thank you for your interest
        </h2>
        <p>
          {'Our representative will be in touch with you within the next 2 working days.'}
        </p>

        {(
          <p>
            Discover our specialised curriculum and why we are the chosen
            preschool by{' '}
            <a
              className="top-inst"
              href="/our-difference/chosen-preschool-by-top-institutions"
              target="_blank"
            >
              top institutions.
            </a>
          </p>
        )}

        <div className="back-to-list">
          <Link to={
            '/our-curriculum/curriculum-overview'}
          >
            <button className="yellow-funky-button back-btn">
              {'View Curriculum'}
            </button>
          </Link>
        </div>
        {(
          <div className="ty-page-banner">
            <Link to='/happenings/videos/primary-school-readiness-graduation' className="banner-center">
              <img
                src={totGif}
                alt="tot-leader-banner"
                
              />
            </Link>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ThankYouNew
