import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import './FlipBookMobile.scss';
import { pages } from '../FlipBook/FlipBook';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { getLanguage } from '../../common/utils/utils';

const FlipBookMobile = ({ language, data }) => {
  const [currentPos, setCurrentPos] = useState(0);

  const [flipBookData, setFlipBookData] = useState(data.nodes);
  const [flipBookImg, setFlipBookImg] = useState([]);

  useEffect(() => {
    const lang = language ? language : getLanguage();
    let arr = [];
    data && data.nodes && data.nodes.forEach(ele => {
      if (ele.home_page.flipBook.language && ele.home_page.flipBook.language.toLowerCase() === lang) {
        arr.push(ele);
      }
    })

    const pages1 = []
    const imgArr = []
    arr && arr.length > 0 && arr[0].home_page.flipBook.flipBookPage.forEach((item, i) => {
      imgArr.push(
        {
          bgImage: item.flipBookTile?.mobileImage?.mediaItemUrl
        }
      )
      pages1.push(
        {
          content: (
            <>
              <ReactMarkdown
                source={item.flipBookTile?.description}
                key={`FlipBook_Paragraph${i}`}
                plugins={[gfm]}
              />
              <a href={item.flipBookTile?.linkTo} target="blank">
                <button className="yellow-funky-button blue-text">{item.flipBookTile?.buttonText}</button>
              </a>
            </>
          )
        }
      )
    })
    setFlipBookData(pages1);
    setFlipBookImg(imgArr);
  }, []);

  const getStyles = (position) => {
    const styles = {};
    styles.background = `url(${flipBookImg[position]?.bgImage}) no-repeat`;
    return styles;
  };

  return (
    <div className="flipbook-mobile-container">
      <div className={`top-banner top-banner-img`} style={getStyles(currentPos)}></div>
      {/* top-banner-img-${currentPos} */}
      <div className="flip-book">
        <div className="content">
          <Slider
            dots={true}
            arrows={false}
            afterChange={setCurrentPos}
            autoplay
            pauseOnDotsHover
            pauseOnFocus
          >
            {flipBookData &&
              flipBookData.map(
                (page, i) =>
                  page.content && (
                    <div className="content-box" key={i}>
                      {i !== 0 && <br />}
                      {page.content}
                    </div>
                  )
              )}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default FlipBookMobile;
