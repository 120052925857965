import React from 'react';
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Layout from '../../layout/layout';
import { RECAPTCHA_KEY } from '../../config/appConfig';
import ContactUsForm from '../../components/common/ContactUsForm/ContactUsForm';


const ContactUs = () => {
  return (<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
     <Layout
      bannerClass="about-us"
      heading="Contact Us"
      breadCrumbs={[{ label: 'Enquiry' }, { label: 'Contact Us' }]}
      noBanner={true}
      metaData={{
        url: 'https://www.littleskoolhouse.com/enquiry/contact-us',
        title: 'Contact Us Today for Enquiries | Little Skool-House',
        description: `Have an enquiry? Visit this page to schedule a visit to any centre, find out more about school life at Little Skool-House or see current job openings.`
      }}
    >
    <ContactUsForm />
    </Layout>
  </GoogleReCaptchaProvider>)
};

export default ContactUs;
