import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../../layout/layout';
import './StoryDetails.scss';
import FlexibleContent from '../../../components/common/FlexibleContent/FlexibleContent';

const StoryDetails = ({ pageContext }) => {
  const { title, data, slug } = pageContext;
  const metaTitle =
    data && data.metaTag && data.metaTag.metaTitle
      ? `${data.metaTag.metaTitle}`
      : 'Stories | The Little Skool-House';
  const metaDescription =
    data && data.metaTag && data.metaTag.metaDescription
      ? data.metaTag.metaDescription
      : 'The Little Skool-House is a bilingual preschool that fosters proficiency in English & Mandarin. Learn about upcoming events & speak to our educators today.';
  return (
    <Layout
      bannerClass="happenings"
      heading="Stories"
      noBanner={true}
      breadCrumbs={[
        { label: 'happenings' },
        { label: 'Our Stories', link: '/happenings/ourstories' },
        { label: title }
      ]}
      metaData={{
        url: `https://www.littleskoolhouse.com/events/${slug}`,
        title: metaTitle,
        description: metaDescription
      }}
    >
      <div className="stories-detail-container">
        <div style={{ position: 'relative' }}>
          <Link
            to={'/happenings/ourstories'}
            style={{ position: 'absolute', top: '0', right: '20px' }}
          >
            <button
              className="yellow-funky-button"
              style={{ padding: '10px 30px' }}
            >
              Back to list
            </button>
          </Link>
        </div>
        {data.title && data.title.line1 && (
          <h2 style={{ marginTop: '60px' }}>{data.title.line1}</h2>
        )}
        {data.title && data.title.line2 && <h2>{data.title.line2}</h2>}
        {data.flexibleContent && (
          <FlexibleContent data={data.flexibleContent} />
        )}
      </div>
    </Layout>
  );
};
export default StoryDetails;
