import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import './promoSectionCn.scss';
import Rebate from '../../../images/objects/open-house/rebate.png';
import GIFT from '../../../images/objects/gift.png';
import SpecialKit from '../../../images/objects/kit.png';


const PromoSectionPart = ({
    showArrow,
    serial,
    promoImg,
    altText,
    children,
    rebate,
    rebateAmountCls,
    customRebateCls
  }) => (
    <div className="promo-section">
      <div className={`promo-head ${showArrow ? 'show-arrow' : ''}`}>
        <span>
          <div className="text">{serial}</div>
        </span>
      </div>
      <div className="promo-body">{children}</div>
    </div>
  );

const PromoSectionCn = () => {

    const data = useStaticQuery(graphql`
    query {
      cmsData: cms {
        pageBy(uri: "xianshicuxiao") {
          open_house {
            openhousestatus
            privacyPolicy
            promotionPrice
            promotionTitle
            subTitle
            title
            worthLabel
            promoSection {
                subHeading
            }
          }
        }
      }
    }
  `);
  const cmsPromo = data.cmsData.pageBy.open_house;

  return (
    <div className="promo-left-section">
    <div className="top-section">
      <h4 className="cn-promo-title">{cmsPromo.title}</h4>
      <div className="money-section">
        <p className="promo-sub-title">{ cmsPromo.worthLabel || '享有高达'}</p>
        <p className="promo-sub-title"><span className="promo-price-cn">${cmsPromo.promotionPrice}</span>  的折扣*</p>
        {/* <div className="money" style={{ width: '59%' }}>{cmsPromo.promotionPrice}</div> */}
      </div>
    </div> 
   
    <div className="center-section">
      <div className="" />
    
   {cmsPromo.promoSection.map((item, i) => (
    <PromoSectionPart
        serial={i + 1}
        promoImg={Rebate}
        altText="Rebate"
        rebate={cmsPromo.promotionPrice}
    >
     <h4>{item.subHeading}</h4>
    </PromoSectionPart>
    ))}
     
      <div className="terms-section">*条款与条件适用</div>
    </div>
    <div className="under-section-cn">
      <div className="white-triangle" />
      <h2 className="huge">93%</h2>
      <p>
        小小学庭的学童 <br />
        被评定为
        <br />
        <b> 精通英语和华语两种语言 </b>
        <br /> <br /><br />
      </p>
      <p className="small-font-cn">
        <i>
          {' '}
          根据南洋理工大学司徒佩佩教授的 研究结果
        </i>
      </p>
      <p className="small">
        今天就登记，了解我们是如何做到的！
      </p>
    </div>
  </div>
  );
};


export default PromoSectionCn;
