import React from 'react';
import PropTypes from 'prop-types';
import { JsonLd } from 'gatsby-plugin-next-seo';
import { normalizePhoneNumber } from '../utils/utils';

const EducationalOrganization = ({ name, url, logo, telephone, sameAs }) => {
  return (
    <JsonLd
      json={{
        '@context': 'http://www.schema.org',
        '@type': 'EducationalOrganization',
        name,
        url,
        logo,
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: normalizePhoneNumber(telephone)
        },
        sameAs
      }}
    />
  );
};

EducationalOrganization.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  telephone: PropTypes.string.isRequired,
  sameAs: PropTypes.arrayOf(PropTypes.string)
};

EducationalOrganization.defaultProps = {
  sameAs: []
};

export default EducationalOrganization;
