import React, { useState } from 'react';
import Layout from '../../layout/layout';
import '../../pages/our-curriculum/curriculum-details.scss';
import Kids from '../../images/objects/curriculum-details/teacher-with-kids.jpg';
import Kids2 from '../../images/objects/our-educators/kid-4.jpg';
import LeftQuote from '../../images/objects/left-quote.png';
import RightQuote from '../../images/objects/right-quote.png';
import { Link } from 'gatsby';

import Graphic1 from '../../images/objects/curriculum-details/graphic-1.png';
import Graphic2 from '../../images/objects/curriculum-details/graphic-2.png';
import Graphic3 from '../../images/objects/curriculum-details/graphic-3.png';
import Graphic4 from '../../images/objects/curriculum-details/graphic-4.png';
import Graphic8 from '../../images/objects/curriculum-details/graphic-8.png';
import { mandarinAcquisitionPageLinkCn } from '../../config/appConfig';
import WheelNavCn from '../../components/common/WheelNav/WheelNavCn';

const wheelOptions = [
    [
        `参观小学`,
        `体验课室学习`,
        `在午餐及茶点时间购买食物`
    ],
    [
        `寻求帮助`,
        `结交新朋友`,
        `提出问题以厘清学习`
    ],
    [
        `进阶数学`,
        `进阶科学`,
        `社交与社区知识`,
        `金钱管理`,
        `认识时间`
    ],
    [
        `时间表概念`,
        `整理个人物品`,
        `在餐厅用餐`,
        `为幼儿和自己的情绪做好准备`
    ]
];

const sections = [
    {
        text: {
            heading: `全面发展`,
            content: (
                <p>
                    我们相信提供扎实的学术基础及有信心的社会和情绪发展，能让幼儿做好面对小学及未来生活的准备。
                    整合六大领域的学习：语言与读写能力、数学、科学、创意、身体动作，以及社会发展。
                </p>
            )
        },
        graphic: Graphic1
    },
    {
        text: {
            heading: `英语和华语能力`,
            content: (
                <>
                    <p>
                        我们关注的元素之一，
                        <a
                            href="/zh/womendeyuzhongbutong/jīngtongyingyuhehuayu"
                            className="text-link"
                        >
                            是让幼儿精通英语和华语两种语言
                        </a>
                        。小小学庭的课程让93％的幼儿达到精通双语的程度。小小学庭通过绘本、
                        游戏、专业的学习与应用等等，让幼儿对学习产生真正的兴趣，也使语言变得更有趣。
                    </p>
                    <h3>
                        小小学庭深化华语习得的教学法
                    </h3>
                    <p>
                        我们
                        <a
                            href="/zh/womendeyuzhongbutong/womendehuojiangjiaoshi"
                            target="blank"
                            style={{ color: '#4ec7e2', textDecoration: 'underline' }}
                        >
                            训练有素的教师
                        </a>
                        对学前幼儿设计和开展语言及读写能力活动的方法及策略非常有经验，
                        并理解我们的独特教学来加强幼儿的华语习得。
                    </p>
                </>
            ),
            link: { url: mandarinAcquisitionPageLinkCn }
        },
        graphic: Graphic2
    },
    {
        text: {
            heading: `方案与创新项目`,
            content: (
                <p>
                    本项目在通过幼儿的科学与数学学习中，逐渐注入批判性思考及解决问题的思维。
                    并通过各种趣味活动掌握读写技能。我们的课程让幼儿针对各种课题的讨论、探索、
                    研究，以及报告的过程中，建立语言技能的信心。我们的幼儿可以自由且自在地对他们好奇的事物提问，
                    例如"消化系统如何运作"并与同学进行此问题的深入分析。
                </p>
            )
        },
        graphic: Graphic3
    },
    {
        text: {
            heading: `深化华语习得的教学法`,
            content: (
                <p>
                    这是我们的学前幼儿精通华语的关键因素。本教学法的三大支柱是：扎实的语言基础
                    （如词汇、文法、句型）、完整健全的教师培训与技能的掌握，以及幼儿日常表达他们复杂想法的教学方法。
                </p>
            ),
            link: { url: mandarinAcquisitionPageLinkCn }
        },
        graphic: Graphic2
    },
    {
        text: {
            heading: `结构化阅读与识字评估`,
            content: (
                <p>
                    本课程根据孩子的进展与能力，鼓励并推动独立阅读。首先，每个幼儿接受语言及读写能力的评估。
                    然后，根据评估的结果，我们将对此课程做相应的调整以发挥幼儿的最大潜能。
                </p>
            )
        },
        graphic: Graphic4
    },
    {
        text: {
            heading: `为小学做好准备`,
            content: (
                <p>
                    小小学庭为4至6岁幼儿所开发的课程，着重于如何让他们为小学及未来的生活做好准备。
                    为幼儿园幼儿开展的语言与读写能力的活动及课程，专注于过渡到小学阶段必须的学术准备、
                    自信的双语沟通、行为成熟，以及社交与情绪技能发展。通过一系列语言、数学以及读写能力的趣味活动，
                    希望我们小小学庭的学前幼儿进入小学后继续茁壮成长。
                </p>
            )
        },
        graphic: Graphic8
    }
];

export const EPSection = ({
    section: {
        text: { heading, content, link },
        graphic,
        left,
    },
    right = false
}) => (
    <div className={`top-section ${right ? 'top-section-right' : ''} ${left ? 'justify-content-start' : ''}`}>
        <div className={`img-section${right ? '-mobile' : ''}`}>
            <img src={graphic} alt={heading} />
        </div>
        <div className="text">
            <h3>{heading}</h3>
            {content}
            {link && (
                <Link to={link.url}>
                    <button className="yellow-funky-button">
                        {link.label || '更多详情'}
                    </button>
                </Link>
            )}
        </div>
        {right && (
            <div className="img-section">
                <img src={graphic} alt={heading} />
            </div>
        )}
    </div>
);

const LiteracyBasedCn = () => {
    const [wheelPosition, setWheelPosition] = useState(1);

    return (
        <Layout
            bannerClass="curriculum-details"
            heading="我们的课程"
            breadCrumbs={[
                { label: '我们的课程' },
                { label: '文学化课程（4至6岁）' }
            ]}
            metaData={{
                url: "https://www.littleskoolhouse.com/zh/womendekecheng/wenxuehuakecheng4zhi6sui",
                title: "以掌握读写能力为主的幼儿园学生课程 | 小小学庭",
                description: "学前教育对于以后在学校取得成功至关重要。我们提供以语言和读写能力为主的课程活动使孩童精通双语。今天就报读。"
            }}
        >
            <div className="curriculum-details-container">
                <h2>
                    文学化课程 <br className='mobile-only'/>（4至6岁）
                </h2>
            </div>

            <div className="little-skool-house-container">
                <div className="left-section">
                    <img src={Kids} draggable={false} alt="LSH Kids" />
                </div>
                <div className="right-section">
                    <p className="intro">
                        让4至6岁的幼儿奠定小学的基础
                        <img src={LeftQuote} alt="Left quote" className="quote" />
                    </p>
                    <p className="text">
                        我们的焦点包括学术预备、有信心地使用双语进行沟通、行为成熟，以及最终大学校环境的接触。
                    </p>
                    <div className="right-quote">
                        <img src={RightQuote} alt="Right quote" />
                    </div>
                </div>
            </div>
            <div className="curriculum-details-master-container">
                <div className="learning-environment-container">
                    {sections.map(
                        (section, i) =>
                            i < 2 && (
                                <EPSection section={section} right={i % 2 !== 0} key={i} />
                            )
                    )}
                </div>

                <div className="curriculum-details-container">
                    <h3>小学的接触与准备</h3>
                    <div className="wheel-nav-section">
                        <WheelNavCn sections={4} onChange={setWheelPosition} />
                        <div className="wheelnav-description">
                            {wheelOptions[wheelPosition - 1].map((text, i) => (
                                <div key={i}>
                                    <div className="line-number">
                                        <span>{i + 1}</span>
                                    </div>
                                    <div className="text">{text}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="little-skool-house-container">
                    <div className="right-section">
                        <p className="intro">
                            小学培养课程
                            <img src={LeftQuote} alt="Left quote" className="quote" />
                        </p>
                        <p className="text">
                            我们学术基础的特点之一为通过以下小学培养课程来巩固幼儿在数学、科学，以及沟通方面的能力。
                        </p>
                        <div className="right-quote">
                            <img src={RightQuote} alt="Right quote" />
                        </div>
                    </div>
                    <div className="left-section">
                        <img src={Kids2} draggable={false} alt="LSH Kids" />
                    </div>
                </div>

                <div className="learning-environment-container">
                    {sections.map(
                        (section, i) =>
                            i > 1 && (
                                <EPSection section={section} right={i % 2 !== 0} key={i} />
                            )
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default LiteracyBasedCn;
