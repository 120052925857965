import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import { Formik, Form } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { saveGwlEnquiry } from '../../../api/api';
import Dropdown from '../Dropdown/Dropdown';
import DatePick from '../DatePick/DatePick';
import AlertModal from '../AlertModal/AlertModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { TextField, Checkbox } from '../FormInput/FormInput';
import { HolidayTable, NotesDetails } from './helper';
import './GwlForm.scss';

const errorMsg =
  "Sorry, we can't process your enquiry now. Please try again later";

const FormInput = ({ label, name, placeholder, type = 'text', required }) => (
  <div className="gwl-form-input">
    <div className="label-wrapper">
      <label htmlFor={name}>
        {label}
        <span className="gwl-label-star">*</span>
      </label>
    </div>
    <div className="input-wrapper">
      <TextField
        className="gwl-form-input-outline"
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        backgroundColor="#e7f1f9"
      />
    </div>
  </div>
);

const GwlMandarinForm = ({ type, inCentreList, data }) => {

  const {
    yourDetailsLabel,
    scheduleSubTitle,
    thanksCopy,
    liveClassLabel,
    email,
    mobileNumber,
    childName,
    childDob,
    noteTitle,
    submitButtonLabel,
    slotsLabel
  } = data.gwlFields;
  const { copyContent, subtitle } = thanksCopy;
  const { liveClassSubTitle, liveClassTitle } = liveClassLabel;
  const { emailFieldLabel, emailFieldsPlaceholder, emailErrorCopy, emailInvalidError, emailInstruction } = email;
  const { mobileFieldErrorCopy, mobileFieldLabel, mobileFieldPlaceholder, subError } = mobileNumber;
  const {
    childnameErrorCopy,
    childnameLabel,
    childnamePlaceholder
  } = childName;
  const {
    dobErrorCopy,
    dobLabel,
    dobPlaceholder
  } = childDob;

  const {
    englishDropLabel,
    englishDropdownErrorCopy,
    englishDropdownPlaceholder
  } = data.gwlFields.englishDropLabel;

  const onlineListMN = slotsLabel.map(eachSlot => ({ english: eachSlot.english, strikeThroughOption: eachSlot.isfull === 'Full' }));

  const [formSubmitting, setFormSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef();


  const handleForm = async formData => {
    setFormSubmitting(true);

    try {
      const reqData = {
        email: formData.email,
        mobileNumber: formData.phone,
        childName: formData.childName,
        onlineSchedule: formData.englishOnline,
        dob: formData.date,
        // centreName: formData.centre,
        // centreSchedule: formData.englishInCentre,
        onlineScheduleMandarin: formData.mandarinOnline,
        // centreScheduleMandarin: formData.mandarinInCentre,
        language: formData.type
      };
      const { status } = await saveGwlEnquiry(reqData);
      setFormSubmitting(false);

      if (status !== 'success') {
        setSubmitError(errorMsg);
        return;
      }
      setShowModal(true);

      const form = formRef.current;
      if (form) {
        form.resetValidationState(true);
      }
    } catch (e) {
      setSubmitError(errorMsg);
      setFormSubmitting(false);
    }
  };

  const closeModalHandler = () => {
    setShowModal(false);
    // window.location = 'https://gwl.littleskoolhouse.com/';
  };

  return (
    <>
      {showModal && (
        <AlertModal handleClose={closeModalHandler} buttonType="gwl" redirectURL="/gwl-thanks">
          <div className="success-alert-gwl">
            <div className="gw-popup">{subtitle}</div>
            {copyContent}
          </div>
        </AlertModal>
      )}
      <div className="gwl-form-wrapper">
        <Formik
          initialValues={{
            type,
            email: '',
            childName: '',
            phone: '',
            date: '',
            englishOnline: '',
            // englishInCentre: '',
            mandarinOnline: ''
            // mandarinInCentre: '',
            // centre: ''
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email(emailInvalidError)
              .required(emailErrorCopy),
            childName: Yup.string().required(childnameErrorCopy),
            date: Yup.string().required(dobErrorCopy),
            phone: Yup.string()
              .matches(/^[0-9]{8}$/, subError)
              .required(mobileFieldErrorCopy),
            mandarinOnline: Yup.mixed()
              .required(englishDropdownErrorCopy)
              .notOneOf([englishDropdownPlaceholder], englishDropdownErrorCopy)
            // mandarinInCentre: Yup.mixed()
            //   .required('Please select an option')
            //   .notOneOf(['Choose schedule'], 'Please select an option'),
            // centre: Yup.mixed()
            //   .required('Please select an option')
            //   .notOneOf(['Choose centre'], 'Please select an option')
          })}
          onSubmit={(formData, actions) => {
            formData.date = moment(formData.date).format('DD/MM/YYYY');

            handleForm(formData).then(() => {
              actions.setSubmitting(false);
              actions.resetForm();
            });
          }}
        >
          {({ errors, setFieldValue, values }) => (
            <Form className="gwl-form-main">
              {submitError && (
                <div className="submit-error error">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="icon"
                  />
                  {submitError}
                </div>
              )}
              <div className="gwl-form-controls">
                <h3 className="gwl-sub-heading gw-underline">{yourDetailsLabel}</h3>
                <div className="your-details-wrap">
                  <div className="details-child">
                    <FormInput
                      label={emailFieldLabel}
                      name="email"
                      type="email"
                      required={true}
                      placeholder={emailFieldsPlaceholder}
                    />
                    <p className="gwl-para pad-liner">
                      {' '}
                      {emailInstruction}
                    </p>
                  </div>
                  <div className="details-child">
                    <FormInput
                      label={mobileFieldLabel}
                      name="phone"
                      required={true}
                      placeholder={mobileFieldPlaceholder}
                    />
                  </div>
                  <div className="details-child">
                    <FormInput
                      label={childnameLabel}
                      name="childName"
                      type="text"
                      required={true}
                      placeholder={childnamePlaceholder}
                    />
                  </div>
                  <div className="details-child">
                    <div className="gwl-form-input">
                      <div className="label-wrapper">
                        <label htmlFor="date">{dobLabel}</label>
                        <span className="gwl-label-star">*</span>
                      </div>
                      <div className="input-wrapper">
                        <DatePick
                          changeHandle={date => {
                            setFieldValue('date', date, true);
                          }}
                          name="date"
                          englishPlaceholder={dobPlaceholder}
                        />
                        {errors.date && (
                          <div className="error-field">
                            <FontAwesomeIcon
                              icon={faExclamationTriangle}
                              className="error-icon"
                            />
                            {errors.date}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <h3 className="gwl-sub-heading gw-underline">
                  {scheduleSubTitle}
                </h3>
                {/* <div className="gwl-col-wrapper"> */}
                {/* <div className="left-col"> */}
                <div className="head-wrap-n">
                  <h4 className="gwl-col-header">{liveClassTitle}</h4>
                  <h5 className="gwl-col-heading2">{liveClassSubTitle}</h5>
                </div>
                <div className="gwl-form-input">
                  <div className="label-wrapper">
                    <label htmlFor="name">{englishDropLabel}</label>
                  </div>
                  <div className="input-wrapper gwl-form-input-outline">
                    <Dropdown
                      resetValue={!values.mandarinOnline && englishDropdownPlaceholder}
                      onChange={mandarinOnline => {
                        setFieldValue('mandarinOnline', mandarinOnline, true);
                      }}
                      options={onlineListMN.map(item => ({
                        key: item.strikeThroughOption
                          ? `[FULL] ${item.english}`
                          : item.english,
                        value: item.strikeThroughOption
                          ? `[FULL] ${item.english}`
                          : item.english,
                        notClickable: item.strikeThroughOption,
                        strikeThroughOption: item.strikeThroughOption
                      }))}
                      theme="white"
                      bottomLine={true}
                      type={type}
                      category="gwl"
                      isGwlDropdown
                    />
                    {errors.mandarinOnline && (
                      <div className="error-field">
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="error-icon"
                        />
                        {errors.mandarinOnline}
                      </div>
                    )}
                  </div>
                </div>
                {/* </div> */}
                {/* <div className="divider"></div> */}
                {/* <div className="right-col">
                    <h4 className="gwl-col-header">DISCOVER & LEARN</h4>
                    <div className="gwl-head-wrap">
                      <h5 className="gwl-col-heading2">IN CENTRE</h5>
                    </div>
                    <div className="gwl-form-input">
                      <div className="label-wrapper">
                        <label htmlFor="name">Centre</label>
                      </div>
                      <div className="input-wrapper gwl-form-input-outline">
                        <Dropdown
                          resetValue={!values.centre && 'Choose Centre'}
                          onChange={centre => {
                            setFieldValue('centre', centre, true);
                          }}
                          options={inCentreList.map(item => ({
                            key: item.centre && item.centre,
                            value: item.centre && item.centre,
                            notClickable: !item.centre
                          }))}
                          theme="white"
                          bottomLine={true}
                          type={type}
                        />
                        {errors.centre && (
                          <div className="error-field">
                            <FontAwesomeIcon
                              icon={faExclamationTriangle}
                              className="error-icon"
                            />
                            {errors.centre}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="gwl-form-input">
                      <div className="label-wrapper">
                        <label htmlFor="name">Mandarin</label>
                      </div>
                      <div className="input-wrapper gwl-form-input-outline">
                        <Dropdown
                          resetValue={
                            !values.mandarinInCentre && 'Choose Schedule'
                          }
                          onChange={mandarinInCentre => {
                            setFieldValue(
                              'mandarinInCentre',
                              mandarinInCentre,
                              true
                            );
                          }}
                          options={inCentreList.map(item => ({
                            key: item.mandarin && item.mandarin,
                            value: item.mandarin && item.mandarin,
                            notClickable: !item.mandarin
                          }))}
                          theme="white"
                          bottomLine={true}
                          type={type}
                        />
                        {errors.mandarinInCentre && (
                          <div className="error-field">
                            <FontAwesomeIcon
                              icon={faExclamationTriangle}
                              className="error-icon"
                            />
                            {errors.mandarinInCentre}
                          </div>
                        )}
                      </div>
                    </div>
                  </div> */}
                {/* </div> */}
              </div>

              <h3 className="gwl-sub-heading">{noteTitle}</h3>
              <ol className="gwl-ol">
                <NotesDetails data={data} />
              </ol>
              <HolidayTable mode="mobile-only" data={data} />
              <div className="form-input">
                <div className="input-wrapper full gwl-btn-wrap">
                  <button
                    type="submit"
                    className="gwl-button"
                    disabled={formSubmitting}
                  >
                    {formSubmitting ? 'Submitting...' : submitButtonLabel}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default GwlMandarinForm;
