import React from 'react';
export const HolidayTable = ({mode ='desktop-only', data}) => {
    const tData = data.gwlFields.holidayTable.table;
    const isTableActive = data.gwlFields.holidayTable.holidayTableStatus;
    if (!isTableActive || !tData) return null;
    const tHeaderA = tData[0].columnA;
    const tHeaderB = tData[0].columnB;
    return (<table className={`gwl-table ${mode}`} >
      <thead>
        <tr>
          <th rowSpan="2">{tHeaderA}</th>
          <th rowSpan="2">{tHeaderB}</th>
        </tr>
      </thead>
      <tbody>
        {
          tData.map((eachRow, idx) => {
            if (idx > 0) {
              return (
                <tr className="bg-1">
                  <td className="table-bullet" style={{ borderBottom: (idx < tData.length - 1) ? `1px dashed #c9c9c9` : '' }}>
                     &nbsp; {eachRow.columnA}
                  </td>
                  <td className="table-bullet" style={{ borderBottom: (idx < tData.length - 1) ? `1px dashed #c9c9c9` : '' }}>
                     &nbsp; {eachRow.columnB}
                  </td>
                </tr>
              )
            }
          })
        }
      </tbody>
    </table>)
  };

  export const NotesDetails = ({ data }) => {
    const notesData = data.gwlFields.notes;
    const frag = notesData.map((eachNote, idx) => {
      if (idx < notesData.length - 1) {
        return (<li>
          <p className="gwl-li-para">
            {eachNote.noteCopy}
          </p>
        </li>);
      } else {
        return (<li>
          <p className="gwl-li-para">
            {eachNote.noteCopy}
          </p>
          <div className="gwl-table-wrap">
            <HolidayTable mode="desktop-only" data={data} />
          </div>
        </li>);
      }
    })
    return frag;
  }