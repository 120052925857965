import React from 'react';
import { Helmet } from 'react-helmet-async';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Link } from 'gatsby';
import Layout from '../../layout/layout';
import MothersDayLandingForm from '../../components/common/ScheduleVisitForm/MothersDayLandingForm';
import '../../templates/schedule-a-visit/schedule-a-visit.scss';
import { MlCentres, RECAPTCHA_KEY } from '../../config/appConfig';
import MdLandingImg from '../../images/mothers-day-celebration.png';

const MothersDayEvent = () => {
  return (
    <Layout
      bannerClass="schedule"
      heading="Events"
      pageLn="en"
      breadCrumbs={[{ label: 'Events' }]}
      noBanner={true}
      metaData={{
        url: 'https://www.littleskoolhouse.com/mothers-day-event-6-may/',
        title: 'Mother’s Day Celebration | The Little Skool-House',
        description:
          'Visit one of our Little Skool-House centres to see how 93% of our graduates are Highly Proficient in Both English and Mandarin. Schedule a visit today.'
      }}
    >
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="schedule-container">
        <div>
          <img src={MdLandingImg} className="md-landing-img" />
        </div>
        <div className="md-landing-desc">
          <div className="md-landing-desc-child">
            ❤️ Create unforgettable memories with your little ones through our
            specially curated parent-child activities, including storytelling,
            sensory play experiences, and more.
          </div>
          <div className="md-landing-desc-child">
            ✨ Take home a sustainable 'pot of love' craft as a keepsake of your
            time with us and an exclusive goodie bag
          </div>
          <div className="md-landing-desc-child">
            ⚽️ Explore our engaging Science, Music & Sports activities in
            partnership with our learning partners!
          </div>
        </div>
        <div className="md-landing-list-ul">
          <div className="md-landing-list-desc">
            RSVP for your preferred centre:
          </div>
          <ul>
            <li>
              At-Delta-House
            </li>
            <div className="md-padding-10">Featuring talk by Suu Balm at 10.30am on “Tips on managing Eczema & sensitive skin”. Do you have questions on managing Eczema & sensitive skin? Join us for this talk with Dr. John O'Shea, a medical doctor and co-founder of Suu Balm, and learn more about how menthol helps in relieving itch, and how the environment and stress influence the condition of your skin. Discover the importance of moisturising for sensitive skin and bring home some samples too!</div>
            <li>
              At-Devan-Nair Institute
            </li>
            <div className="md-padding-10">Featuring talk by Nestle Gerber at 10.30am on “Nutrition tips for your preschooler”. Looking for nutrition tips for your preschooler or do you have a fussy eater at home and wish to overcome this? We are excited to have Nestle Gerber share with us the importance of a balanced diet, calcium, protein, etc. Learn from accredited nutritionist, Belinda Leow, on the different foods to boost immunity and to support optimal growth.</div>
            <li>At-Downtown-East</li>
            <div className="md-padding-10">Learn more about Sustainability, Deforestation and Recycling with your little one. To honour mothers and in celebration of Mother's Day, our learning partner will be guiding you to create a Tree of Wisdom and Love!</div>
            <li>At-Mountbatten-Square</li>
            <div className="md-padding-10">Enjoy a Music and Movement experience with our learning partner as you move and groove with your little one. Expose your child to simple rhythmic play, circle dance, etc and feel the beat through the dances.</div>
            <li>
              At-Tampines-Junction
            </li>
            <div className="md-padding-10">Featuring talk by Flip for Joy at 10.30am on “Chinese language for our children - living and loving it!” by founder Jiang Meiru. Meiru is a trained Chinese language teacher with experience teaching in a local junior college and is a mother of two. She is passionate about reading, the learning of the Chinese language and raising bilingual children.</div>
          </ul>
        </div>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
          <MothersDayLandingForm
            type="mothers-day"
            eventType="event_may_6"
            centerList={MlCentres}
          />
        </GoogleReCaptchaProvider>
      </div>
    </Layout>
  );
};

export default MothersDayEvent;
