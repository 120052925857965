import React, { useState } from 'react';
import Layout from '../layout/layout';
import Kids from '../images/objects/about-lsh-kids.jpg';
import LeftQuote from '../images/objects/left-quote.png';
import RightQuote from '../images/objects/right-quote.png';
import LeftImg from '../images/objects/our-vision-left-image.jpg';
import CertifiedCentersImg from '../images/objects/about-awards/about-awards-1.jpeg';
import OurHealthyFood from '../images/objects/about-awards/about-awards-2.jpg';
import SPARK from '../images/logos/SPARK.png';
import HMPP from '../images/logos/HMPP.png';
import AwardsSectionCn from '../components/common/AwardsSection/AwardsSectionCn';
import '../pages/about-us/the-little-skool-house.scss';
import WheelNavCn from '../components/common/WheelNav/WheelNavCn';

const wheelContent = [
    <>
        <h2>我们的教师</h2>
        <p>
         我们的老师是我们幼儿的向导，同伴和探险的伙伴。
        </p>
    </>,
    <>
        <h2>我们的环境</h2>
        <p>
         我们的环境驱动幼儿探索与发现，提升好奇心，培养创造力和自信心。
        </p>
    </>,
    <>
        <h2>国际儿童早期教育和保育体系</h2>
        <p>我们的质量标准是依据国际标准而设置的</p>
    </>,
    <>
        <h2>我们的家长</h2>
        <p>
          我们的家长是我们幼儿学习旅程中忠实与值得信赖的合作伙伴。
        </p>
    </>,
    <>
        <h2>我们的校长</h2>
        <p>
         我们的校长为塑造和引导幼儿的学习旅程提供方向和领导。
        </p>
    </>,
    <>
        <h2>我们的课程</h2>
        <p>
          我们的课程以绘本为媒介，注重双语和情境中学习。
        </p>
    </>
];

const AboutUsCn = () => {
    const [currPos, setCurrPos] = useState(1);

    return (
        <Layout
            heading="关于我们"
            bannerClass="about-us"
            breadCrumbs={[{ label: '关于我们' }]}
            metaData={{
                url: 'https://www.littleskoolhouse.com/zh/guanyuwomen',
                title: "关于我们的愿景和奖项 | 小斯库尔之家",
                description: "我们在新加坡提供全面的学前教育和托儿服务方面拥有 25 年的经验。访问此处了解我们的愿景和奖项。"
            }}
        >
            <div className="little-skool-house-container">
                <div className="left-section">
                    <img src={Kids} draggable={false} alt="LSH Kids" />
                </div>
                <div className="right-section">
                    <p className="intro">
                        在小小学庭，<br className="mobile-only" />
                        <span>我们透过推动孩子去探索、</span>
                        <br />
                        <span>创造和创新来挖掘他们对学习和发现的自然好奇心。</span>
                        <img src={LeftQuote} alt="Left quote" className="quote" />
                    </p>
                    <p className="text">
                        我们沉浸式的双语课程让孩子精通英语和华语两种语言。我们专业的教师团队让我们在过去八年多次赢得杰出学前教师和杰出学前母语教师的奖项。小小学庭25年来持续引导孩子们通过探索、发现，有效建构知识。我们的愿景是让孩子为小学和生活做好准备。
                    </p>
                    <div className="right-quote">
                        <img src={RightQuote} alt="Right quote" />
                    </div>
                    <p className="text new-para-margin">
                    作为社会型企业职总优儿学府的一员，小小学庭致力于为每位幼儿及其家庭开创美好的未来。                    </p>
                </div>
            </div>

            {/* Our Vision */}
            <h1 className="heading">我们的愿景</h1>
            <div className="our-vision-container padding-top-0">
                <div className="little-skool-house-container">
                    <div className="left-section">
                        <img src={LeftImg} draggable={false} alt="LSH Kids" />
                    </div>
                    <div className="right-section">
                        <p className="intro">
                        我们致力于培育和协助每一位幼儿，{' '}
                            <span>发掘他们的潜能。</span>
                            <img src={LeftQuote} alt="Left quote" className="quote" />
                        </p>
                        <p className="text">
                            小小学庭聚焦于六大领域，
                            针对有待改进的部分，确保持续的反思和评估，不断提升教学质量。我们的愿景是对全部重要领域的坚持与创新。
                        </p>
                        <div className="right-quote">
                            <img src={RightQuote} alt="Right quote" />
                        </div>
                    </div>
                </div>

                <div className="wheelnav-section">
                    <WheelNavCn onChange={setCurrPos} />
                    <div className="wheel-nav-details">
                        {currPos && wheelContent[currPos - 1]}
                    </div>
                </div>
            </div>

            {/* Our Awards */}
            <div className="our-awards-container">
                <AwardsSectionCn />
                <div className="award-bottom">
                    <div className="photo-section">
                        <h2 className="photo-section-title-cn">我们通过认证的幼儿中心</h2>
                        <img
                            src={CertifiedCentersImg}
                            alt="Our Certified Centers"
                            className="children-img"
                        />
                        <p>
                            我们的幼儿园都通过新加坡学前教育认证框架（Singapore Preschool Accreditation Framework，简称SPARK）的审核。教育部引进新加坡学前教育认证框架以提升幼儿园的品质。该框架支持幼儿园校长加强教学、学习、行政，以及管理流程。
                        </p>
                        <img src={SPARK} alt="SPARK Logo" className="logo-img logo-spark" />
                    </div>
                    <div className="photo-section">
                        <h2 className="photo-section-title-cn">我们健康的食物</h2>
                        <img
                            src={OurHealthyFood}
                            alt="Our Healthy Food"
                            className="children-img"
                        />
                        <p>
                            我们幼儿园的食物是由园内专属的厨师以爱心烹制，并获得学龄前儿童健康饮食计划（Healthy Meals in Preschools Programme ，简称HMPP）的认证，其中九所幼儿园曾获颁白金奖。
                        </p>
                        <img src={HMPP} alt="HMPP Logo" className="logo-img" />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AboutUsCn;
