import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Link } from 'gatsby';
import Layout from '../../layout/layout';
import ScheduleVisitForm from '../../components/common/ScheduleVisitForm/ScheduleVisitForm';
import './schedule-a-visit.scss';
import { centres, RECAPTCHA_KEY } from '../../config/appConfig';
import ScheduleLocation from '../../images/objects/schedule-location.png';

const Schedule = () => {
  return (
    <Layout
      bannerClass="schedule"
      heading="Schedule A Visit"
      pageLn="en"
      breadCrumbs={[{ label: 'Enquiry' }, { label: 'Schedule A Visit' }]}
      noBanner={true}
      metaData={{
        url: 'https://www.littleskoolhouse.com/enquiry/schedule-a-visit/',
        title: 'Schedule a Centre Visit | The Little Skool-House',
        description:
          'Visit one of our Little Skool-House centres to see how 93% of our graduates are Highly Proficient in Both English and Mandarin. Schedule a visit today.'
      }}
    >
      <div className="schedule-container">
        <div className="benefit-box reverse">
          <img src={ScheduleLocation} alt="schedule-location" />
          <div className="divider" />
          <div className="benefit-content">
            <h4>
              Visit one of our Little Skool-House centres located islandwide and
              find out how 93% of our children are Highly Proficient in English
              and Mandarin
            </h4>
            <p>
              Our immersive bilingual programmes are available for infant,
              toddler, nursery and kindergarten levels. Fill out the form below
              and our centre personnel will contact you for an appointment.
            </p>

            <div className="btn-wrapper">
              <Link
                className="yellow-funky-button"
                to="/our-centres/centres/all-centres"
              >
                Locate a centre
              </Link>
            </div>
            <p>
            如需切换至中文表格，请单击&nbsp;
              <a style={{ color: '#4ec7e2', textDecoration: 'underline' }} href="/zh/xunwen/yuyuecanguan">
              此处
              </a>
              .
            </p>
          </div>
        </div>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
          <ScheduleVisitForm type="normal" centerList={centres} />
        </GoogleReCaptchaProvider>
      </div>
    </Layout>
  );
};

export default Schedule;
