import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../layout/layout';
import Linyuzheng from '../../images/tot/linyuzheng.png';
import YuXuan from '../../images/tot/yu-xuan.png';
import Mathieu from '../../images/tot/mathieu.png';
import Megan from '../../images/tot/megan.png';
import logo from '../../images/icons/location_1.png';
import './totPages.scss';

const TotList = ({ pageContext: {} }) => {
  return (
    <Layout
      bannerClass="schedule"
      breadCrumbs={[{ label: 'Tot Talks' }]}
      noFooter={true}
      noBanner={true}
      metaData={{
        url: 'https://www.littleskoolhouse.com/tot-talks',
        title: 'Tot Talks | The Little Skool-House Singapore',
        description:
          'Visit one of our Little Skool-House centres to see how 93% of our graduates are Highly Proficient in Both English and Mandarin. Schedule a visit today.'
      }}
    >
      <div className="tot-list-container">
        <div class=" top-sec">
          <div className="mar-custom">
            <div className="col-width bg-1">
              <div className="height-50 pos-rel">
                <div className="img-center">
                  <img className="child-img" src={Linyuzheng} alt="MOE" />
                </div>
              </div>
              <div className="height-50 p-top-15">
                <h3>Athlete</h3>
                <h2>LIN YUZHENG</h2>
                <div className="flex-w">
                  <img className="logo" src={logo} width="12px" alt="logo" />
                  <p className="m-l">Little Skool-House Singapore</p>
                </div>
                <p>
                  Singapore <span className="cr-bl">500+ connections</span>
                </p>
                <a
                  href="/tot-talks/tot-leader-lyz"
                  className="center p-top-15  anch"
                >
                  <button className="btn-black">View Profile</button>
                </a>
              </div>
            </div>
          </div>
          <div className="mar-custom">
            <div className="col-width bg-1">
              <div className="height-50 pos-rel">
                <div className="img-center">
                  <img className="child-img" src={Mathieu} alt="MOE" />
                </div>
              </div>
              <div className="height-50  p-top-15">
                <h3>Environmental Activist</h3>
                <h2>MATHIEU GOH</h2>
                <div className="flex-w">
                  <img className="logo" src={logo} width="12px" alt="logo" />
                  <p className="m-l">Little Skool-House Singapore</p>
                </div>
                <p>
                  Singapore <span className="cr-bl">500+ connections</span>
                </p>
                <a
                  href="/tot-talks/tot-leader-mg"
                  className="center p-top-15  anch"
                >
                  <button className="btn-black">View Profile</button>
                </a>
              </div>
            </div>
          </div>
          <div className="mar-custom">
            {/* <div className="overlay">
              <div className="overlay-txt">
                <p className="desktop-only">
                  COMING SOON <br /> IN MAY
                </p>
                <p className="mobile-only">COMING SOON IN MAY</p>
              </div>
            </div> */}
            <div className="col-width bg-1">
              <div className="height-50 pos-rel">
                <div className="img-center">
                  <img className="child-img" src={YuXuan} alt="MOE" />
                </div>
              </div>
              <div className="height-50  p-top-15">
                <h3>Lawyer</h3>
                <h2>TEO YU XUAN</h2>
                <div className="flex-w">
                  <img className="logo" src={logo} width="12px" alt="logo" />
                  <p className="m-l">Little Skool-House Singapore</p>
                </div>
                <p>
                  Singapore <span className="cr-bl">500+ connections</span>
                </p>
                <a
                  href="/tot-talks/tot-leader-tyx"
                  className="center p-top-15  anch"
                >
                  <button className="btn-black">View Profile</button>
                </a>
              </div>
            </div>
          </div>
          <div className="mar-custom">
            {/* <div className="overlay">
              <div className="overlay-txt">
                <p className="desktop-only">
                  COMING SOON <br /> IN MAY
                </p>
                <p className="mobile-only">COMING SOON IN MAY</p>
              </div>
            </div> */}
            <div className="col-width bg-1">
              <div className="height-50 pos-rel">
                <div className="img-center">
                  <img className="child-img" src={Megan} alt="MOE" />
                </div>
              </div>
              <div className="height-50  p-top-15">
                <h3>Life Coach</h3>
                <h2>MEGAN NG</h2>
                <div className="flex-w">
                  <img className="logo" src={logo} width="12px" alt="logo" />
                  <p className="m-l">Little Skool-House Singapore</p>
                </div>
                <p>
                  Singapore <span className="cr-bl">500+ connections</span>
                </p>
                <a href="/tot-talks/tot-leader-mn" className="center p-top-15 anch">
                  <button className="btn-black">View Profile</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <h1 className="pad-30">
          Here at Little Skool-House, 93% of our children are highly proficient
          in both English and Mandarin. Find out how we nurture articulate Tot
          Leaders through our unique curriculum.
        </h1>
        <div className="center-btn">
          <button className="yellow-funky-button button-tot">
          <Link to='/enquiry/limited-time-promotion'>
            Schedule a Visit
          </Link>
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default TotList;
