export const mandarinAcquisitionPageLink =
  '/our-difference/high-proficiency-in-english-and-mandarin';
  export const mandarinAcquisitionPageLinkCn =
  '/zh/womendeyuzhongbutong/jīngtongyingyuhehuayu';
  export const GWL_MN_SLUG = 'gwl-scheduling-mand-only';
  export const GWL_EN_SLUG = 'gwl-scheduling-eng-only';
  export const GWL_BILINGUAL_SLUG = 'gwl-scheduling-bilingual';
  export const OLE_CN_SLUG = 'our-learning-environment-cn';
  export const HOME_PAGE_CN_SLUG = 'home-page-cn';
  export const HOME_PAGE_EN_SLUG = 'flip-book-en';
  export const OLE_EN_SLUG = 'our-learning-environment-en';
  export const ONM_CN_SLUG = 'our-nutritious-meals-cn'
  export const ONM_EN_SLUG = 'our-nutritious-meals-en'


  export const inCentreList = [
    {
      centre: 'The Little Skool-House At-Mountbatten-Square (Central)',
      english: 'Every Saturday 9 – 9:45am',
      mandarin: 'Every Saturday 9 – 9:45am'
    },
    {
      centre: 'The Little Skool-House At-Republic-Polytechnic (Woodlands)',
      english: 'Every Saturday 10:15 – 11am',
      mandarin: 'Every Saturday 10:15 – 11am'
    }
  ]
  export const onlineListEN = [
    {
      english: 'Every Tuesday 10 - 10:20am',
      strikeThroughOption: true
    },
    {
      english: 'Every Tuesday 10:45 - 11:05am',
      strikeThroughOption: true
    },
    {
      english: 'Every Tuesday 11:30 - 11:50am',
    },
    {
      english: 'Every Thursday 10 - 10:20am',
      strikeThroughOption: true
    },
    {
      english: 'Every Thursday 10:45 - 11:05am',
      strikeThroughOption: true
    },
    {
      english:'Every Thursday 11:30 - 11:50am',
    }
  ];

  export const onlineListMN = [
    {
      mandarin: 'Every Monday 10 - 10:20am',
      strikeThroughOption: true
    },
    {
      mandarin: 'Every Monday 10:45 - 11:05am',
      strikeThroughOption: true
    },
    {
      mandarin: 'Every Monday 11:30 - 11:50am'
    },
    {
      mandarin: 'Every Wednesday 10 - 10:20am',
      strikeThroughOption: true
    },
    {
      mandarin:'Every Wednesday 10:45 - 11:05am',
      strikeThroughOption: true
    },
    {
      mandarin:'Every Wednesday 11:30 - 11:50am'
    }
  ];
  
  
  export const centres = [
    {
      location: 'North',
      chinese: '北部'
    },
    {
      centreName: 'On-The-Green (Orchid Country Club)',
      chinese: '胡姬乡村俱乐部中心'
    },
    {
      centreName: 'By-The-Lake (KTPH)',
      chinese: '邱德拔医院中心'
    },
    {
      centreName: 'At-Republic-Polytechnic',
      chinese: '共和理工学院中心'
    },
    {
      location: 'Central',
      chinese: '中央'
    },
    {
      centreName: 'At-Marina-Boulevard (Raffles Place)',
      chinese: '滨海林荫道（莱佛士坊）'
    },
    {
      centreName: 'At-Delta-House (Alexandra Road)',
      chinese: '立达大厦中心 (亚历山大坊)'
    },
    {
      centreName: 'At-OCBC-Centre',
      chinese: '华侨银行中心'
    },
    {
      centreName: 'At-Thomson (MSF Building)',
      chinese: '社会及家庭发展部中心 （汤申）'
    },
    {
      centreName: 'At-Outram (OCH)',
      chinese: '欧南社区医院中心'
    },
    {
      centreName: 'At-KK Women’s and Children’s Hospital',
      chinese: '竹脚妇幼医院中心'
    },
    {
      location: 'East',
      chinese: '东部'
    },
    {
      centreName: 'At-Mountbatten-Square',
      chinese: '蒙巴登广场中心'
    },
    {
      centreName: 'At-Tampines-Junction',
      chinese: '淡滨尼职总英康中心'
    },
    {
      centreName: 'At-Downtown-East',
      chinese: '白沙乐怡度假村中心'
    },
    {
      centreName: 'At-OCBC-Tampines',
      chinese: '华侨银行中心（淡滨尼）'
    },
    {
      centreName: 'At-Temasek-Polytechnic',
      chinese: '淡马锡理工学院'
    },
    {
      location: 'West',
      chinese: '西部'
    },
    {
      centreName: 'By-The-Vista (Ulu Pandan CC)',
      chinese: '乌鲁班丹社区俱乐部中心'
    },
    {
      centreName: 'At-Devan-Nair-Institute',
      chinese: '蒂凡娜学院中心'
    },
    {
      centreName: 'At-Ministry-Of-Education (MOE HQ)',
      chinese: '教育部总部中心'
    },
    {
      centreName: 'At-Alexandra (Alexandra Hospital)',
      chinese: '亚历山大医院中心'
    },
    {
      centreName: 'At-Kent-Vale (NUS Campus)',
      chinese: '国大肯特谷中心'
    },
    {
      centreName: 'At-Ngee-Ann-Polytechnic',
      chinese: '义安理工学院中心'
    }
  ];

  export const MlCentres = [
      {
        centreName: 'At-Delta-House (Alexandra Road)'
      },
      {
        centreName: 'At-Devan-Nair-Institute'
      },
      {
        centreName: 'At-Downtown-East'
      },
      {
        centreName: 'At-Mountbatten-Square'
      },
      {
        centreName: 'At-Tampines-Junction'
      }
    ]

    export const MlCentres2 = [
      {
        centreName: 'At-Alexandra (Alexandra Hospital)'
      },
      {
        centreName: 'At-Kent-Vale (NUS Campus)'
      },
      {
        centreName: 'At-Ngee-Ann-Polytechnic'
      },
      {
        centreName: 'At-OCBC-Centre'
      },
      {
        centreName: 'By-The-Lake (KTPH)'
      }
    ]

    export const MlCentres20may = [
      {
        centreName: 'At-Marina-Boulevard (Raffles Place)'
      }
    ]

    export const MlCentres20may2 = [
      {
        centreName: 'At-KK Women’s And Children’s Hospital'
      },
      {
        centreName: 'At-Ministry-Of-Education (MOE HQ)'
      },
      {
        centreName: 'At-OCBC-Tampines'
      },
      {
        centreName: 'At-Outram (OCH)'
      },
      {
        centreName: 'At-Republic-Polytechnic'
      },
      {
        centreName: 'At-Temasek-Polytechnic'
      },
      {
        centreName: 'At-Thomson (MSF Building)'
      },
      {
        centreName: 'On-The-Green (Orchid Country Club)'
      },
      {
        centreName: 'By-The-Vista (Ulu Pandan CC)'
      }
    ]

export const aboutUs = [
  'Choose one',
  'Social Media',
  'Word of Mouth',
  'Search (Google, Yahoo, etc)',
  'Parenting Website',
  'Electronic Direct Mailer',
  'Print Ads',
  'Outdoor Advertisement',
  'Workplace HR Initiatives',
  'Others'
];

export const aboutUsNewCn = [
  '社交媒体',
  '口碑相传',
  '搜索 (谷歌，雅虎，等)',
  '平面广告',
  '户外广告',
  '所在工作场所人力资源部的宣传',
  '其他'
]
export const aboutUsCn = [
  {
    cn:'请选择一项',
    option:'Choose one',
    en:'Choose one'
  },
  {
    cn:'平面广告',
    en:'Print Ad'
  },
  {
    cn:'小小学庭网站',
    en:'LSH Website'
  },
  {
    cn:'育儿网站',
    en:'Parenting Website'
  },
  {
    cn:'社交媒体',
    en:'Social Media'
  },
  {
    cn:'传单',
    en:'Flyer'
  },
  {
    cn:'口碑相传',
    en:'Word of Mouth'
  },
  {
    cn:'电子邮件',
    en:'Electronic Direct Mailer'
  },
  {
    cn:'杂志',
    en:'Magazines'
  },
  {
    cn:'报纸',
    en:'Newspaper'
  },
  {
    cn:'朋友或亲戚',
    en:'Friends or Relatives'
  },
  {
    cn:'户外广告',
    en:'Outdoor Advertisement'
  },
  {
    cn:'谷歌或雅虎搜索',
    en:'Google or Yahoo'
  },
  {
    cn:'所在工作场所人力资源部的宣传',
    en:'Workplace HR Initiatives'
  },
  {
    cn:'其他',
    en:'Others'
  },
];

export const siteMapObj = [
  {
    label: 'About Us',
    link: 'about-us',
    noPage: true,
    subMenu: [
      {
        label: 'The Little Skool-House',
        link: 'the-little-skool-house'
      },
      { label: 'Our Successes', link: 'our-successes' },
      { label: 'Our Awards', link: 'our-awards' },
      { label: 'Our Vision', link: 'our-vision' }
    ]
  },
  {
    label: 'Our Difference',
    link: 'our-difference',
    noPage: true,
    subMenu: [
      {
        label: 'Why Choose The Little Skool-House',
        link: 'why-choose-the-little-skool-house'
      },
      {
        label: 'High Proficiency in English and Mandarin',
        link: 'high-proficiency-in-english-and-mandarin'
      },
      {
        label: 'Our Award-Winning Educators',
        link: 'our-award-winning-educators'
      },
      {
        label: 'Chosen Preschool by Top Institutions',
        link: 'chosen-preschool-by-top-institutions'
      }
    ]
  },
  {
    label: 'Our Curriculum',
    link: 'our-curriculum',
    noPage: true,
    subMenu: [
      { label: 'Curriculum Overview', link: 'curriculum-overview' },
      {
        label: 'Infant – 3 years Relationships-Based Curriculum',
        link: 'infant-3-years-relationships-based-curriculum'
      },
      {
        label: '4 – 6 years Literacy-Based Curriculum',
        link: '4-6-years-literacy-based-curriculum'
      },
      {
        label: 'Enrichment Programme',
        link: 'enrichment-programme'
      }
    ]
  },
  {
    label: 'Our Centres',
    link: 'our-centres',
    noPage: true,
    subMenu: [
      {
        label: 'Locate Us',
        link: 'centres/all-centres'
      },
      { label: 'Admissions', link: 'admissions' },
      {
        label: 'Our Learning Environment',
        link: 'our-learning-environment',
      },
      { label: 'Our Nutritious Meals',
        link : 'our-nutritious-meals'
      }
    ]
  },
  { label: 'Home', link: '/' },
  {
    label: 'Expert Opinion',
    link: 'articles',
    noPage: true,
    subMenu: [
      {
        label: 'All',
        link: ''
      },
      {
        label: 'Learning',
        link: 'learning/infant-years'
      },
      {
        label: 'Behaviour',
        link: 'behaviour/toddler-years'
      },
      {
        label: 'Play',
        link: 'play/toddler-years'
      },
      {
        label: 'Health & Nutrition',
        link: 'health-and-nutrition/nursery-years'
      },
      {
        label: 'Parenting',
        link: 'parenting/infant-years'
      }
    ]
  },
  {
    label: 'Happenings',
    link: 'happenings',
    noPage: true,
    subMenu: [
      {
        label: 'Our Stories',
        link: 'ourstories'
      },
      {
        label: 'Promotions',
        link: 'promotions'
      },
      { label: 'Videos', link: 'videos' },
      { label: 'Media Coverage', link: 'mediacoverage' }
    ]
  },
  {
    label: 'Enquiry',
    link: 'enquiry',
    noPage: true,
    subMenu: [
      { label: 'Schedule A Visit', link: 'limited-time-promotion' },
      { label: 'FAQs', link: 'faqs' },
      { label: 'Contact Us', link: 'contact-us' },
      { label: 'Careers', link: 'careers' },
      { label: 'Partnership', link: 'partnership' },
      { label: 'Privacy Policy', link: 'others/privacy-policy' } 
    ]
  },
];

export const menuItems = [
  {
    label: 'About Us',
    link: 'about-us',
    noPage: true,
    subMenu: [
      {
        label: 'The Little Skool-House',
        link: 'the-little-skool-house'
      },
      { label: 'Our successes', link: 'our-successes' },
      { label: 'Our Awards', link: 'our-awards' },
      { label: 'Our vision', link: 'our-vision' }
    ]
  },
  {
    label: 'Our Difference',
    link: 'our-difference',
    noPage: true,
    subMenu: [
      {
        label: 'Why choose The Little Skool-House',
        link: 'why-choose-the-little-skool-house'
      },
      {
        label: 'High Proficiency in English and Mandarin',
        link: 'high-proficiency-in-english-and-mandarin'
      },
      {
        label: 'Our Award-Winning Educators',
        link: 'our-award-winning-educators'
      },
      {
        label: 'Chosen Preschool by Top Institutions',
        link: 'chosen-preschool-by-top-institutions'
      }
    ]
  },
  {
    label: 'Our Curriculum',
    link: 'our-curriculum',
    noPage: true,
    subMenu: [
      { label: 'Curriculum Overview', link: 'curriculum-overview' },
      {
        label: 'Infant – 3 years Relationships-Based Curriculum',
        link: 'infant-3-years-relationships-based-curriculum'
      },
      {
        label: '4 – 6 years Literacy-Based Curriculum',
        link: '4-6-years-literacy-based-curriculum'
      },
      {
        label: 'Enrichment Programme',
        link: 'enrichment-programme'
      }
    ]
  },
  {
    label: 'Our Centres',
    link: 'our-centres',
    noPage: true,
    subMenu: [
      {
        label: 'Locate Us',
        link: 'centres/all-centres'
      },
      { label: 'Admissions', link: 'admissions' },
      {
        label: 'Our Learning Environment',
        link: 'our-learning-environment',
      },
      { label: 'Our Nutritious Meals',
        link : 'our-nutritious-meals'
      }
    ]
  },
  { label: 'Home', link: '/' },
  {
    label: 'Expert Opinion',
    link: 'articles',
    noPage: true,
    subMenu: [
      {
        label: 'All',
        link: ''
      },
      {
        label: 'Learning',
        link: 'learning/infant-years'
      },
      {
        label: 'Behaviour',
        link: 'behaviour/toddler-years'
      },
      {
        label: 'Play',
        link: 'play/toddler-years'
      },
      {
        label: 'Health & Nutrition',
        link: 'health-and-nutrition/nursery-years'
      },
      {
        label: 'Parenting',
        link: 'parenting/infant-years'
      }
    ]
  },
  {
    label: 'Happenings',
    link: 'happenings',
    noPage: true,
    subMenu: [
      {
        label: 'Our Stories',
        link: 'ourstories'
      },
      {
        label: 'Promotions',
        link: 'promotions'
      },
      { label: 'Videos', link: 'videos' },
      { label: 'Media Coverage', link: 'mediacoverage' }
    ]
  },
  {
    label: 'Enquiry',
    link: 'enquiry',
    noPage: true,
    subMenu: [
      { label: 'Schedule A Visit', link: 'schedule-a-visit' },
      { label: 'FAQs', link: 'faqs' },
      { label: 'Contact Us', link: 'contact-us' },
      { label: 'Careers', link: 'careers' },
      { label: 'Partnership', link: 'partnership' }
      
    ]
  },
];

export const menuItemsCn = [
  {
    label: '关于我们',
    link: 'zh/guanyuwomen',
    noPage: true,
    redirect: true,
  },
  {
    label: '我们的与众不同',
    link: 'zh/womendeyuzhongbutong',
    noPage: true,
    subMenu: [
      {
        label: '为什么选择小小学庭',
        link: 'weishemexuanzexiaoxiaoxueting'
      },
      {
        label: '精通英语和华语',
        link: 'jīngtongyingyuhehuayu'
      },
      {
        label: '我们的获奖教师',
        link: 'womendehuojiangjiaoshi'
      },
      {
        label: '顶尖机构选择的幼儿园',
        link: 'dingjianjigouxuanzedeyoueryuan'
      }
    ]
  },
  {
    label: '我们的课程',
    link: 'zh/womendekecheng',
    noPage: true,
    subMenu: [
      { label: '课程框架概述', link: 'kechengkuangjiagaishu' },
      {
        label: '关系为本课程（出生至3岁）',
        link: 'guanxiweibenkechengchushengzhi3sui'
      },
      {
        label: '文学化课程（4至6岁)',
        link: 'wenxuehuakecheng4zhi6sui'
      },
      {
        label: '增益课程',
        link: 'zengyikecheng'
      }
    ]
  },
  {
    label: '我们的中心',
    link: 'zh/womendezhongxin',
    noPage: true,
    subMenu: [
      {
        label: '找到我们',
        link: 'suoyouzhongxin'
      },
      {
        label: '我们的学习环境',
        link: 'womendexuexihuanjing'
      },
      { 
        label: '我们的营养膳食',
        link : 'womendeyingyangshanshi'
      }
    ]
  },
  { label: '首页', link: '/' },
  {
    label: '传媒与活动',
    link: 'zh/chuanmeiyuhuodong',
    noPage: true,
    subMenu: [
      { label: '新闻稿', link: 'xinwengao' },
      { label: '促销活动',link: 'cuxiaohuodong' },
      { label: '视频', link: 'shipin' },
    ]
  },
  {
    label: '询问',
    link: 'zh/xunwen',
    noPage: true,
    subMenu: [
      { label: '预约参观', link: 'yuyuecanguan' },
      { label: '常见问题', link: 'changjianwenti' },
      { label: '联络我们', link: 'lianluowomen' }
    ]
  }
];

export const WIDGET_TYPE = {
  TEXT: 1,
  IMAGE: 2,
  TEXT_IMAGE_LEFT: 3,
  TEXT_IMAGE_RIGHT: 4,
  CAROUSEL: 5,
  FILE_DOWNLOAD: 6,
  VIDEO: 7
};

export const TEXT_TYPE = {
  UNSTYLED: 'unstyled',
  HEADER_3: 'header-three',
  HEADER_2: 'header-two',
  HEADER_1: 'header-one',
  UL: 'unordered-list-item',
  MARKDOWN: 'mark-down',
  BUTTON: 'button'
};

// Annoucement bar top close state persisting time - Value in milliseconds
export const ANNOUNCEMENT_CLOSE_EXPIRY_TIME = 24 * 3600 * 1000;

export const schema = {
  '@context': 'https://schema.org',
  '@type': 'Event',
  name: 'Oct Personalised Open House',
  startDate: '2020-10-05',
  endDate: '2020-11-15',
  eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
  eventStatus: 'https://schema.org/EventScheduled',
  url: 'https://www.littleskoolhouse.com/enquiry/open-house',
  location: {
    '@type': 'Place',
    name: 'Little Skool-House',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Singapore',
      addressCountry: 'SG'
    }
  },
  image:
    'https://www.littleskoolhouse.com/static/our-successes-kid-with-mom-42c841ef36ec0ff17b1a3d09308bb5b4.jpg',
  description:
    'Schedule A One-To-One Centre Visit With Little Skool-House Today To Enjoy A Special Fee Rebate!',
  performer: {
    '@type': 'PerformingGroup',
    name: 'Little Skool-House'
  },
  organizer: {
    '@type': 'Organization',
    name: 'Little Skool-House',
    url: 'https://www.littleskoolhouse.com'
  }
};

export const APP_ID = '1010244996049605';
export const RECAPTCHA_KEY = '6Ldql_AdAAAAAPlGMaFriz-D-Nm95siGmWXs1FDW';
export const AllowedDomians = ["com", "org", "sg", "uk", "in", "my", "ca", "fr", "edu"];