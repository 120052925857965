import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../../layout/layout';
import Meals from '../../images/objects/difference-le/meals.png';
import Award from '../../images/objects/difference-le/award.png';
import HealthyPreSchool from '../../images/objects/difference-le/healthy-pre-school.png';
import YoutubeVideo from '../../components/common/YoutubeVideo/YoutubeVideo';

export const query = graphql`
query ($slugId: ID!) {
  cms {
    page(id: $slugId, idType: URI) {
      our_nutritious_meals_cn {
        language
        pageHeader
        metaTitle
        metaUrl
        metaDescription
        detailsSection {
          sectionDescription
          sectionTitle
        }
      }
    }
  }
}`
const OurMeals = ({ pageContext: { language, preview }, data }) => {

  const custom = {
    english: {
      breadCrumb: [
        { label: 'Our Centres' },
        { label: 'Our Nutritious Meals' }
      ],
    },
    chinese: {
      breadCrumb: [
        { label: '我们的中心' },
        { label: '我们的营养膳食' }
      ],

    }
  }
  let dataList = {};
  if(!preview) {
    dataList = data?.cms?.page?.our_nutritious_meals_cn
  } else {
    dataList = data?.our_nutritious_meals_cn;
  }
  if(!dataList) {
    return '';
  }

  return (
    <>
        <Layout
          bannerClass="our-difference"
          heading={dataList.pageHeader}
          breadCrumbs={custom[language].breadCrumb}
          language={language}
          metaData={{
            url: dataList.metaUrl,
            title: dataList.metaTitle,
            description: dataList.metaDescription
          }}
          noFooter={preview}
          noBanner={preview}
          noHeader={preview}
        >
          <div className="learning-environment-container">
            <div className="top-section">
              <div className="img-section">
                <img src={Meals} alt="Meals" />
              </div>

              <div className="text">
                <h3>
                  {dataList.detailsSection[0].sectionTitle}
                </h3>
                <p>
                  {dataList.detailsSection[0].sectionDescription}
                </p>
              </div>
            </div>
            <div className="top-section top-section-right">
              <div className="img-section-mobile">
                <img src={Award} alt="Meals" />
              </div>


              <div className="text">
                <h3>
                  {dataList.detailsSection[1].sectionTitle}
                </h3>
                <p>
                  {dataList.detailsSection[1].sectionDescription}
                </p>
                <p className="hps-img">
                  <img src={HealthyPreSchool} alt="Healthy Pre School" />
                </p>
              </div>
              <div className="img-section">
                <img src={Award} alt="Meals" />
              </div>
            </div>
            <div className="bottom-section-video">
              <YoutubeVideo
                title="Our Meals Video"
                youtubeVideoId="W2Be4gtv72U"
                fullscreen={false}
                autoPlay={0}
              />
            </div>
          </div>
        </Layout>
    </>
  );
};

export default OurMeals;
