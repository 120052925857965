import React, { useState, Fragment } from 'react';
import Layout from '../../layout/layout';
import AdmissionsImg from '../../images/objects/centers-admission/admission.png';
import './admissions.scss';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet-async';

const positionDetails = [
  <>
    Book a<br />
    Center Tour
  </>,
  <>
    Complete the
    <br />
    registration
  </>,
  <>Confirmation &amp; Orientation</>
];

const positionContent = [
  <p>
    Visit our centre to find out if our preschool is the right fit your child.
    You will be asked to complete a non-obligatory pre-enrolment form during
    your visit. This is solely used as a record of your visit and for us to make
    further follow-ups where necessary.
  </p>,
  <>
    <p>
      To confirm your child’s registration at our school, the following
      transactions need to be made at your selected Little Skool-House centre:
      <ul>
        <li>
          Deposit one (1) month school fees. Amount is refundable with one (1)
          month’s notice
        </li>
        <li>
          Pay Registration Fee. Amount is non-refundable. Cheque or credit card
          (MasterCard/Visa) payment is accepted.
        </li>
      </ul>
    </p>
    <p>
      Enrolment forms and documents required must also be submitted at least two
      weeks before your child’s enrolment date. Guidelines on required documents
      will be given along with enrolment forms.
    </p>
    <p>
      Should there be no available slots for your child in your chosen centre,
      you may choose to be on our waitlist or consider the next nearest centre.
    </p>
  </>,
  <p>
    Once registration is complete, we’ll send you a welcome letter inviting you
    to attend our orientation session. Here, you get to meet our teachers and
    learn about the things you need to prepare for your child’s first day in
    school. Welcome to The Little Skool-House!
  </p>
];

const Admissions = () => {
  const [position, setPosition] = useState(0);

  return (
    <Layout
      bannerClass="admissions"
      heading="Admissions"
      breadCrumbs={[{ label: 'Our Centres' }, { label: 'Admissions' }]}
      metaData={{
        url: 'https://www.littleskoolhouse.com/our-centres/admissions',
        title: 'Read Our Admission Policy Here | Little Skool-House',
        description: `Start your child's exciting learning journey here at Little Skool-House. Visit this page for our admission policy and find out more about enrolling your child.`
      }}
    >
      <div className="admissions-container">
        <p>
          We believe that every child should have access to quality education.
          That is why we make it our mission to provide quality early childhood
          education for all by setting up our schools in choice locations across
          Singapore. We also partner government ministries, tertiary
          institutions, hospitals and private corporations to provide childcare
          services in the workplace. Through these workplace centres, we give
          working parents peace of mind in knowing that their little wonders are
          well taken care of during working hours and are receiving a quality
          early childhood education.
        </p>
        <h2>Admissions Policy</h2>
        <div className="top-section">
          <div className="img-section">
            <img src={AdmissionsImg} alt="Admissions" />
          </div>
          <div className="text-section">
            Applying for enrolment at The Little Skool-House can be done in 3
            easy steps. Enrolments are open all year round, depending on
            availability. Registration can be done up to 12 months before your
            child’s intended enrolment date.
          </div>
        </div>
        <div className="selector-section">
          <div className="lines">
            {positionDetails.map((text, posNum) => (
              <Fragment key={posNum}>
                <div
                  className={`number-box ${posNum <= position &&
                    'box-selected'}`}
                  onClick={() => setPosition(posNum)}
                >
                  <div className="number">
                    <span>{posNum + 1}</span>
                  </div>
                </div>
                {posNum < 2 && (
                  <div
                    className={`line ${posNum <= position && 'line-selected'}`}
                  />
                )}
              </Fragment>
            ))}
          </div>
          <div className="text-section">
            {positionDetails.map((text, posNum) => (
              <div
                className={`text ${posNum <= position && 'text-selected'}`}
                key={posNum}
              >
                {text}
              </div>
            ))}
          </div>
        </div>
        <div className="bottom-section">
          {/* <div className="row">
            <div className="number-box">
              <div className="number">
                <span>1</span>
              </div>
            </div>
            <p>
              At The Little Skool-House, we believe that the best way to know if
              we are the right preschool for your child is to make a visit to
              our centre. Join us at our centre to understand what makes us
              different and why we are the right preschool for your child.
            </p>
          </div> */}
          {positionContent[position]}
          {/* <div className="btn-section"> */}
            <>
              <div className="yellow-funky-button">
              <span className="hs-cta-wrapper" id="hs-cta-wrapper-1b59f843-eb42-469c-8411-004cd7665bbb" style={{ justifyContent: 'center', display: 'flex' }}><span
                className="hs-cta-nodehs-cta-1b59f843-eb42-469c-8411-004cd7665bbb" id="hs-cta-1b59f843-eb42-469c-8411-004cd7665bbb">
                <a
                  onClick={() => (typeof window !== "undefined") && window && window.hbspt && window.hbspt.cta && window.hbspt.cta.load(21698896, '1b59f843-eb42-469c-8411-004cd7665bbb', { "useNewLoader": "true", "region": "na1" })}
                  href="https://cta-redirect.hubspot.com/cta/redirect/21698896/1b59f843-eb42-469c-8411-004cd7665bbb">
                  <img
                    className="hs-cta-img" id="hs-cta-img-1b59f843-eb42-469c-8411-004cd7665bbb" style={{ display: 'none' }}
                    src="https://no-cache.hubspot.com/cta/default/21698896/1b59f843-eb42-469c-8411-004cd7665bbb.png"
                    alt="Book aTour "
                  />
                  Book a Tour
                </a>
              </span>
                {/* <script charset="utf-8" src="https://js.hscta.net/cta/current.js"></script>
    <script
        type="text/javascript"> {hbspt.cta.load(21698896, '1b59f843-eb42-469c-8411-004cd7665bbb', { "useNewLoader": "true", "region": "na1" })} </script> */}
              </span>
              </div>
            </>
          {/* </div> */}
        </div>
      </div>
    </Layout>
  );
};

export default Admissions;
