import React from 'react';
import Layout from '../../layout/layout';
import Graphic1 from '../../images/objects/chosen-preschool/graphic-1.png';
import Graphic2 from '../../images/objects/chosen-preschool/graphic-2.png';
import Graphic3 from '../../images/objects/chosen-preschool/graphic-3.png';
import Graphic4 from '../../images/objects/chosen-preschool/graphic-4.png';
import Graphic5 from '../../images/objects/chosen-preschool/graphic-5.png';
import { EPSection } from '../../pages/our-curriculum/4-6-years-literacy-based-curriculum';
import '../../pages/our-difference/chosen-preschool-by-top-institutions.scss';

const sections = [
  {
    text: {
      heading: `新加坡政府部门`,
      content: (
        <ul>
          <li>教育部</li>
          <li>新加坡社会及家庭发展部</li>
        </ul>
      )
    },
    graphic: Graphic1
  },
  {
    text: {
      heading: `主要大专院校`,
      content: (
        <ul className="right-sided">
          <li>新加坡国立大学</li>
          <li>义安理工学院</li>
          <li>共和理工学院</li>
          <li>淡马锡理工学院</li>
        </ul>
      )
    },
    graphic: Graphic2
  },
  {
    text: {
      heading: `医疗机构`,
      content: (
        <ul>
          <li>亚历山大医院</li>
          <li>邱德拔医院</li>
          <li>竹脚妇幼医院</li>
          <li>新加坡中央医院</li>
        </ul>
      )
    },
    graphic: Graphic3
  },
  {
    text: {
      heading: `银行`,
      content: (
        <ul className="right-sided">
          <li>华侨银行</li>
          <li>星展银行</li>
        </ul>
      )
    },
    graphic: Graphic4
  },
  {
    text: {
      heading: `其他企业伙伴关系`,
      content: (
        <ul>
          <li>新加坡野生动物保育集团</li>
        </ul>
      )
    },
    graphic: Graphic5
  }
];

const ChosenPreschoolCn = () => {
  return (
    <Layout
      bannerClass="our-difference"
      heading="顶尖机构选择的幼儿园"
      breadCrumbs={[
        { label: '我们的与众不同' },
        { label: '顶尖机构选择的幼儿园' }
      ]}
      metaData={{
        url:
          'https://www.littleskoolhouse.com/zh/womendeyuzhongbutong/dingjianjigouxuanzedeyoueryuan',
        title: '获得名校青睐的学前教育中心 | 小小学庭',
        description:
          '小小学庭是新加坡最好的学前教育中心之一，为儿童提供学前教育。今天就查看我们的中心所在地列表。'
      }}
    >
      <div className="learning-environment-container chosen-ps-container">
        <h2 className="intro">
          我们是新加坡最具盛名机构的首选的幼儿园，
          <br className="desktop-only" />
          为他们的员工孩子提供优质的学前教育。
        </h2>
        {sections.map((section, i) => (
          <EPSection section={section} right={i % 2 !== 0} key={i} />
        ))}
      </div>
    </Layout>
  );
};

export default ChosenPreschoolCn;
