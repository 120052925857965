import React from 'react';
import { Link } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Layout from '../../layout/layout';
import ScheduleVisitForm from '../../components/common/ScheduleVisitForm/ScheduleVisitForm';
import './open-house.scss';
import { RECAPTCHA_KEY, schema } from '../../config/appConfig';
import ReactMarkdown from 'react-markdown';
import PromoSection from '../../components/common/PromoSection/promoSection';

const OpenHouse = ({ pageContext }) => {
  const { title, slug, data } = pageContext;
  if (!data || !slug || !(data && data.centres && data.centres.length) ) {
    return '';
  }
  const { centres } = data;
  let north = centres.filter(item => item.location === 'North');
  north.unshift({ location: 'North' });
  let central = centres.filter(item => item.location === 'Central');
  central.unshift({ location: 'Central' });
  let east = centres.filter(item => item.location === 'East');
  east.unshift({ location: 'East' });
  let west = centres.filter(item => item.location === 'West');
  west.unshift({ location: 'West' });

 


  const centerList = north.concat(central, east, west);

  return (
    <Layout
      schemaMarkup={schema}
      bannerClass="enquiry"
      heading={data.title}
      breadCrumbs={[{ label: 'Enquiry' }, { label: title }]}
      noBanner={true}
      metaData={{
        url: `https://www.littleskoolhouse.com/enquiry/${slug}`,
        title: `See Our Limited Time Promotions | Little Skool-House`,
        description:
          `Interested in enrolling your child with Little Skool-House for a rewarding learning journey? Don't miss out on our ongoing limited-time promotions here!`
      }}
    >
      <div className="open-house-container">
        <header>
          {/* <Link to="/">
            <div className="logo-section">
              <img src={LSHLogo} alt="LSH Logo" />
            </div>
          </Link> */}
          {/* {data.title && <h1>{title}</h1>} */}
          {data.subTitle && <h2>{data.subTitle}</h2>}
          {data.promotionTitle && (
            <p className="promo">{data.promotionTitle}</p>
          )}
        </header>
        <div className="main-content">
          <PromoSection />
          <div className="right-section">
            <h2>Register your interest</h2>
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
              <ScheduleVisitForm type="open-house" centerList={centerList} />
            </GoogleReCaptchaProvider>
          </div>
        </div>
        <footer>
          <h2>Terms &amp; Conditions</h2>
          <div className="content">
            <ReactMarkdown source={data.privacyPolicy} />
          </div>
        </footer>
      </div>
    </Layout>
  );
};

export default OpenHouse;
