// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-our-awards-js": () => import("./../src/pages/about-us/our-awards.js" /* webpackChunkName: "component---src-pages-about-us-our-awards-js" */),
  "component---src-pages-about-us-our-successes-js": () => import("./../src/pages/about-us/our-successes.js" /* webpackChunkName: "component---src-pages-about-us-our-successes-js" */),
  "component---src-pages-about-us-our-vision-js": () => import("./../src/pages/about-us/our-vision.js" /* webpackChunkName: "component---src-pages-about-us-our-vision-js" */),
  "component---src-pages-about-us-the-little-skool-house-js": () => import("./../src/pages/about-us/the-little-skool-house.js" /* webpackChunkName: "component---src-pages-about-us-the-little-skool-house-js" */),
  "component---src-pages-enquiry-careers-js": () => import("./../src/pages/enquiry/careers.js" /* webpackChunkName: "component---src-pages-enquiry-careers-js" */),
  "component---src-pages-enquiry-contact-us-js": () => import("./../src/pages/enquiry/contact-us.js" /* webpackChunkName: "component---src-pages-enquiry-contact-us-js" */),
  "component---src-pages-enquiry-faqs-js": () => import("./../src/pages/enquiry/faqs.js" /* webpackChunkName: "component---src-pages-enquiry-faqs-js" */),
  "component---src-pages-enquiry-partnership-js": () => import("./../src/pages/enquiry/partnership.js" /* webpackChunkName: "component---src-pages-enquiry-partnership-js" */),
  "component---src-pages-giftredemption-js": () => import("./../src/pages/giftredemption.js" /* webpackChunkName: "component---src-pages-giftredemption-js" */),
  "component---src-pages-gwl-thanks-js": () => import("./../src/pages/gwl-thanks.js" /* webpackChunkName: "component---src-pages-gwl-thanks-js" */),
  "component---src-pages-gwl-trial-thanks-js": () => import("./../src/pages/gwl-trial-thanks.js" /* webpackChunkName: "component---src-pages-gwl-trial-thanks-js" */),
  "component---src-pages-mothers-day-event-20-may-2-index-js": () => import("./../src/pages/mothers-day-event-20-may-2/index.js" /* webpackChunkName: "component---src-pages-mothers-day-event-20-may-2-index-js" */),
  "component---src-pages-mothers-day-event-20-may-index-js": () => import("./../src/pages/mothers-day-event-20-may/index.js" /* webpackChunkName: "component---src-pages-mothers-day-event-20-may-index-js" */),
  "component---src-pages-mothers-day-event-6-may-2-index-js": () => import("./../src/pages/mothers-day-event-6-may-2/index.js" /* webpackChunkName: "component---src-pages-mothers-day-event-6-may-2-index-js" */),
  "component---src-pages-mothers-day-event-6-may-index-js": () => import("./../src/pages/mothers-day-event-6-may/index.js" /* webpackChunkName: "component---src-pages-mothers-day-event-6-may-index-js" */),
  "component---src-pages-others-privacy-policy-js": () => import("./../src/pages/others/privacy-policy.js" /* webpackChunkName: "component---src-pages-others-privacy-policy-js" */),
  "component---src-pages-our-centres-admissions-js": () => import("./../src/pages/our-centres/admissions.js" /* webpackChunkName: "component---src-pages-our-centres-admissions-js" */),
  "component---src-pages-our-curriculum-4-6-years-literacy-based-curriculum-js": () => import("./../src/pages/our-curriculum/4-6-years-literacy-based-curriculum.js" /* webpackChunkName: "component---src-pages-our-curriculum-4-6-years-literacy-based-curriculum-js" */),
  "component---src-pages-our-curriculum-curriculum-overview-js": () => import("./../src/pages/our-curriculum/curriculum-overview.js" /* webpackChunkName: "component---src-pages-our-curriculum-curriculum-overview-js" */),
  "component---src-pages-our-curriculum-enrichment-programme-js": () => import("./../src/pages/our-curriculum/enrichment-programme.js" /* webpackChunkName: "component---src-pages-our-curriculum-enrichment-programme-js" */),
  "component---src-pages-our-curriculum-infant-3-years-relationships-based-curriculum-js": () => import("./../src/pages/our-curriculum/infant-3-years-relationships-based-curriculum.js" /* webpackChunkName: "component---src-pages-our-curriculum-infant-3-years-relationships-based-curriculum-js" */),
  "component---src-pages-our-difference-chosen-preschool-by-top-institutions-js": () => import("./../src/pages/our-difference/chosen-preschool-by-top-institutions.js" /* webpackChunkName: "component---src-pages-our-difference-chosen-preschool-by-top-institutions-js" */),
  "component---src-pages-our-difference-curriculum-highlights-js": () => import("./../src/pages/our-difference/curriculum-highlights.js" /* webpackChunkName: "component---src-pages-our-difference-curriculum-highlights-js" */),
  "component---src-pages-our-difference-high-proficiency-in-english-and-mandarin-js": () => import("./../src/pages/our-difference/high-proficiency-in-english-and-mandarin.js" /* webpackChunkName: "component---src-pages-our-difference-high-proficiency-in-english-and-mandarin-js" */),
  "component---src-pages-our-difference-our-award-winning-educators-js": () => import("./../src/pages/our-difference/our-award-winning-educators.js" /* webpackChunkName: "component---src-pages-our-difference-our-award-winning-educators-js" */),
  "component---src-pages-our-difference-why-choose-the-little-skool-house-js": () => import("./../src/pages/our-difference/why-choose-the-little-skool-house.js" /* webpackChunkName: "component---src-pages-our-difference-why-choose-the-little-skool-house-js" */),
  "component---src-pages-preview-index-js": () => import("./../src/pages/preview/index.js" /* webpackChunkName: "component---src-pages-preview-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-tot-talks-tot-leader-lyz-js": () => import("./../src/pages/tot-talks/tot-leader-lyz.js" /* webpackChunkName: "component---src-pages-tot-talks-tot-leader-lyz-js" */),
  "component---src-pages-tot-talks-tot-leader-mg-js": () => import("./../src/pages/tot-talks/tot-leader-mg.js" /* webpackChunkName: "component---src-pages-tot-talks-tot-leader-mg-js" */),
  "component---src-pages-tot-talks-tot-leader-mn-js": () => import("./../src/pages/tot-talks/tot-leader-mn.js" /* webpackChunkName: "component---src-pages-tot-talks-tot-leader-mn-js" */),
  "component---src-pages-tot-talks-tot-leader-tyx-js": () => import("./../src/pages/tot-talks/tot-leader-tyx.js" /* webpackChunkName: "component---src-pages-tot-talks-tot-leader-tyx-js" */),
  "component---src-templates-about-us-cn-js": () => import("./../src/templates/about-us-cn.js" /* webpackChunkName: "component---src-templates-about-us-cn-js" */),
  "component---src-templates-articles-article-article-new-js": () => import("./../src/templates/articles/article/articleNew.js" /* webpackChunkName: "component---src-templates-articles-article-article-new-js" */),
  "component---src-templates-articles-article-list-js": () => import("./../src/templates/articles/articleList.js" /* webpackChunkName: "component---src-templates-articles-article-list-js" */),
  "component---src-templates-articles-articles-js": () => import("./../src/templates/articles/articles.js" /* webpackChunkName: "component---src-templates-articles-articles-js" */),
  "component---src-templates-centres-centre-details-js": () => import("./../src/templates/centres/centre-details.js" /* webpackChunkName: "component---src-templates-centres-centre-details-js" */),
  "component---src-templates-centres-centres-js": () => import("./../src/templates/centres/centres.js" /* webpackChunkName: "component---src-templates-centres-centres-js" */),
  "component---src-templates-centres-our-learning-env-js": () => import("./../src/templates/centres/our-learning-env.js" /* webpackChunkName: "component---src-templates-centres-our-learning-env-js" */),
  "component---src-templates-centres-our-nutritious-meal-js": () => import("./../src/templates/centres/our-nutritious-meal.js" /* webpackChunkName: "component---src-templates-centres-our-nutritious-meal-js" */),
  "component---src-templates-enquiry-contact-us-cn-js": () => import("./../src/templates/enquiry/contact-us-cn.js" /* webpackChunkName: "component---src-templates-enquiry-contact-us-cn-js" */),
  "component---src-templates-enquiry-faqs-cn-js": () => import("./../src/templates/enquiry/faqs-cn.js" /* webpackChunkName: "component---src-templates-enquiry-faqs-cn-js" */),
  "component---src-templates-happenings-happenings-js": () => import("./../src/templates/happenings/happenings.js" /* webpackChunkName: "component---src-templates-happenings-happenings-js" */),
  "component---src-templates-happenings-promotion-detail-promotion-detail-cms-js": () => import("./../src/templates/happenings/promotion-detail/promotion-detail-cms.js" /* webpackChunkName: "component---src-templates-happenings-promotion-detail-promotion-detail-cms-js" */),
  "component---src-templates-happenings-story-story-details-js": () => import("./../src/templates/happenings/story/StoryDetails.js" /* webpackChunkName: "component---src-templates-happenings-story-story-details-js" */),
  "component---src-templates-happenings-video-details-video-details-cms-js": () => import("./../src/templates/happenings/video-details/video-details-cms.js" /* webpackChunkName: "component---src-templates-happenings-video-details-video-details-cms-js" */),
  "component---src-templates-home-home-js": () => import("./../src/templates/home/home.js" /* webpackChunkName: "component---src-templates-home-home-js" */),
  "component---src-templates-open-house-open-house-cn-js": () => import("./../src/templates/open-house/open-house-cn.js" /* webpackChunkName: "component---src-templates-open-house-open-house-cn-js" */),
  "component---src-templates-open-house-open-house-js": () => import("./../src/templates/open-house/open-house.js" /* webpackChunkName: "component---src-templates-open-house-open-house-js" */),
  "component---src-templates-our-curriculum-4-6-years-literacy-based-curriculum-cn-js": () => import("./../src/templates/our-curriculum/4-6-years-literacy-based-curriculum-cn.js" /* webpackChunkName: "component---src-templates-our-curriculum-4-6-years-literacy-based-curriculum-cn-js" */),
  "component---src-templates-our-curriculum-curriculum-overview-cn-js": () => import("./../src/templates/our-curriculum/curriculum-overview-cn.js" /* webpackChunkName: "component---src-templates-our-curriculum-curriculum-overview-cn-js" */),
  "component---src-templates-our-curriculum-enrichment-programme-cn-js": () => import("./../src/templates/our-curriculum/enrichment-programme-cn.js" /* webpackChunkName: "component---src-templates-our-curriculum-enrichment-programme-cn-js" */),
  "component---src-templates-our-curriculum-infant-3-years-relationships-based-curriculum-cn-js": () => import("./../src/templates/our-curriculum/infant-3-years-relationships-based-curriculum-cn.js" /* webpackChunkName: "component---src-templates-our-curriculum-infant-3-years-relationships-based-curriculum-cn-js" */),
  "component---src-templates-our-difference-chosen-preschool-by-top-institutions-cn-js": () => import("./../src/templates/our-difference/chosen-preschool-by-top-institutions-cn.js" /* webpackChunkName: "component---src-templates-our-difference-chosen-preschool-by-top-institutions-cn-js" */),
  "component---src-templates-our-difference-high-proficiency-in-english-and-mandarin-cn-js": () => import("./../src/templates/our-difference/high-proficiency-in-english-and-mandarin-cn.js" /* webpackChunkName: "component---src-templates-our-difference-high-proficiency-in-english-and-mandarin-cn-js" */),
  "component---src-templates-our-difference-our-award-winning-educators-cn-js": () => import("./../src/templates/our-difference/our-award-winning-educators-cn.js" /* webpackChunkName: "component---src-templates-our-difference-our-award-winning-educators-cn-js" */),
  "component---src-templates-our-difference-why-choose-the-little-skool-house-cn-js": () => import("./../src/templates/our-difference/why-choose-the-little-skool-house-cn.js" /* webpackChunkName: "component---src-templates-our-difference-why-choose-the-little-skool-house-cn-js" */),
  "component---src-templates-schedule-a-visit-schedule-a-visit-cn-js": () => import("./../src/templates/schedule-a-visit/schedule-a-visit-cn.js" /* webpackChunkName: "component---src-templates-schedule-a-visit-schedule-a-visit-cn-js" */),
  "component---src-templates-schedule-a-visit-schedule-a-visit-js": () => import("./../src/templates/schedule-a-visit/schedule-a-visit.js" /* webpackChunkName: "component---src-templates-schedule-a-visit-schedule-a-visit-js" */),
  "component---src-templates-thank-you-thank-you-js": () => import("./../src/templates/thank-you/thank-you.js" /* webpackChunkName: "component---src-templates-thank-you-thank-you-js" */),
  "component---src-templates-thank-you-thank-you-new-js": () => import("./../src/templates/thank-you/thank-you-new.js" /* webpackChunkName: "component---src-templates-thank-you-thank-you-new-js" */),
  "component---src-templates-tot-pages-index-js": () => import("./../src/templates/tot-pages/index.js" /* webpackChunkName: "component---src-templates-tot-pages-index-js" */)
}

