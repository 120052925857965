import React, { Fragment, useState } from 'react';
import Layout from '../../layout/layout';
import './articles.scss';
import { navigate, Link } from 'gatsby';
import Dropdown from '../../components/common/Dropdown/Dropdown';
import Learning from '../../images/objects/expert-opinion/learning.png';
import Behaviour from '../../images/objects/expert-opinion/behavior.png';
import Play from '../../images/objects/expert-opinion/play.png';
import HealthAndNutrition from '../../images/objects/expert-opinion/health-nutrition.png';
import Parenting from '../../images/objects/expert-opinion/parenting.png';

const Articles = ({
  pageContext: {
    articles,
    allCategories,
    selectedCategoryUrl,
    selectedSubCategoryUrl,
    subCategoryMetaData
  }
}) => {
  const subCategoryHandler = (categoryUrl, subCategoryUrl) =>
    navigate(`articles/${categoryUrl}/${subCategoryUrl}`);

  const subCategoriesMobile = allCategories.find(
    c => c && c?.url === selectedCategoryUrl
  ).subCategories;

  const categoryLabel = allCategories?.find(c => c?.url === selectedCategoryUrl)
    ?.label;
  const subCategoryLabel = allCategories
    ?.find(c => c?.url === selectedCategoryUrl)
    ?.subCategories.find(sub => sub.url === selectedSubCategoryUrl)?.label;
  const currentCategory = allCategories.find(
    item => item?.url == selectedCategoryUrl
  );
  const mapIcon = icon => {
    if (icon == 'parenting') return Parenting;
    if (icon == 'learning') return Learning;
    if (icon == 'behavior') return Behaviour;
    if (icon == 'play') return Play;
    if (icon == 'health-nutrition') return HealthAndNutrition;
  };
  return (
    <Layout
      bannerClass="expert-opinion"
      heading="Expert Opinion"
      breadCrumbs={[
        { label: 'Articles', link: '/articles' },
        { label: categoryLabel },
        { label: subCategoryLabel }
      ]}
      metaData={{
        url: `https://www.littleskoolhouse.com/articles/${selectedCategoryUrl}/${selectedSubCategoryUrl}`,
        title: `${subCategoryMetaData && subCategoryMetaData.title}`,
        description: `${subCategoryMetaData && subCategoryMetaData.description}`,
      }}
    >
      <div className="expert-opinion-container">
        <p>
          Little Skool-House’s team of award-winning educators providing expert
          opinion on preschool education, parenting tips, health and nutrition,
          and family relationships.
        </p>
        <div className="article-selection-section">
          <div className="selector">
            <Fragment key={currentCategory.url}>
              <div className="category-option">
                <img
                  src={mapIcon(currentCategory.icon)}
                  style={{ width: '30px', margin: '0px 4px -8px' }}
                />
                <label htmlFor={currentCategory.url}>
                  {currentCategory.label}
                </label>
              </div>
              <div
                className="sub-menu-container"
                style={{ marginLeft: '30px' }}
              >
                {currentCategory.url === selectedCategoryUrl &&
                  currentCategory.subCategories.map(subCat => (
                    <div className="sub-category-option" key={subCat.url}>
                      <input
                        type="radio"
                        id={subCat.url}
                        name="subCategory"
                        checked={selectedSubCategoryUrl === subCat.url}
                        value={subCat.url}
                        onChange={e => {
                          subCategoryHandler(
                            selectedCategoryUrl,
                            e.target.value
                          );
                        }}
                      />
                      <label htmlFor={subCat.url}>{subCat.label}</label>
                    </div>
                  ))}
              </div>
            </Fragment>

            <div className="mobile-only-strict">
              <div className="category-dropdown">
                <Dropdown
                  defaultValue={selectedSubCategoryUrl}
                  onChange={subCatUrl => {
                    subCategoryHandler(selectedCategoryUrl, subCatUrl);
                  }}
                  options={
                    subCategoriesMobile &&
                    subCategoriesMobile.map(op => ({
                      key: op.url,
                      value: op.label
                    }))
                  }
                  hideSelectedOption
                />
              </div>
            </div>
          </div>
          <div className="articles-list">
            {articles.length > 0 &&
              articles.map(({ title, slug, article, type }) => (
                <Link to={`/articles/${slug}`} key={slug}>
                  <div className={`article-card ${selectedSubCategoryUrl}`}>
                    <div className="text">
                      <h4>{title}</h4>
                      {article && article.author && (
                        <p className="author">
                          <span>By </span>
                          {article.author}
                        </p>
                      )}
                      {article && article.description && (
                        <p className="subtitle">{article.description}</p>
                      )}
                    </div>
                    <div className="plus-sign">
                      <span>+</span>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Articles;
