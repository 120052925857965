import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './OurDifferenceTilesSlider.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ImageSlider = ({ images }) => {
  let bannerSlider = null;
  const [modal, setModal] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(null);
  const [sliderTrigger, setsliderTrigger] = useState(null);

  useEffect(() => {
    currentSlide && bannerSlider.slickGoTo(currentSlide);
  }, [currentSlide, sliderTrigger]);

  const temps = images.map((tile, index) => (
    <div className="tile" key={index}>
      <div
        onClick={() => {
          setModal(true);
          setCurrentSlide(index);
          setsliderTrigger(!sliderTrigger);
        }}
        className={`tile-content`}
        style={{
          cursor: 'pointer',
          background: `url(${tile.sourceUrl})`,
          backgroundSize: 'cover',
          width: '100%',
          paddingBottom: '100%',
          borderRadius: '20px'
        }}
      ></div>
    </div>
  ));

  const SliderPhotos = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true
    };

    return (
      <Slider ref={c => (bannerSlider = c)} {...settings}>
        {images.map((image, index) => (
          <div className="img-holder" key={index}>
            <img
              src={image.sourceUrl}
              alt={`The-Little-Skool-House-25th-Anniversary_${index}`}
            />
          </div>
        ))}
      </Slider>
    );
  };

  function click(e) {
    e.stopPropagation();
  }

  return (
    <div className="our-diff-tiles-slider-container">
      {modal && (
        <div className="tile-modal" onClick={() => setModal(null)}>
          <div className="tile-modal-container" onClick={e => click(e)}>
            <div
              className="close-btn"
              role="button"
              onClick={() => setModal(null)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="slider-div">
              <SliderPhotos />
            </div>
          </div>
        </div>
      )}
      <div className="tiles tablet-and-desktop-only">{temps}</div>

      <div className="clearfix"></div>
      <Slider
        className="mobile-slider mobile-only-strict"
        dots={true}
        arrows={false}
      >
        {temps}
      </Slider>
    </div>
  );
};

export default ImageSlider;
