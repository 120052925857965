import React from "react";
import Layout from "../../layout/layout";
import Graphic1 from "../../images/objects/curriculum-overview/graphic-1.png";
import Graphic2 from "../../images/objects/curriculum-overview/graphic-2.png";
import Graphic3 from "../../images/objects/curriculum-overview/graphic-3.png";
import Graphic4 from "../../images/objects/curriculum-overview/graphic-4.png";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { mandarinAcquisitionPageLinkCn } from "../../config/appConfig";

const CurriculumOverviewCn = () => {
  const images = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativePath: {
            in: [
              "objects/curriculum-overview/photo-1.jpg"
              "objects/curriculum-overview/photo-2.jpg"
            ]
          }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const [image1, image2] = images.allFile.edges.map(
    ({ node: { childImageSharp } }) => childImageSharp.fluid
  );

  return (
    <Layout
      bannerClass="curriculum-details"
      heading="课程框架概述"
      breadCrumbs={[
        { label: "我们的课程" },
        { label: "课程框架概述" },
      ]}
      metaData={{
        url:
          "https://www.littleskoolhouse.com/zh/womendekecheng/kechengkuangjiagaishu",
        title: "关系为本课程 | 小小学庭",
        description:
          "以建立关系为主的课程有利于促进学龄前儿童的认知发展。今天就为您的孩子报读我们在新加坡的任何一所学习中心。",
      }}
    >
      <div className="learning-environment-container">
        <div className="top-section">
          <div className="img-section">
            <img src={Graphic1} alt="Graphics 1" />
          </div>
          <div className="text">
            <h3>幼儿教育趋势的引领者</h3>
            <p>
              身为新加坡幼儿教育趋势的引领者，我们的课程是由幼儿培育署的专家和先驱研究者所设计。我们的教学法则是以世界顶尖大学的儿童发展与教育的临床研究为依据。
            </p>
          </div>
        </div>
        <div className="top-section top-section-right">
          <div className="img-section-mobile">
            <img src={Graphic2} alt="Meals" />
          </div>
          <div className="text">
            <h3>全面发展与精通英语及华语</h3>
            <p>
              小小学庭的课程针对幼儿全面发展的需求，为学龄前儿童提供国际认可的学习经验。通过符合婴幼儿到学前教育需求而特别研发的教学法，我们的课程将各种规划融入其
              <a
                href="/zh/womendekecheng/zengyikecheng"
                target="blank"
                style={{ color: "#4ec7e2", textDecoration: "underline" }}
              >
                {" "}
                教学框架{" "}
              </a>{" "}
              重点如下：
            </p>

            <ul className="right-sided">
              <li>通过深化的华语习得教学法达到精通英语和华语</li>
              <p>
                <Link to={mandarinAcquisitionPageLinkCn}>
                  <button className="yellow-funky-button">更多详情</button>
                </Link>
              </p>
              <li>培养对数学及科学等学术科目的热情与兴趣。</li>
              <li>建立自信，发展社交与情感能量。</li>
              <li>为每个幼儿进小学做好准备。</li>
            </ul>
          </div>
          <div className="img-section">
            <img src={Graphic2} alt="Meals" />
          </div>
        </div>
        <div className="top-section">
          <div className="img-section">
            <img src={Graphic3} alt="Graphics 1" />
          </div>
          <div className="text">
            <h3>小学形成课程</h3>
            <p>我们的小学形成课程标志性支柱如下：</p>
            <ul>
              <li>方案与创新项目</li>
              <li>深化华语习得教学法</li>
              <li>结构式阅读与识字能力的评估</li>
            </ul>
            <p>我们的目的是为幼儿日后的小学及未来生活做好准备。</p>
          </div>
        </div>
        <div className="top-section top-section-right">
          <div className="img-section-mobile">
            <img src={Graphic4} alt="Meals" />
          </div>
          <div className="text">
            <h3>新加坡顶尖机构所选择的幼儿园</h3>
            <p>
              能成为
              <a
                href="/zh/womendeyuzhongbutong/dingjianjigouxuanzedeyoueryuan"
                style={{ color: "#4ec7e2", textDecoration: "underline" }}
                target="blank"
              >
                {" "}
                新加坡最负盛名的各机构为他们自己的孩子所选择的幼儿园{" "}
              </a>
              小小学庭引以为荣。这些机构包括了教育部、社会及家庭发展部、新加坡国立大学等
            </p>
          </div>
          <div className="img-section">
            <img src={Graphic2} alt="Meals" />
          </div>
        </div>
        <div className="photo-section">
          <div className="img-section">
            <Img fluid={image1} alt="Picture 1" />
          </div>
          <div className="text">
            <h2>出生至3岁</h2>
            <h4>关系为本课程（出生至3岁）</h4>
            <p>
              关系为本课程相信：建立幼儿的信心是每个新加坡幼儿学习之旅的起点，更是课程的基础。
            </p>
            <Link
              to="/zh/womendekecheng/guanxiweibenkechengchushengzhi3sui"
              className="btn-section"
            >
              <button className="yellow-funky-button">更多详情</button>
            </Link>
          </div>
        </div>
        <div className="photo-section photo-section-right">
          <div className="img-section">
            <Img fluid={image2} alt="Picture 2" />
          </div>
          <div className="text">
            <h2>4至6岁</h2>
            <h4>文学化课程（4至6岁）</h4>
            <p>文学化课程的目标是全面发展，让幼儿的学习与生活都能获得成功。</p>
            <Link
              to="/zh/womendekecheng/wenxuehuakecheng4zhi6sui"
              className="btn-section"
            >
              <button className="yellow-funky-button">更多详情 </button>
            </Link>
          </div>
        </div>
        <div className="subtext text-center content-text-center text-head">
          <h2> 小小学庭课程框架 </h2>
          <p>
            <br />
            <a href="/zh">小小学庭</a>{" "}
            非常重视为您的孩子打造一个坚若盘石的基础。为了帮助孩子的终生学习做好准备，我们经验丰富的学前教师遵循以教育部及幼儿培育署制订的教学目标为依据而设计的课程框架。我们的学前课程框架的目标是培养幼儿对学习的喜爱，并确保他们从婴幼儿教育到学前教育都能维持这样的学习热忱。
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default CurriculumOverviewCn;
