import React, { useState, useEffect, useRef } from 'react';
import DDClosed from '../../../images/icons/dropdown-closed.png';
import CaretBlue from '../../../images/icons/caret-blue.png';
import './Dropdown.scss';


const Dropdown = ({
  options,
  onChange,
  defaultValue,
  resetValue,
  theme = '',
  hideSelectedOption,
  bottomLine = false,
  type,
  category,
  isGwlDropdown,
  dropDownForm
}) => {
  const [menuOpen, setMenuOpen] = useState('');
  const menuRef = useRef(null);
  const defValue = options && options[0]?.label ? options[0]?.label : options[0]?.value;
  const [selectedItem, setSelectedItem] = useState(
    defaultValue || defValue
  );
  let ddMenu;
  resetValue && resetValue !== selectedItem && setSelectedItem(resetValue);

  const toggleDropdown = (event) => {
    if(event.target !== menuRef.current){
      setMenuOpen(false);//if clicked element (target) not the drop down close it
    }
    else{
      setMenuOpen(!menuOpen);
    }
  };


  // document.addEventListener('click',toggleDropdown())





    // const closeMenu = () => {
    //   setMenuOpen(false);
    //   console.log("reached closed");
    //   if (typeof document !== `undefined`){
    //     document.removeEventListener('click', () => {
    //         closeMenu();
    //     });
    //   }
    // };
  
    // const openMenu = () => {
    //   console.log("reached on click open");
    //   setMenuOpen(true);
    //   if (typeof document !== `undefined`){
    //     document.addEventListener('click', () => {
    //       closeMenu();
    //     });
    //   }
    // };


  

  const changeHandler = (key, notClickable = false) => {
    if (!notClickable) {
      const opt = options.find(o => o.key === key);
      if (opt) setSelectedItem(opt);
      if(dropDownForm && opt?.value) {
        onChange(opt.value);
      } else {
        onChange(key);
      }
    }
    setMenuOpen(false)
  };

  const getOptions = option => (
    <div
      className={`option ${(option.notClickable && !isGwlDropdown) ? 'sub-category' : ''}`}
        key={option.key} 
        onClick={() => changeHandler(option.key, option.notClickable)} 
        style={{
        background:
        ((type == 'open-house' || type == 'open-house-cn') && option.selectedCenter && 'lightGrey') ||
        (category === "gwl" && '#e7f1f9'),
        textDecoration: option.strikeThroughOption && 'line-through',
        cursor: option.strikeThroughOption && 'no-drop'
      }}
    >
      {option.label ? option.label : option.value}
    </div>
  );

  return (
    <div className={`dropdown-container ${theme}`}>
      <div
        className="select"
        onClick ={(e) => toggleDropdown(e)}
        ref={menuRef}
        onFocus={(e) => toggleDropdown(e)}
        style={{ background: '#e7f1f9' }}
      >
        {selectedItem.label || selectedItem.value || selectedItem}
        <div className="dropdown-img">
        <img
          src={theme === 'white' ? CaretBlue : DDClosed}
          className={menuOpen ? 'open' : ''}
          alt="Button"
          width="12"
          height="15"
        />
        </div>
      </div>
      {menuOpen ? (
        <div
          className="options"
          ref={elem => {
            ddMenu = elem;
          }}
        >
          {options &&
            options.map(option =>
              hideSelectedOption
                ? option.key !== selectedItem.value && getOptions(option)
                : getOptions(option)
            )}
        </div>
      ): null}
    </div>
  );
};

export default Dropdown;
