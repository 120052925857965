// const promotions = [
//   {
//     slug: 'promotion-1',
//     date: '2020-04-01T15:43:00+08:00',
//     title: 'Open House Promotions',
//     description:
//       'Using our Unique Storytelling Method, we develop and teach: English, Mandarin, Math, Science, Motor Skills and Creative Expression.',
//     banner_image: {
//       image: {
//         desktop: 'static/happenings-photos/home-banner.jpg',
//         mobile: 'static/happenings-photos/home-banner.jpg'
//       },
//       alt_text: 'home banner',
//       description: ''
//     },
//     catalog_image: {
//       image: {
//         desktop: 'static/happenings-photos/about-lsh-kids.jpg',
//         mobile: 'static/happenings-photos/about-lsh-kids-mobile.jpg'
//       },
//       alt_text: 'about lsh kids',
//       description: ''
//     },
//     overview: [
//       {
//         id: 1583308021633,
//         type: 1,
//         data: {
//           content: {
//             blocks: [
//               {
//                 key: '8meup',
//                 text: 'School Fee Promotions',
//                 type: 'unstyled',
//                 depth: 0,
//                 inlineStyleRanges: [{ offset: 0, length: 21, style: 'BOLD' }],
//                 entityRanges: [],
//                 data: {}
//               },

//               {
//                 key: '6lij4',
//                 text:
//                   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//                 type: 'unstyled',
//                 depth: 0,
//                 inlineStyleRanges: [],
//                 entityRanges: [],
//                 data: {}
//               },

//               {
//                 key: '8l6hd',
//                 text:
//                   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
//                 type: 'unstyled',
//                 depth: 0,
//                 inlineStyleRanges: [],
//                 entityRanges: [],
//                 data: {}
//               },

//               {
//                 key: 'e4t55',
//                 text:
//                   'It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
//                 type: 'unstyled',
//                 depth: 0,
//                 inlineStyleRanges: [],
//                 entityRanges: [],
//                 data: {}
//               }
//             ],
//             entityMap: {}
//           }
//         }
//       }
//     ],
//     category: ''
//   }
// ];
const promotions = [
  // {
  //   slug: '',
  //   link: '/enquiry/limited-time-promotion',
  //   cardTitle: 'Exclusive Rebate',
  //   category: 'promotions',
  //   catalog_image: {
  //     image: {
  //       desktop: 'static/happenings-photos/open-house.jpg',
  //       mobile: 'static/happenings-photos/open-house.jpg'
  //     },
  //     alt_text: 'expat living',
  //     description: ''
  //   }
  // },
  {
    slug: 'sibling-discount',
    link: '/happenings/promotions/sibling-discount',
    cardTitle: 'Sibling Discount',
    title: 'Sibling Discount',
    title2: '',
    bannerImage: '',
    category: 'promotions',
    catalog_image: {
      image: {
        desktop: 'static/happenings-photos/sibling-discount.jpeg',
        mobile: 'static/happenings-photos/sibling-discount.jpeg'
      },
      alt_text: 'expat living',
      description: ''
    },
    table: false,
    promoCodes: [
      {
        price: '200',
        message: 'Sibling’s Monthly Full Day Fee'
      },
      {
        price: '100',
        message: 'Sibling’s Monthly Half Day Fee'
      }
    ],
    termsAndConditions: {
      title: 'Sibling',
      conditions: [
        {
          list: 'The sibling discount is applied to the older sibling',
          sublist: []
        },
        {
          list:
            'Newly enrolled twins will also be able to enjoy the sibling discount',
          sublist: []
        },
        {
          list:
            'The sibling discount is valid for as long as the elder sibling is enrolled with The Little Skool-House. The discount stops when the elder sibling graduate',
          sublist: []
        },
        {
          list:
            'This promotion is not applicable for children enrolled in our Workplace Centres',
          sublist: []
        },
        {
          list:
            'Please indicate your interest in utilizing the sibling discount when completing our enquiry form, under field “Additional Notes”',
          sublist: []
        },
        {
          list:
            'The Little Skool-House reserves the right to amend the terms & conditions of the Refer-a-Friend discount from time to time without prior notice and without any liability',
          sublist: []
        }
      ]
    }
  },
  {
    slug: 'refer-a-friend',
    link: '/happenings/promotions/refer-a-friend',
    cardTitle: 'Refer a Friend',
    title: 'Refer a friend to The Little Skool-House',
    title2: ' & receive up to $921 in benefits!',
    bannerImage: '',
    category: 'promotions',
    catalog_image: {
      image: {
        desktop: 'static/happenings-photos/refer-a-friend.jpeg',
        mobile: 'static/happenings-photos/refer-a-friend.jpeg'
      },
      alt_text: 'expat living',
      description: ''
    },
    table: true,
    promoCodes: [],
    termsAndConditions: {
      title: 'Refer-a-Friend',
      conditions: [
        {
          list:
            'The Refer-a-Friend discount is open to all existing The Little Skool-House parents with effect from 1 July 2019',
          sublist: []
        },
        {
          list:
            'Referee must be new parents who have not previously enrolled their child in any of The Little Skool-House centres',
          sublist: []
        },
        {
          list:
            'The referral reward ($300 off one month’s school fees, $300 worth of shopping vouchers and uniforms) will be awarded to the referrer after 3 calendar months from referee’s enrolment date',
          sublist: []
        },
        {
          list:
            'The referee reward of $300 off one month’s school fees will be awarded to the referee on the second month after enrolment',
          sublist: []
        },
        {
          list:
            'The reward is applicable for school fees of ONE child with The Little Skool-House and must not be used in conjunction with other promotions or discounts, unless otherwise stated',
          sublist: []
        },
        {
          list:
            'The rewards stated are fixed and are non-negotiable and non-exchangeable for cash',
          sublist: []
        },
        {
          list:
            'The Little Skool-House reserves the right to amend the terms & conditions of the Refer-a-Friend discount from time to time without prior notice and without any liability',
          sublist: []
        },
        {
          list: 'The referral is considered successful when:',
          sublist: [
            'The referrer informs and introduces his/her referral to the Centre Principal at the point of registration',
            'The referee completes and submits his/her enrolment application and the child is enrolled with The Little Skool-House for at least 2 calendar months',
            'The referee indicates the referrer’s name on the pre-enrolment form or the enrolment form'
          ]
        },
        {
          list:
            'Referrers will not be entitled to receive the reward if the referee:',
          sublist: [
            'Is an existing The Little Skool-House child who is promoting to the next level',
            'Is a sibling of an existing child with The Little Skool-House',
            'Is an existing The Little Skool-House child transferring to a different The Little Skool-House centre OR has withdrawn from The Little Skool-House within the last 6 months'
          ]
        }
      ]
    }
  }
];
const press = [
  {
    title: '30 preschools & kindergartens reviewed',
    category: 'press',
    date: '2020-03-19T00:00:00+08:00',
    author: 'Expat Living SG',
    catalog_image: {
      image: {
        desktop: 'static/happenings-photos/expat-living.png',
        mobile: 'static/happenings-photos/expat-living.png'
      },
      alt_text: 'expat living',
      description: ''
    },
    link:
      'https://expatliving.sg/handy-guide-best-preschools-kindergartens-in-singapore/'
  },
  {
    title:
      'Ultimate Guide to the Best Preschools and Kindergartens in Singapore',
    category: 'press',
    date: '2020-02-18T00:00:00+08:00',
    author: 'Sassy Mama SG',
    catalog_image: {
      image: {
        desktop: 'static/happenings-photos/sassy-mama.png',
        mobile: 'static/happenings-photos/sassy-mama.png'
      },
      alt_text: 'sassy mama',
      description: ''
    },
    link: 'https://www.sassymamasg.com/learn-best-preschools-in-singapore/'
  },
  {
    title:
      'Top Preschools In Singapore - Best Programs Available For The Little Ones!',
    category: 'press',
    date: '2020-01-22T00:00:00+08:00',
    author: 'Little Steps Singapore',
    catalog_image: {
      image: {
        desktop: 'static/happenings-photos/little-steps.png',
        mobile: 'static/happenings-photos/little-steps.png'
      },
      alt_text: 'little steps',
      description: ''
    },
    link:
      'https://www.littlestepsasia.com/singapore/articles/learn/top-preschools-singapore'
  },
  {
    title:
      'This FULLY Bilingual Preschool Aims To Drive Kid’s Confidence – And The Learning Follows',
    category: 'press',
    date: '2020-01-13T00:00:00+08:00',
    author: 'The Finder',
    catalog_image: {
      image: {
        desktop: 'static/happenings-photos/the-finders-logo.png',
        mobile: 'static/happenings-photos/the-finders-logo.png'
      },
      alt_text: 'little steps',
      description: ''
    },
    link:
      'https://www.thefinder.com.sg/kids/fully-bilingual-preschool-makes-kids-secure-learning-follows/'
  },
  {
    title: '小小学庭新校舍迎小孩',
    category: 'press',
    date: '2020-01-02T00:00:00+08:00',
    author: 'Lianhe Wanbao',
    catalog_image: {
      image: {
        desktop: 'static/happenings-photos/lianhe-wanbao.jpg',
        mobile: 'static/happenings-photos/lianhe-wanbao.jpg'
      },
      alt_text: 'lianhe wanbao',
      description: ''
    },
    link: '/docs/LHWB-article.pdf'
  },
  {
    title: '新加坡保健集团于欧南社区医院内设托儿所',
    category: 'press',
    date: '2020-01-03T00:00:00+08:00',
    author: 'Lianhe Zaobao',
    catalog_image: {
      image: {
        desktop: 'static/happenings-photos/lianhe-zanbao.png',
        mobile: 'static/happenings-photos/lianhe-zanbao.png'
      },
      alt_text: 'lianhe zanbao',
      description: ''
    },
    link: '/docs/LHZB-article.pdf'
  },
  {
    title: '5 Ways Teachers at This Preschool Bring Learning to Life',
    category: 'press',
    date: '2018-12-20T00:00:00+08:00',
    author: 'Sassy Mama SG',
    catalog_image: {
      image: {
        desktop: 'static/happenings-photos/sassy-mama.png',
        mobile: 'static/happenings-photos/sassy-mama.png'
      },
      alt_text: 'sassy mama',
      description: ''
    },
    link:
      'https://www.sassymamasg.com/learn-little-skool-house-preschool-bilingual/'
  },
  {
    title:
      'The Little Skool-House: An Award-Winning Premium Preschool with 25 Years of Driving Discovery',
    category: 'press',
    date: '2020-10-22T00:00:00+08:00',
    author: 'Sassy Mama SG',
    catalog_image: {
      image: {
        desktop: 'static/happenings-photos/sassy-mama.png',
        mobile: 'static/happenings-photos/sassy-mama.png'
      },
      alt_text: 'sassy mama',
      description: ''
    },
    link:
      'https://www.sassymamasg.com/learn-little-skool-house-fees-awards-25-years/'
  },
  {
    title:
      '6 things you need to know about the Little Skool-House preschool at Downtown East',
    category: 'press',
    date: '2019-03-08T00:00:00+08:00',
    author: 'Young Parents',
    catalog_image: {
      image: {
        desktop: 'static/happenings-photos/young-parents.png',
        mobile: 'static/happenings-photos/young-parents.png'
      },
      alt_text: 'young parents',
      description: ''
    },
    link:
      'https://www.youngparents.com.sg/education/6-things-you-need-to-know-about-new-little-skool-house-preschool-downtown-east/'
  },
  {
    title:
      '16 Spoil-Market Preschools In Singapore To Sign Your Child Up For In Advance',
    category: 'press',
    date: '2020-03-16T00:00:00+08:00',
    author: 'The Smart Local Singapore',
    catalog_image: {
      image: {
        desktop: 'static/happenings-photos/smart-local.jpg',
        mobile: 'static/happenings-photos/smart-local.jpg'
      },
      alt_text: 'sassy mama',
      description: ''
    },
    link: 'https://thesmartlocal.com/read/preschools-singapore/'
  }
];

const events = [
  // {
  //   slug: 'the-little-skool-house-open-days',
  //   link: '/enquiry/open-house',
  //   version: 158330817921,
  //   date: '',
  //   published: true,
  //   title: 'The Little Skool-House Open House',
  //   description:
  //     'Schedule a one-to-one centre visit for our October 2020 Open House today to enjoy a special fee rebate! T&Cs apply.',
  //   comments: '',
  //   banner_image: {
  //     image: {
  //       desktop: 'static/happenings-photos/home-banner.jpg',
  //       mobile: 'static/happenings-photos/home-banner.jpg'
  //     },
  //     alt_text: 'home banner',
  //     description: ''
  //   },
  //   catalog_image: {
  //     image: {
  //       desktop: 'static/happenings-photos/about-lsh-kids.jpg',
  //       mobile: 'static/happenings-photos/about-lsh-kids-mobile.jpg'
  //     },
  //     alt_text: 'about lsh kids',
  //     description: ''
  //   },
  //   overview: [
  //     {
  //       id: 1583308021633,
  //       type: 1,
  //       data: {
  //         content: {
  //           blocks: [
  //             {
  //               key: '6lij4',
  //               text:
  //                 'Stay tuned for participating centres and exclusive promotion for the Open Day',
  //               type: 'unstyled',
  //               depth: 0,
  //               inlineStyleRanges: [],
  //               entityRanges: [],
  //               data: {}
  //             }
  //           ],
  //           entityMap: {}
  //         }
  //       }
  //     }
  //   ],
  //   created_at: '2020-03-04T15:49:39+08:00',
  //   updated_at: '0001-01-01T08:00:00+08:00',
  //   created_by: 'inthizar',
  //   updated_by: '',
  //   tags: [],
  //   category: 'events'
  // },
  {
    slug: '25th-anniversary-celebrations',
    link: '/happenings/events/25th-anniversary-celebrations',
    version: 158330817921,
    date: '2019-10-5',
    published: true,
    title: '25th Anniversary Celebrations',
    description: 'Celebrating #3millionachievements',
    comments: '',
    banner_image: {
      image: {
        desktop: 'static/happenings-photos/home-banner.jpg',
        mobile: 'static/happenings-photos/home-banner.jpg'
      },
      alt_text: 'home banner',
      description: ''
    },
    catalog_image: {
      image: {
        desktop: 'static/happenings-photos/25th-anniversary.jpg',
        mobile: 'static/happenings-photos/25th-anniversary.jpg'
      },
      alt_text: 'lsh 25th anniversary',
      description: ''
    },
    overview: [
      {
        id: 1583308021633,
        type: 1,
        data: {
          content: {
            blocks: [
              {
                key: '6lij4',
                text:
                  'Stay tuned for participating centres and exclusive promotion for the Open Day',
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {}
              }
            ],
            entityMap: {}
          }
        }
      }
    ],
    created_at: '2020-03-04T15:49:39+08:00',
    updated_at: '0001-01-01T08:00:00+08:00',
    created_by: 'inthizar',
    updated_by: '',
    tags: [],
    category: 'events'
  }
];

const videos = [
  {
    title: 'Kiss 92 DJ, Maddy Barber goes to Little Skool-House',
    link: 'kiss-92-dj-maddy-barber-goes-to-little-school-house',
    subTitle: [
      'Find out how we achieve 93% proficiency in Mandarin and English!'
    ],
    category: 'videos',
    catalog_image: {
      image: {
        desktop: 'static/video-gallery/video-7.jpg',
        mobile: 'static/video-gallery/video-7.jpg'
      },
      alt_text: 'Maddy Barber’s Journey'
    },
    data: {
      videoId: 'XJ2hF-_mtpk',
      description: `Did you know that 93% of our preschoolers are highly proficient in Mandarin and English? Without the use of rote memorisation, the Little Skool-House curriculum allows children to understand concepts easily through the Builders and Innovation project. Using an investigative approach, they find out answers to various problems, all using Mandarin!`
    }
  },
  {
    title: 'Getting our Preschoolers Ready for Primary School',
    link: 'getting-our-preschoolers-ready-for-primary-school',
    subTitle: [
      'From infancy to K2, our curriculum prepares our children for the future.'
    ],
    category: 'videos',
    catalog_image: {
      image: {
        desktop: 'static/video-gallery/video-8.jpg',
        mobile: 'static/video-gallery/video-8.jpg'
      },
      alt_text: 'Preschoolers Ready for Primary School'
    },
    data: {
      videoId: 'hXqvGyys0Hg',
      description: `Our relationship-based curriculum starts our children on the road to independence and self confidence, driving a strong sense of learning during the ‘golden window’ of growth. With a robust framework that focuses on bilingual education, our children are prepared for primary school through programme such as the Buliders and Innovation project, Chinese theatre pedagogy as well as encouraging healthy journal writing habits.`
    }
  },
  {
    title: `How does the digestive system work? Hear what our children say.`,
    pageTitle:
      'How does the digestive system work? <br/> Hear what our children say.',
    link: 'how-does-the-digestive-system-work',
    category: 'videos',
    subTitle: [
      'Our kids explain the journey of food inside the human body in Mandarin!'
    ],
    catalog_image: {
      image: {
        desktop: 'static/video-gallery/video-2.jpg',
        mobile: 'static/video-gallery/video-2.jpg'
      },
      alt_text: 'digestive system',
      description: ''
    },
    data: {
      videoId: 'K4a-oYpiJTs',
      description: `Through our Builders & Innovation Programme, our children gain critical thinking and problem-solving skills in Science and Math. By giving them the freedom to inquire about interesting subject matters, they are encouraged to conduct in-depth analysis of their queries amongst themselves. Coupled with the literacy skills acquired through our various activities, our children can explain their new found discoveries in Mandarin!`
    }
  },
  {
    title: 'Developing children’s love for numbers',
    link: 'developing-children’s-love-for-numbers',
    subTitle: [
      'How number bonds make math fun to learn at The Little',
      'Skool-House.'
    ],
    category: 'videos',
    catalog_image: {
      image: {
        desktop: 'static/video-gallery/video-1.jpg',
        mobile: 'static/video-gallery/video-1.jpg'
      },
      alt_text: 'love for numbers'
    },
    data: {
      videoId: 'SnE4xQ5EHTI',
      description: `We make Math fun to learn! Among a wide variety of techniques, number bonds is one technique that make use of number bonds which helps students split numbers easily. Such visualisation gives our kids a sense of easy understanding, preparing them for their primary school journey!`
    }
  },
  {
    title: 'Believe it or not, our kids love broccoli!',
    link: 'believe-it-or-not-our-kids-love-broccoli',
    category: 'videos',
    subTitle: [
      'Find out why  this vegetable is now their favorite at The Little Skool-House'
    ],
    catalog_image: {
      image: {
        desktop: 'static/video-gallery/video-3.jpg',
        mobile: 'static/video-gallery/video-3.jpg'
      },
      alt_text: 'kids love broccoli',
      description: ''
    },
    data: {
      videoId: 'B5BVApLJsiw',
      description: `Lovingly prepared by our in-house chefs in every centre, our children are treated to a wide variety of dishes. Ranging from traditional favourites to more adventurous recipes, our meals help to broaden their palates. Our children go for seconds because our healthy meals are that delicious – yes, even meals with broccoli!`
    }
  },
  {
    title: 'Mandarin as a second language!',
    link: 'mandarin-as-a-second-language',
    category: 'videos',
    subTitle: [
      'Curious how foreign preschoolers learn Mandarin?',
      'Have a listen!'
    ],
    catalog_image: {
      image: {
        desktop: 'static/video-gallery/video-4.jpg',
        mobile: 'static/video-gallery/video-4.jpg'
      },
      alt_text: 'second language',
      description: ''
    },
    data: {
      videoId: 'aC3IvwOKEzc',
      description: `Mastering Mandarin as a second language comes easy for our preschoolers as we make learning fun through performance art & craft sessions. Active communication between peers and their teachers in class helps to build a strong foundation. You’ll even catch them breaking out into a song, mid-lesson!`
    }
  },
  {
    title: 'The makings of a future Dr Seuss!',
    link: 'the-makings-of-a-future-dr-Seuss',
    category: 'videos',
    subTitle: [
      'When kids have fun learning language, they become creative with words.'
    ],
    catalog_image: {
      image: {
        desktop: 'static/video-gallery/video-5.jpg',
        mobile: 'static/video-gallery/video-5.jpg'
      },
      alt_text: 'future Dr Seuss',
      description: ''
    },
    data: {
      videoId: 'iFa7lqLHLeQ',
      description: `When we make it fun for our kids, creativity comes naturally. Hear our future poets string words starting with "CH" in the most surprising and inventive way!`
    }
  },
  {
    title: 'How do we get our toddlers to eat their vegetables?',
    link: 'how-do-we-get-our-toddlers-to-eat-their-vegetables',
    category: 'videos',
    subTitle: [
      'All it takes are these 3 essential ingredients: Great recipes + Encouraging teachers + Supportive peers.'
    ],
    catalog_image: {
      image: {
        desktop: 'static/video-gallery/video-6.jpg',
        mobile: 'static/video-gallery/video-6.jpg'
      },
      alt_text: 'eat vegetables',
      description: ''
    },
    data: {
      videoId: 'W2Be4gtv72U',
      description: `With great recipes on hand, our meals are nutritious and delicious. Certified under the Healthy Meals in Preschool Programme (HMPP), we are empowered to provide healthier meals for our children, without compromising on taste!`
    }
  },
  {
    title: 'Making the Difference with The Extra Devotion',
    link: 'making-the-difference-with-the-extra-devotion',
    subTitle: ['In-depth learning fun with field trips.'],
    category: 'videos',
    catalog_image: {
      image: {
        desktop: 'static/video-gallery/video-9.jpg',
        mobile: 'static/video-gallery/video-9.jpg'
      },
      alt_text: 'In-depth learning fun with field trips'
    },
    data: {
      videoId: 'EMEf1_LLHIs',
      disclaimer: `*Video was shot prior to the Covid-19 situation*`,
      description: `Committed to making a difference with the extra devotion, we take pride in providing high quality care and attention to all children. With field trips, learning is brought to life and our storybook  drive learning experience is further enhanced. Through nourishing their little minds, our children gain knowledge and experience success while having a fun and rewarding childhood.`
    }
  }
];

const articleTypes = {
  PARENTING: 'parenting',
  LEARNING: 'learning',
  BEHAVIOR: 'behaviour',
  PLAY: 'play',
  HEALTH_NUTRITION: 'health-and-nutrition'
};
const articlesCategories = [
  {
    label: `Learning`,
    url: `learning`,
    icon: articleTypes.LEARNING,
    subCategories: [
      {
        label: `Infant Years`,
        url: `infant-years`,
        key: 'learning-infant-years',
        articles: [
          {
            slug: 'safe-learning-environment',
            title: 'Safe Learning Environment for Infants and Toddlers',
            type: articleTypes.LEARNING,
            author: 'Coreen Soh',
            description: `A safe learning environment nurtures skills vital for a child’s development. Learn how Little Skool-House creates this space and how you can do the same at home.`,
            metaData: {
              title: 'Safe Learning Environment for Infants and Toddlers',
              description: `A safe learning environment nurtures skills vital for a child’s development. Learn how Little Skool-House creates this space and how you can do the same at home.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'lish2',
                        text: 'What is a Conducive Learning Environment',
                        type: 'header-one',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'Defined as the physical environment with specific settings and surroundings, a learning environment can vary according to a child’s age, needs, learning objectives, and more. For example, infants and toddlers are at the formative age where they should be in a conducive environment that encourages floor exploration, fosters a sense of personal safety, and closeness for healthy social and emotional development.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lish2',
                        text:
                          'Why Is Your Child’s Learning Environment Important',
                        type: 'header-two',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'Many may think that an environment is insignificant in its ability to affect one’s well-being, learning ability, and emotions. However, as parents, teachers, and educators, we are aware of how children’s learning is influenced by their environment and immediate surroundings.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text: `Capable of influencing how a child feels, behaves, grows, and develops, a conducive learning environment is the second teacher – a resource that is structured to promote positive behaviour. This is especially so for infants and toddlers, explaining the emphasis preschools like [Little Skool-House](/) (LSH) places on its [learning environment](/our-centres/our-learning-environment).`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lish2',
                        text:
                          'What Type of Learning Environment is Best for Your Child',
                        type: 'header-two',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'Preschoolers, even infants, are active learners – everything is a stimulus. Besides being a place where basic tasks are learned to ensure self-sufficiency, a safe learning environment fosters a sense of security. This safe and secure bubble allows children to learn better as their mistakes and failures are interpreted as stepping stones to success.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'So how does Little Skool-House create a conducive environment, and what attempts can you make to mimic it?',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lish2',
                        text: '1. Promote Learning: Keeping Children Engaged',
                        type: 'header-three',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'Influencing all aspects of development, children learn a great deal during play. Its main purpose is to provide children with the opportunity to take risks without fearing serious consequences or failure.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'At our early childhood learning centres in Singapore, we encourage:',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'e654e',
                        text: 'Exploration and experimentation',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'e654e',
                        text: 'Risk taking',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'e654e',
                        text: 'The use of all senses',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'By exposing LSH kids to such direct experiences from a young age, they gain meaningful knowledge on the world around them. For children under 2 years old, these novice experiences lead to rapid brain development.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'Speak to your toddler’s educator about their interaction with various stimuli. With the newfound information, gauge how much is too much for your child, and apply that to your home.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 109,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 109,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 109,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 109,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text: `*Watch how the exposure to a conducive learning environment before the age of two can have a dramatic effect on a child’s development  [here](https://www.youtube.com/watch?v=hXqvGyys0Hg).*`,
                        type: 'mark-down',
                        externalLink: true,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ltc',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lish2',
                        text: '2. Language-Rich Environment',
                        type: 'header-three',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'By creating a space that exposes toddlers to various modes of communication such as, reading and speaking, expressive and receptive vocabulary is acquired.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'At our early childhood learning centres in Singapore, we:',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'e654e',
                        text:
                          'Create a print-rich learning environment e.g label objects that are used daily, display materials with print',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'e654e',
                        text:
                          'Read to kids daily to drive strong learning by triggering interest and curiosity',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text: `These methods that have been integrated into the Little Skool-House’s [curriculum](/our-curriculum/curriculum-overview) has led to 93% of LSH children being [Highly Proficient in both English and Mandarin](/our-difference/high-proficiency-in-english-and-mandarin). With three core pillars that lead to language comprehension and expression, having an assortment of print materials at home can create a language-rich environment.`,
                        externalLink: false,
                        type: 'mark-down',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text: `*Watch how our LSH children get creative with words when they have fun learning languages  [here](https://www.youtube.com/watch?v=iFa7lqLHLeQ)*`,
                        type: 'mark-down',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lish2',
                        text: '3. Consistent Routine',
                        type: 'header-three',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'Though variation and stimulation are necessary elements of healthy development, children, especially those under the age of two, need a predictable environment that strengthens the building blocks of confidence.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'At our early childhood learning centres in Singapore, we:',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'e654e',
                        text:
                          'Set a consistent routine to allow the child to ‘know what’s next’',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'e654e',
                        text: 'Respond positively and consistently to them',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'e654e',
                        text: 'Organise materials',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'Consistency builds confidence as kids are aware of what is happening around them. At home, try setting rules and routines to mimic our preschool practices.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lish2',
                        text: 'Parental Advice',
                        type: 'header-two',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text: `[Enrolling your child](/enquiry/contact-us) in an esteemed preschool is always the better option, as transforming a home into a safe learning environment is a tricky feat. It is also best to seek the advice of trained  [educators](/our-difference/our-award-winning-educators)  if your child is under the age of two, where neural network formation is at its peak.`,
                        type: 'mark-down',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 113,
                            style: 'color-rgb(54,57,53)'
                          },
                          {
                            offset: 0,
                            length: 113,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 113,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 113,
                            style: 'fontfamily-Open Sans", sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'However, if there is one thing that applies to all parents, it would be the bond you have with your child. At Little Skool-House, the learning environment of those under three coincides with a relationships-based curriculum that fosters a sense of community and trust. Our educators are caring leaders that children can rely on, and the same culture should be cultivated at home. Though we would all love for kids to grab concepts and read overnight, realistic expectations and goals should be set – reaching small, attainable targets are joyous occasions.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 129,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 129,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 129,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 129,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      }
                    ]
                  }
                }
              }
            ]
          },
          {
            slug: 'confidence-and-social-skills',
            title: 'Confidence & Social Skills: Understanding Curriculum Goal',
            type: articleTypes.LEARNING,
            author: 'Coreen Soh',
            description: `A safe learning environment nurtures skills vital for a child’s development. Learn how Little Skool-House creates this space and how you can do the same at home.`,
            metaData: {
              title:
                'Confidence & Social Skills: Understanding Curriculum Goal',
              description: `Social skills are vital for the development of all kids. Learn about the preschool curriculum implemented by the Little Skool-House to achieve social growth.`
            },
            overview: [
              {
                id: 1585116935690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'dsklh1',
                        text: 'The Development of Social Skills: Ages 1 to 2',
                        type: 'header-one',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dskl',
                        text: `Questions on whether a child is too young to be independent or on the right track for [developing a high proficiency in languages](/our-difference/high-proficiency-in-english-and-mandarin) are always at the forefront of parents’ minds. The ability to socialise, is another aspect of a child’s overall development that requires just as much attention, as the acquisition of social skills is a milestone in and of itself.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh2',
                        text: 'What are Social Skills',
                        type: 'header-two',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dskl',
                        text: `Vital for effective interaction and communication, being socially skilful lies on a spectrum. Ranging from verbal communication to non-verbal facial expressions, it lays the foundation for basic comprehension of social cues. From displaying good manners and being considerate to being open with personal wants and needs, children should develop these skills with age.`,
                        type: 'unstyled',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh2',
                        text: 'Why are Social Skills Important for Children',
                        type: 'header-two',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `The way we behave and portray ourselves in social situations is pivotal in how we are perceived by the public and maintain positive peer relationships. A study published by the Journal of Personality and Social Psychology also indicated that having social skills and confidence made children more adept in Mathematics and reading. Although 60% of these academic achievements were attributed to genetics, the remaining 40% were due to environmental factors that both parents and teachers can manipulate.`,
                        type: 'unstyled',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'wsklh2',
                        text:
                          'How to Improve & Teach Social Skills to Children',
                        type: 'header-two',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `If looking to build critical social intelligence, it is important to observe and attempt to make changes when the child is young – 18 months to 2 years old. According to a licensed psychologist, Heather Wittenberg, parents should start to notice social milestones when their kids reach the 1-year mark. Some of these changes include:`,
                        type: 'unstyled',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `**Age 1:** Recognising familiar faces and  beginning basic communication triggered by the social cues they have picked up. They are also wary of strangers, and at times excited to interact.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `**Age 2:** This is the age where kids begin socialising and defending their territories and belongings. They will seek out interaction from others, express a range of emotions, and act defiantly to get a gist of the consequences. They will also go through mood changes, which is a sign that they are attempting to control their feelings and impulses.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `To enable your child to achieve these milestones, most [preschool curriculum](/our-curriculum/curriculum-overview) in Singapore strives to give children the opportunity to adapt, empathise, and form meaningful bonds with others. The curriculum goals can be summarised into what is known as the four “Cs”:`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `1. Cooperation`,
                        type: 'header-three',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `The ability to offer help, share, negotiate, and take turns. These are socially acceptable behaviours that are accompanied by empathy and moral reasoning.`,
                        type: 'unstyled',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `2. Curiosity`,
                        type: 'header-three',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `Nurturing a thirst for knowledge allows for growth through learning and investigating their immediate surroundings – an essential skill for life and school.`,
                        type: 'unstyled',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `3. Communication`,
                        type: 'header-three',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `Freely expressing feelings and thoughts are crucial skills that prevent them from acting out.`,
                        type: 'unstyled',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `4. Confidence`,
                        type: 'header-three',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `Having confidence is a life-long skill that determines how a child feels about themselves and in relationships with others.`,
                        type: 'unstyled',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ltc',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `To achieve these four  preschool curriculum goals, [Little Skool-House](/) in Singapore focus on three key areas that have proven to provide children with the opportunity to grow socially in a safe environment.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `1. Relationships-Based Curriculum`,
                        type: 'header-three',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `Tapping onto a child’s “golden window” through rigorous reading sessions and academic activities, social and emotional intelligence, sensory, motor, and communicative skills are developed. With the establishment of a responsive relationship being the main focus of [relationships-based care in early childhood](/our-curriculum/infant%E2%80%933-years-relationships-based-curriculum), the curriculum encourages the formation of dependable relationships where kids experience security. Inculcating a sense of trust and safety boosts confidence in making mistakes, learning from them, and in turn, flourishing in social situations.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `2. Learning Environment`,
                        type: 'header-three',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `One way to encourage socialisation is to build communities within a safe [learning environment](/our-centres/our-learning-environment). At our [Little Skool-House centres](/our-centres/centres/all-centres), for example, the open space in the classrooms will allow for interaction with peers. Regardless of the form of conversation, they are crucial in fostering social and emotional intelligence. Conversations between children will be monitored by our [educators](/our-difference/our-award-winning-educators) to ensure that there are only positive interactions.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `3. Exercising Freedom of Choice`,
                        type: 'header-three',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `Allowing children to make their own decisions and take responsibility for their actions reap several benefits. Firstly, by allowing them to make choices, it fuels their independence and confidence, especially if the decision is received well. Bad decisions also allow them to reflect on their decision-making processes, preventing repetition in the future. Activities such as drama where active participation and interaction is required amongst peers will also improve their decision-making abilities.`,
                        type: 'unstyled',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `As part of building a strong language foundation without rote memorisation, the Pedagogy for Enhanced Mandarin Acquisition practised at the Little Skool-House encourages progressive language usage through immersive experiences, from daily to complex conversations, and journal writing exercises. These foster self-reflection and assessment, pivotal for the mature understanding of autonomy.`,
                        type: 'unstyled',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `Social skills are imperative for advancement through school, jobs, social situations, and life. There is much to be learnt from external influences in a social context. However, to ensure that the right skills are ingrained in kids from a young age, an effective preschool curriculum can do wonders in their early years. The Little Skool-House understands its benefit and takes on an advanced approach when educating their students – this has been proven effective over the years.`,
                        type: 'unstyled',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `References:`,
                        type: 'header-three',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `1. University of Texas at Austin (2018). Intellectual Curiosity and Confidence Help Children Take on Math and Reading. Retrieved from:`,
                        type: 'unstyled',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1dassl',
                        text: `[https://news.utexas.edu/2018/12/19/intellectual-curiosity-and-confidence-help-children-take-on-math-and-reading/](https://news.utexas.edu/2018/12/19/intellectual-curiosity-and-confidence-help-children-take-on-math-and-reading/)`,
                        type: 'mark-down',
                        externalLink: true,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {},
                        styleInline: { marginTop: '-10px' }
                      }
                    ]
                  }
                }
              }
            ]
          }
        ]
      },
      {
        label: `Toddler Years`,
        url: `toddler-years`,
        key: 'learning-toddler-years',
        articles: [
          {
            slug: 'cultivating-young-readers',
            title: 'Cultivating young readers',
            type: articleTypes.LEARNING,
            author: 'Coreen Soh',
            description: `Toddlers are natural do-ers. They use their five senses to learn intently and this includes putting things (like books) in their mouths. They are also attracted to bright colours, pictures and special features in the book (e.g. pop-ups, textures, pull-outs).`,
            metaData: {
              title: 'Cultivating Young Readers',
              description: `Reading is a lifelong habit and if cultivated well, opens up a huge window of possibilities for the child. See our tips on how you can do this with your child.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text:
                          '“My toddler doesn’t like to read, he only tears the pages.”',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 59,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 59,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 59,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 59,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 0,
                            length: 59,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '62dlq',
                        text:
                          '“My toddler chews the books so I have kept away all the books at home.”',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 71,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 71,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 71,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 71,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 0,
                            length: 71,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '3mqtp',
                        text: `These are some of the common woes of parents and most of us can relate to these situations. It is common knowledge that reading should start from young. However, the reality is that some of us do face [real challenges](/articles/managing-tantrums) in getting our toddler to sit down with a book.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ecmi7',
                        text:
                          'Toddlers are natural do-ers. They use their five senses to learn intently and this includes putting things (like books) in their mouths. They are also attracted to bright colours, pictures and special features in the book (e.g. pop-ups, textures, pull-outs). To get them interested in books, use board books that are easily held by the toddlers themselves, that they can fidget with, with thick pages as they do not tear easily when the toddlers turn the pages. This will minimize reading bloopers which discourages both parents and toddlers. Adults could also get the toddler’s interest by talking about the pictures, relate the pictures to a previous experience, holding the child’s hand to feel the textures in the books and trying the pull-outs together. Do not be discouraged if these pockets of interaction do not last very long as that is the nature of how toddlers learn but are important pre-reading activities that a toddler should be engaging in regularly.  ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 969,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 969,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 969,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 969,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ciq5a',
                        text:
                          "What activities can a toddler get involved in because they can't read yet? ",
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 75,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 75,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 75,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 75,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '27o42',
                        text: `There are many activities that a [toddler](/our-curriculum/infant–3-years-relationships-based-curriculum) can be involved in before reading actually happens. Pretending to read, flipping the pages, talking about the illustrations, are examples of such activities. First, we need to understand that [language development](/our-difference/high-proficiency-in-english-and-mandarin) starts from listening, speaking, reading then writing. Therefore, having lots of [conversations with the toddlers](/articles/meaningful-conversations-using-open-ended-questions-with-children) is really a good way to get them ready for language. They are receiving it but not using them yet because they are developmentally not ready. So talking to the toddlers sets the first language environment. They will try to model after the adults and imitate the sounds or phrases they hear. This is important as it will help them make the connection that another form of language is written and it can be found primarily in books around them.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '8nd4g',
                        text: `[Singing, playing and dramatizing](/articles/the-power-of-creative-movement-dance-and-drama) are activities that are considered as low-risk to children. This means that when children are engaged in these activities, they are not under any pressure. This is especially true for play as play is intrinsic in every child. Therefore, using these activities as a platform to learn language works best as the child is not put in any ‘I have to get it right” situation.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '2nel',
                        text:
                          'Parents can also pick books with topics that interest their children. For example, the child likes aeroplanes so parents could select books based on this subject or have stories that have aeroplanes in them. This encourages the child to read.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 242,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 242,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 242,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 242,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ltc',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '2avat',
                        text: `Creating [an environment that supports](/our-centres/our-learning-environment) reading`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '67d3q',
                        text: `Creating a print-rich environment is one of the first steps to support reading at home. A few things that can be done at home would be to label some of the furniture. Labels should be in lowercase as this is the natural form of words in books and reading materials. We should not be too concerned with upper case letters or any other grammar structures at the initial stage as it is the child’s interest that we are concerned with. The grammatical concepts would be incidentally picked up with exposure to language and [good role modelling](/our-difference/our-award-winning-educators).`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ab02t',
                        text:
                          'A print-rich environment would have interactive songs and rhyme charts visibly displayed, great selection of developmentally appropriate books, different kinds of reading materials like informational books, stories, newspapers etc.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 231,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 231,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 231,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 231,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '81t58',
                        text:
                          'There should also be materials for the child to doodle on. Put drawing tools like crayons, thick pencils, paints, paper etc. easily available to the child. These ‘doodling’ activities are pre-writing activities. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 212,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 212,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 212,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 212,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'a0t9j',
                        text:
                          'Beside a print-rich environment, there could also be a language expression corner where children have materials to dramatise or engage in pretend play. Puppets (finger puppets, hand puppets, paper bag puppets etc.), masks, head gears can be left with the story books to encourage children in extending their reading by dramatizing the story, pretending to be one of the characters or tell a story through the puppet. This makes reading more interesting and also helps children who are less confident to visit the reading experience.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 532,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 532,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 532,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 532,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1lhne',
                        text:
                          'In many homes where the day goes by in a frantic rush, it is helpful to create a corner at home called the reading corner. Give it a catchy name that children would love. Set aside time every day (15mins the most) where the whole family will go into that corner and read something. It could be shared reading time or individual reading. Though the toddler would not be able to read, they should be encouraged to pick up a book and look at the pictures and talk about it in their own capacity. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 493,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 493,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 493,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 493,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'd5m94',
                        text:
                          'This corner should be made as cozy as possible to invite the child in. Do ensure that the corner is child-friendly and child-safe as the child should be encouraged to go there any time of the day.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 196,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 196,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 196,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 196,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '9h8fc',
                        text: 'What’s the adult’s role in this?',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 32,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 32,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 32,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 32,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '2pgpc',
                        text:
                          'Adults act as important role models at home. Reading begins when the toddlers see their parents/siblings or other adults reading. When reading the newspapers, an adult should attempt to make it visible for the toddler. Read out the captions or tag lines aloud to the toddlers and draw their attention to the print. As toddlers are in the stage of imitating, engage their participation by giving them a book, magazine or papers to flip. Though they may not read, this is an important process that cultivates their reading habit for the future. During this process, the adult could facilitate by guiding the child to hold the book/reading material the right way up and turn the pages in the correct way. The toddler might not be able to do it straight away since their motor skills are still developing, especially if the pages are thin. It is alright and not necessary to force the toddler to do it.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 898,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 898,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 898,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 898,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '52c99',
                        text:
                          'Have books, magazines and reading materials easily available in the home environment so that the toddler can easily access them when they feel like it. Also, reading need not always start from books. The idea is to get the toddler to be interested in text from young so that they will love to read later. Text exists everywhere in the environment. We could draw their attention to signboards, text on food packages (especially their favourite food), pamphlets or brochures. Read out the signboards, road signs, text on the packages, mails to the toddler. It should be done in a very natural and incidental way so that we do not make the process ‘forced’ or stressful for the little one. It could be a fun thing during a supermarket trip where you draw the toddler’s attention to some text of things that he/she is interested in. e.g. toddler is looking at some chocolates. Just read the words on the packages at that moment. It is important to catch this moment as when interest is high, the learning is meaningful.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 1015,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 1015,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 1015,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 1015,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ahbaa',
                        text: '',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5i6om',
                        text:
                          'Reading is a lifelong habit and if cultivated well, opens up a huge window of possibilities for the child as they journey in their life, acquiring new knowledge and appreciating good literature. It is never too late to start and the key to growing readers is to read with them. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 278,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 278,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 278,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 278,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'bd9j7',
                        text: 'We read, they will read too!',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 28,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 28,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 28,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 28,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          },
          {
            slug: 'ways-to-foster-creativity-in-your-child',
            title: 'Ways to foster creativity in your child',
            type: articleTypes.LEARNING,
            author: 'Charmaine Toh',
            description:
              'In this age of technology where toys are made more sophisticated and ‘high-tech’, it can be difficult for children to hone their creativity. Instead of letting children decide what they want to do or what they want to create, children are subconsciously led to a particular end goal of close-ended toys (toys with limited ways of playing with them).',
            metaData: {
              title: 'Ways To Foster Creativity In Your Child',
              description: `Creativity is the key to success in nearly everything we do. Learn our tips by our award-winning educators on how you can help your child develop this skill.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text: 'Everyone is creative, including you. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 37,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 37,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 37,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 37,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'fdant',
                        text: `I used to have the belief that creativity equates to harnessing a certain skill. Now, I see creativity being displayed in various ways. In a typical [preschool](/) [classroom](/our-centres/our-learning-environment), I see myself putting out fires, healing animals, flying on a spaceship, being a doctor, driving trucks with children – and it is all thanks to the children's creative minds that I get to [embark on adventures](/our-curriculum/enrichment-programme) like that!`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'bpbe3',
                        text:
                          'In this age of technology where toys are made more sophisticated and ‘high-tech’, it can be difficult for children to hone their creativity. Instead of letting children decide what they want to do or what they want to create, children are subconsciously led to a particular end goal of close-ended toys (toys with limited ways of playing with them). This leads to parents thinking that children are bored or disinterested and that their children always need something to keep them occupied. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 491,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 491,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 491,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 491,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ltw',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '32k4',
                        text:
                          "It is a mindful action when we leave our children to do nothing and allow them to choose what they want to do. That moment of boredom could spark a child's creative thought, such as working in an imaginary restaurant, be a fireman, or even create a new story by doodling on a piece of paper! These moments are precious as they are self-led and children's creativity is not influenced by anything or anyone.",
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 406,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 406,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 406,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 406,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'd7a7',
                        text:
                          'Here are some tips to foster creativity in your child:',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 54,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 54,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 54,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 54,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '8pbtt',
                        text:
                          '    1. Provide open-ended materials such as scarves and cardboard boxes. You will be surprised by what your child can come up with!',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 131,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 131,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 131,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 131,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'euidd',
                        text:
                          '    2. Emphasize the process rather than the product (and do shower them with lots of encouragement).',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 101,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 101,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 101,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 101,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '4k4ae',
                        text:
                          '    3. Give your child time and freedom to explore their ideas and do what they want, within the parameters of safety.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 118,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 118,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 118,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 118,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '74o25',
                        text:
                          '    4. Allow your child to make mistakes and fail so that they can continue to create.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 86,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 86,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 86,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 86,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '6nghi',
                        text:
                          '    5. Encourage your child to find more than one route to a solution, and more than one solution to a problem. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 112,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 112,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 112,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 112,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'freii',
                        text:
                          'Creativity is a skill that we can help children to develop and it is also the key to success in nearly everything we do!\n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 120,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 120,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 120,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 120,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          },
          {
            slug: 'raising-bilingual-children',
            title:
              'Raising Bilingual Children: Why is Chinese Important to Learn?',
            type: articleTypes.LEARNING,
            author: 'Coreen Soh',
            description: `Raising bilingual children confers several advantages from the development of social skills to improved academic grades`,
            metaData: {
              title:
                'Raising Bilingual Children: Why is Chinese Important to Learn?',
              description: `Raising bilingual children confers several advantages from the development of social skills to improved academic grades`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'lish2',
                        text: 'Raising Bilingual Children in Singapore ',
                        type: 'header-one',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          '  True bilingualism is said to be distinct from mere second language acquisition. Unlike second language acquisition, bilingualism not only requires the ability to understand and produce meaningful sounds learned through phonological and grammatical pattern recognition, but also the ability to carry out smooth conversations in accordance to the subcultural context. Raising truly bilingual children, however, is dependent on several factors; from the age at which a child is exposed to various sounds and intonations of a second language to the motivation and environment. With the many stages of language development in early childhood and factors that affect one from being highly competent in a second language, why then is raising a child who is bilingual in English and Mandarin important to many parents?',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          '  Understanding the importance of learning the Chinese language and raising bilingual children in Singapore is an asset both locally and globally. With Mandarin being the most spoken language worldwide and English, the third, learning Chinese is a strategic investment that opens a world of opportunities. Locally, the importance of learning Chinese has been emphasised by the government with movements such as the Speak Mandarin Campaign. Also, a second language must be learned till primary school, and beyond, making it an integral part of every child’s education journey. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lish2',
                        text: 'Language Development in Early Childhood',
                        type: 'header-two',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'As a parent raising bilingual children in Singapore, several questions may come to mind; the most commonly asked being: at what age should a child be exposed to a second language? Most experts state the earlier, the better. The director of the NUS Infant and Child Language Centre supports this by noting that establishing early bilingualism predisposes children to success.  ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          ' Exposure to Mandarin at a young age will be required and well-suited for toddlers to learn the language easily. Adolescence is said to be the “golden window” where children can learn multiple languages solely with an immersion experience. This is possible as their neural network formation is at its peak. The reinforcement and building of neuron connections rather than relying on memorisation also aid in language fluency and efficiency. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ltc',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lish2',
                        text:
                          'Ease of Language Development in Bilingual Children',
                        type: 'header-three',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text: `Should parents have reservations about raising a child who is bilingual in English and Mandarin, it should be understood that it is relatively attainable, given the flexibility and adaptability children innately possess. Children have fewer inhibitions, removing any hurdles that may hinder their learning experience. Mandarin is also a tonal language, thus replicating the sounds and intonations of consonants and vowels will be easier at a younger age because of the brain’s elasticity. To further support the claim on a child’s rapid language learning ability, an NTU study showed that 93% of [Little Skool-House](/) children were [Highly Proficient in English and Mandarin](/our-difference/high-proficiency-in-english-and-mandarin). Within a span of only 5 months, our bilingual preschool in Singapore and its [comprehensive curriculum](/our-curriculum/curriculum-overview) managed to reduce the number of students who had low proficiency in English and Mandarin from 1.4% to 0%. With the massive exposure to the two languages, code-switching ability organically develops even if the child is from a home environment where Mandarin is not the dominant language.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },

                      {
                        key: 'lish2',
                        text:
                          'Importance of Learning Chinese for Cognitive Development',
                        type: 'header-two',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'With the world now being more globalised and cultures becoming increasingly diverse, bilingual children will not only be able to adapt to socio-economic changes but also reap the intellectual and cognitive benefits true bilingualism confers. From social to psychological advantages, how else does Mandarin aid in cognitive development? ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lish2',
                        text: '1. Exercises the Brain',
                        type: 'header-three',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'Studies have shown that unlike English that only utilises the left temporal lobe, speaking Mandarin requires the use of both. This not only increases the brain’s efficiency but is also said to improve one’s ability to multitask. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lish2',
                        text: '2. Better Academic Results',
                        type: 'header-three',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'With the increased utilisation of all aspects of the brain’s function and forming permanent neural pathways, bilingual children garner the potential to be more analytical than their monolingual counterparts. Their improved multitasking ability also aids in being academically successful.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text: `Given the many benefits associated with raising bilingual children in Singapore, it is crucial to be mindful of introducing children to a language and the potential expectations and pressures placed on them. If you are looking to take advantage of the critical years of your child and have them enrolled in a bilingual preschool in Singapore, schedule a centre consultation [here](https://www.youtube.com/watch?v=hXqvGyys0Hg).`,
                        externalLink: true,
                        type: 'mark-down',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      }
                    ]
                  }
                }
              }
            ]
          },
          {
            slug: 'bilingual-proficiency-development',
            title:
              'Bilingual Proficiency Development at The Little Skool-House',
            type: articleTypes.LEARNING,
            author: '',
            description: `Raising bilingual children confers several advantages from the development of social skills to improved academic grades`,
            metaData: {
              title:
                'Bilingual Proficiency Development at The Little Skool-House',
              description: `An NTU study demonstrated that 93% of The Little Skool-House children are Highly Proficient in English and Mandarin. Find out more about the study.`
            },
            overview: [
              {
                id: 1585116289690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'dsklh1',
                        text:
                          '*A 2019 [study conducted by Dr. Setoh](https://research.littleskoolhouse.com/) (PhD. The University of Illinois) of Nanyang Technological University of Singapore, demonstrated[SMH-LSH1] that 93% of Little Skool-House’s K2 students are Highly Proficient in English and Mandarin. We examine the specificities of the study in an attempt to corroborate the results with that of the school’s pedagogy and methodology.*',
                        type: 'mark-down',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text:
                          'K2 students who were not proficient in the English and Mandarin languages gained bilingual proficiency and became more balanced bilinguals within 5 months',
                        type: 'unordered-list-item',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text:
                          'Parents believe that the LSH preschool curriculum has a positive impact on a child’s development',
                        type: 'unordered-list-item',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text:
                          'The Little Skool-House Comprehensive Preschool Curriculum',
                        type: 'header-two',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text:
                          'Little Skool-House (LSH) is the [chosen  preschool  by  top  institutions](/our-difference/chosen-preschool-by-top-institutions) across Singapore. Working alongside [award-winning  educators](/our-difference/our-award-winning-educators), the bilingual preschool strives to remain front runners in the early childhood education sector in Singapore. This is done through the implementation of a comprehensive [curriculum  framework](/our-curriculum/curriculum-overview) specially designed for each phase of a child’s education journey. Composing of a [relationships-based](/our-curriculum/infant–3-years-relationships-based-curriculum) and [literacy-based  curriculum](/our-curriculum/4-6-years-literacy-based-curriculum), it facilitates holistic development and [High  Proficiency  in  English  and  Mandarin](/our-difference/high-proficiency-in-english-and-mandarin).',
                        type: 'mark-down',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text:
                          'How the LSH Curriculum Impacts Acquisition of Bilingualism',
                        type: 'header-two',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text:
                          'As trendsetters in early childhood education, the LSH pedagogy is based on thorough clinical research conducted by top universities around the world. Aimed at building a strong academic foundation, the bilingual preschool places emphasis on its ability in enabling its children to be highly proficient in both English and Mandarin.',
                        type: 'unstyled',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text: '1. Scientific Evaluation: Phase 1',
                        type: 'header-three',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text:
                          'The evaluation was split between two testing periods, spanning the course of 5 months. In the first testing period, 68 LSH Kindergarten 2 children were put through a series of tests to capture their English and Mandarin proficiency. These tests included a receptive vocabulary and expressive vocabulary test. In addition to the test results, other factors such as the child’s home literacy environment and language frequency were considered for a more accurate analysis of language proficiency. Of the major findings, it was established that 76.8% of LSH children had high proficiency in both English and Chinese.',
                        type: 'unstyled',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text:
                          '2. Exposure: Effective Preschool Curriculum in Singapore',
                        type: 'header-three',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text:
                          'Over 5 months, the language dominance of the participants was put to the test through exposure to the LSH literacy-based curriculum designed by pioneering ECDA fellows. Focused on preparing kindergarten children for Primary School, the Little Skool-House’s literacy-based curriculum incorporates various language and literacy activities and programmes designed for 4 to 6-year olds. Without much influence from external variables such as home-based learning, the impact of the LSH programme was later measured.',
                        type: 'unstyled',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text: '3. Scientific Evaluation: Phase 2',
                        type: 'header-three',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text:
                          'Due to the exposure to language and literacy activities part of the LSH curriculum for kindergarten 2 children, receptive vocabulary scores improved for both English and Mandarin – indicative of **significant vocabulary growth**. The 1.4% of LSH children who had **low English and Mandarin proficiency** in Phase **1 decreased to 0%**. Overall, LSH children become more balanced bilinguals, with **93%  proven to be highly proficient in both English and Mandarin (6.2% increase).**',
                        type: 'mark-down',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text:
                          'Tremendous Growth Due to Comprehensive Curriculum',
                        type: 'header-two',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text:
                          'Witnessing the effectiveness of the LSH curriculum first-hand, parents firmly believe in its ability to positively influence a child’s development and academic abilities in several aspects. As shown in the study conducted by Dr. Setoh, the LSH curriculum lends support to the hypothesis that proficiency in both English and Mandarin can be high with the right tools and exposure, regardless of the home literacy environment.',
                        type: 'unstyled',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text:
                          'Offering robust support for children as young as 18-months, the bilingual preschool in Singapore aims to provide parents with the necessary services that will ensure significant literacy development in their child – browse their list of [centres](/our-centres/centres/all-centres) and [contact LSH](/enquiry/contact-us) for more information.',
                        type: 'mark-down',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text: 'About the Little Skool-House',
                        type: 'header-two',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text:
                          'The Little Skool-House is a preschool located islandwide. Focusing on key developmental areas, the school’s [vision](/about-us/our-vision) is to nurture young learners to be ready for primary school and life. Having designed a comprehensive curriculum framework, the preschool has acquired a range of accolades and finds pride in fostering inquisitive learners who are Highly Proficient in English and Mandarin.',
                        type: 'mark-down',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dsklh1',
                        text:
                          'To visit the Little Skool-House centres, please go to: [https://www.littleskoolhouse.com/enquiry/schedule-a-visit](/enquiry/schedule-a-visit/)',
                        type: 'mark-down',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      }
                    ]
                  }
                }
              }
            ]
          }
        ]
      },
      {
        label: `Nursery Years`,
        url: `nursery-years`,
        key: 'learning-nursery-years',
        articles: [
          {
            slug: 'tips-for-helping-your-child-to-stay-focused',
            title: 'Tips for Helping Your Child to Stay Focused',
            type: articleTypes.LEARNING,
            author: '',
            description:
              'Do you find it difficult to get your child to focus on a task? With so many distractions in our daily lives, it can be a struggle for your child to pay attention and concentrate.',
            metaData: {
              title: 'Tips To Help Your Child Stay Focused',
              description: `Try our tips from Little Skool-House educators to get your child to be more focused and task-oriented - Read more here.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text:
                          'Do you find it difficult to get your child to focus on a task? With so many distractions in our daily lives, it can be a struggle for your child to pay attention and concentrate.  Instead of losing your cool, why not try these tips to get your child to be more focused and task-oriented?\n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 287,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 287,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 287,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 287,
                            style: 'fontfamily-Arial'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: 'a3gdm',
                        text: 'Tip #1: Move it Move it',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 23,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 23,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 23,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 23,
                            style: 'fontfamily-Arial'
                          },
                          {
                            offset: 0,
                            length: 23,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: '57qjr',
                        text:
                          'Asking your child to sit down and complete a task can be a huge challenge. Find ways to make it fun for the child by incorporating some physical activities to it. For example, doing math does not necessary require a pen and paper. It can be done through fun games and movement activities.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 288,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 288,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 288,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 288,
                            style: 'fontfamily-Arial'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: '11qb1',
                        text: 'Tip #2: Play Pretend',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 20,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 20,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 20,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 20,
                            style: 'fontfamily-Arial'
                          },
                          {
                            offset: 0,
                            length: 20,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: 'e8ntv',
                        text: `One thing that I love to do in the [classroom](/our-centres/our-learning-environment) is to [create scenarios for them to play pretend](/articles/the-power-of-creative-movement-dance-and-drama). For example, when they are wearing their clothes after shower time, we like to pretend that we are race cars having a pit stop and changing our “tyres” (clothes). Creating exciting scenarios like this gives them the sense of urgency as they focus on their task and have fun in the process! This also helps eliminate the distractions as they are concentrated in their task.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lnt',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'f33lo',
                        text: 'Tip #3: Bite-sized Instructions',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 31,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 31,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 31,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 31,
                            style: 'fontfamily-Arial'
                          },
                          {
                            offset: 0,
                            length: 31,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: '4hcf6',
                        text:
                          'Breaking down your instructions into bite-sized pieces allows your child to process information more effectively. Instructions that are short and simple to follow not only helps children to catch on better, it also provides them with more time to accomplish the task. Coming up with a rap, rhyme, or song for your instructions also adds a nice ring to it. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 356,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 356,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 356,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 356,
                            style: 'fontfamily-Arial'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: '8mvg6',
                        text: 'Tip #4: Have Breaks',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 19,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 19,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 19,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 19,
                            style: 'fontfamily-Arial'
                          },
                          {
                            offset: 0,
                            length: 19,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: 'fk2h8',
                        text:
                          'This is a crucial step to have. Instead of finishing the entire task in a sitting, break it down so that they can recharge their energy. Breaks are necessary for them to refocus and get their mind moving again. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 211,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 211,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 211,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 211,
                            style: 'fontfamily-Arial'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: '5eeqq',
                        text: 'Tip #5: Limit the Screen Time',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 29,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 29,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 29,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 29,
                            style: 'fontfamily-Arial'
                          },
                          {
                            offset: 0,
                            length: 29,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: 'c4406',
                        text: `This needs no explanation – it does makes it easier for your child to do a task (i.e. eating) with the aid of [electronic devices](/articles/gadget-free-entertainment-on-the-go-from-the-perspective-of-an-educator-mom). However, it makes children rely on gadgets, and it will be a much harder process to get them to wean the habit in the future. Start by keeping the task short and simple and stick to your rules.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '41l5l',
                        text: `It sometimes can be infuriating when a [child refuse to cooperate](/articles/managing-tantrums). It is important that we maintain our composure, be patient and try different ways to go about accomplishing a task!`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          }
        ]
      },
      {
        label: `Kindergarten Years`,
        url: `kindergarten-years`,
        key: 'learning-kindergarten-years',
        articles: [
          {
            slug:
              'transitioning-to-primary-one-from-the-perspective-of-an-educator',
            title:
              '‘Transitioning to Primary One’ (from the perspective of an educator)',
            type: articleTypes.LEARNING,
            author: 'Adinursuryana Btw Mohd Rashid',
            description:
              'The term “Kindergarten 2” is quickly becoming synonymous with “Primary One” these days. Often, children and parents ask how they will be getting ready for primary schools.',
            metaData: {
              title: 'Transitioning To Primary School',
              description: `Transitioning to Primary One is the beginning of the many that your child will be going through in life. Read from our educators on what we do to support this.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text:
                          'The term “Kindergarten 2” is quickly becoming synonymous with “Primary One” these days. Often, children and parents ask how they will be getting ready for primary schools. Some of these questions are asked even during the beginning of the K2 year. It is easy to understand the anxiety and excitement of those moving on to primary education; it is a big leap. Children would need to demonstrate a higher level of independence and learning skills once they start Primary One. An effective transitioning process can help to ease the anxiety of both parents and children and better prepare them for what is to come. \n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 612,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 612,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 612,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 612,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'd866a',
                        text: `A transition refers to a period of change between one “condition” to another. For the Kindergarten 2 children, they will be experiencing the transition from [preschool](/) to primary school during the year. This transition does not only focus on the learning but includes routines, social and emotional aspects. It is important to be mindful that every child responds to transitions differently. Some children may feel excited while others may be worried. Hence, it is crucial for parents and [educators](/our-difference/our-award-winning-educators) to work together to implement transitioning activities based on the child’s readiness and capabilities, and communicate frequently to support the child in this transition.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '85j7a',
                        text:
                          'As an educator, I aim to create a fun and meaningful transition for children. Through games, children pick up and practice numeracy concepts like addition and subtraction, telling time and understanding schedules. Experiential learning activities are designed to provide opportunities for children to learn the concept of money in preparing them on purchasing food during recess. \n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 380,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 380,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 380,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 380,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lkt',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'd891n',
                        text:
                          'In terms of social development, the transition programme prepares them for group work through small group practices in Kindergarten 2. The children are often given opportunities to participate in small group activities, encouraging them to engage in group discussions, work division and understanding the value of teamwork. While working in groups, I have seen how children are able to conduct simple online research on a topic of interest, create and take charge of game stalls for a school carnival and present wonderful shared responses from a brainstorming discussion. These experiences are all building blocks and equip children with a good start to formal learning. \n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 672,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 672,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 672,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 672,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ejhk',
                        text: `In addition, the [classroom layout](/our-centres/our-learning-environment) is rearranged towards the end of the year to resemble a primary school setting. Each child is assigned their own seat during lessons and they would practice bringing their own pencil cases to school and learning how to be responsible for their own stationery. All of these transitioning experiences are concrete, allowing children to learn and familiarize themselves with the seemingly new routines by doing them. It is the educator’s role to ensure that these experiences are effectively integrated into the [kindergarten curriculum](/our-curriculum/4-6-years-literacy-based-curriculum) and routines to ensure a seamless transition.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dtbam',
                        text:
                          'Transitions are part of the learning process and every child needs to go through many transitions in their growing-up years. Learning how to cope with transitions is an important skill. Besides planning for the transition to take place, an educator can support the child by helping the child to understand that it is natural to feel worried during transitions but what are the actions they can take to ease themselves through transitions. Transitioning to Primary One is just a beginning.  \n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 490,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 490,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 490,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 490,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          },
          {
            slug:
              'meaningful-conversations-using-open-ended-questions-with-children',
            title:
              'Meaningful Conversations Using Open-Ended Questions with Children',
            type: articleTypes.LEARNING,
            author: 'Savadi Gauri',
            description:
              'Creating meaningful conversations with pre-schoolers can be made easy by the means of open-ended questioning. Open-ended questions dissuade one-word answers such as yes or no and is also a wonderful way through which a child is able to provide more detailed responses using their curiosity, reasoning skills and creativity.',
            metaData: {
              title:
                'Meaningful Conversations Using Open-ended Questions With Children',
              description: `Hear about the benefits and how you can start having interesting conversations with your child today from Little Skool-House's educators - Read more here.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text: `Creating meaningful conversations with pre-schoolers can be made easy by means of open-ended questioning. Open-ended questions dissuade one-word answers such as yes or no and are also a wonderful way through which a child is able to provide more detailed responses using their curiosity, reasoning skills, and creativity. The open-ended questioning technique also encourages a child to stimulate their thinking and [develop their language skills](/our-difference/high-proficiency-in-english-and-mandarin). Such questions will enable a child to focus and make meaning of his/her experiences, see possibilities, and encourage the child to use words to describe and relate their experiences.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ck99v',
                        text: '',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '6ce0p',
                        text: 'Benefits of open-ended questioning',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'aeq2n',
                        text:
                          'When an adult engages a child in a conversation using open-ended questions, it shows that you are genuinely interested in what they are doing. An open-ended question encourages a child to:',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'b55eg',
                        text:
                          'develop their language by using different words and a wider range of vocabulary to answer the question',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'e654e',
                        text: 'provide more information and details',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '6415u',
                        text: 'express their thoughts, ideas, and opinions',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '423m5',
                        text: `[be creative](/articles/ways-to-foster-creativity-in-your-child)`,
                        type: 'unordered-list-item',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'a4dpl',
                        text:
                          'develop positive relationships by engaging in meaningful conversations',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'b67e0',
                        text: `provide a [safe environment for learning](/our-centres/our-learning-environment) as there are no ‘right’ or ‘wrong’ answers`,
                        type: 'unordered-list-item',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lkm',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '8vugt',
                        text: '',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '6itt5',
                        text:
                          'Now you may ask: "How to ask open-ended questions?"',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 51,
                            style: 'ITALIC'
                          },
                          {
                            offset: 0,
                            length: 51,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'av42h',
                        text:
                          'First, let’s talk about closed-ended questioning. Opinions, thoughts, ideas are not developed in closed-ended questioning. For example, the question, "What is the colour of this crayon?" will allow the child to provide a one-word answer, with only one correct answer. This often leads to disassociation and disengagement and does not encourage further thinking. For a child to feel that his or her opinions, ideas, thoughts and feelings matter, open-ended questions are preferred over close-ended ones. As parents, we generally ask our children lots of questions, about their activities and everyday encounters. Further using open-ended questions can help a child to develop thinking skills as they begin to consider how they approach, plan, carry and extend their own ideas.  These ideas and concepts will strengthen the child’s positive self-esteem, and, self-image as well as strengthen your relationship with your child. Some examples of open-ended questions:',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'c1dh4',
                        text: 'Tell me about what you’re doing?',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '6ubf7',
                        text: 'Why do you think…?',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1pmst',
                        text: 'How do you know…?',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '36ips',
                        text: 'Why do you think this happened?',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '4dmhc',
                        text: 'What is likely to happen next?',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1urr8',
                        text: 'How did this happen?',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '9isfb',
                        text: 'How do you think it could work?',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '2nhd3',
                        text: 'How are these the same/different?',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '286f7',
                        text: 'How are these different?',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '4o3ke',
                        text: 'I wonder why…',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'cnn2r',
                        text: 'Why did you…',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5k32f',
                        text: '',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'enf1a',
                        text:
                          "While answering open-ended questions, a child needs to have a particular level of verbal skills. The back-and-forth questioning and answering process is also an excellent platform to hone the child’s language skills through role-modelling, paraphrasing, and exposure to new words.  Asking open-ended type of questions also stimulates creative thinking, affirms the child’s ideas, increases their confidence and self-esteem. With open-ended questions, you do not know what your child's response is going to be. ",
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '7augn',
                        text:
                          'Expect the unexpected, start having interesting conversations with your child today.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          },
          {
            slug: 'instilling-a-love-for-performing-arts-in-young-children',
            title: 'Instilling a Love for Performing Arts in Young Children',
            type: articleTypes.LEARNING,
            author: '',
            description:
              'Performing arts is an excellent medium to enhance learning in young children. Performing arts genre may include dance, music, opera, theatre and musical theatre, magic, illusion, mime, spoken word, puppetry, circus arts, performance art, recitation and public speaking.',
            metaData: {
              title: 'Instilling A Love For Performing Arts In Young Children',
              description: `At Little Skool-House, we understand the benefits of performing arts and creative expressions. Learn how you can support your child's growth in this area here.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text:
                          'Performing arts is an excellent medium to enhance learning in young children. Performing arts genre may include dance, music, opera, theatre and musical theatre, magic, illusion, mime, spoken word, puppetry, circus arts, performance art, recitation and public speaking. There is also a specialized form of fine art, in which the artists perform their work live to an audience. \n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 112,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 112,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 376,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 376,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 112,
                            length: 264,
                            style: 'color-rgb(34,34,34)'
                          },
                          {
                            offset: 112,
                            length: 264,
                            style: 'bgcolor-rgb(255,255,255)'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: '4ota2',
                        text: `At [The Little Skool-House](/), in-line with one of [our principles](/about-us/the-little-skool-house) of learning, “Creative Expressions Broadens Children Development”, rich learning areas are set up with elements in creative expressions which enhances the children’s learning process.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '4lhp9',
                        text:
                          'Performing comes naturally for very young children and the level of self-consciousness increases as they approach pre-school years. The key to instilling their love is to start encouraging and positively reinforce any performing efforts from young. While skills can be honed progressively, confidence and motivation are cultivated right from the minute they can utter a word. Puppets are a great way to start with as the use of the puppet provides a safe proxy for the child to experiment with performing. The risk is greatly reduced as the attention is on the puppet, and not the child. This works for children who are naturally shy or not as confident. \n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 655,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 655,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 655,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 655,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: 'ald37',
                        text:
                          'We see children’s abilities and learning styles develop and unfold while they are engaged in performing arts. Children’s imagination is ignited through active exploration and play. Below are some strategies which you can work with your child to form the best building blocks for their development.\n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 297,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 297,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 297,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 297,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: 'c9t8u',
                        text: `Adaptation can be embraced to make performing arts relevant for young children in the home environment. Exposing children to simple story narration and role playing will allow them to gain a better understanding of the text. As a family, incorporating Reader’s Theatre, where children read simple scripts aloud from a story, is one way to allow your child to portray his/her favorite story, confidently with familiar adults. Through the use of movement and voice, your child is given the opportunity to [enhance communication](/our-difference/high-proficiency-in-english-and-mandarin) and expression without the emphasis for memorizing words. Being a home-based activity, with the familiarity of the environment, also makes it more conducive for little performances to happen.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lki',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '4dht7',
                        text: `How about [reading a story](/articles/cultivating-young-readers) aloud together? A form of choral reading, this way of expression is a great approach to playing around with the spoken language. Planning short snippets of time at home where you can start with short passages from your child’s favorite story and reading aloud for him/her to model. Besides enjoying the story, this activity develops the use of voice, intonation, speed, and pitch to demonstrate emotions through reading. At the same time, this makes reading a fun process, and children will eventually develop strategies to read independently in the long run. Reading need not be limited to take place indoors. It can also be in the outdoors where the immediate interaction with the nature around them can be incorporated. This helps to bring a sense of liveliness and vitality in their existing common environment.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'a9gqv',
                        text: `Why stop at reading a story where you can live in it? With simple props and costumes that you can provide for your child, he/she can be exposed to different perspectives of the story and effectively portray the characters, emotions and plot of the story. Be creative in this, allow your child to use household items to create a costume and have silly fun together. This hones the level of expression and [creative thinking](/articles/ways-to-foster-creativity-in-your-child) in the process. Your child is also given the autonomy to make sense of the story in their own understanding and develop new ideas to express his/her emotions and ideas.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '8he4o',
                        text:
                          'In order to develop children in their competencies and dispositions necessary for performance, ample opportunities can be given to the children to consistently use musical instruments and songs during activities. Music is an important accompaniment to puppetry; a simple box, a metal plate and stick or even your voice can do wonders in supporting and facilitating children in exploring the performing arts a lot more. Introduction of musical instruments also allows children to appreciate music which increases the children’s awareness of stage presence and engagement level. Both you and your child’s experiences could be enriched through these pathways. \n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 657,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 657,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 657,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 657,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: '5a6kn',
                        text: `The [role of a parent](/articles/enjoying-parenting) is crucial, as you will be supporting, participating and engaging your child in his/her daily interaction. Instilling love for performing arts in young children will take time and they should be given the freedom and space to explore any dialogue and interaction. Children’s level of confidence also plays a huge part in building their own development, mastery and application of skills. There should be moments where your child can re-visit and return to his/her experiences without being largely influenced by the adults’ preconceptions. Start your day with some elements of performing arts!`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    label: `Behaviour`,
    url: `behaviour`,
    icon: articleTypes.BEHAVIOR,
    subCategories: [
      {
        label: `Toddler Years`,
        url: `toddler-years`,
        key: 'behaviour-toddler-years',
        articles: [
          {
            slug: '5-daily-habits-to-raise-a-child-with-self-control',
            title: '5 Daily Habits to Raise a Child with Self-Control',
            type: articleTypes.BEHAVIOR,
            author: 'Cindy Goh',
            description:
              'To raise a child with strong self-control, it is important to establish consistent daily habits, set clear expectations and practice good rationalization with children.',
            metaData: {
              title: '5 Daily Habits To Raise A Child With Self-Control',
              description: `A child with good self-control knows the difference between a need and a want. Self-control can be built through introducing 5 daily habits. Read more here.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text:
                          'The ability to practice self-control as a child is one of the early predictors of success in adulthood, not his socio-economic status, not his birth order, and definitely not his intelligence. A child with good self-control is able to know the limits of acceptable social norms, has a strong concept of right and wrong, is able to delay gratifications and knows the difference between a need and a want. Many people might feel that it is too early to expect that of children. Habits form at an early age and very often, the understanding of what is appropriate or not, begins from simple habits. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 596,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 596,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 596,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 596,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '839rn',
                        text:
                          '\nTo raise a child with strong self-control, it is important to establish consistent daily habits, set clear expectations and practice good rationalization with children. These are some ways to do so:',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 1,
                            length: 198,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 1,
                            length: 198,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 1,
                            length: 198,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 1,
                            length: 198,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'bhdr8',
                        text: `Set consistent [bed times](/articles/6-tips-for-helping-your-child-fall-asleep). We know how difficult it is to establish consistent bedtimes especially when we are attending social events on weekends or your kid just refuses to put away his fire engine truck. Knowing when is play time and rest time is a good start for self-control. A habit to raise from young, children who adhere to consistent bed times are more likely to lead a disciplined routine later in life.`,
                        type: 'unordered-list-item',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5j6m4',
                        text:
                          'Set parameters. Parameters can be set for everything at home. This is especially important to curb indulgence. Parameters can be set for simple things like consuming sweets (e.g. every Friday, or twice a week), when can the child get a toy or television time. Do remember the more parameters you set, the more adherence you need to establish. It is important to explain the reasons behind setting these parameters to help children understand why they need to do so.',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 465,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 465,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 465,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 465,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'bt5',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '3k9ch',
                        text: `Always talk through expectations before an [outing](/articles/rethinking-outdoor-play). Children get very excited when they are out for a party, a trip to the mall or an overseas trip. The last thing we want as parents is to turn the trip into a major sour discipline trip. Always talk through the expectations before leaving the house and ensure that these expectations are adhered to. Leave room for discussion and make joint decisions together with your child where possible.`,
                        type: 'unordered-list-item',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '8nvf2',
                        text:
                          'Discuss the day’s routine. Introducing the concept of planning and keeping to the plan at an early age is a good way to hone self-control skills. Discuss the day’s routine with your child as a habit. Have your child agree to the plan and keep to it. Affirm him for keeping to the plan. It is also important to discuss different scenarios of the plan if it does not turn out as anticipated and learning how to accept changes without a fit also builds self-control. ',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 464,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 464,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 464,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 464,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ca7n1',
                        text:
                          'Rationalising purchasing decisions aloud. Demonstrating habits of financial prudence is a good start to developing self-control. Before your next trip to the supermarket, draw up a shopping list with your child. Instead of allowing him to sweep the aisle for all his favourite cereals, discuss and agree on a parameter e.g. choose only 2 types of cereal. Over time, he will develop a way of thinking through before deciding on what to buy.    ',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 443,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 443,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 443,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 443,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          },
          {
            slug: 'managing-tantrums',
            title: 'Managing Tantrums',
            type: articleTypes.BEHAVIOR,
            author: 'Cindy Goh',
            description:
              'You are out shopping and your child wants a Paw Petrol toy. You reject him as he has a few of it at home. He goes into a tantrum fit and refuses to leave the mall.',
            metaData: {
              title: `Managing Your Child's Tantrums`,
              description: `Your child's first tantrums will happen from 12-15 months and peaks at 18-36 months. Here are 5 tips from our award-winning educators on how to manage them.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text:
                          'You are out shopping and your child wants a Paw Petrol toy. You reject him as he has a few of it at home. He goes into a tantrum fit and refuses to leave the mall.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 163,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 163,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 163,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 163,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '6t03s',
                        text: `It is [meal time](/our-centres/our-nutritious-meals) and your child wants to watch the cartoon that is on screen. You tell him nicely that it is meal time and not television time. Not getting what he wants, your child throws a big tantrum.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ac64a',
                        text:
                          'These are common scenarios in most household, and even in schools. According to Watson and Gebhardt (2010), first tantrums happen from 12-15 months and peaks at 18-36 months of a child’s life.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 192,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 192,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 192,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 192,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '9qa75',
                        text: ' So what can we do?',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 19,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 19,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 19,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 19,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '63urk',
                        text: 'Tip #1: 1.. 2… 3… Breathe!',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 26,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 26,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 26,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 26,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 0,
                            length: 26,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dlr5c',
                        text:
                          "Yes, mummies and daddies, the first thing is to BREATHE and calm yourself down. As shared by Lyness (2015), ‘Don't complicate the problem with your own frustration or anger’. Take this chance to calm down. ",
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 206,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 206,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 109,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 175,
                            length: 31,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 109,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 175,
                            length: 31,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 109,
                            length: 66,
                            style: 'fontsize-9pt'
                          },
                          {
                            offset: 109,
                            length: 66,
                            style: 'fontfamily-Verdana'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'bg02s',
                        text: 'Tip #2: Acknowledge Your Child’s Feelings',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 41,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 41,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 41,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 41,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 0,
                            length: 41,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '7qkok',
                        text:
                          'Your child is going through a lot of emotions at this time. He is trying to cope with disappointment. He is learning how to accept no for an answer. Acknowledge their feelings by verbalizing it,” I know you are feeling sad because you wanted the Paw Petrol toy”. By doing so, you are giving your child a voice. This allows them to know that they can use their voice to express their frustrations rather than throwing a tantrum.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 427,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 427,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 427,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 427,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'btm',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '4d7r7',
                        text: 'Tip #3: Wait It Out',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 19,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 19,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 19,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 19,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 0,
                            length: 19,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '90n39',
                        text: `Allow your child to cry and do whatever he needs (in a [safe environment](/our-centres/our-learning-environment) of course). It’s too late to stop your child from crying once it starts. But stay close to your child. It might be embarrassing in public and the pressure may be too great to cope. If it happens in public, you may try to find a place where there are fewer people and wait it out.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '65c17',
                        text: 'Tip #4: Do Not Give In',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 22,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 22,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 22,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 22,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 0,
                            length: 22,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'f0ad2',
                        text:
                          'Let your child know that throwing a tantrum will not get what he wants. Let your ‘yes’ be ‘yes’ and your ‘no’ be ‘no’. Explain why you had to make certain decisions. Pincus (2017) explains that ‘If you give in to your child’s requests when he has an outburst, it will set up a pattern where you will create more tantrums. In effect, you’ve taught your child that the best way to get what he wants is to scream, yell and be out of control.’',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 439,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 439,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 439,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 439,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '9he38',
                        text: 'Tip #5: Give a Hug',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 18,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 18,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 18,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 18,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 0,
                            length: 18,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '9nret',
                        text: `Once your child has calmed down, speak to your child again. [Talk about the situation](/articles/meaningful-conversations-using-open-ended-questions-with-children). For example, “Thank you for calming down. Now we are ready to talk. I am sorry that we cannot get the Paw Petrol toy because you have one at home. Let me give you a hug. Now, would you like to hold my right or left hand as we head home?” Expressing affection helps the child to understand that it is the behavior that is undesirable, and you are managing the behavior but still love him.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'fibgn',
                        text: `Consistency is key to establishing desired behaviors. While we know this is not something easy to manage, keeping in mind a few of these handy tips might just [make parenting smoother](/articles/enjoying-parenting).`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          }
        ]
      },
      {
        label: `Nursery Years`,
        url: `nursery-years`,
        key: 'behaviour-nursery-years',
        articles: [
          {
            slug: 'resolving-conflicts',
            title: 'Resolving Conflicts',
            type: articleTypes.BEHAVIOR,
            author: '',
            description:
              'Very young children (toddlers and playgroup children) resolve their issues with their peers by instinctive reactions; like snatching an objects, hitting, biting, pushing etc.',
            metaData: {
              title: 'Resolving Conflicts',
              description: `Our educators share how teachers deal with children who are full of emotions when faced with conflict. Read the article here. `
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text: `Very young children ([toddlers and playgroup children](/our-curriculum/infant–3-years-relationships-based-curriculum)) resolve their issues with their peers by instinctive reactions; like snatching an objects, hitting, biting, pushing etc. The older children especially from the [age of four](/our-curriculum/4-6-years-literacy-based-curriculum) are more aware and knowledgeable of acceptable behaviours so their natural instinct was to approach their teachers to resolve their conflicts. They will become more sophisticated and competent as they cross-over to five and six years old mark.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'e7lft',
                        text: `Children around four years of age are in a challenging phase of working towards acceptable social behaviours, and resolving conflicts is one of the most difficult behaviour as it comprises of [self-control](/articles/5-daily-habits-to-raise-a-child-with-self-control) such as holding back negative reaction in a situation and problem solving to achieve their desire. Hence it is important that adults need to understand their emotions and support them with skills and strategies in this area.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'bp486',
                        text: `Aside from setting up a [friendly and caring environment](/our-centres/our-learning-environment) to facilitate and create opportunities for children in play and learning, Teachers should also take on an active role in creating a friendly and caring community in the classroom. It is necessary to have the buy-in from children with essential agreement in rules and expectations.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'bnr',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '6k3dd',
                        text:
                          'Teachers have to take time to resolve conflicts with all the parties involved in the issue when conflicts happened. Help them to express and exchange (empathize) their views and feelings in the situation. Assist to facilitate the situation and provide solutions to help resolve their conflict. Guide them to work out their action and check in on their actions.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 360,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 360,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 360,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 360,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: '4laar',
                        text: `In Singapore [Early Childhood teachers](/our-difference/our-award-winning-educators) are usually the main and active adults in the development of this skill because many children are the only child at home. Hence, there are little opportunities for parents to develop this skill. Parents could arrange for play dates with their child’s peers, neighbourhood children or cousins of around the same range.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          }
        ]
      },
      {
        label: `Kindergarten Years`,
        url: `kindergarten-years`,
        key: 'behaviour-kindergarten-years',
        articles: [
          {
            slug: 'building-resilience-in-children',
            title: 'Building Resilience in Children',
            type: articleTypes.BEHAVIOR,
            author: 'Nur’aqilah Abdul Malek',
            description:
              '“Success is not final, failure is not fatal. It is the courage to continue that counts,” -	Winston S. Churchill',
            metaData: {
              title: 'Building Resilience in Children',
              description: `Read what Little Skool-House award-winning educators has to say about boosting children’s resilience through everyday interactions and modeling here.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text:
                          '“Success is not final, failure is not fatal. It is the courage to continue that counts,” - Winston S. Churchill',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 90,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 91,
                            length: 20,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 90,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 91,
                            length: 20,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 90,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 91,
                            length: 20,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 90,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 91,
                            length: 20,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 0,
                            length: 111,
                            style: 'ITALIC'
                          },
                          {
                            offset: 89,
                            length: 22,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1vnpf',
                        text:
                          'The world can be a frightening place for anyone, even for young children. Being in an environment that is not their home for more than 6 hours a day can be challenging for some. Going through many routines, activities and meeting different people every day could be quite stressful for some children. Therefore it is crucial that young children develop strengths, acquire skills and be prepared for future challenges. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 418,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 418,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 418,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 418,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'altsk',
                        text:
                          'Resilience supports healthy development in a child. Growing up, I had many opportunities to explore, make mistakes and learn from my mistakes. My parents were positive role models and were constantly there to tell me, “It’s okay. Get up, dust yourself off.” Whenever I began whining or sobbing in pain, my father would always say, “I told you not to do it. Consequences. It’s okay, you are okay”. Back then, I would think, “my parents don’t love me, they don’t care”. After every incident, I learn and become stronger. I learned to be independent, I had self-control, I was able to think on my own, I was responsible for all my actions and I became confident. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 660,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 660,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 660,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 660,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'am31m',
                        text: `As an [educator](/our-difference/our-award-winning-educators), I strive to build or foster resilience in all my children - regardless if they are two years old or six years old. In my opinion, the best and strongest way to build resilience is to be a positive role model for children. By showing affection and giving them attention, the children feel loved and connected to you. This would also build their sense of worth, “I am valued, I am important”. In the [classroom](/our-centres/our-learning-environment), we should always strive to listen and to understand the children; whether it is their complaints about someone or their sharing about what they did over the weekends. When we listen and understand them, the children will feel understood and accepted. This would then allow them to understand others.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'bkb',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '8bf3k',
                        text:
                          'A resilient child is someone who is willing to try again and not give up after making a mistake. As an educator, I believe that when we are patient, children would develop the patience to learn and to explore. The way educators react to mistakes made by children also affects their confidence. When adults overreact or make a big fuss over something, children will get the message that “it is not okay to do that and I won’t do that anymore”. Instead, calm down, get to the child, assure him that he is okay and let him see his strength. For example, when a child ran too fast and fell, approach him and tell him gently that he is running too fast. Next time, he should look where he is running to and be careful. With that, children become willing to try again, explore different ways to run so that he will not fall and eventually master the skill of running and be confident.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 878,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 878,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 878,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 878,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '7et1j',
                        text: `Children also need to [develop self-control](/articles/5-daily-habits-to-raise-a-child-with-self-control). “Children who realise that they can control the outcomes of their decisions are more likely to realise that they have the ability to bounce back. Your child’s understanding that he or she can make a difference further promotes competence and confidence,” (HealthyChildren, 2007).`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'fqub2',
                        text:
                          'Together, with the support of other caring family and community members, we can boost children’s resilience through everyday interactions and modeling. “Children need to know that there is an adult in their life who believes in them and loves them unconditionally,” (Ginsburg, 2006).\n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 283,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 283,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 283,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 283,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    label: `Play`,
    url: `play`,
    icon: articleTypes.PLAY,
    subCategories: [
      {
        label: `Toddler Years`,
        url: `toddler-years`,
        key: 'play-toddler-years',
        articles: [
          {
            slug: 'rethinking-outdoor-play',
            title: 'Rethinking Outdoor Play',
            type: articleTypes.PLAY,
            author: 'Michelle Lee',
            description:
              'Why do children need to be engaged in outdoor play? Why do they need to spend time under the sun and running around? Does outdoor play only include going up and down the playground structure? Or just playing on the swings and see-saws?',
            metaData: {
              title: 'Rethinking Outdoor Play',
              description: `Including outdoor play is important as a child grows as it offers multiple development opportunities, including environmental awareness. Read more today.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text:
                          'Why do children need to be engaged in outdoor play? Why do they need to spend time under the sun and running around? Does outdoor play only include going up and down the playground structure? Or just playing on the swings and see-saws? \n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 236,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 236,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 236,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 236,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '29i6h',
                        text:
                          'In this article, we will focus on the importance of outdoor play and its benefits. The aim is to help us identify the opportunities that will support children’s learning during outdoor play. \n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 191,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 191,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 191,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 191,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ftig3',
                        text: `Louv (2005) uses the term ‘nature deficit disorder’ to describe the characteristics of children who seem anxious and show physical discomfort in natural settings. Children who live in dominantly urban settings may have a higher tendency to develop this deficit, resulting from overexposure to passive [screen time](/articles/gadget-free-entertainment-on-the-go-from-the-perspective-of-an-educator-mom), prolonged hours in passive transport and more sedentary time than the active time a day.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'a9ris',
                        text: `Research has shown that much of the recent interest in children’s outdoor play has been motivated by alarming changes in [children’s health](/our-centres/our-nutritious-meals) and predictions of their health status as adults. Chronic illnesses such as obesity and Type II diabetes are on the increase in many developed and developing countries (Stanley, Richardson, and Prior 2005; Jeffery and Sherwood 2008). Fighting diabetes has been a hot topic by our Prime Minister in his recent National Day Rally too!`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'd00br',
                        text:
                          'So what does an outdoor environment potentially offer that the indoor setting cannot? \n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 86,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 86,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 86,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 86,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ptr',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '73vri',
                        text: `Play spaces in a natural environment include plants, trees, sand, rocks, mud, water and other elements from nature. These spaces invite [open-ended interactions](/articles/meaningful-conversations-using-open-ended-questions-with-children), spontaneity, risk-taking, exploration, discovery, and connection with nature. They foster an appreciation of the natural environment, develop environmental awareness and provide a platform for ongoing environmental education (DEEWR, 2009, p.16). Hence, as adults, did have we provided sufficient opportunities for children to connect with nature and discuss global environmental issues with them?`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '8rp7e',
                        text:
                          'Here are some suggestions to how we can introduce environmental awareness to your child during outdoor play: - ',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 111,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 111,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 111,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 111,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '64kbi',
                        text:
                          'Water conservation – Talk about the water cycle, the rain, the clouds and how we conserve water',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 95,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 95,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 95,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 95,
                            style: 'fontfamily-Noto Sans Symbols", sans-serif'
                          },
                          {
                            offset: 0,
                            length: 95,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '704qa',
                        text:
                          'Incorporate animals and plants that the children have observed during outdoor play/walks in conversations with them',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 115,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 115,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 115,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 115,
                            style: 'fontfamily-Noto Sans Symbols", sans-serif'
                          },
                          {
                            offset: 0,
                            length: 115,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '3u2te',
                        text:
                          'Allow opportunities for children to observe and sort natural materials such as fallen sticks, leaves, feathers, shells, stones and bark according to their five senses',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 166,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 166,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 166,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 166,
                            style: 'fontfamily-Noto Sans Symbols", sans-serif'
                          },
                          {
                            offset: 0,
                            length: 166,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'bfglo',
                        text:
                          'Allowing opportunities for children to play on slopes, natural puddles, grass, sand etc. ',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 89,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 89,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 89,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 89,
                            style: 'fontfamily-Noto Sans Symbols", sans-serif'
                          },
                          {
                            offset: 0,
                            length: 89,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '2rjtv',
                        text:
                          'Provide props such as binoculars, magnifiers, books or posters for investigation of the great outdoors and encourage children to identify plants and animals',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 156,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 156,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 156,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 156,
                            style: 'fontfamily-Noto Sans Symbols", sans-serif'
                          },
                          {
                            offset: 0,
                            length: 156,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '4qgo4',
                        text:
                          'Embark on outdoor adventure trips to the parks, open areas, natural reserves, beaches etc. \n',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 91,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 91,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 91,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 91,
                            style: 'fontfamily-Noto Sans Symbols", sans-serif'
                          },
                          {
                            offset: 0,
                            length: 91,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '4vr5p',
                        text: `Both children and parents can learn to notice and respond to changes in natural settings and collaborate to create sustainable outdoor learning environments. [Parents play a significant role](/articles/enjoying-parenting) in offering children direct experiences with nature and being [positive role models](/our-difference/our-award-winning-educators). This is essential if children are to understand the complexities of sustainability, the systems that support life and why caring for the land, plants, animals and ourselves matter (Young & Elliott, 2013).`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '76upa',
                        text:
                          'It is important to help children to develop an appreciation for the outdoors as it offers a place for motor and sensory exploration, environmental experience, nurturing interactions and adult-child conversation (Greenman, Stonehouse & Schweikert, 2008). \n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 254,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 254,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 254,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 254,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'bmtfd',
                        text:
                          'Embrace the richness of the outdoors by starting with a nature walk this weekend. You may be amazed by what you may see. \n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          },
          {
            slug: 'the-power-of-creative-movement-dance-and-drama',
            title: 'The Power of Creative Movement, Dance and Drama',
            type: articleTypes.PLAY,
            author: 'Michelle Lee',
            description:
              'Ever had the feeling where sitting down all day left you less energised as compared to heading outdoors and moving about?',
            metaData: {
              title: 'The Power Of Creative Movement, Dance And Drama',
              description: `Creative movements require bodies, hearts, and minds to work in sync, that is beneficial to the growth of our children. Read more about other benefits here.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text:
                          'Ever had the feeling where sitting down all day left you less energised as compared to heading outdoors and moving about? ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '45je9',
                        text:
                          'Ever woke up after a long nap and felt more tired before you slept?',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '6shre',
                        text: `In fact, your body tends to feel more refreshed and energised when there are adequate movement and [outdoor activities](/articles/rethinking-outdoor-play). This is because the human body thrives on engagements. Stimulation through movement is important for all, even more so for the development of young children.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'uu32',
                        text: `Creative movement, dance, and drama are powerful imaginative tools that we use to help children explore, form a positive image of themselves and build healthy self-identities. It also serves as a wonderful way for the little ones to work on their self-awareness and self-esteem while being physically active. The beautiful thing about it is that there is no definite right or wrong when children are engaged in creative movement, dance, and drama. Therefore, it creates a [safe environment](/our-centres/our-learning-environment) for children to explore outside of their comfort zones.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'tofq',
                        text:
                          'Creative movements require bodies, hearts, and minds to work in sync. The movements are to be coordinated with what the child is trying to express while ensuring balance. This process helps to exercise the brain as it works out the complexity of combining both the dexterity and the emotive language of the movements. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'av3ap',
                        text: '',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '4cfuj',
                        text: 'Benefits of creative movement:',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 30,
                            style: 'BOLD'
                          },
                          {
                            offset: 0,
                            length: 30,
                            style: 'UNDERLINE'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5dqov',
                        text:
                          '1. Body awareness. To work on their coordination, body control, balance, stamina, and overall strength.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 3,
                            length: 16,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ejnul',
                        text:
                          '2. Spatial awareness. To move in their own personal space, be aware of other children’s personal space and respect others as everyone move together in a shared space.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 3,
                            length: 18,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ejjra',
                        text:
                          '3. Self-confidence. Taking risks in activities, performing for an audience and to trust their ideas and abilities.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 3,
                            length: 17,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '92lf7',
                        text: `4. **Concentration**. [Develop a sustained focus](/articles/tips-for-helping-your-child-to-stay-focused) of mind and body.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'bk5gj',
                        text:
                          '5. Cooperation and Collaboration. Working in groups, listening and responding, offering suggestions, exploring others’ ideas. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 3,
                            length: 31,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '8ramj',
                        text:
                          '6. Problem-solving. Look at problems in new ways and practice their critical thinking skills.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 3,
                            length: 16,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '26a4s',
                        text: `7. **Imagination**. [Making creative choices](/articles/ways-to-foster-creativity-in-your-child), thinking of new ideas, and interpreting familiar materials in new ways.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '63vdl',
                        text:
                          '8. Fun! Learning through play and at the same time improves motivation and reduces stress.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 3,
                            length: 4,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ptt',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '8n935',
                        text: '',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '98b9',
                        text:
                          'Creative movement is a wonderful way to get active with your child at home. You may feel that you do not have the expertise to do so but have no fear, it is not that difficult! You can add creative movement, drama and dance elements into your everyday routine. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'a6hhk',
                        text:
                          'For example, you can get your child to mimic the movement of animals when moving from the living room to the toilet. - “Let’s pretend to be kangaroos. How will a kangaroo move?” or to have them imagine moving through different surfaces or objects - “The floor is now filled with thick oozy mud. How can we get across to the other side of the room?”',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 119,
                            length: 59,
                            style: 'ITALIC'
                          },
                          {
                            offset: 249,
                            length: 99,
                            style: 'ITALIC'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ap87c',
                        text: '',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '24apq',
                        text:
                          'Here are another 3 activities for you to try at home:',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 53,
                            style: 'BOLD'
                          },
                          {
                            offset: 0,
                            length: 53,
                            style: 'UNDERLINE'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '66r5h',
                        text: '1. Freeze Dance',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 15,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '225pm',
                        text:
                          'This is a very popular activity for children of all ages. Play music and have them move around the room however they wish. When the music stops, they freeze right where they are. Once they have accomplished this, have them move like different animals, in different heights and pathways, etc.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '7c23m',
                        text: '2. Dancing with Scarves',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 23,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '3ou76',
                        text:
                          'Play music of different beat and tempo that might provoke a certain emotion. Give your child some scarves, or even just scraps of fabric. Let them dance to the music. Encourage them to explore the material and use it to dance in as many ways as they can.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1mskm',
                        text: '3. Pass the ball',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 16,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5sl6a',
                        text:
                          'Pretend to hold on to a ball of your choice and describe it. Using your imagination, you are going to pass the ball around. Increase the challenge by giving more unique features to your ball. For example, “It is made out of glass. How will we then pass the ball? How is it different from the basketball?”',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'esdhm',
                        text: '',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'bk0ei',
                        text:
                          'Simple as that! All you need to do is make sure that you have a large enough space in your living room, play space, or outdoors. So what are you waiting for? Let’s start moving. ☺',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'cbqs1',
                        text: '',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    label: `Health & Nutrition`,
    url: `health-and-nutrition`,
    icon: articleTypes.HEALTH_NUTRITION,
    subCategories: [
      {
        label: `Nursery Years`,
        url: `nursery-years`,
        key: 'health-and-nutrition-nursery-years',
        articles: [
          {
            slug: 'wonders-of-gardening',
            title: 'Wonders of Gardening',
            type: articleTypes.HEALTH_NUTRITION,
            author: 'Siti Nadiah Binte Mohd & Fiona Ng',
            description:
              'Is your child a picky eater? Are you struggling to get your child to eat a healthy and balanced diet?',
            metaData: {
              title: 'Wonders Of Gardening',
              description: `Gardening as an activity provides many growth opportunities for your child. Read about the benefits of starting this planting journey together with your child.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text: `Is your child a picky eater? Are you struggling to get your child to eat a [healthy and balanced diet](/our-centres/our-nutritious-meals)? Gardening might just be a crucial solution to your problems! In our little city with limited space, all you need is a few containers, soil, a sunny spot and love for nature to grow some herbs and sweet cherry tomatoes that your child will not be able to resist.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'd7qc8',
                        text: `Getting in touch with nature can [support children in learning new skills](/our-curriculum/enrichment-programme), do something fun with people and develop their self-confidence and self-help skills by spending time in the garden, tending to plants and growing their own food. The wonder of seeing a garden might spark your child’s curiosity and inspire him to ask questions like “Why do plants need sunlight?” “Why are worms good for the plants?” Take the opportunity to add a little numeracy element to the conversation by counting the flowers on each plant or measuring how the plants have grown on a weekly basis. [Supplement their experience with books](/articles/cultivating-young-readers) about plants, a day out to the Botanical Gardens or engage them in crafts to create a photo journal of the plants’ growth. As they tend to their plants, children will learn about responsibility, a love for nature and increased awareness of how the outdoor environment is a safe and pleasant place.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '22esr',
                        text:
                          'Even if your kids may not love the food that they have grown initially, encourage them to keep tasting and trying. This will increase their exposure to a variety of fruits and vegetables. Besides consuming the food, the act of gardening can promote a healthy body and introduce your child to basic ideas of environmental sustainability. Seeing food grow from seeds can be quite an experience for our children who usually buy food from the supermarket or cooked food stalls. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 474,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 474,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 474,
                            style: 'fontsize-12pt'
                          },
                          {
                            offset: 0,
                            length: 474,
                            style: 'fontfamily-Arial'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: 'hnw',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '37irv',
                        text: `Most [children love the outdoors](/articles/rethinking-outdoor-play), getting dirty and creating things. This might seem overwhelming to parents initially, with the concerns of hygiene issues. However, physical activities such as transferring soil and digging into dirt benefit children not only in the enhancement of their gross motor skills but also reduce their phobia and resistance to ‘dirty earth’. Touching the plants, soil, and being in touch with the natural environment can be therapeutic and calming for over-active children too.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dpq9j',
                        text: `In this electronic age, children need time for more active learning, be it with their family or peers. Gardening allows for a lot of meaningful social interactions among people and develops children’s co-operative and [communication skills](/our-difference/high-proficiency-in-english-and-mandarin). Furthermore, the self-fulfillment a child gets from eating vegetables and fruits that he/she has grown is priceless.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dcvro',
                        text:
                          'For many children, gardening may be their best chance to learn about the concepts that underlie all life on earth. So, there you go! What are you waiting for? Time to get your shovel and start planting away with your child! \n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 224,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 224,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 224,
                            style: 'fontsize-12pt'
                          },
                          {
                            offset: 0,
                            length: 224,
                            style: 'fontfamily-Arial'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    label: `Parenting`,
    url: `parenting`,
    icon: articleTypes.PARENTING,
    subCategories: [
      {
        label: `Infant Years`,
        url: `infant-years`,
        key: 'parenting-infant-years',
        articles: [
          {
            slug: 'enjoying-parenting',
            title: 'Enjoying Parenting',
            type: articleTypes.PARENTING,
            author: 'Coreen Soh',
            description: `Parenting is indeed a huge change in most of our lives. It is a time when many of us feel a great sense of achievement, at the same time, a great sense of despair.`,
            metaData: {
              title: 'Enjoying Parenting',
              description: `Parenting is a huge change in most of our lives and can impact us emotionally, physically and mentally. See our advice on how you can enjoy parenting.`
            },
            overview: [
              {
                id: 1585052373917,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: '8mu5g',
                        text:
                          '“Why do I feel tired even after sleeping the night through?”',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 60,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 60,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 60,
                            style: 'fontsize-10pt'
                          },
                          {
                            offset: 0,
                            length: 60,
                            style: 'fontfamily-Arial'
                          },
                          {
                            offset: 0,
                            length: 60,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: '4cu39',
                        text:
                          '“Why do other parents seem to cope better than me?”',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 51,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 51,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 51,
                            style: 'fontsize-10pt'
                          },
                          {
                            offset: 0,
                            length: 51,
                            style: 'fontfamily-Arial'
                          },
                          {
                            offset: 0,
                            length: 51,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: 'arfjp',
                        text: '“I wished I have 72 hours a day.”',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 33,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 33,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 33,
                            style: 'fontsize-10pt'
                          },
                          {
                            offset: 0,
                            length: 33,
                            style: 'fontfamily-Arial'
                          },
                          {
                            offset: 0,
                            length: 33,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: 'dhq9g',
                        text:
                          'Are these thoughts running through your mind? Do you often feel drained and lethargic? Parenting is indeed a huge change in most of our lives. It is a time when many of us feel a great sense of achievement, at the same time, a great sense of despair. The sentiments swing like a pendulum, moving from side to side, sometimes, causing us to question our own sanity.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 364,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 364,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 364,
                            style: 'fontsize-10pt'
                          },
                          {
                            offset: 0,
                            length: 364,
                            style: 'fontfamily-Arial'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: '5aggn',
                        text: `Every child is unique and so is every parent. The first mistake we make is to compare our parenting competencies with other parents and most often, we end up feeling more pathetic than before the conversation started. How do we grow as parents while we grow together with our children? How do we [ensure that our children grow healthily](/our-centres/our-nutritious-meals) and happily, and we as parents, develop healthily and happily too?`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1b883',
                        text:
                          'Parents are the main caregivers. The act of care-giving is a draining one if our own ‘care bank’ is not filled up as we care for others. We often deplete our own ‘care bank’ till a point of deficit, sometimes feeling frustrated and doubtful if we have made the right choice to be parents.  Very often, we hear parents lamenting that they have to function like normal despite them nursing an illness. Life simply goes on. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 421,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 421,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 421,
                            style: 'fontsize-10pt'
                          },
                          {
                            offset: 0,
                            length: 421,
                            style: 'fontfamily-Arial'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: 'pie',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '88h81',
                        text:
                          'How do we fill up our own ‘care banks’? Acknowledging that we need to be cared for, is the first step to establishing the balance. Are our kids capable of caring for us? Definitely yes! Even a young toddler can do it! Demonstrating acts of care by children can be advocated by the other parent who is well. The act of caring need not be limited to tasks. For example, if your spouse is unwell, be a role model of care by asking or checking-in on your spouse’s well-being, encourage the children to do so too. Simple acts like covering your spouse with a blanket, encourage the children to choose quiet activities so that your spouse can rest, dim the lights or even a simple gesture of a stroke on the head or a pat on the shoulder, can be acts of care that fill up our ‘care bank’. Children will pick up these behaviours gradually and develop a sense of empathy, care and concern. They start to understand that they can be reciprocal in a parent-child relationship. In this way, our own ‘care banks’ are being replenished as we provide care for our children. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 1060,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 1060,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 1060,
                            style: 'fontsize-10pt'
                          },
                          {
                            offset: 0,
                            length: 1060,
                            style: 'fontfamily-Arial'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: '3ocn7',
                        text:
                          'We can only care well for others only if we know how to care for ourselves. However, many parents face the torment of guilt when they even think of taking time-out of their parenting duties. Exercising self-care is an important contributor to positive parenting. We have experienced how our interactions with our children tend to be negative when we have had a bad day. Hence, a happy parent raises a happy child. Taking short couple trips, nights-off, me-time, are just some ways to exercise self-care. Self-care allows parents to recharge, lead a balanced life and build their stamina in their parenting journey. Often, the outcome would be a higher quality parenting relationship with their children.   ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 706,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 706,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 706,
                            style: 'fontsize-10pt'
                          },
                          {
                            offset: 0,
                            length: 706,
                            style: 'fontfamily-Arial'
                          }
                        ],
                        entityRanges: [],
                        data: {
                          'text-align': 'justify'
                        }
                      },
                      {
                        key: 'adpko',
                        text: `Parenting is a choice. Enjoying or resenting it, is a choice too. Taking the first step to acknowledge that parenting can be enjoyable by making small changes to our routines, develop self-care habits and [communicate these needs to your children](/articles/meaningful-conversations-using-open-ended-questions-with-children) and spouse. We often forget that children can support us in their own ways too. Parenting is not a one-way journey, it is reciprocal.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          }
        ]
      },
      {
        label: `Toddler Years`,
        url: `toddler-years`,
        key: 'parenting-toddler-years',
        articles: [
          {
            slug: 'are-you-and-your-child-preschool-ready',
            title: 'Are you and your child preschool ready?',
            type: articleTypes.PARENTING,
            author: 'Cindy Goh',
            description:
              'The first step forward is usually the toughest. You may probably be overwhelmed with so many emotions running through your head.',
            metaData: {
              title: 'Are You & Your Child Preschool Ready?',
              description: `Learn how you can prepare yourself and your child for the first day of school, from preparation to first day of school. Read more here.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text:
                          '“You’re off to great places! Today is your day! Your mountain is waiting, so get on your way!” ~ Dr Seuss',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 105,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 105,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 105,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 105,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 0,
                            length: 95,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'The day is finally here. The very first day of embarking on a learning journey. The first mountain to climb and conquer. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1r6hg',
                        text:
                          'Congratulations and welcome to preschool education. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 52,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 52,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 52,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 52,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '8bl4d',
                        text:
                          'The first step forward is usually the toughest. You may probably be overwhelmed with so many emotions running through your head. You may feel elated and grateful that your child has been offered a place at the [preschool](/) that you have waited a year for; but you feel the little tugging in your heart and you can’t bear with the thought of your baby going on the adventure without you. Rest assure that you are not alone. ',
                        type: 'mark-down',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 420,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 420,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 420,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 420,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'bkadu',
                        text: 'First things first. \n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 20,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 20,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 20,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 20,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'acbgp',
                        text: 'Preparing for school',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 20,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 20,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 20,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 20,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 0,
                            length: 20,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '9sru7',
                        text:
                          'Introduce your child to the concept of school early. Reading books in relation to the first days of school would help him/her to better identify with the concept of school. You may also want to enact scenarios or do pretend play to help your child have a clearer understanding of what going to school is all about. ',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 315,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 315,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 315,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 315,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '30kuk',
                        text: `Bring your child along for a [centre visit](/enquiry/schedule-a-visit)/orientation. It would be helpful for your child and you (yes, you parents) to be oriented to the new [centre environment](/our-centres/our-learning-environment) and be acquainted with the [class teachers](/our-difference/our-award-winning-educators). After all, these teachers are going to be your child’s primary caregivers for the entire school year.`,
                        type: 'unordered-list-item',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '8qen4',
                        text: `Adjust your child’s habits and routines. Routines give children a sense of security and let them [develop self-discipline](/articles/5-daily-habits-to-raise-a-child-with-self-control) so do find out the time schedule of the preschool (nap and lunchtime especially) and some of the routines they practice, i.e. using shower-heads. Gradually adjust your child’s routine to suit the school’s routine. If you have yet to set a routine, it would be best to establish one.`,
                        type: 'unordered-list-item',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'cd62v',
                        text:
                          'Be involved in preparing for school. Find out what your child needs for school and bring him/her along for the selection and purchase of items needed for school – from picking out a school bag to buying a new blanket.',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 217,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 217,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 217,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 217,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '6iq07',
                        text:
                          'Pack the school bag early. It would be best to pack it before the first day so you would not miss anything out. Let your child assist you in putting in the things into the new bag where he/she learns to take ownership of his/her own belonging. You can even pick out an outfit for a very important day together. ',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 311,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 311,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 311,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 311,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'pta',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '8r6gi',
                        text: 'First day of school',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 19,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 19,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 19,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 19,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 0,
                            length: 19,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '6rigk',
                        text:
                          'Brace yourself. You may be dealing with anxiety internally but please stay calm. Otherwise, pretend to be calm. ',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 112,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 112,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 112,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 112,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'bsdff',
                        text:
                          'Talk to your child while preparing for school together. Keep the excitement and momentum upbeat about going to school. It would be good to arrive before the core hour starts as it gives your child time to settle down.',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 217,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 217,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 217,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 217,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '9amcu',
                        text:
                          'Typical preschools allow you to accompany your child for the first few days so don’t be so eager to say goodbye. It would be great if you could participate in the activities planned, especially in the toddler class. Do join in the fun – dance, paint, run, and more. You are a role model to your child. Also, please say hello to the rest of the children. ',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 354,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 354,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 354,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 354,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ebicc',
                        text: `Do interact with the teachers. Your child would be observing and taking cues from you on how to react to the new environment. Chances are if you trust the teachers, your child would feel more at ease too. If in doubt, ask. It would be best to clarify with the teachers on your [queries](/enquiry/faqs) but, ask at appropriate times, not when the teachers are engaging with the children.`,
                        type: 'unordered-list-item',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '8lrai',
                        text: `Don’t do the disappearing act. You are not a magician. Your child needs to feel safe in the environment. If you do need to go off, say goodbye to your child. Your child may cry and it may tug at your heartstrings but it is better for your child to be informed and expect what is to come. Nobody likes to be caught unaware. If your child cries, please try to resist the temptation to run back. Trust that the teachers would do their job to calm your child down. You can always [call the school](/enquiry/contact-us) to get an update subsequently.`,
                        type: 'unordered-list-item',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 't430',
                        text:
                          'The mountain is waiting, so let’s get on our way.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 49,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 49,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 49,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 49,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          },
          {
            slug: '6-tips-for-helping-your-child-fall-asleep',
            title: '6 tips for helping your child fall asleep',
            type: articleTypes.PARENTING,
            author: 'Cindy Goh',
            description:
              'You are excited as a new parent, always imagining how fun it will be to have a baby at home. Your joy starts to wean off as you realised that your sleep patterns and quality of sleep are now compromised because your child does not seem to sleep well.',
            metaData: {
              title: 'Tips For Helping Your Child Fall Asleep',
              description: `Are you having trouble getting your child to sleep on time at night? See what our award-winning educators say and their tips on helping your child fall asleep.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text:
                          'You are excited as a new parent, always imagining how fun it will be to have a baby at home. Your joy starts to wean off as you realised that your sleep patterns and quality of sleep are now compromised because your child does not seem to sleep well. \n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 251,
                            style: 'color-rgb(54,57,53)'
                          },
                          {
                            offset: 0,
                            length: 251,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 251,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 251,
                            style: 'fontfamily-Open Sans", sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5nnls',
                        text:
                          'Are you having trouble getting your child to sleep on time at night? Or having your child waking up in the middle of the night which prevents you from getting quality sleep? \n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 174,
                            style: 'color-rgb(54,57,53)'
                          },
                          {
                            offset: 0,
                            length: 174,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 174,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 174,
                            style: 'fontfamily-Open Sans", sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '7oatd',
                        text:
                          'Here are some tips that might help you with getting your kids to bed on time and sleeping through the night.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 108,
                            style: 'color-rgb(54,57,53)'
                          },
                          {
                            offset: 0,
                            length: 108,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 108,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 108,
                            style: 'fontfamily-Open Sans", sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'ck8tu',
                        text:
                          "Establish a consistent and relaxing bedtime routine that lasts between 20 and 30 minutes and ends in your child's bedroom. Avoid scary stories, screen time or sweet snacks before bedtime as these tend to over-stimulate the child’s brain and he will have difficulty unwinding. Create a sense of calmness by reading a favourite book every night or humming a favourite tune. These will help your child to relax, making it easier for him to go to bed. ",
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 448,
                            style: 'color-rgb(54,57,53)'
                          },
                          {
                            offset: 0,
                            length: 448,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 448,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 448,
                            style: 'fontfamily-Open Sans", sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '4s14m',
                        text:
                          'Set a time limit. Stay for perhaps five minutes or so, then kiss your child and reassure them you will be right back to check on her. Young children may have difficulty understanding the concept of time. We can set the time limit by using concrete items e.g. after 2 stories and 2 songs or use a timer,  ',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 304,
                            style: 'color-rgb(54,57,53)'
                          },
                          {
                            offset: 0,
                            length: 304,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 304,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 304,
                            style: 'fontfamily-Open Sans", sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'pnn',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '9qmcs',
                        text:
                          'Discuss the sleeping plan well ahead of time. Each night, remind your child that soon mommy won’t be lying down with her at night, but mommy will check on her as she falls asleep. This way she can be prepared for the change.',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 224,
                            style: 'color-rgb(54,57,53)'
                          },
                          {
                            offset: 0,
                            length: 224,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 224,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 224,
                            style: 'fontfamily-Open Sans", sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'f92pq',
                        text:
                          'Set small milestones. It will be unrealistic to expect a child who wakes up frequently to transit immediately to not waking up in the night at all. Prepare yourself by focusing on reducing the frequency of waking up. At every stage, it is good to affirm your child. Eventually, this will work towards a longer stretch of sleep without disturbance. ',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 348,
                            style: 'color-rgb(54,57,53)'
                          },
                          {
                            offset: 0,
                            length: 348,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 348,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 348,
                            style: 'fontfamily-Open Sans", sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '629m8',
                        text:
                          'Set up a reward system. For every night your child goes to bed on time and sleeps through the night, or achieves a small milestone, she gets a sticker. Children do need positive affirmation and at times, a tangible affirmation may seem more visible.',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 249,
                            style: 'color-rgb(54,57,53)'
                          },
                          {
                            offset: 0,
                            length: 249,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 249,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 249,
                            style: 'fontfamily-Open Sans", sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '9rt66',
                        text:
                          'Set up a conducive environment. Some children are afraid of total darkness or cannot relate to the fact that the parents are still nearby but in a different bedroom. If your child is afraid of the dark, install a nice night lamp to assure him. Using a baby monitor, and demonstrating to the child that you are just a call away. ',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 328,
                            style: 'color-rgb(54,57,53)'
                          },
                          {
                            offset: 0,
                            length: 328,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 328,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 328,
                            style: 'fontfamily-Open Sans", sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'fb9i6',
                        text:
                          'Remember, there is no one age that this will be right for every child. Some may be ready at age 2, some at age 8.\n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 113,
                            style: 'color-rgb(54,57,53)'
                          },
                          {
                            offset: 0,
                            length: 113,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 113,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 113,
                            style: 'fontfamily-Open Sans", sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          },
          {
            slug: 'guide-to-choosing-a-preschool-in-singapore',
            title: 'Guide to Choosing a Preschool in Singapore',
            type: articleTypes.PARENTING,
            author: 'Coreen Soh',
            description: `Choosing a school is a key decision to be made during a child’s formative years. We put together 5 factors to consider when choosing a preschool. Read more.`,
            metaData: {
              title: 'Guide to Choosing a Preschool in Singapore',
              description: `Choosing a school is a key decision to be made during a child’s formative years. We put together 5 factors to consider when choosing a preschool. Read more.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'lish2',
                        text: 'Guide to Choosing a Preschool ',
                        type: 'header-one',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'With your child entering their formative years, as a parent, you may have several questions and stresses of finding the perfect preschool and navigating your way through the array of choices. If it is your first child that you are choosing a school for, it may very well be one of the biggest parenting decisions you will have to make. With over 2000 preschools in Singapore that offer a variety of programmes, specialised curriculums, and facilities, there are several factors to consider so that the best preschool that fits the needs of both you and your child is chosen. From full-day childcare and kindergartens to learning philosophies, we cover the top 5 things to consider when choosing a preschool.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lish2',
                        text: '1. Your Needs',
                        type: 'header-two',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text: `Preschools are attended by young ones before taking the big leap into compulsory primary school education. With childcare centres and kindergartens being the primary provider of an educational environment for children aged 3 to 6 years old, parents need to decide which of these options cater to their specific needs. Childcare centres typically offer full-day programmes, while kindergartens, if run by MOE, offer 4-hour programmes. Some preschools like The [Little Skool-House](/) also offer infant care programmes (2 months to 17 months) for working parents in Singapore. With these options at hand, it is best to take a step back, and reflect on these three questions, “Do we require both education and care components in the programme?”, “Is the school of choice within our budget”, and “Is it conveniently located?” These will help ease the decision-making process and narrow down your options to a targeted few.`,
                        externalLink: false,
                        type: 'mark-down',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lish2',
                        text: '2. Singapore Preschool Curriculum',
                        type: 'header-two',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text: `Every school boasts its own unique pedagogy, and choosing a preschool with a curriculum that not only subscribes to your preferences but also supports holistic development, is crucial. Look through the preschools’ varied curriculums and determine its primary focus of each age group and if it adapts with growth. Even if a curriculum seems comprehensive on paper, a school with a team of well-trained teachers will help you ascertain if the school can hone the skills and achieve maximum learning goals they have set within their established [learning environment](/our-centres/our-learning-environment).`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text: `With bilingual preschools on the rise in Singapore, the approach each school takes when incorporating a well-rounded bilingual curriculum varies dramatically. The Little Skool-House (LSH), for example, is a bilingual preschool in Singapore that engages students in a curriculum that has led to [High Proficiency in English and Mandarin](/our-difference/high-proficiency-in-english-and-mandarin) through equal emphasis on both core languages. Such preschools are thus a better option for parents with the goal of having their children be bilingual upon graduation.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lish2',
                        text: '3. Learning Philosophy',
                        type: 'header-three',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          'Just like how every parent has their own parenting goals, every school has its own approach to education. As simple as it may sound, it is quintessential for parents to reflect on their goals and decipher if it is aligned with that of the school’s learning philosophy. Even if a preschool has a good reputation, its teaching style and philosophy may not be in line with your conventions on why preschool education is important for your child. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },

                      {
                        key: 'ltc',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lish2',
                        text: '4. Niche Enrichment Programmes ',
                        type: 'header-two',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text: `Tuition classes and [enrichment programmes](/our-curriculum/enrichment-programme) have become a norm since parents are enthralled by its ability to equip children with competency skills essential for their future. Instead of sourcing for these programmes elsewhere, some schools are designed with specialised learning pedagogies that expose students to areas of interest such as dance to cultivate a love for learning. Enquire how these classes are structured to ensure that it will instil and inculcate in your child the habits of an active learner.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'lish2',
                        text: '5. School Culture ',
                        type: 'header-two',
                        depth: 0,
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text:
                          ' A well-established relationship between teacher and child is critical for healthy development, thus explaining the rise in relationships-based preschool curriculums in Singapore. But what about parent-teacher relationships? Schedule a visit with the school and take advantage of the opportunity to ask questions related to you and your child – What are their methods of discipline, will the parent-teacher communication style be a one-way or two-way exchange, and what are the ways rapport is built between parents and teachers. Communicating with the educators on such topics will give you a gist of the school’s culture and if it is one that will allow seamless integration. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 121,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 121,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5a6kn',
                        text: `Take a walk around the campus and see if it encourages collaborative learning and fosters a sense of community. Observe how your child interacts with the space and if they are suited to the teacher-student ratio. Also, check the cleanliness of classrooms, and if the [meals](/our-centres/our-nutritious-meals) inculcate healthy living. Be sure the school’s learning environment coincides with their stated curricular approach – do they have play areas that coincide with their supposed play-based approach?`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'fb9i6',
                        text: `Choosing a school or childcare centre may seem tedious, but if done successfully, it is a great stride towards your child’s future development. Take advantage of the passion of every educator and build a relationship with them to help guide your child through life.`,
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 123,
                            style: 'color-rgb(54,57,53)'
                          },
                          {
                            offset: 0,
                            length: 123,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 123,
                            style: 'fontsize-10.5pt'
                          },
                          {
                            offset: 0,
                            length: 123,
                            style: 'fontfamily-Open Sans", sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      }
                    ]
                  }
                }
              }
            ]
          }
        ]
      },
      {
        label: `Nursery Years`,
        url: `nursery-years`,
        key: 'parenting-nursery-years',
        articles: [
          {
            slug: 'nurturing-curiosity-in-kids',
            title: 'Nurturing Curiosity in kids',
            type: articleTypes.PARENTING,
            author: 'Coreen Soh',
            description:
              '“Why is the sky blue?”, “Why is the grass green?”, and “Why are the clouds white?”, “Why, why, why….”',
            metaData: {
              title: 'Nurturing Curiosity In Kids',
              description: `Children are often full of awe about many things and they have endless questions on why things are the way they are. Learn how to encourage and nurture this.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text:
                          '“Why is the sky blue?”, “Why is the grass green?”, and “Why are the clouds white?”, “Why, why, why….”',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 101,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'bcj73',
                        text:
                          'Sounds familiar? Have you once heard these from your child? The world is full of wonders…and when babies are born, they are full of curiosity for the world. What happens thereafter? Have we noticed that the level of curiosity in a child seems to diminish as he progresses? Is it because the child is learning more and thus, things seemed to be less interesting? Most likely not. One reason, I believe, is that too many adult-directed activities and instructions begin to undermine their autonomy and competence, thus decreasing their level of intrinsic motivation and curiosity.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'cabsc',
                        text:
                          'The child is often full of awe of the things around them. They have endless questions on why things are the way they are. Sometimes, catching us adults dumbfounded because we simply do not have the answers for every question asked. A good example is a question about the blue sky. We take for granted that the sky is blue and probably do not have the time to think about the reason. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '31o82',
                        text: 'ACKNOWLEDGE YOUR CHILD’S QUESTION',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 33,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'asqjs',
                        text:
                          'Nurturing their curiosity starts with acknowledging their questions. Acknowledging is not the same as providing an answer. Acknowledging their question means to let them know that we hear the question, we are thinking about it and we would like to find out the answers together with them. And it is perfectly fine to let them know that parents do not necessarily have the answers to everything. Refrain from reprimanding children when they have questions. That will discourage them from being curious.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5c5vl',
                        text: 'GIVE THEM TIME',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 14,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'blrk4',
                        text: `Giving time to children to delve into concepts or topics is important. It is not sufficient to spoon-feed children with lots of information. Focus on depth rather than breadth. We should give them time to ponder, reflect, and think so that their competence can be developed. With an increase in competence, they can [be more self-motivated to learn](/articles/building-resilience-in-children) new things.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '61ujj',
                        text: 'CONSTRUCTIVE DESTRUCTION',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 24,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'aqgri',
                        text:
                          'Young children are at a concrete learning stage. They need to work on real objects to learn. Many times, they are seen to be destroying their toys, breaking them up into pieces and parts. This is called constructive destruction. Children break things apart to see how things work. They need to destruct in order to construct more or new knowledge. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'pnn',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5al6l',
                        text: 'DON’T GIVE READY ANSWERS',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 24,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5llnb',
                        text:
                          'Cultivate in children, a need for them to find out things for themselves. Let them feel a sense of achievement when they have discovered something new. Do not be too ready to provide answers. Celebrate every small discovery, even if they seemed trivial to you.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '4sg2e',
                        text: 'ENVIRONMENT PLAYS A PART',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 24,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '3jua6',
                        text: `An [environment that supports the development](/our-centres/our-learning-environment) of a child’s curiosity is often open-ended. Materials are placed within the children’s reach and the environment encourages the child’s autonomy in the selection of tasks and activities. An environment that nurtures curiosity is also one that embraces trial and error, experimentation and exploration.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '94ok7',
                        text:
                          '10 PRACTICAL TIPS TO NURTURE CURIOSITY IN YOUR CHILD',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 52,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '6sss7',
                        text:
                          '1. Acknowledge the questions asked by your child. Even if you cannot attend to it immediately, give a positive response e.g. “That’s really interesting but Mummy is busy right now. Let’s write it down and we will find out tonight.”',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '5orht',
                        text:
                          '2. Model a curious spirit. Who said we can’t be curious as adults. Ask questions and model it for your child. They will develop the spirit of curiosity with time.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '27k60',
                        text:
                          '3. Google together with your child. There is nothing wrong with exposing your child to the world of the Internet, as long as they are being supervised. Let them see how vast the world is and enjoy the process of finding out things together.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'che4n',
                        text:
                          '4. Allow time for your child to think. You may think that they are daydreaming but most likely not. ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '4mb09',
                        text:
                          '5. Involve your children in cookery activities. Observing the changes in food during a cookery activity is a great way to stimulate your child’s curious cat in him.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'd5uss',
                        text:
                          '6. Let children help or observe little ‘handyman’ tasks e.g. cleaning/repairing fans, changing batteries or even fixing a radio or computer.  ',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '9jevs',
                        text:
                          '7. Do not throw out old appliances. Just ensure that they are safe enough (no small parts for very young children) or have a supervised session when children can dismantle the parts and see what is inside.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'av6lr',
                        text:
                          '8. Hide things under the pillow or blanket and have your infant wonder where has it disappeared to.',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1ct1c',
                        text: `9.&nbsp;[Learn through the natural environment](/articles/rethinking-outdoor-play). Take time to smell the flowers, listen to the rain, smell the grass with your child. Stimulating the senses encourages children to explore and wonder about the natural environment around them.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '99dou',
                        text: `10.&nbsp;[Read, read, read!](/articles/cultivating-young-readers) Reading opens up another world to the children, evoking their sense of environment. From books, children will have a platform to wonder, and parents can extend their learning from the stories.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'd0pr2',
                        text: `Start nurturing your child’s curiosity at a very young age (even when they are babies). As the child grows older, they begin to lose their sense of curiosity. Remember, nurturing curiosity is like [equipping your child with a lifelong learning tool](/enquiry/schedule-a-visit). Help keep the awe burning in your child, for a life of success.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '2n2mu',
                        text: '',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          },
          {
            slug:
              'gadget-free-entertainment-on-the-go-from-the-perspective-of-an-educator-mom',
            title:
              'Gadget-free Entertainment-On-The-Go (From the perspective of an educator mom)',
            type: articleTypes.PARENTING,
            author: 'Lynette Fang',
            description:
              'When I was pregnant with my first child, I often get comments from well-meaning friends and relatives saying “You are lucky, you are a teacher. You will know what to do and how to teach your child.”',
            metaData: {
              title: 'Gadget-Free Entertainment On The Go',
              description: `Read how our award-winning educators exploring strategies and ways to keep their little ones entertained on-the-go, without gadgets! Tricks and tips provided.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text: `When I was pregnant with my first child, I often get comments from well-meaning friends and relatives saying “You are lucky, you are a [teacher](/our-difference/our-award-winning-educators). You will know what to do and how to teach your child.” But the truth is, I was never a parent, and as a first-time parent, a lot of things were new to me. And I am pretty sure most parents out there would agree with me that [parenting](/articles/enjoying-parenting) is the heart and hard work. The decisions we have to make parenting even trickier. One of such decision was to limit screen time for my child. We agreed that with increasing reports of the effects screen-time has on a young child’s developing brain, we would not be offering our phones or iPads to both our children anytime soon.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '3oh6l',
                        text: `My husband and I love traveling and exploring different places in and out of Singapore, and this passion never quite died down even after 2 kids. But the challenge came when the elder one was no longer entertained by the dangling rattles and toys that were placed on his stroller when we were on-the-go. [Meal times](/our-centres/our-nutritious-meals) outside became battles because no one could hold a decent conversation or even eat in peace with a toddler trying all ways to grab your attention. From there, I started exploring strategies and ways to keep my little ones entertained onthe-go.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'png',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '7mth6',
                        text: `I started by bringing books to read to my son, and slowly expanded my “busy-bag” repertoire by adding a couple of his favourite toy cars, some paper and crayons for doodling and even stickers since he loved them and peeling the stickers gives him opportunities to practice his fine motor muscles. Now that he is almost [3.5 years old](/our-curriculum/infant–3-years-relationships-based-curriculum), the busy-bag is still with us whenever and wherever we go. These are some items we have for his busy-bag.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'crqpj',
                        text: 'Busy Bag Ideas:',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 15,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 15,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 15,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 15,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '9b1hi',
                        text:
                          'Find an unused mesh zipper bag (or any large Ziplocks) to contain all your busy bag materials.',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 94,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 94,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 94,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 94,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '6qfp4',
                        text:
                          'Look around your house for recycled paper, unwanted notebooks and writing materials for your child to doodle or write.',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 118,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 118,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 118,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 118,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1gd51',
                        text:
                          'Put together some open-ended items which your little one can explore. Here are some examples: ',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 94,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 94,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 94,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 94,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'b1mb3',
                        text:
                          'a. Bread tags – a wonderful item that can be used for sorting, counting, or even threading (just add a ribbon to your bag)',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 2,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 3,
                            length: 119,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 3,
                            length: 119,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 3,
                            length: 119,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 3,
                            length: 119,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '19h19',
                        text:
                          'b. Playdough or slime (a little messy but these are really popular!)',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 2,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 3,
                            length: 65,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 3,
                            length: 65,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 3,
                            length: 65,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 3,
                            length: 65,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'a69s0',
                        text: 'c. Magnets and paper clips',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 2,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 3,
                            length: 23,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 3,
                            length: 23,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 3,
                            length: 23,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 3,
                            length: 23,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'cqhse',
                        text: 'd. LEGO Bricks',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 2,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 3,
                            length: 11,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 3,
                            length: 11,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 3,
                            length: 11,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 3,
                            length: 11,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '2a9ii',
                        text:
                          'e. Magnetic letters – encourage your pre-schoolers to form words they know',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 2,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 3,
                            length: 71,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 3,
                            length: 71,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 3,
                            length: 71,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 3,
                            length: 71,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '3qhj3',
                        text:
                          'f. A favorite storybook – you can have a conversation about the characters in the book',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 2,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 3,
                            length: 83,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 3,
                            length: 83,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 3,
                            length: 83,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 2,
                            style: 'fontfamily-Calibri, sans-serif'
                          },
                          {
                            offset: 3,
                            length: 83,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '9rv6h',
                        text:
                          'As your child grows, the items in your busy bag would start to change and evolve to suit your child’s needs and preferences. You don’t have to spend too much to create a busy bag, sometimes the best resources lie around in your own home!\n',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 237,
                            style: 'color-rgb(0,0,0)'
                          },
                          {
                            offset: 0,
                            length: 237,
                            style: 'bgcolor-transparent'
                          },
                          {
                            offset: 0,
                            length: 237,
                            style: 'fontsize-11pt'
                          },
                          {
                            offset: 0,
                            length: 237,
                            style: 'fontfamily-Calibri, sans-serif'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          },
          {
            slug: 'bedtime-stories-with-daddy',
            title: 'Bedtime Stories with Daddy!',
            type: articleTypes.PARENTING,
            author: 'Lynette Fang',
            description:
              'Children are made readers on the laps of their parents. — Emilie Buchwald',
            metaData: {
              title: 'Bedtime Stories With Daddy',
              description: `One simple way for fathers to be involved with their children is by reading stories. Here are some strategies to start your reading routine with your child.`
            },
            overview: [
              {
                id: 1585116910690,
                type: 1,
                data: {
                  content: {
                    blocks: [
                      {
                        key: 'ahlmu',
                        text:
                          'Children are made readers on the laps of their parents. — Emilie Buchwald',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 73,
                            style: 'ITALIC'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'erm3r',
                        text: '',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'c1bn9',
                        text: '“What role does Daddy play in reading at home?”',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 47,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '3j5uj',
                        text: `We’ve all heard or read about the importance and benefits of fathers being involved in their children’s lives – such as an increase in children’s cognitive abilities, [self-control](/articles/5-daily-habits-to-raise-a-child-with-self-control), empathy and emotional well-being. But that’s not all! A research study from Harvard University revealed that children benefit more when their father reads to them before bedtime. Mothers often focus on the characters’ feelings whilst dads will link the narrative to something more pertinent to the child. This challenges the children cognitively in different ways.`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1fp2c',
                        text: '',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '2qpfs',
                        text: '“But how do I start?”',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [
                          {
                            offset: 0,
                            length: 21,
                            style: 'BOLD'
                          }
                        ],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '8gusd',
                        text: `Daddies, do not fret if you feel unsure how and where to start. Here are some strategies to [kick start your reading routine with your little one](/articles/cultivating-young-readers).`,
                        type: 'mark-down',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'kgvl',
                        text: '',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'bnano',
                        text:
                          'Visit a nearby library – the children’s section in our local neighbourhood libraries or our National Library have a rather comprehensive collection of pictures and information books that are age-appropriate for children. ',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'dbf30',
                        text: `Find out your child’s current topics of interests, and select a book related to that topic. It will be easier to [keep your child engaged](/articles/tips-for-helping-your-child-to-stay-focused) in the reading session if it is a topic they like.`,
                        type: 'unordered-list-item',
                        externalLink: false,
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'pnb',
                        type: 'button',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '24q7m',
                        text:
                          'Read the book at least once to yourself – getting yourself familiarized with the story helps you better prepare for the potential discussion points; questions you might ask or questions your child might have. If the story is too long, consider reading the story in parts to your child.',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '2njj',
                        text:
                          'Plan and set aside an unhurried and relaxed time before your child’s bedtime to read him/her the story. If you find that your weekday evenings are simply too packed, consider starting your bedtime story sessions on a weekend evening. This would eventually be a special bonding time between you and your child. ',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '1aop6',
                        text:
                          'Read aloud with animation and don’t be shy to be silly or make mistakes. Find a silly voice for different characters – children really enjoy silly voices and it helps to capture and captivate the children’s attention. ',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: '9g07h',
                        text:
                          'Most importantly, have fun while reading and feel free to laugh a lot!',
                        type: 'unordered-list-item',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      },
                      {
                        key: 'sg3b',
                        text: '',
                        type: 'unstyled',
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {}
                      }
                    ],
                    entityMap: {}
                  }
                }
              }
            ]
          }
        ]
      }
    ]
  }
];
const centreDirectionContent = {
  north: {
    mainSection: {
      heading: 'Kindergartens in Singapore (North)',
      description: `<p>
          <br />
                There are several <a href="/" className="text-link"> Little Skool-House</a> campuses throughout Singapore. Options for Little Skool-House kindergarten in the North of Singapore include ones located at <a href="/our-centres/the-little-skool-house-at-the-hub-ang-mo-kio-hub" className="text-link">Ang Mo Kio Hub</a>, <a href="/our-centres/the-little-skool-house-on-the-green-orchid-country-club" className="text-link"> Orchid Country Club</a>, as well as POP Centres at <a href="/our-centres/the-little-skool-house-by-the-lake" className="text-link">Khoo Teck Puat Hospital</a> and <a href="/our-centres/the-little-skool-house-at-republic-polytechnic" className="text-link">Republic Polytechnic</a>.
          <br /> <br />
                These preschools are easily accessible to families living in the North East and North of Singapore around Yio Chu Kang, Bishan, Thomson, Yishun, Mandai, Woodlands, and Sembawang.
            </p>`
    },
    subSection: [
      {
        heading: 'Our Facilities and Curriculum',
        description: `<p>
          <br />
          At Little Skool-House preschools, two core <a href="/our-curriculum/curriculum-overview" className="text-link">curriculums</a> developed specifically for all campuses by pioneering and established ECDA fellows are followed closely by our teachers in the North and Northeast of Singapore. When enrolled in any one of our preschools, students are exposed to these comprehensive curriculums that have led to 93% of Little Skool-House children being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>. A range of <a href="/our-curriculum/enrichment-programme" className="text-link">enrichment programmes</a> and a Primary School Formation Programme is also offered in the kindergartens in the North to ensure that your child transitions seamlessly into a primary school in Singapore.
          <br /> <br/>
          Addressing the holistic developmental needs of children during their formative years, our pedagogy encourages divergent thinking amongst our students in our childcare centres in Singapore. In some of our campuses in the North, such as the ones in the Republic Polytechnic, Khoo Teck Puat, and Orchid Country Club, there are outdoor play areas that give children ample space to develop their spatial awareness and coordination.
        </p>`
      },
      {
        heading: 'Our Award-Winning Educators',
        description: `<p>
          <br />
          Our <a href="/our-difference/our-award-winning-educators" className="text-link">award-winning educators</a> go through professional development in four key areas: curriculum, quality, and professional development, as well as developing children’s learning assessment programmes. This ensures that all Little Skool-House educators are experienced and qualified in educating the younger generation. They passionately act as guiding leaders who constantly provide encouragement and offer support to every child, and their <a href="/about-us/our-awards" className="text-link">accolades</a> are a testament to their professionalism.
        </p>`
      },
      {
        heading: 'Choice Preschool Amongst Parents',
        description: `<p>
          <br/>
            Little Skool-House kindergartens and preschools in the North and North East are some of the  <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link">chosen preschools by top institutions</a>. Every Little Skool-House campus has its team of in-house educators that foster different teaching approaches to ensure preparedness for life and primary school in Singapore. With 27 years of experience and a selection of <a href="/about-us/our-successes" className="text-link">achievements</a>, Little Skool-House teachers will lead your child down the road to success.
        </p>`
      }
    ]
  },
  central: {
    mainSection: {
      heading: 'Preschools in Singapore (Central)',
      description: `<p>
        <br />
        There are several <a href="/" className="text-link"> Little Skool-House</a> campuses throughout Singapore. Options for Little Skool-House preschools in Central Singapore include ones located at <a href="/our-centres/the-little-skool-house-at-marina-boulevard-raffles-place" className="text-link">One Marina Boulevard</a>, <a href="/our-centres/the-little-skool-house-at-delta-house-alexandra-road" className="text-link"> Delta House</a>, as well as POP Centres at <a href="/our-centres/the-little-skool-house-at-ocbc-centre" className="text-link"> OCBC Centre at Raffles Place</a>, the <a href="/our-centres/the-little-skool-house-at-thomson" className="text-link"> Ministry of Social and Family Development</a>, <a href="/our-centres/the-little-skool-house-at-outram" className="text-link">Outram Community Hospital</a>, and <a href="/our-centres/the-little-skool-house-at-kk-hospital" className="text-link">KK Women’s and Children’s Hospital</a>. Of these preschools, both the OCBC Centre at Raffles Place and KK Women’s and Children’s Hospital offer an infant care programme in addition to the preschool programme.
        <br/> <br/>
        These kindergartens are easily accessible to families living in Central Singapore around Marina South, Bukit Merah, Outram, Orchard, Guilin, Toa Payoh, Rocher, Novena, and Marymount or parents working around the CBD area.
      </p>`
    },
    subSection: [
      {
        heading: 'Our Facilities and Curriculum',
        description: `<p>
          <br />
          Every part of our specially designed preschool <a href="/our-curriculum/curriculum-overview" className="text-link">curriculum</a> abides by our <a href="/about-us/our-vision" className="text-link">vision</a> to nurture bilingualism and promote contextualised learning. Our comprehensive bilingual curriculum developed by pioneering ECDA fellows has led to 93% of Little Skool-House children being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>.
          <br /> <br/>
            Beyond academics, most of our preschools in Singapore, especially those in the Central area, come with either an indoor or outdoor play area. By engaging children in indoor and outdoor activities, their senses are stimulated, and their education journey is made more enjoyable.
        </p>`
      },
      {
        heading: 'Our Award-Winning Educators',
        description: `<p>
          <br />
          Our <a href="/our-difference/our-award-winning-educators" className="text-link">award-winning educators</a> have gone through rigorous training, qualifying them as leaders in a child’s education journey. Having received several <a href="/about-us/our-awards" className="text-link">awards</a>, our qualified teachers will prepare your child for primary school and life.
        </p>`
      },
      {
        heading: 'Choice Preschool Amongst Parents',
        description: `<p>
          <br/>
            With over 27 years of experience under our belts, Little Skool-House will encourage your child to become a lifelong learner. To add to this, Little Skool-House is the  <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link">chosen preschool by some of the top institutions</a> in Singapore.
        </p>`
      }
    ]
  },
  east: {
    mainSection: {
      heading: 'Preschools in Singapore (East)',
      description: `<p>
        <br />
        There are several <a href="/" className="text-link"> Little Skool-House</a> campuses throughout Singapore. Options for Little Skool-House preschools in the East of Singapore include ones located at <a href="/our-centres/the-little-skool-house-at-mountbatten-square" className="text-link"> Mountbatten</a>, <a href="/our-centres/the-little-skool-house-at-tampines-junction" className="text-link"> Tampines Junction</a>, and <a href="/our-centres/the-little-skool-house-at-downtown-east" className="text-link"> Downtown East Pasir Ris</a>, as well as POP Centres at <a href="/our-centres/the-little-skool-house-at-ocbc-tampines" className="text-link"> OCBC Tampines Plaza</a> and <a href="/our-centres/the-little-skool-house-at-temasek-polytechnic" className="text-link"> Temasek Polytechnic</a>. Of these childcare centres in the East Coast of Singapore, our Mountbatten Square and Downtown East Pasir Ris branches offer infant care programmes.
        <br/> <br/>
        These preschools are easily accessible to families living in the East Coast and North East of Singapore around Boon Keng, Geylang, Marine Parade, Kembangan, Bedok, Siglap, Simei, and Pasir Ris.
      </p>`
    },
    subSection: [
      {
        heading: 'Our Facilities and Curriculum',
        description: `<p>
          <br />
            When enrolled in our infant care centres and kindergartens in Singapore, like that in the East, our educators expose children to a comprehensive <a href="/our-curriculum/curriculum-overview" className="text-link">curriculum</a>  that is based on thorough clinical research on child development. This curriculum designed specifically for Little Skool-House by established ECDA fellows has led to 93% of our children being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link"> Highly Proficient in both English and Mandarin</a>. Our childcare centres in the East of Singapore also offer a range of optional <a href="/our-curriculum/enrichment-programme" className="text-link"> enrichment programmes</a> that aid in building knowledge better.
          <br /> <br/>
            The <a href="/our-centres/our-learning-environment" className="text-link"> learning environment</a> in the Little Skool-House kindergartens in Singapore also fosters a sense of collaboration to promote positive social behaviour regardless if your child is on the East Coast campus or others.
        </p>`
      },
      {
        heading: 'Our Award-Winning Educators',
        description: `<p>
          <br />
          Our preschool and infant care <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> in the East Coast of Singapore go through rigorous pedagogical and curriculum training and learning journeys to master various teaching methodologies. Therefore, parents who enrol their child at the Little Skool-House kindergarten in the East Coast of Singapore can rest assured that the teachers at our learning centres will act as role models, offering support to every child.
        </p>`
      },
      {
        heading: 'Choice Preschool of Top Institutions',
        description: `<p>
          <br/>
          At our child and infant care centre in the East Coast and around Singapore, we believe that no child is too young to start learning. Being the <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link"> chosen preschool by top institutions</a> in Singapore, we believe in seizing the opportunity during a child’s formative years and communicating with parents effectively to guarantee preparedness for primary school.
        </p>`
      }
    ]
  },
  west: {
    mainSection: {
      heading: 'Preschools in Singapore (West)',
      description: `<p>
          <br />
              There are several <a href="/" className="text-link">Little Skool-House</a> campuses throughout Singapore. Options for Little Skool-House kindergarten in the West coast of Singapore include ones located at <a href="/our-centres/the-little-skool-house-at-devan-nair-institute" className="text-link">  Devan Nair Institute</a> and <a href="/our-centres/the-little-skool-house-by-the-vista-ulu-pandan-cc" className="text-link"> Buona Vista</a>, as well as POP Centres at the <a href="/our-centres/the-little-skool-house-at-ministry-of-education" className="text-link">MOE building</a>, <a href="/our-centres/the-little-skool-house-at-alexandra" className="text-link">Alexandra Hospital</a>, <a href="/our-centres/the-little-skool-house-at-kent-vale" className="text-link">National University of Singapore</a> and <a href="/our-centres/the-little-skool-house-at-ngee-ann-polytechnic" className="text-link">  Ngee Ann Polytechnic</a>. Of these childcare centres in the West, our Devan Nair Institute, By-The-Vista and Ngee Ann Polytechnic centre offer an infant care programme for those living in the West Coast of Singapore in addition to the preschool programme.
              <br /> <br />
              These childcare centres in Singapore are easily accessible to families living in West around Ghim Moh, Queenstown, Jurong East, Clementi and Alexandra.
            </p>`
    },
    subSection: [
      {
        heading: 'Our Facilities and Curriculum',
        description: `<p>
          <br />
          The two core <a href="/our-curriculum/curriculum-overview" className="text-link">curriculums</a> followed by our educators were developed by pioneering ECDA fellows, and has led to 93% of Little Skool-House children being Highly Proficient in English and Mandarin. In addition, the By-The-Vista child and infant care in Ulu Pandan Community Club (Singapore) is also an accredited IB school in the West that offers the PYP programme for Nursery 2 to Kindergarten 2 children.
          <br /> <br/>
            Our <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a> fosters a sense of collaboration and aid in improving key competencies. This collaborative environment at the Little Skool-House Kindergartens in Singapore, like the ones located in the West Coast, will create enriching experiences for your child.
        </p>`
      },
      {
        heading: 'Our Award-Winning Educators',
        description: `<p>
          <br />
          Our preschool and infant care <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> in the West of Singapore go through rigorous training, continuous professional development, and one-to-one mentoring to achieve teaching mastery, ensuring that they are up to par with the educators’ standards set by MOE. Amongst the many <a href="/about-us/our-awards" className="text-link">awards</a>, The Little Skool-House has been awarded the ECDA Outstanding and Promising Early Childhood Educator Awards for 9 consecutive years.
        </p>`
      },
      {
        heading: 'Choice Preschool Amongst Parents',
        description: `<p>
          <br/>
          With the Primary School Formation Programme and Pedagogy for Enhanced Mandarin Acquisition, Little Skool-House early years centres and kindergartens in Singapore is the <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link">choice preschool by top institutions</a> and for parents looking for a bilingual preschool in the West.
        </p>`
      }
    ]
  }
}

const centreDirectionContentCn = {
  north: {
    mainSection: {
      heading: '新加坡（北）幼儿园',
      description: `<p>
          <br />
            新加坡有几个 <a href="/zh" className="text-link"> 小小学庭</a> 校区。新加坡北部小小学庭幼儿园的选择包括位于<a href="/zh/womendezhongxin/hujixiangcunjulebuzhongxin" className="text-link"> 兰花乡村俱乐部</a> 的幼儿园，以及 <a href="/zh/womendezhongxin/qiudebayiyuanzhongxin" className="text-link">邱德拔医院</a>和<a href="/zh/womendezhongxin/gongheligongxueyuanzhongxin" className="text-link">共和理工学院</a>.
          <br /> <br />
          居住在新加坡东北部和北部的杨厝港、碧山、汤姆逊、义顺、万礼、兀兰和三巴旺附近的家庭可以轻松抵达这些幼儿园。            
        </p>`
    },
    subSection: [
      {
        heading: '我们的设施和课程',
        description: `<p>
          <br />
          在小小学庭幼儿园，我们在新加坡北部和东北部的教师密切关注由先驱和成熟的ECDA研究员为所有校园开发的两个核心<a href="/zh/womendekecheng/kechengkuangjiagaishu" className="text-link">课程</a>。 入读我们的任何一所幼儿园时，学生都会接触到这些综合课程，这些课程使93%的小小学庭<a href="/zh/womendeyuzhongbutong/jīngtongyingyuhehuayu" className="text-link">儿童精通英语和普通话</a>。北部的幼儿园还提供一系<a href="/zh/womendekecheng/zengyikecheng" className="text-link">列丰富课程</a> 和小学教育课程，以确保您的孩子顺利过渡到新加坡的小学。
          <br /> <br/>
          为了满足儿童在成长时期的整体发展需求，我们的教学法鼓励新加坡儿童保育中心的学生进行不同的思考。我们在北部的一共和理工学院，邱德拔医院和胡姬乡村俱乐部，都有户外游乐区，为孩子们提供了足够的空间来培养他们的空间意识和协调能力。
          </p>`
      },
      {
        heading: '我们屡获殊荣的教育工作者',
        description: `<p>
          <br />
          我们 <a href="/zh/womendeyuzhongbutong/womendehuojiangjiaoshi" className="text-link">屡获殊荣的教育工作者</a> 在四个关键领域进行专业发展：课程、质量和专业发展，以及制定儿童学习评估计划。这确保了所有小小学庭教育工作者在教育年轻一代方面经验丰富且合格。他们热情地充当指导领导者，不断为每个孩子提供鼓励和支持，他们的<a href="/zh/guanyuwomen" className="text-link">荣誉</a> 证明了他们的专业精神。
        </p>`
      },
      {
        heading: '家长选择学前班',
        description: `<p>
          <br/>
          北部和东北部的小小学庭幼儿园和学前班是  <a href="/zh/womendeyuzhongbutong/dingjianjigouxuanzedeyoueryuan" className="text-link">一些顶级机构选择的学前班</a>。每个小小学庭校园都有自己的内部教育者团队，他们培养不同的教学方法，以确保为新加坡的生活和小学做好准备。小小学庭教师拥有超过25年的经验和一系列 <a href="/zh/womendeyuzhongbutong/weishemexuanzexiaoxiaoxueting" className="text-link">成就</a>，将带领您的孩子走向成功之路。
        </p>`
      }
    ]
  },
  central: {
    mainSection: {
      heading: '新加坡学前班（中区)',
      description: `<p>
        <br />
        新加坡有几个 <a href="/zh" className="text-link"> 小小学庭</a> 校校区。新加坡中部的小小学庭校幼儿园的选择包括位于 <a href="/zh/womendezhongxin/binhailinyindaozhongxin" className="text-link">滨海林荫道（莱佛士坊)</a>、 <a href="/zh/womendezhongxin/lidadashazhongxinyalishandafang" className="text-link"> 立达大厦中心 (亚历山大坊) </a>以及位于莱佛士坊 <a href="/zh/womendezhongxin/huaqiaoyinhangyouerzhongxin" className="text-link"> 华侨银行中心</a>、 <a href="/zh/womendezhongxin/shehuijijiatingfazhanbuyouerzhongxintangshen" className="text-link"> 社会和家庭发展部</a>、 <a href="/zh/womendezhongxin/ounanshequyiyuanzhongxin" className="text-link">欧南社区医院l</a>、 和 <a href="/zh/womendezhongxin/zhujiaofuyouyiyuanzhongxin" className="text-link">竹脚妇幼医院中心</a>。在这些幼儿园中，莱佛士坊的华侨银行中心和 竹脚妇幼医院中心都提供除学前班计划之外的婴儿护理计划。
        <br/> <br/>
        居住在新加坡中部滨海南、红山、欧南、乌节路、桂林、大巴窑、梧槽、诺维娜和玛丽蒙附近的家庭或在中央商务区作的父母可以轻松抵达这些幼儿园。
        </p>`
    },
    subSection: [
      {
        heading: '我们的设施和课程',
        description: `<p>
          <br />
          我们专门设计的学前 <a href="/zh/womendekecheng/kechengkuangjiagaishu" className="text-link">课程</a> 的每个部分都遵循我们培养双语和促进情境化学习的 <a href="/zh/guanyuwomen" className="text-link">愿景</a>。我们由领先的ECDA研究员开发的综合双语课程已使93%的小小学庭 <a href="/zh/womendeyuzhongbutong/jīngtongyingyuhehuayu" className="text-link">儿童精通英语和普通话</a>。
          <br /> <br/>
          除了学术之外，我们在新加坡的大多数幼儿园，尤其是中区的幼儿园，都设有室内或室外游乐区。通过让孩子们参与室内和室外活动，他们的感官得到了刺激，他们的教育之旅也变得更加愉快。
          </p>`
      },
      {
        heading: '我们屡获殊荣的教育工作者',
        description: `<p>
          <br />
          我们 <a href="/zh/womendeyuzhongbutong/womendehuojiangjiaoshi" className="text-link">屡获殊荣的教育工作者</a> 经过严格的培训，使他们有资格成为儿童教育之旅的领导者。我们的合格教师已获得多 <a href="/zh/guanyuwomen" className="text-link">项奖项</a>，将为您的孩子上小学和生活做好准备。
        </p>`
      },
      {
        heading: '家长选择学前班',
        description: `<p>
          <br/>
          小小学庭拥有超过25年的经验，将鼓励您的孩子成为终身学习者。此外，小小学庭是新加坡  <a href="/zh/womendeyuzhongbutong/dingjianjigouxuanzedeyoueryuan" className="text-link">一些顶级机构选择的幼儿园</a>。
        </p>`
      }
    ]
  },
  east: {
    mainSection: {
      heading: '新加坡（东）学前班',
      description: `<p>
        <br />
        新加坡有几个 <a href="/zh" className="text-link"> 小小学庭</a> 校区。新加坡东部小小学庭幼儿园的选择包括位于 <a href="/zh/womendezhongxin/mengbadengguangchangzhongxin" className="text-link"> 蒙巴顿</a>、<a href="/zh/womendezhongxin/danbinnizhizongyingkangzhongxin" className="text-link"> 淡滨尼路口</a>和<a href="/zh/womendezhongxin/baishaleyidujiacunzhongxin" className="text-link"> 东巴西立市中心</a>的幼儿园，以及位于 <a href="/zh/womendezhongxin/huaqiaoyinhangzhongxindanbinni" className="text-link"> 华侨银行淡滨尼广场</a>和<a href="/zh/womendezhongxin/danmaxiligongxueyuanzhongxin" className="text-link">淡马锡理工学院</a>的工作中心。在新加坡东海岸的这些托儿中心中，我们的蒙巴顿广场和东巴西立市中心分行提供婴儿护理计划。
        <br/> <br/>
        居住在新加坡东海岸和东北部附近文庆、芽笼、马林百列、肯邦安、勿洛、实谷、四美和巴西立的家庭可以轻松抵达这些幼儿园。
      </p>`
    },
    subSection: [
      {
        heading: '我们的设施和课程',
        description: `<p>
          <br />
          当我们在新加坡的婴儿护理中心和幼儿园注册时，就像在东方一样，我们的教育工作者让孩子们接触到基于对儿童发展的全面临床研究的综合<a href="/zh/womendekecheng/kechengkuangjiagaishu" className="text-link">课程</a>。这个由ECDA资深研究员专门为小小学庭设计的课程使我们93%的孩子<a href="/zh/womendeyuzhongbutong/jīngtongyingyuhehuayu" className="text-link">精通英语和普通话</a>。我们位于新加坡东部的托儿中心还提供一系列可选的<a href="/zh/womendekecheng/zengyikecheng" className="text-link">丰富课程</a>，有助于更好地积累知识。
          <br /> <br/>
          新加坡小小学庭幼儿园的<a href="/zh/womendezhongxin/womendexuexihuanjing" className="text-link">学习环境</a>还培养了一种协作意识，以促进积极的社会行为，无论您的孩子是在东海岸校区还是其他校区。
        </p>`
      },
      {
        heading: '我们屡获殊荣的教育工作者',
        description: `<p>
          <br />
          我们在新加坡东海<a href="/zh/womendeyuzhongbutong/womendehuojiangjiaoshi" className="text-link">岸屡获殊荣的学前和婴儿护理教育工作者</a>经过严格的教学和课程培训以及学习之旅，以掌握各种教学方法。因此，让孩子在新加坡东海岸的小小学庭幼儿园注册的家长可以放心，我们学习中心的老师会以身作则，为每个孩子提供支持。
        </p>`
      },
      {
        heading: '顶级机构的选择幼儿园',
        description: `<p>
          <br/>
          在我们位于东海岸和新加坡周边的儿童和婴儿护理中心，我们相信没有孩子太小而不能开始学习。作为<a href="/zh/womendeyuzhongbutong/dingjianjigouxuanzedeyoueryuan" className="text-link">新加坡顶级机构选择的学前班</a> ，我们相信在孩子的成长时期抓住机会并与父母有效沟通，以确保为小学做好准备。
        </p>`
      }
    ]
  },
  west: {
    mainSection: {
      heading: '新加坡（西部）的学前班',
      description: `<p>
        <br />
        新加坡有几个<a href="/zh" className="text-link">小小学庭</a>校区。新加坡西海岸小小学庭幼儿园的选择包括位于<a href="/zh/womendezhongxin/difannaxueyuanzhongxin" className="text-link">蒂凡娜学院</a>和<a href="/zh/womendezhongxin/wulubandanshequjulebuzhongxin" className="text-link">波那维斯达</a>的幼儿园，以及位于<a href="/zh/womendezhongxin/jiaoyubuzongbuzhongxin" className="text-link">教育部大楼</a>、<a href="/zh/womendezhongxin/yalishandayiyuanzhongxin" className="text-link">亚历山德拉医院</a>、<a href="/zh/womendezhongxin/guodakentegangzhongxin" className="text-link">新加坡国立大学</a>和<a href="/zh/womendezhongxin/yianligongxueyuanzhongxin" className="text-link">义安理工学院</a>的工作中心。在西部的这些托儿中心中，我们的蒂凡娜学院中心、乌鲁班丹社区俱乐部中心和淡马锡理工学院中心除了学前班计划外，还为新加坡西海岸的儿童提供婴儿护理计划。
        <br /> <br />
        居住在锦茂、皇后镇、裕廊东、金文泰和亚历山德拉附近的西部家庭可以轻松抵达新加坡的这些托儿中心。
      </p>`
    },
    subSection: [
      {
        heading: '我们的设施和课程',
        description: `<p>
          <br />
          我们的教育工作者遵循的两个核心<a href="/zh/womendekecheng/kechengkuangjiagaishu" className="text-link">课程</a>是由先驱ECDA研究员开发的，已使93%的小小学庭儿童精通英语和普通话。此外，位于(新加坡) 的乌鲁班丹社区俱乐部儿童和婴儿护理也是西方认可的 IB 学校，为幼儿园 2 至幼儿园 2 的孩子提供 PYP 课程。
          <br /> <br/>
          我们的 <a href="/zh/womendezhongxin/womendexuexihuanjing" className="text-link">学习环境</a>培养协作意识并有助于提高关键能力。新加坡小小学庭幼儿园的这种协作环境，就像位于西海岸的幼儿园一样，将为您的孩子创造丰富的体验。
        </p>`
      },
      {
        heading: '我们屡获殊荣的教育工作者',
        description: `<p>
          <br />
          我们在新加坡西部<a href="/zh/womendeyuzhongbutong/womendehuojiangjiaoshi" className="text-link">屡获殊荣的学前和婴儿护理教育工作者</a>经过严格的培训、持续的专业发展和一对一的指导，以达到教学精通，确保他们达到教育部制定的教育工作者标准.在众多奖项中，小小学庭已连续 8 年获得 ECDA 杰出和有前途的幼儿教育工作者奖。
        </p>`
      },
      {
        heading: '家长选择学前班',
        description: `<p>
          <br/>
          新加坡的小小学庭早教中心和幼儿园通过小学形成计划和加强普通话学习的教学法，成为顶<a href="/zh/womendeyuzhongbutong/dingjianjigouxuanzedeyoueryuan" className="text-link">级机构和在西方寻找双语幼儿园的父母的首选幼儿园</a>。
        </p>`
      }
    ]
  }
}

const centerIbFAQs = [
  {
    q: `What are the objectives of the International Baccalaureate (IB)?`,
    a: [
      ``
    ],
    id: 'iBMission'
  },
  {
    q: `What are the IB Learner Profiles?`,
    a: [
      `The aim for the IB programme is to develop international minded people who recognise their common humanity and shared guardianship of the planet, and help to create a better and more peaceful world.`
    ],
    id: 'iBLearner'
  },
  {
    q: `What is the International Baccalaureate Primary Years Programme (IB PYP)?`,
    a: [
      `The IB PYP prepares children to be active participants in their lifelong learning journey. It focuses on the development of the whole child as an inquirer both in the classroom and in the world outside.`,
      `The curriculum structure strives for a balance between the search for understanding, the acquisition of essential knowledge and skills, the development of positive dispositions, and the opportunity for positive action.`
    ],
    id: 'iBPrimary'
  },
  {
    q: `What are the pillars of the PYP framework?`,
    a: [
      ``
    ],
    id: 'iBPyp'
  },
  {
    q: `What are the essential elements of the IB PYP?`,
    a: [
      ``
    ],
    id: 'iBEpyp'
  },
  {
    q: `What is the concept of Learner Agency?`,
    a: [
      `It is believed that our children are agents for their own and others’ learning. Learners with agency have the following mindset and traits: `
    ],
    id: 'iBAgency'
  }
];

const centerIbFAQsCn = [
  {
    q: `国际文凭的目标为何?`,
    a: [
      ``
    ],
    id: 'iBMission'
  },
  {
    q: `国际文凭学习者培养目标为何?`,
    a: [
      `国际文凭课程旨在培养具有国际情怀的人，他们承认人类共有的博爱精神，分担守护地球的责任，帮助创造一个更美好、更和平的世界。`
    ],
    id: 'iBLearner'
  },
  {
    q: `国际文凭小学项目（IB PYP）为何?`,
    a: [
      `国际文凭小学项目将孩子培养为一个终身学习的积极参与者，注重在课堂内外将儿童培养成为全面发展的探究者。`,
      `课程结构力求在理解的追寻、基础知识与技能的获得、正面性格的发展及积极行动的机会之间取得平衡。`
    ],
    id: 'iBPrimary'
  },
  {
    q: `小学项目（PYP）课程框架的支柱为何?`,
    a: [
      ``
    ],
    id: 'iBPyp'
  },
  {
    q: `国际文凭小学项目的基本元素为何?`,
    a: [
      ``
    ],
    id: 'iBEpyp'
  },
  {
    q: `学习者能动性的概念为何?`,
    a: [
      `孩子是自己与他人学习的能动者。具有能动性的学习者具备以下的思维及特征：`
    ],
    id: 'iBAgency'
  }
];

const centreDetails = {
  north: {
    centerList: [
      {
        name: `On-The-Green`,
        location: `Orchid Country Club`,
        name2: ``,
        slug: `the-little-skool-house-on-the-green-orchid-country-club`,
        pics: 4,
        defaultPic: 2,
        awards: ['SPARK', 'HEALTH'],
        address: [`1 Orchid Club Road`, `Singapore 769162`],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.602091844304!2d103.84447921523602!3d1.4147499617563983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da14181e028ead%3A0xb52a3b9e684504f9!2s1%20Orchid%20Club%20Rd%2C%20Singapore%20769162!5e0!3m2!1sen!2ssg!4v1585021185926!5m2!1sen!2ssg`,
        contact: `6759 0393`,
        email: `lshocc@littleskoolhouse.com`,
        operatingHours: [
          `Mondays to Fridays: 7am to 7pm`,
          `Saturdays: 7am to 2pm`
        ],
        programmesOffered: [
          `Preschool (18 months to 6 years) - Full Day, Half Day`
        ],
        faqs: [
          {
            q: `Is there a school bus service for this centre?`,
            a: [
              `Yes, school bus service is available for nearby estates. There is also a free shuttle bus service for Orchid Country Club visitors from Yishun MRT Station`
            ]
          },
          {
            q: `Are there structured swimming lessons at this centre?`,
            a: [
              `No, however our Nursery 2 - Kindergarten 2 children will use the club's swimming facilities once a week for their water play activity.`
            ]
          },
          {
            q: `How do I get to the Orchid Country Club preschool?`,
            a: [
              `Located minutes away from Khatib MRT station, the Little Skool-House Orchid Country Club preschool can be conveniently accessed by families residing in Yishun, Sembawang, and Punggol. Bus service 811, 117, and 807 opposite Khatib Station is available. Bus service 811 can also be accessed from Yishun MRT station, while 117 can
              be taken from opposite Yishun station. There is also a free shuttle bus service for OCC visitors from Yishun MRT station. Families living in nearby estates can access the school bus service as well.`
            ]
          }
        ],
        principal: [
          {
            name: `Avril Wong`,
            url: `avril-wong-on-the-green`,
            title: `Principal`,
            qualifications: [
              `Bachelor of Science in Early Childhood Education`
            ],
            description: [
              `Since joining the early childhood industry in 2003, Avril has worked in both kindergarten and childcare settings and has held teaching and principal positions. She believes in fostering open communication, pursuing a hands-on approach to mentoring her team and promoting  parental and community involvement.`
            ]
          },
          {
            name: `Shen Pei Feng`,
            url: `shen-pei-feng-on-the-green`,
            title: `Vice Principal`,
            qualifications: [
              `Bachelor's Degree in Early Childhood Education (B.ECE)`
            ],
            description: [
              `Backed by a 25-year experience in early childhood education, Pei Feng was an exemplary Chinese Language teacher before she took on the Vice Principal position. This advantage gives her the edge in implementing a quality bilingual programme in the centre where she works.`
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading: 'What Sets our Orchid Country Club Preschool Apart',
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> in OCC has been awarded the Healthy Pre-Schools (HPS) Accreditation and the Spark certification. This is a testament to the effort that is put in by our <a href="/our-difference/our-award-winning-educators" className="text-link">award-winning educators and teachers</a> to constantly update the education landscape to keep up with the changing educational trends and pedagogies.<br/><br/>
          The <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a> in our Orchid Country Club (OCC) preschool was designed to facilitate specialised learning. Featuring a swimming pool and outdoor playground, children’s skills are developed in a multitude of ways. Preschool students (Nursery 2 to Kindergarten 2) will use the swimming facility on a weekly basis for water play activities that help improve coordination, balance, and instil the spirit of sportsmanship from a young age.
        <p>`,
          programmesOffered: `<p>
          <br/>
        At our Orchid Country Club campus, we offer full-day and half-day programmes for preschoolers aged between 18 months and 6 years old. Our highly effective <a href="/our-curriculum/curriculum-overview" className="text-link">curriculum</a> that has led to 93% of our children being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a> ensures that they are confident learners equipped with strong social and emotional skills essential for primary school and life. We also take pride in our wide range of <a href="/our-curriculum/enrichment-programme" className="text-link">enrichment programmes</a> that develop a global perspective amongst our preschoolers.
      </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-on-the-green-orchid-country-club',
          title:
            'On the Green Orchid Country Club Preschool (North) | The Little Skool-House',
          description:
            'The curriculum implemented at our Orchid Country Club Preschool has led to 93% of our students gaining High Bilingual Proficiency. Schedule a visit today.'
        }
      },
      {
        name: `By-The-Lake`,
        location: `Khoo Teck Puat Hospital`,
        name2: `POP Centre`,
        slug: `the-little-skool-house-by-the-lake`,
        pics: 4,
        defaultPic: 2,
        awards: ['SPARK', 'HEALTH'],
        temporaryLocation: true,
        address: [
          `2 Yishun Central 2, Yishun Community`,
          `Hospital Tower E, Level 5, Staff Lounge`,
          `Singapore 768024`
        ],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.585351559843!2d103.83582811523603!3d1.4244535617268208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da146c72e65b53%3A0x3ded1eaed9a0a225!2s90%20Yishun%20Central%2C%20Singapore%20768828!5e0!3m2!1sen!2ssg!4v1585026973854!5m2!1sen!2ssg`,
        contact: `6570 8513`,
        email: `lshktph@littleskoolhouse.com`,
        operatingHours: [
          `Mondays to Fridays: 7am to 7pm`,
          `Saturdays: 7am to 2pm`
        ],
        programmesOffered: [
          `Preschool (18 months to 6 years) - Full Day`,
          `Infant Care (2 months to 17 months) - Full Day`
        ],
        faqs: [
          {
            q: `How do I get to the infant & childcare centre in Yishun?`,
            a: [
              `Located minutes away from Yishun MRT station, the Little Skool-House preschool at Khoo Teck Puat Hospital can be conveniently accessed by families residing in Khatib, Canberra, Punggol, Sengkang, and Sembawang. Bus service 883 from Yishun station or 169 and 858 opposite Yishun MRT station are also available.`
            ]
          }
        ],
        principal: [
          {
            name: `Rina Lin`,
            url: `rina-lin-by-the-lake`,
            title: `Principal`,
            qualifications: [
              `Bachelor of Science in Early Childhood Education and Leadership (B.SC. in ECE)`
            ],
            description: [
              `Inspired by the quote: “It takes a whole village to raise a child.”, Rina has been an advocate of collective responsibility when it comes to child care. She puts special emphasis on collaborating with parents, educators, the community and the physical environment.`
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading: 'What Sets our Yishun Preschool Apart',
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> in Yishun, is located at Khoo Teck Puat Hospital, a public hospital managed by Alexandra Health. To help develop your child into an active learner, our childcare centre in Yishun comes equipped with an outdoor play area and a conducive <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a>, where our award-winning educators encourage children to discover and explore their environment through experiential and stimulating activities.<br/><br/>
        The Little Skool-House preschool in Yishun has been awarded the Healthy Pre-Schools (HPS) Accreditation, indicating active promotion of healthy living. It is also Spark certified, signifying standards that meet the baseline quality set out by the Early Childhood Development Agency (ECDA). <br/><br/>
        Being the <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link">chosen preschool by top institutions</a>, the Little Skool-House campus at Khoo Teck Puat Hospital, is the choice preschool for employees of our workplace partner in Yishun. Established to provide working parents with childcare services during working hours, our POP Centre in Yishun is a convenient option.
        </p>`,
          programmesOffered: `<p>
          <br/>
          A full-day infant care programme is offered at our learning centre in Yishun for kids aged between 2 to 17 months. With an extensive activity schedule for infants, the Little Skool-House pedagogy builds self-confidence and independence during a child’s early years. In addition to our highly effective curriculum for preschoolers, aged 18 months and above, that has led to 93% of our children being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>, we also take pride in our vast array of <a href="/our-curriculum/enrichment-programme" className="text-link">preschool enrichment programmes</a> that encourage curiosity and inquiry and builds better knowledge.
       </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-by-the-lake',
          title:
            'Preschool in Khoo Teck Puat Hospital (North-Yishun) | The Little Skool-House',
          description:
            'Being the chosen preschool by top institutions, the Little Skool-House preschool in Yishun ensures preparedness for primary school and life. Enrol today.'
        }
      },
      {
        name: `At-Republic-Polytechnic`,
        location: `Republic Polytechnic`,
        name2: `POP Centre`,
        slug: `the-little-skool-house-at-republic-polytechnic`,
        pics: 4,
        defaultPic: 2,
        awards: ['HEALTH'],
        address: [
          `17 Woodlands Avenue 9`,
          `#01-01, Carpark P4`,
          `Singapore 738968`
        ],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.55264962255!2d103.78305681523604!3d1.4432212616691786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1305aa7388d7%3A0x2b7f5e0463382f8!2sRepublic%20Polytechnic!5e0!3m2!1sen!2ssg!4v1585028114045!5m2!1sen!2ssg`,
        contact: `6219 8136`,
        email: `lshrep01@littleskoolhouse.com`,
        operatingHours: [
          `Mondays to Fridays: 7am to 7pm`,
          `Saturdays: 7am to 2pm`
        ],
        programmesOffered: [
          `Preschool (18 months to 6 years) - Full Day`,
          `Infant Care (2 months to 17 months) - Full Day`
        ],
        faqs: [
          {
            q: `How do I get to the Infant & Childcare Centre in Woodlands?`,
            a: [
              `Located minutes away from Woodlands MRT station, Little Skool-House preschool at Republic Polytechnic can be conveniently accessed by families residing in Admiralty, Marsiling, and Sembawang. Bus service 169 is also available from Woodlands Interchange.`
            ]
          }
        ],
        principal: [
          {
            name: `Tan Chu Jun`,
            url: `tan-chu-jun-at-republic-polytechnic`,
            title: `Principal`,
            qualifications: [
              `Bachelor of Science in Early Childhood Education  (B.Sc in ECE)`
            ],
            description: [
              `Chu Jun believes in playing an active role in engaging families and the community to support children in their growth and learning. Together as partners, imagination, laughter and joy for learning will be ignited.`
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading: 'What Sets our Preschool in Woodlands Apart',
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> childcare centre in Woodlands, is located at the Republic Polytechnic, a major tertiary institution in Singapore. Placing value on fun activities to nurture a child’s mind, our preschool in Woodlands features well-equipped classrooms and playrooms, as well as an outdoor playground where our <a href="/our-difference/our-award-winning-educators" className="text-link">award-winning educators</a> conduct activities that foster healthy social and emotional development.<br/><br/>
          Research has shown a correlation between a child’s health and their ability to excel in academics. Awarded the Healthy Pre-Schools (HPS) Accreditation, our early childhood learning centre at Republic Polytechnic possesses a comprehensive school health promotion practice. <br/><br/>
          Being the <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link">chosen preschools by top institutions</a>, the Little Skool-House campus at Republic Polytechnic is the choice preschool for employees of our POP Centre partner in Woodlands. Established to provide working parents in Singapore with childcare services during working hours, our POP Centre in Republic Polytechnic Woodlands is a convenient option.
        </p>`,
          programmesOffered: `<p>
          <br/>
            At the Little Skool-House preschool in Woodlands, we offer a full-day infant care programme for children starting from 2 months. Our extensive <a href="/our-curriculum/infant–3-years-relationships-based-curriculum" className="text-link">Relationships-Based curriculum</a> for infants is aimed at developing a secure foundation required for proper learning and growth in a child’s formative years. These comprehensive bilingual programmes have led to 93% of Little Skool-House preschoolers being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>. In addition to the infant care programme, the Little Skool-House preschool in Woodlands and all around Singapore, offers a range of enrichment programmes for a more holistic development.
        </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-republic-polytechnic',
          title:
            'Workplace Preschool in Republic Polytechnic (North-Woodlands) | The Little Skool-House',
          description:
            'Little Skool-House is the chosen preschool by the top institution in Woodlands, Republic Polytechnic of Singapore. Schedule a visit to our POP Centre.'
        }
      }
      // {
      //   name: `At-The-Hub`,
      //   location: `Ang Mo Kio Hub`,
      //   name2: ``,
      //   slug: `the-little-skool-house-at-the-hub-ang-mo-kio-hub`,
      //   pics: 3,
      //   defaultPic: 3,
      //   awards: ['SPARK', 'HEALTH'],
      //   address: [`53 Ang Mo Kio Ave 3`, `#03-22`, `Singapore 569933`],
      //   map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.6784191028446!2d103.8460155152362!3d1.3696355618919023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da16e6c4a66681%3A0xf11abbbc2db3390b!2s53%20Ang%20Mo%20Kio%20Ave%203%2C%20Singapore%20569933!5e0!3m2!1sen!2ssg!4v1585020891198!5m2!1sen!2ssg`,
      //   contact: `6853 8131`,
      //   email: `lshamk@littleskoolhouse.com`,
      //   operatingHours: [
      //     `Mondays to Fridays: 7am to 7pm`,
      //     `Saturdays: 7am to 2pm`
      //   ],
      //   programmesOffered: [`Preschool (18 months to 6 years) - Full Day`],
      //   principal: [
      //     {
      //       name: `Jocelyn Lim`,
      //       url: `jocelyn-lim-at-the-hub`,
      //       title: `Principal`,
      //       qualifications: [
      //         `Bachelor of Arts degree in Early Childhood Education`
      //       ],
      //       description: [
      //         `Starting as an intern from Ngee Ann Polytechnic, Jocelyn's 10-year journey at The Little Skool-House has been guided by her belief that all children are unique and have the desire to learn and acquire new skills. She strives for strong home-school partnerships to provide every child in the centre a holistic education.`
      //       ]
      //     }
      //   ],
      //   scheduleAVisit: false
      // }
    ],
    data: {
      mainSection: {
        heading: 'Kindergartens in Singapore (North)',
        description: `<p>
          <br />
                There are several <a href="/" className="text-link"> Little Skool-House</a> campuses throughout Singapore. Options for Little Skool-House kindergarten in the North of Singapore include ones located at <a href="/our-centres/the-little-skool-house-at-the-hub-ang-mo-kio-hub" className="text-link">Ang Mo Kio Hub</a>, <a href="/our-centres/the-little-skool-house-on-the-green-orchid-country-club" className="text-link"> Orchid Country Club</a>, as well as POP Centres at <a href="/our-centres/the-little-skool-house-by-the-lake" className="text-link">Khoo Teck Puat Hospital</a> and <a href="/our-centres/the-little-skool-house-at-republic-polytechnic" className="text-link">Republic Polytechnic</a>.
          <br /> <br />
                These preschools are easily accessible to families living in the North East and North of Singapore around Yio Chu Kang, Bishan, Thomson, Yishun, Mandai, Woodlands, and Sembawang.
            </p>`
      },
      subSection: [
        {
          heading: 'Our Facilities and Curriculum',
          description: `<p>
          <br />
          At Little Skool-House preschools, two core <a href="/our-curriculum/curriculum-overview" className="text-link">curriculums</a> developed specifically for all campuses by pioneering and established ECDA fellows are followed closely by our teachers in the North and Northeast of Singapore. When enrolled in any one of our preschools, students are exposed to these comprehensive curriculums that have led to 93% of Little Skool-House children being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>. A range of <a href="/our-curriculum/enrichment-programme" className="text-link">enrichment programmes</a> and a Primary School Formation Programme is also offered in the kindergartens in the North to ensure that your child transitions seamlessly into a primary school in Singapore.
          <br /> <br/>
          Addressing the holistic developmental needs of children during their formative years, our pedagogy encourages divergent thinking amongst our students in our childcare centres in Singapore. In some of our campuses in the North, such as the ones in the Republic Polytechnic, Khoo Teck Puat, and Orchid Country Club, there are outdoor play areas that give children ample space to develop their spatial awareness and coordination.
        </p>`
        },
        {
          heading: 'Our Award-Winning Educators',
          description: `<p>
          <br />
          Our <a href="/our-difference/our-award-winning-educators" className="text-link">award-winning educators</a> go through professional development in four key areas: curriculum, quality, and professional development, as well as developing children’s learning assessment programmes. This ensures that all Little Skool-House educators are experienced and qualified in educating the younger generation. They passionately act as guiding leaders who constantly provide encouragement and offer support to every child, and their <a href="/about-us/our-awards" className="text-link">accolades</a> are a testament to their professionalism.
        </p>`
        },
        {
          heading: 'Choice Preschool Amongst Parents',
          description: `<p>
          <br/>
            Little Skool-House kindergartens and preschools in the North and North East are some of the  <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link">chosen preschools by top institutions</a>. Every Little Skool-House campus has its team of in-house educators that foster different teaching approaches to ensure preparedness for life and primary school in Singapore. With over 27 years of experience and a selection of <a href="/about-us/our-successes" className="text-link">achievements</a>, Little Skool-House teachers will lead your child down the road to success.
        </p>`
        }
      ]
    }
  },
  central: {
    centerList: [
      {
        name: `At-Marina-Boulevard`,
        location: `One Marina Boulevard - Raffles Place`,
        name2: ``,
        slug: `the-little-skool-house-at-marina-boulevard-raffles-place`,
        pics: 4,
        defaultPic: 2,
        address: [`No.1 Marina Boulevard`, `#B1-01`, `Singapore 018989`],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8192980414783!2d103.85046261523644!3d1.2822057621450356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da190fb22a7bc5%3A0x1d643baa61eb0f17!2s1%20Marina%20Blvd%2C%20Singapore%20018989!5e0!3m2!1sen!2ssg!4v1585028845366!5m2!1sen!2ssg`,
        contact: `6221 4424`,
        email: `lshomb01@littleskoolhouse.com`,
        operatingHours: [`Mondays to Fridays: 7am to 7:30pm`],
        programmesOffered: [`Preschool (18 months to 6 years) - Full Day`],
        faqs: [
          {
            q: `How do I get to the childcare centre at Marina Boulevard?`,
            a: [
              `Just a walking distance away from Raffles Place, Telok Ayer, and Downtown MRT station, the Little Skool-House Marina Boulevard kindergarten can be conveniently accessed by families residing in the Central Area, Tanjong Pagar, and Outram. Bus service 186 is also available from Hong Lim Park.`
            ]
          }
        ],
        principal: [
          {
            name: `Cindy Goh`,
            url: `cindy-goh-at-marina-boulevard`,
            title: `Principal`,
            qualifications: [
              `Bachelor of Science in Early Childhood Education and Leadership Studies (B.SC. in EC)`
            ],
            description: [
              `Cindy believes that children need a safe and stimulating environment in order to grow and mature holistically. She  encourages children to ask questions, remain curious and engage in critical thinking. She also believes in working closely with parents to ensure continuity in their child’s learning and development at school and at home.`
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading:
            'What Sets our Preschool at Marina Boulevard Singapore Apart',
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> preschool in Raffles Place Singapore is part of the Healthy Meals in Pre-Schools Programme (HMPP).
          Our <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> foster a supportive <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a> to educate all our preschoolers about the importance of proper nutrition by
          providing nutritious meals and promoting an active lifestyle. This ensures parents that our
          educators will effectively implement the best practices to reap the
          optimal health and education outcomes.
          </p>`,
          programmesOffered: `<p>
          <br/>
          At our preschool at Marina Boulevard, Singapore, we offer full-day programmes for
          preschoolers aged between 18 months and 6 years old. Our educators at the Marina
          Boulevard follow either one of two core <a href="/our-curriculum/curriculum-overview" className="text-link">curriculums</a> that has been developed specifically
          for our kindergartens: the <a href="/our-curriculum/infant–3-years-relationships-based-curriculum" className="text-link">relationships-based curriculum</a> and the <a href="/our-curriculum/4-6-years-literacy-based-curriculum" className="text-link">literacy-based curriculum</a>. This specialised curriculum framework coupled with Pedagogy for Enhanced
          Mandarin Acquisition has led to 93% of Little Skool-House children being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>.
          <br/><br/>
          In addition to our comprehensive curriculum, our preschool in Raffles Place Singapore also
          offers a range of <a href="/our-curriculum/enrichment-programme" className="text-link">enrichment programmes</a> that address the holistic developmental needs of
          every child, making Little Skool-House a <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link"> chosen preschool by top institutions</a>.
          </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-marina-boulevard-raffles-place',
          title:
            'Preschool at Marina Boulevard Singapore (Central) | The Little Skool-House',
          description:
            'The curriculum at the Little Skool-House Marina Boulevard kindergarten has led to 93% of our kids gaining High Bilingual Proficiency. Schedule a visit today.'
        }
      },
      {
        name: `At-Delta-House`,
        name2: ``,
        location: `Delta House`,
        slug: `the-little-skool-house-at-delta-house-alexandra-road`,
        pics: 5,
        defaultPic: 2,
        awards: ['SPARK', 'HEALTH'],
        address: [`2 Alexandra Rd`, `#01-03A`, `Singapore 159919`],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.805393981688!2d103.82324131523639!3d1.291097962119873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19a1996ca459%3A0x5616d429365151cc!2sDelta%20House!5e0!3m2!1sen!2ssg!4v1585040183127!5m2!1sen!2ssg`,
        contact: `6904 2129`,
        email: `lshdth01@littleskoolhouse.com`,
        operatingHours: [`Mondays to Fridays: 7am to 7pm`],
        programmesOffered: [
          `Preschool (18 months to 6 years) - Full Day, Half Day, Flexi Care Programme, Prime Programme`
        ],
        faqs: [
          {
            q: `How do I get to the childcare centre at Delta House?`,
            a: [
              `Located near Tiong Bahru MRT station, the Little Skool-House preschool at Delta House, Singapore can be conveniently accessed by families residing in Guilin, Bukit Merah, and the River Valleys. Bus services 121 and 122 are available from opposite Tiong Bahru MRT Station, and bus service 32 and 64 is also available from Redhill station.`
            ]
          }
        ],
        principal: [
          {
            name: `Felicia Yan`,
            url: `felicia-yan-at-delta-house`,
            title: `Executive Principal`,
            qualifications: [
              `Master of Science in Early Childhood Education (M.SC. in ECE)`
            ],
            description: [
              `Prior to becoming an educator, Felicia volunteered in a local organisation helping children at risk. She started at The Little Skool-House as trainee teacher and has since moved on to overseeing centres of various sizes, conducting teacher training, while providing experiential learning in collaboration with teachers and parents.`
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading: 'What Sets our Preschool at Delta House Singapore Apart',
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> preschool at Delta House, Singapore has been awarded the Spark
          certification and the Healthy Pre-Schools (HPS) Accreditation. This ensures parents that
          when their child is enrolled in our preschool at Delta House or other parts of Singapore,
          they will be exposed to <a href="/our-centres/our-nutritious-meals" className="text-link"> nutritious meals</a> and activities that are in line with the Health
          Promotion Board’s school health promotion best practices.
          <br/><br/>
          Our kindergarten at Delta House features a huge indoor playroom that nurtures confidence
          and aids in the development of social and emotional skills. With daily opportunities to play,
          positive social behaviour is promoted and collaboration efforts between peers improve
          engagement in their <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a>. 
          </p>`,
          programmesOffered: `<p>
          <br/>
          Full-day, half-day, flexi care and prime programmes, and childcare services are offered at the Little
          Skool-House campus at Delta House for preschoolers aged between 18 months and 6-years
          old. Our <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> follow a comprehensive curriculum that has led to 93%
          of Little Skool-House children being is <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>. Our kindergarten at Delta House includes the Primary School Formation Programme that ensures
          that every Little Skool-House preschooler transitions seamlessly into primary school. Our centre
          also offers a range of <a href="/our-curriculum/enrichment-programme" className="text-link">enrichment programmes</a> that caters to various interest.
          </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-delta-house-alexandra-road',
          title:
            'Preschool at Delta House Singapore (Central) | The Little Skool-House',
          description:
            'Our award-winning Little Skool-House kindergarten educators at Delta House ensure Holistic Development and High Bilingual Proficiency. Schedule a visit today.'
        }
      },
      {
        name: `At-OCBC-Centre`,
        name2: `POP Centre`,
        location: `OCBC Centre - Raffles Place`,
        slug: `the-little-skool-house-at-ocbc-centre`,
        pics: 4,
        defaultPic: 1,
        awards: ['SPARK', 'HEALTH'],
        address: [
          `65 Chulia Street`,
          `OCBC Centre West Wing, #02-02`,
          `Singapore 049513`
        ],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.814400796456!2d103.84715871523632!3d1.2853447621361616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da10cce69f4bd9%3A0x353493f48d179f50!2sThe%20Little%20Skool-House!5e0!3m2!1sen!2ssg!4v1585040683429!5m2!1sen!2ssg`,
        contact: `6236 0159`,
        email: `lshocbc@littleskoolhouse.com`,
        operatingHours: [`Mondays to Fridays: 7am to 7:30pm`],
        programmesOffered: [
          `Preschool (18 months to 6 years) - Full Day`,
          `Infant Care (2 months to 17 months) - Full Day`
        ],
        faqs: [
          {
            q: `How do I get to the childcare centre in Chulia Street?`,
            a: [
              `A walking distance away from Raffles Place MRT station, the Little Skool-House infant care centre in Chulia street, can be conveniently accessed by families residing in Outram, Tanjong Pagar, Guilin, and the Central area. Bus services 63 and 33 can be accessed from Outram Park MRT station.`
            ]
          }
        ],
        principal: [
          {
            name: `Christina Tan`,
            url: `christina-tan-at-ocbc-centre`,
            title: `Principal`,
            qualifications: [
              `Master of Education (Early Childhood) (M.Ed. in EC)`
            ],
            description: [
              `Throughout her 22 year-career, Christina saw learning opportunities where others saw obstacles and failures; an attitude she has passed on to her staff and to children in her centre.  While she strives to bring out the individual potential of children, she always strives to create partnerships with fellow educators and parents in her centre.`
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading: 'What Sets our Workplace Preschool in OCBC Centre Apart',
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> childcare centre in Chulia street, is located at the OCBC centre. To
          help build self-confidence and encourage social and emotional development, our Chulia
          street preschool in Singapore comes equipped with an outdoor play area and a conducive
          <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a>. Our <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> guide each child with care through
          their education journey by encouraging discovery and exploration at our learning centre in
          Chulia street.
          <br/><br/>
          Being the <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link"> chosen preschool by top institutions</a>, the Little Skool-House POP Centre in
          the OCBC centre, is the preferred childcare centre for employees of our workplace partner in
          Chulia street. Established to provide working parents with child and infant care services
          during working hours, our OCBC POP Centre is a convenient option.
          </p>`,
          programmesOffered: `<p>
          <br/>
          A full-day infant care programme is offered at our learning centre in Chulia street for kids
          aged between 2 to 17 months. With an extensive <a href="/our-curriculum/infant–3-years-relationships-based-curriculum" className="text-link">relationships-based curriculum</a> that
          includes extensive reading schedules by our educators, the Little Skool-House pedagogy
          aims to build a sturdy brain architecture during a child’s early years. We also offer full-day
          programmes for preschoolers aged between 18 months and 6 years old.
          <br/><br/>
          Staying true to <a href="/about-us/our-vision" className="text-link">our vision</a> to nurture every child’s potential, the Little Skool-House acts as
          trendsetters in early childhood education. This has led to 93% of our children being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>. With our carefully designed <a href="/our-curriculum/curriculum-overview" className="text-link">curriculum</a> and
          integration of a vast array of <a href="/our-curriculum/enrichment-programme" className="text-link">enrichment programmes</a>, the Chulia street Little Skool-House
          preschool in Singapore strives to equip all children with the essential skills necessary for
          primary school and life.
          </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-ocbc-centre',
          title:
            'Workplace Preschool in OCBC Centre (Central) | The Little Skool-House',
          description:
            'As the chosen preschool by top institutions, the Little Skool-House preschool in the OCBC centre ensures preparedness for primary school and life. Enrol today.'
        }
      },
      {
        name: `At-Thomson`,
        name2: `POP Centre`,
        location: `Ministry of Social and Family Development`,
        slug: `the-little-skool-house-at-thomson`,
        pics: 3,
        defaultPic: 1,
        awards: ['SPARK', 'HEALTH'],
        address: [
          `512 Thomson Road`,
          `#05-00 MSF Building`,
          `Singapore 298136`
        ],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7374914550137!2d103.83746161523628!3d1.3336729619975247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da175c7dc37927%3A0x2ca3ff43e6a93da3!2s512%20Thomson%20Rd%2C%20Singapore%20298136!5e0!3m2!1sen!2ssg!4v1585041245255!5m2!1sen!2ssg`,
        contact: `6259 0273`,
        email: `lshmsf@littleskoolhouse.com`,
        operatingHours: [`Mondays to Fridays: 7am to 7pm`],
        programmesOffered: [`Preschool (18 months to 6 years) - Full Day`],
        faqs: [
          {
            q: `How do I get to the preschool in the Singapore MSF Building?`,
            a: [
              `A short distance away from Caldecott MRT station, the Little Skool-House preschool in Thomson Road can be conveniently accessed by families residing in the Toa Payoh, Novena, and Bishan. Bus service 141, 105, 129, 153, and 56 is also available from Toa Payoh bus interchange.`
            ]
          }
        ],
        principal: [
          {
            name: `Siti Nadiah
            `,
            url: `siti-nadiah-at-thomson`,
            title: `Vice Principal`,
            qualifications: [
              `Bachelor in Early Childhood Studies`
            ],
            description: [
              `Tell me and I forget, teach me and I remember, involve me and I learn." This quote by Benjamin Franklin which Nadiah firmly believes in reminds her that children learn best through hands-on experiences. Children learn from observing, imitating and experimenting with their hands and body as soon as they are born. With great partnership between educators and families, learning in school and at home through hands-on experiences continues to be the most important way of learning new skills for children.`
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading: 'What Sets our Workplace Preschool in Thomson Road Apart',
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> preschool in the Singapore Ministry of Social and Family
          Development (MSF) building has been awarded the Spark certification and the Healthy PreSchools (HPS) Accreditation. Our <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> nurture a supportive <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a> whereby the importance of proper nutrition is taught to all children on a daily
          basis. An active lifestyle is also fostered amongst the children in the outdoor play area. This
          reassures parents that when their child is enrolled in our Thomson Road preschool, they
          will reap optimal health and educational outcomes.
          </p>`,
          programmesOffered: `<p>
          <br/>
          At our preschool in Thomson Road, we offer full-day programmes for preschoolers aged
          between 18 months and 6 years old. Our educators at Thomson Road follow either one of
          two core <a href="/our-curriculum/curriculum-overview" className="text-link">curriculums</a> that has been developed specifically Little Skool-House preschools:
          the <a href="/our-curriculum/infant–3-years-relationships-based-curriculum" className="text-link">relationships-based curriculum</a> and the <a href="/our-curriculum/4-6-years-literacy-based-curriculum" className="text-link">literacy-based curriculum</a>. The Little SkoolHouse curriculum framework has led to 93% of our students being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>.
          <br/><br/>
          In addition to being the <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link"> chosen preschool by top institutions</a>, to meet the holistic
          developmental needs of every child enrolled in Little Skool-House, our preschool in the
          Singapore MSF building also offers a range of <a href="/our-curriculum/enrichment-programme" className="text-link">enrichment programmes</a>.
          </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-thomson',
          title:
            'Workplace Preschool in Singapore MSF Building (Central) | The Little Skool-House',
          description:
            'The curriculum at the Little Skool-House preschool in Thomson Road has led to 93% of our kids gaining High Bilingual Proficiency. Schedule a visit today.'
        }
      },
      {
        name: `At-Outram`,
        name2: `POP Centre`,
        location: `Outram Community Hospital`,
        slug: `the-little-skool-house-at-outram`,
        pics: 3,
        defaultPic: 1,
        awards: ['SPARK', 'HEALTH'],
        address: [`10 Hospital Boulevard `, `#05-01`, `Singapore 168582`],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8259609169263!2d103.83317011523636!3d1.27792266215711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19657bddf7e9%3A0xb781666bfe01576b!2sOutram%20Community%20Hospital!5e0!3m2!1sen!2ssg!4v1585041475926!5m2!1sen!2ssg`,
        contact: `6514 7687`,
        email: `lshsgh@littleskoolhouse.com`,
        operatingHours: [
          `Mondays to Fridays: 7am to 7pm`,
          `Saturdays: 7am to 2pm`
        ],
        programmesOffered: [`Preschool (18 months to 6 years) - Full Day`],
        faqs: [
          {
            q: `How do I get to the Preschool in Hospital Boulevard (Singapore)?`,
            a: [
              `Located a walking distance away from Outram Park MRT station, Little Skool-House kindergarten in Outram Singapore can be conveniently accessed by families residing in Bukit Merah, Downtown core, Outram, and Radin Mas. Bus service 196 is available from opposite the MAS building.`
            ]
          }
        ],
        principal: [
          {
            name: `Marie Luo`,
            url: `marie-luo-at-outram`,
            title: `Vice Principal`,
            qualifications: [
              `Diploma in Kindergarten Education – Teaching`
            ],
            description: [
              `Marie believes in creating a "Home Away from Home". This means providing a positive and safe
              environment for children to explore and learn, unleash their potential and prepare them for life. She
              believes that this vision can be achieved through close partnerships with teachers, families and the
              community. In her free time, Marie enjoys singing and playing the piano with her husband and children.`
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading: 'What Sets our Preschool in Outram Apart',
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> preschool in Outram, is located at the Outram Community Hospital,
          a major institution in Singapore housed within SingHealth Tower. Placing value on the
          importance of nurturing a child’s mind, our preschool in the Hospital Boulevard features a
          conducive learning environment where our <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> strive to meet
          educational goals set by the education ministry in Singapore.
          <br/><br/>
          The Little Skool-House kindergarten in Outram has been awarded the Spark certification by
          ECDA and Healthy Pre-Schools (HPS) Accreditation in Singapore. We create a safe and
          nurturing <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a> that teaches kids the importance of proper nutrition and
          staying active.
          <br/><br/>
          Being the <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link"> chosen preschool by top institutions</a>, the Little Skool-House campus at Hospital
          Boulevard Singapore is the preferred preschool for employees of our POP Centre partner
          in Outram. Established to provide working parents with a safe place for their children
          during working hours, our POP Centre in Outram Community Hospital is a convenient
          option.
          </p>`,
          programmesOffered: `<p>
          <br/>
          At the Little Skool-House kindergarten in Outram, we offer full-day programmes for
          preschoolers in Singapore, aged between 18 months and 6-years old. Our pedagogy based
          on clinical research on child development and education has led to 93% of Little Skool-House preschoolers being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>. In addition, the
          Little Skool-House kindergarten in Outram and all around Singapore, offers a range of
          <a href="/our-curriculum/enrichment-programme" className="text-link">enrichment programmes</a> for a more holistic development.
          </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-outram',
          title:
            'Preschool in Outram Community Hospital Singapore (Central) | The Little Skool-House',
          description:
            'Being the chosen preschool by top institutions, the Little Skool-House preschool in Outram ensures preparedness for primary school and life. Enrol today.'
        }
      },
      {
        name: `At-KK Women’s and Children’s Hospital`,
        name2: `POP Centre`,
        location: `Alfa Centre`,
        slug: `the-little-skool-house-at-kk-hospital`,
        pics: 4,
        defaultPic: 2,
        address: [
          `99 Bukit Timah Rd`,
          `#01-01 Alfa Centre`,
          `Singapore 229835`
        ],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.779485674694!2d103.84489661523638!3d1.3075060620730654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19bf87aa49e1%3A0x4a6ad3298ddada0d!2s99%20Bukit%20Timah%20Rd%2C%20Singapore%20229835!5e0!3m2!1sen!2ssg!4v1585041808029!5m2!1sen!2ssg`,
        contact: `6316 7512`,
        email: `lshkkh@littleskoolhouse.com`,
        operatingHours: [
          `Mondays to Fridays: 6.45am to 7.15pm`,
          `Saturdays: 6.45am to 2.15pm`
        ],
        programmesOffered: [
          `Preschool (18 months to 6 years) - Full Day`,
          `Infant Care (2 months to 17 months) - Full Day`
        ],
        faqs: [
          {
            q: `How do I get to the childcare centre at Bukit Timah Road?`,
            a: [
              `Located a walking distance away from Little India MRT station, the Little Skool-House childcare centre at Alfa Centre can be conveniently accessed by families residing in Orchard, Newton, Kallang, Rochor, and Novena. Bus service 131, 56, 57, 851, and 980 from Novena station is available.`
            ]
          }
        ],
        principal: [
          {
            name: `Fiona Ng`,
            url: `fiona-ng-at-kk-hospital`,
            title: `Vice Principal`,
            qualifications: [`Bachelor of Early Childhood Studies`],
            description: [
              `Fiona believes that each child is unique with strengths that can be nurtured. Having worked with various families throughout her teaching years, the saying “Alone we can do so little. Together we can do so much." deeply resonates with her. With consistent and cooperative partnership between school and family, she believes in working together hand in hand to help each child achieve his or her fullest potential.`
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading: 'What Sets our Alfa Centre Singapore Preschool Apart',
          description: `<p>
          <br/>
         The <a href="/" className="text-link">Little Skool-House</a> preschool in Bukit Timah Road, is located at KK Women’s and
         Children’s Hospital. To help promote positive social behaviour, our childcare centre at Alfa
         centre comes equipped with an indoor play area and a conducive <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a>.
         Our <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> guide children through their education journey by
         encouraging discovery and exploration through experiential and stimulating activities.
         <br/><br/>
         Being the chosen preschool by top institutions, the Little Skool-House POP Centre at
         KK Women’s and Children’s Hospital, is the preferred preschool for employees of our
         workplace partner in Bukit Timah Road. Established to provide working parents with
         childcare services during working hours, our POP Centre in Alfa centre is a convenient
         option.
         </p>`,
          programmesOffered: `<p>
          <br/>
          A full-day infant care programme is offered at our learning centre in Alfa centre for kids
          aged between 2 to 17 months. With an extensive activity schedule that includes daily
          reading for infants, the Little Skool-House <a href="/our-curriculum/infant–3-years-relationships-based-curriculum" className="text-link">relationships-based curriculum</a> promotes wellbeing and confidence during a child’s early years. The specially designed <a href="/our-curriculum/curriculum-overview" className="text-link">curriculum</a>
          followed closely at our childcare centre at Bukit Timah Road has led to 93% of our children
          being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>. Moreover, as the <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link"> chosen preschool by top institutions</a>, we take pride in our vast array of preschool <a href="/our-curriculum/enrichment-programme" className="text-link">enrichment programmes</a> that
          inspires imagination and inquiry, building a solid foundation in early childhood. 
          </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-kk-hospital',
          title:
            'Preschool in Bukit Timah Road (Central) | The Little Skool-House',
          description:
            'As the chosen preschool by top institutions, the Little Skool-House preschool in Bukit Timah Road ensures preparedness for primary school and life. Enrol today.'
        }
      }
    ],
    data: {
      mainSection: {
        heading: 'Preschools in Singapore (Central)',
        description: `<p>
        <br />
        There are several <a href="/" className="text-link"> Little Skool-House</a> campuses throughout Singapore. Options for Little Skool-House preschools in Central Singapore include ones located at <a href="/our-centres/the-little-skool-house-at-marina-boulevard-raffles-place" className="text-link">One Marina Boulevard</a>, <a href="/our-centres/the-little-skool-house-at-delta-house-alexandra-road" className="text-link"> Delta House</a>, as well as POP Centres at <a href="/our-centres/the-little-skool-house-at-ocbc-centre" className="text-link"> OCBC Centre at Raffles Place</a>, the <a href="/our-centres/the-little-skool-house-at-thomson" className="text-link"> Ministry of Social and Family Development</a>, <a href="/our-centres/the-little-skool-house-at-outram" className="text-link">Outram Community Hospital</a>, and <a href="/our-centres/the-little-skool-house-at-kk-hospital" className="text-link">KK Women’s and Children’s Hospital</a>. Of these preschools, both the OCBC Centre at Raffles Place and KK Women’s and Children’s Hospital offer an infant care programme in addition to the preschool programme.
        <br/> <br/>
        These kindergartens are easily accessible to families living in Central Singapore around Marina South, Bukit Merah, Outram, Orchard, Guilin, Toa Payoh, Rocher, Novena, and Marymount or parents working around the CBD area.
      </p>`
      },
      subSection: [
        {
          heading: 'Our Facilities and Curriculum',
          description: `<p>
          <br />
          Every part of our specially designed preschool <a href="/our-curriculum/curriculum-overview" className="text-link">curriculum</a> abides by our <a href="/about-us/our-vision" className="text-link">vision</a> to nurture bilingualism and promote contextualised learning. Our comprehensive bilingual curriculum developed by pioneering ECDA fellows has led to 93% of Little Skool-House children being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>.
          <br /> <br/>
            Beyond academics, most of our preschools in Singapore, especially those in the Central area, come with either an indoor or outdoor play area. By engaging children in indoor and outdoor activities, their senses are stimulated, and their education journey is made more enjoyable.
        </p>`
        },
        {
          heading: 'Our Award-Winning Educators',
          description: `<p>
          <br />
          Our <a href="/our-difference/our-award-winning-educators" className="text-link">award-winning educators</a> have gone through rigorous training, qualifying them as leaders in a child’s education journey. Having received several <a href="/about-us/our-awards" className="text-link">awards</a>, our qualified teachers will prepare your child for primary school and life.
        </p>`
        },
        {
          heading: 'Choice Preschool Amongst Parents',
          description: `<p>
          <br/>
            With over 27 years of experience under our belts, Little Skool-House will encourage your child to become a lifelong learner. To add to this, Little Skool-House is the  <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link">chosen preschool by some of the top institutions</a> in Singapore.
        </p>`
        }
      ]
    }
  },
  east: {
    centerList: [
      {
        name: `At-Mountbatten-Square`,
        name2: ``,
        location: `Mountbatten`,
        slug: `the-little-skool-house-at-mountbatten-square`,
        pics: 4,
        defaultPic: 3,
        awards: ['SPARK', 'HEALTH'],
        address: [
          `229 Mountbatten Road`,
          `#02-13 Mountbatten Square`,
          `Singapore 398007`
        ],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7792598707397!2d103.87899781523626!3d1.307648162072655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da183875f38d85%3A0x8f13d434618d4105!2s229%20Mountbatten%20Rd%2C%2002%20Mountbatten%20Square%2C%20Singapore%20398007!5e0!3m2!1sen!2ssg!4v1585105568336!5m2!1sen!2ssg`,
        contact: `6509 8421`,
        email: `lshmbs@littleskoolhouse.com`,
        operatingHours: [
          `Mondays to Fridays: 7am to 7pm`,
          `Saturdays: 7am to 2pm`
        ],
        programmesOffered: [
          `Preschool (18 months to 6 years) - Full Day, Half Day (selected programmes)`,
          `Infant Care (2 months to 17 months) - Full Day`
        ],
        faqs: [
          {
            q: `How do I get to the Infant & Childcare Centre in Mountbatten Square?`,
            a: [
              `Located minutes away from Mountbatten MRT station, the Little Skool-House preschool in Mountbatten Road, Singapore, can be conveniently accessed by families residing in Tanjong Rhu, Marine Parade, Kallang, Geylang and Boon Keng. Bus services 10, 16, 16M, 31, and 33 can be accessed from Dakota MRT station Exit B.`
            ]
          }
        ],
        principal: [
          {
            name: `Eunice Tong`,
            url: `eunice-tong-at-mountbatten-square`,
            title: `Executive Principal`,
            qualifications: [
              `Master of Science in Early Childhood Education (M.SC. in ECE)`
            ],
            description: [
              `What started as a love for Dr. Seuss’ book “Oh, the Places You’ll Go!”, became a journey into early childhood education for Eunice. Fifteen years later, she has set up new centres, built cohesive teams and has mentored teachers, starting them on their own journey as educators.`
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading: 'What Sets our Mountbatten Square Preschool Apart',
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> Mountbatten Square preschool has been awarded the Spark
          certification and Healthy Pre-Schools (HPS) Accreditation. This indicates that our childcare
          centre in Mountbatten Square is recognised for its comprehensive <a href="/our-curriculum/curriculum-overview" className="text-link">curriculum</a> and strong
          pedagogies that encourage holistic development in a conducive <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a>. 
          <br/><br/>
          Our preschool in Mountbatten Road (Singapore) features a huge outdoor playground that
          sparks enthusiasm for learning and encourages engagement amongst students. With daily
          opportunities to play, not only is confidence and resilience built, but motor and social skills
          are also developed from active exploration.
          </p>`,
          programmesOffered: `<p>
          <br/>
          A full-day infant care programme is offered at our learning centre in Mountbatten Square
          for kids aged between 2 to 17 months. With an extensive <a href="/our-curriculum/infant–3-years-relationships-based-curriculum" className="text-link">relationships-based curriculum</a>
          that aims to expose infants to a variety of stimuli and activity, the Little Skool-House
          pedagogy builds self-confidence and independence during a child’s early years. We also
          offer full-day programmes and selected half-day programmes for preschoolers aged
          between 18 months and 6-years old.
          <br/><br/>
          Our <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> strive to achieve excellence and continue to equip all Little
          Skool-House children with the essential skills necessary for primary school and life. As the
          <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link"> chosen preschool by top institutions</a> in Singapore, the Little Skool-House acts as
          trendsetters in early childhood, leading to 93% of our children being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>.
          </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-mountbatten-square',
          title:
            'Preschool in Mountbatten Road Singapore (East) | The Little Skool-House',
          description:
            'Our preschool in Mountbatten Road, Singapore follows a curriculum that has led to 93% of our kids gaining High Bilingual Proficiency. Schedule a visit today.'
        }
      },
      {
        name: `At-Tampines-Junction`,
        name2: ``,
        location: `Tampines Junction`,
        slug: `the-little-skool-house-at-tampines-junction`,
        pics: 4,
        defaultPic: 4,
        awards: ['SPARK', 'HEALTH'],
        address: [
          `300 Tampines Avenue 5 #04-02/03/04`,
          `Income@Tampines Junction`,
          `Singapore 529653`
        ],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.707157377848!2d103.93967381523625!3d1.3522594619432047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da3d1248017a1b%3A0xa755d0ffa77d0264!2s300%20Tampines%20Ave%205%2C%20Singapore%20529653!5e0!3m2!1sen!2ssg!4v1585123067765!5m2!1sen!2ssg`,
        contact: `6587 7142`,
        email: `lshtmj@littleskoolhouse.com`,
        operatingHours: [
          `Mondays to Fridays: 7am to 7pm`,
          `Saturdays: 7am to 2pm`
        ],
        programmesOffered: [`Preschool (18 months to 6 years) - Full Day`],
        faqs: [
          {
            q: `How do I get to the childcare centre in Tampines?`,
            a: [
              `Located within walking distance from Tampines MRT station, Little Skool-House at Income @ Tampines Junction can be conveniently accessed by families residing in Bedok North, Tanah Merah, and Simei. Bus service 168, 15, 21, 23, 65, and 67 is also available from Tampines West MRT Station. Parents who drive can park their cars at Tampines Mall that is located a short walking distance away from our preschool.`
            ]
          }
        ],
        principal: [
          {
            name: `Amanda Yap`,
            url: `amanda-yap-at-tampines-junction`,
            title: `Vice Principal`,
            qualifications: [
              `Advanced Diploma in Early Childhood Leadership`
            ],
            description: [
              `With "be brave, be kind, and love all" being the motto of her life, Amanda believes in the importance of instilling good moral values in the little ones. One of her greatest desires is for every child to know that their actions, however small they may seem, have the power to make a big difference in the lives of those around them.`
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading: 'What Sets Our Preschool in Tampines Apart',
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> in Tampines has been awarded the Healthy Pre-Schools (HPS) Accreditation and the Spark certification. This ensures parents that when their child is enrolled in our preschool in Tampines or other parts of Singapore, they will be exposed to <a href="/our-centres/our-nutritious-meals" className="text-link">healthy meals</a>, eating habits, and activities that are in line with the recommended school health promotion best practices. <br/><br/>
          Our Tampines preschool features a huge indoor gym-cum-playroom that supports active learning which builds confidence and aids in the development of social and emotional skills. With daily opportunities to play, not only are education processes made more enjoyable, but motor skills are also developed as a result of active exploration.
        </p>`,
          programmesOffered: `<p>
          <br/>
        Full-day programmes for preschoolers aged between 18 months and 6 years old and childcare services are offered at this <a href="/" className="text-link">Little Skool-House</a> campus in Tampines. Our award-winning educators follow either one of two core curriculum that has been developed specifically for Little Skool House. These include the <a href="/our-curriculum/infant–3-years-relationships-based-curriculum" className="text-link">relationships-based curriculum</a> and the <a href="/our-curriculum/4-6-years-literacy-based-curriculum" className="text-link">literacy-based curriculum</a> that has led to 93% of Little Skool-House children being is <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>. Preschoolers are also exposed to a range of <a href="/our-curriculum/enrichment-programme" className="text-link">enrichment programmes</a> that have been designed with care, ensuring that every child enrolled in our Tampines campus and across Singapore transitions seamlessly into primary school.
      </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-tampines-junction',
          title:
            'Preschool in Tampines Junction (East) | The Little Skool-House',
          description:
            'The Little Skool-House preschool in Tampines follows a curriculum that has led to 93% of our kids gaining High Bilingual Proficiency. Schedule a visit today.'
        }
      },
      {
        name: `At-Downtown-East`,
        name2: ``,
        location: `Downtown East - Pasir Ris`,
        slug: `the-little-skool-house-at-downtown-east`,
        pics: 4,
        defaultPic: 2,
        awards: ['HEALTH'],
        address: [
          `1 Pasir Ris Close`,
          `Downtown East E!Hub #03-113`,
          `Singapore 519599`
        ],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.6661503792843!2d103.95223291523618!3d1.37698686187006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da3db427a54c63%3A0xc9a59e1bb0b0a92a!2s1%20Pasir%20Ris%20Cl%2C%20Singapore%20519599!5e0!3m2!1sen!2ssg!4v1585123224931!5m2!1sen!2ssg`,
        contact: `6585 5292`,
        email: `lshdte@littleskoolhouse.com`,
        operatingHours: [`Mondays to Fridays: 7am to 7pm`],
        programmesOffered: [
          `Preschool (18 months to 6 years) - Full Day, Half Day (selected programmes), Prime Programme`,
          `Infant Care (2 months to 17 months) - Full Day`
        ],
        faqs: [
          {
            q: `How do I get to the infant care centre at Pasir Ris?`,
            a: [
              `Located minutes away from Pasir Ris MRT station, the Little Skool-House preschool at Downtown East can be conveniently accessed by families residing in Tampines East, Pasir Ris, and Loyang. Bus service 3 from Pasir Ris Drive 3 and 354 from Pasir Ris interchange are available.`
            ]
          }
        ],
        principal: [
          {
            name: `Daphne Cher`,
            url: `daphne-cher-at-downtown-east`,
            title: `Vice Principal`,
            qualifications: [
              `Master of science in early childhood Education (M.SC. in ECE)`
            ],
            description: [
              `Daphne’s approach to teaching is defined by Hellen Keller’s famous quote “Alone we can do so little, together we can do so much.” Daphne believes that when parents and teachers work hand in hand, children naturally flourish.`
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading: 'What Sets Our Preschool at Pasir Ris Apart',
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> preschool located within Downtown East, has been awarded the Healthy Pre-Schools (HPS) Accreditation. This is an attestation to our kindergarten at Pasir Ris boasting a supportive environment and facilities that complete a child’s areas of development.<br/> <br/>
          Little Skool-House takes extra care in the design and layout of the <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a> of our campuses. We believe that a proper learning environment acts as a third teacher to the students and thus ensure that our campus environment corresponds with our curriculum approach. The large open-space classrooms at our kindergarten in Pasir Ris allows students to move around freely and converse with their classmates, improving their social skills.
        </p>`,
          programmesOffered: `<p>
          <br/>
          At this Little Skool-House, a full-day infant day care programme is offered for children in Singapore aged between 2 months and 17 months. Full-day programmes, half-day (selected programmes) and prime programmes for those aged between 18 months and 6 years old are also part of its services.<br/><br/>
          All of our Little Skool-House <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> follow <a href="/our-curriculum/curriculum-overview" className="text-link">curriculums</a> developed by the ECDA that has led to 93% of our children being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>. We also offer a range of <a href="/our-curriculum/enrichment-programme" className="text-link">enrichment programmes</a> that will allow your child to gain exposure in activities that they are drawn to.
        </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-downtown-east',
          title:
            'Preschool at Downtown-East Pasir Ris (East) | The Little Skool-House',
          description:
            'At the Little Skool-House preschool in Pasir Ris, award- winning educators facilitate exceptional learning with effective curriculums. Schedule a visit today.'
        }
      },
      {
        name: `At-OCBC-Tampines`,
        name2: `POP Centre`,
        location: `Tampines Plaza`,
        slug: `the-little-skool-house-at-ocbc-tampines`,
        pics: 3,
        defaultPic: 2,
        awards: ['HEALTH'],
        address: [
          `5 Tampines Central 1`,
          `#04-03/04, Tampines Plaza 2`,
          `Singapore 529541`
        ],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7057818050994!2d103.94053491523623!3d1.3530962619407312!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da3d1219f659f9%3A0x511ca0310b381e1c!2s5%20Tampines%20Central%201%2C%20Singapore%20529541!5e0!3m2!1sen!2ssg!4v1585123796269!5m2!1sen!2ssg`,
        contact: `6908 1230`,
        email: `lshoce01@littleskoolhouse.com`,
        operatingHours: [`Mondays to Fridays: 7am to 7pm`],
        programmesOffered: [`Preschool (18 months to 6 years) - Full Day`],
        faqs: [
          {
            q: `How do I get to the OCBC Tampines Plaza preschool in Singapore?`,
            a: [
              `Located near Tampines MRT station, the Little Skool-House kindergarten in Tampines Central can be
              conveniently accessed by families residing in Tampines, Pasir Ris, and Loyang. Bus services 23, 65, and 67
              are available from Tampines West MRT station exit B.`
            ]
          }
        ],
        principal: [
          {
            name: `Lycia Yam Li Ping`,
            url: `lycia-at-tampines`,
            title: `Vice Principal`,
            qualifications: [
              `Bachelor of Science in Early Childhood Education`
            ],
            description: [
              `Lycia’s belief as an Early Childhood Educator is to inspire the children to not only discover their
              potential as individuals, but to also grow in character to become independent and confident learners
              with a deep love for learning. All these can only happen through close collaborations with their first
              teachers; Parents, towards the goal to prepare them not just for school, but for life.`
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading:
            'What Sets our Workplace Kindergarten in Tampines Central Apart',
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> Tampines Plaza preschool in Singapore has been awarded the
          Healthy Pre-Schools (HPS) Accreditation. When a child is enrolled in our OCBC Tampines
          preschool or other centres in Singapore, healthy eating habits will be fostered through the
          provision of <a href="/our-centres/our-nutritious-meals" className="text-link">nutritious meals</a> that are in line with the Health Promotion Board’s school
          health promotion best practices.
          <br/><br/>
          Our kindergarten in Tampines Central features an indoor play area that nurtures confidence
          and encourages a sense of community and collaboration when playing. With daily
          opportunities to play, positive social behaviour and key competencies are also promoted in
          our <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a>, specially designed for optimal learning.
          </p>`,
          programmesOffered: `<p>
          <br/>
          A full-day preschool programme is offered at the Little Skool-House preschool in OCBC
          (Tampines) for preschoolers aged between 18 months and 6 years old. Our <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> follow a comprehensive curriculum that has led to 93% of Little Skool-House
          children being is <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>. Our OCBC Tampines
          workplace preschool in Singapore also offers a range of enrichment programmes that
          inspires creativity.
          </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-ocbc-tampines',
          title:
            'Tampines Plaza Preschool in Singapore (East) | The Little Skool-House',
          description:
            'As the chosen preschool by top institutions, the Little Skool-House kindergarten in Tampines central fosters proficiency in English and Mandarin. Enrol today.'
        }
      },
      {
        name: `At-Temasek-Polytechnic`,
        name2: `POP Centre`,
        location: `Temasek Polytechnic`,
        slug: `the-little-skool-house-at-temasek-polytechnic`,
        pics: 4,
        defaultPic: 3,
        awards: ['HEALTH'],
        address: [`21 Tampines Avenue 1`, `Blk 1A #01-87`, `Singapore 529757`],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7187444478664!2d103.92962351523617!3d1.3451900619639285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da3d69fed56771%3A0x8bf2e2929d84a296!2sTemasek%20Polytechnic!5e0!3m2!1sen!2ssg!4v1585124087344!5m2!1sen!2ssg`,
        contact: `6908 1239`,
        email: `lshtep01@littleskoolhouse.com`,
        operatingHours: [`Mondays to Fridays: 7am to 7pm`],
        programmesOffered: [`Preschool (18 months to 6 years) - Full Day`],
        faqs: [
          {
            q: `How do I get to the kindergarten in Temasek Polytechnic?`,
            a: [
              `Located minutes away from Tampines West MRT station, the Little Skool-House kindergarten in Temasek Polytechnic can be conveniently accessed by families residing in Tampines, Bedok, Pasir Ris, and Paya Lebar. Bus service 15 from opposite Century Square is available.`
            ]
          }
        ],
        principal: [
          {
            name: `Ivy Chai`,
            url: `ivy-chai-at-temasek-polytechnic`,
            title: `Principal`,
            qualifications: [`Degree in Early Childhood Care and Education`],
            description: [
              `An early childhood educator since 1997, Ivy finds inspiration in the strength and freedom portrayed by Helen Keller. She thrives on the joy of caring, nurturing and developing every child, and follows an innovative approach in programme implementation.`
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading:
            'What Sets our Workplace Preschool in Temasek Polytechnic Apart',
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> preschool in Temasek Polytechnic has been awarded the Healthy Pre-Schools (HPS) Accreditation. This is a testament to the importance that Little Skool-House
          places on establishing a school culture that promotes healthy living. To help promote
          positive social behaviour, our Tampines Avenue preschool in Singapore comes equipped
          with an indoor play area and a conducive <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a>. Our <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> are also trendsetters in early childhood education, passionate about guiding
          children through their education journey.
          <br/><br/>
          Being the <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link"> chosen preschool by top institutions</a>, the Little Skool-House kindergarten in
          Temasek Polytechnic, is the preferred preschool for employees of our workplace partner in
          Tampines. Established to provide working parents with childcare services during working
          hours, our POP Centre in Tampines Avenue is a convenient preschool option to
          consider in Singapore.
          </p>`,
          programmesOffered: `<p>
          <br/>
          At our kindergarten in Temasek Polytechnic, we offer full-day programmes for preschoolers
          aged between 18 months and 6 years old. The specially designed Little Skool-House
          <a href="/our-curriculum/curriculum-overview" className="text-link">curriculum</a> has led to 93% of Little Skool-House preschoolers being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>. We also take pride in our vast array of preschool <a href="/our-curriculum/enrichment-programme" className="text-link">enrichment programmes</a> that encourages out of the box thinking and inquiry, building an unshakeable
          foundation in a child’s formative years.
          </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-temasek-polytechnic',
          title:
            'Workplace Preschool in Temasek Polytechnic (East) | The Little Skool-House',
          description:
            'As the chosen preschool by top institutions, the Little Skool-House in Temasek Polytechnic ensures preparedness for primary school and life. Enrol today.'
        }
      }
    ],
    data: {
      mainSection: {
        heading: 'Preschools in Singapore (East)',
        description: `<p>
        <br />
        There are several <a href="/" className="text-link"> Little Skool-House</a> campuses throughout Singapore. Options for Little Skool-House preschools in the East of Singapore include ones located at <a href="/our-centres/the-little-skool-house-at-mountbatten-square" className="text-link"> Mountbatten</a>, <a href="/our-centres/the-little-skool-house-at-tampines-junction" className="text-link"> Tampines Junction</a>, and <a href="/our-centres/the-little-skool-house-at-downtown-east" className="text-link"> Downtown East Pasir Ris</a>, as well as POP Centres at <a href="/our-centres/the-little-skool-house-at-ocbc-tampines" className="text-link"> OCBC Tampines Plaza</a> and <a href="/our-centres/the-little-skool-house-at-temasek-polytechnic" className="text-link"> Temasek Polytechnic</a>. Of these childcare centres in the East Coast of Singapore, our Mountbatten Square and Downtown East Pasir Ris branches offer infant care programmes.
        <br/> <br/>
        These preschools are easily accessible to families living in the East Coast and North East of Singapore around Boon Keng, Geylang, Marine Parade, Kembangan, Bedok, Siglap, Simei, and Pasir Ris.
      </p>`
      },
      subSection: [
        {
          heading: 'Our Facilities and Curriculum',
          description: `<p>
          <br />
            When enrolled in our infant care centres and kindergartens in Singapore, like that in the East, our educators expose children to a comprehensive <a href="/our-curriculum/curriculum-overview" className="text-link">curriculum</a>  that is based on thorough clinical research on child development. This curriculum designed specifically for Little Skool-House by established ECDA fellows has led to 93% of our children being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link"> Highly Proficient in both English and Mandarin</a>. Our childcare centres in the East of Singapore also offer a range of optional <a href="/our-curriculum/enrichment-programme" className="text-link"> enrichment programmes</a> that aid in building knowledge better.
          <br /> <br/>
            The <a href="/our-centres/our-learning-environment" className="text-link"> learning environment</a> in the Little Skool-House kindergartens in Singapore also fosters a sense of collaboration to promote positive social behaviour regardless if your child is on the East Coast campus or others.
        </p>`
        },
        {
          heading: 'Our Award-Winning Educators',
          description: `<p>
          <br />
          Our preschool and infant care <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> in the East Coast of Singapore go through rigorous pedagogical and curriculum training and learning journeys to master various teaching methodologies. Therefore, parents who enrol their child at the Little Skool-House kindergarten in the East Coast of Singapore can rest assured that the teachers at our learning centres will act as role models, offering support to every child.
        </p>`
        },
        {
          heading: 'Choice Preschool of Top Institutions',
          description: `<p>
          <br/>
          At our child and infant care centre in the East Coast and around Singapore, we believe that no child is too young to start learning. Being the <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link"> chosen preschool by top institutions</a> in Singapore, we believe in seizing the opportunity during a child’s formative years and communicating with parents effectively to guarantee preparedness for primary school.
        </p>`
        }
      ]
    }
  },
  west: {
    centerList: [
      {
        name: `By-The-Vista`,
        name2: ``,
        location: `Ulu Pandan Community Club - Buona Vista`,
        slug: `the-little-skool-house-by-the-vista-ulu-pandan-cc`,
        pics: 4,
        defaultPic: 2,
        awards: ['SPARK', 'HEALTH', 'IB'],
        address: [
          `170 Ghim Moh Road`,
          `#03-01 Ulu Pandan Community Building`,
          `Singapore 279621`
        ],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.772297207448!2d103.78690441523638!3d1.31202226206011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1a68d98f149f%3A0xeb2e5b9ead503ad5!2sUlu%20Pandan%20Community%20Club!5e0!3m2!1sen!2ssg!4v1585042028328!5m2!1sen!2ssg`,
        contact: `6468 3725`,
        email: `lshupc@littleskoolhouse.com`,
        operatingHours: [
          `Mondays to Fridays: 7am to 7pm`,
          `Saturdays: 7am to 2pm`
        ],
        programmesOffered: [
          `Preschool (18 months to 6 years) - Full Day, Half Day`,
          `Infant Care (2 months to 17 months) - Full Day`
        ],
        faqs: [
          {
            q: `What is the curriculum taught at this centre?`,
            a: [
              `The Little Skool-House By-The-Vista offers the following curriculum:`,
              [
                `The Relationships-Based Curriculum for Infant to Nursery 1`,
                `The International Baccalaureate® (IB) Primary Years Programme (Early Years) curriculum  for Nursery 2 to Kindergarten 2`,
                `Please refer to the IB FAQ below for more information`
              ]
            ]
          },
          {
            q: `How do I get to the Preschool in Buona Vista (Singapore)?`,
            a: [
              `Located minutes away from Buona Vista MRT station, the Little Skool-House childcare centre in Ulu Pandan can be conveniently accessed by families residing in Queenstown, Clementi, Jurong East, Ghim Moh, and Tanglin. Bus service 92 is available from the Buona Vista MRT station Exit C, and 48 from Holland Village MRT station exit A.`
            ]
          }
        ],
        ibFaq:[
          {
            q: `What are the objectives of the International Baccalaureate (IB)?`,
            a: [
                ``
            ],
            id: 'iBMission'
          },
          {
            q:`What are the IB Learner Profiles?`,
            a:[
              `The aim for the IB programme is to develop international minded people who recognise their common humanity and shared guardianship of the planet, and help to create a better and more peaceful world.`
            ],
            id: 'iBLearner'
          },
          {
            q:`What is the International Baccalaureate Primary Years Programme (IB PYP)?`,
            a:[
              `The IB PYP prepares children to be active participants in their lifelong learning journey. It focuses on the development of the whole child as an inquirer both in the classroom and in the world outside.`,
              `The curriculum structure strives for a balance between the search for understanding, the acquisition of essential knowledge and skills, the development of positive dispositions, and the opportunity for positive action.`
            ],
            id: 'iBPrimary'
          },
          {
            q:`What are the pillars of the PYP framework?`,
            a:[
              ``
            ],
            id: 'iBPyp'
          },
          {
            q:`What are the essential elements of the IB PYP?`,
            a:[
              ``
            ]
          },
          {
            q:`What is the concept of Learner Agency?`,
            a:[
              `It is believed that our children are agents for their own and others’ learning. Learners with agency have the following mindset and traits: `
            ],
            id: 'iBAgency'
          }
        ],
        principal: [
          {
            name: `Chay Yoke Hiang`,
            url: `chay-yoke-hiang-by-the-vista`,
            title: `Principal`,
            qualifications: [
              `Bachelor of Science in Early Childhood Education (B. SC. in ECE)`
            ],
            description: [
              `Yoke Hiang believes that children perform better when engaged in activities they enjoy doing. She and her team constantly work toward understanding the children’s needs and interests in order to provide them with learning opportunities that would unleash their potential for growth.`
            ]
          },
          {
            name: `Hou Zhaoxia`,
            url: `hou-zhaoxia-by-the-vista`,
            title: `Vice Principal`,
            qualifications: [
              `Diploma in early childhood teaching – Chinese (DECCE-T)`
            ],
            description: [
              `When Zhaoxia first became a preschool teacher, the quote on the centre's wall - “Every child is someone’s darling!” -  stayed with her forever. Since then, she always aims to provide the most suitable learning environment to support and encourage children’s learning.`
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading: 'What Sets our Preschool in Ulu Pandan Apart',
          description: `<p>
          
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> preschool in Ulu Pandan, has been awarded the Spark certification, the Healthy Pre-Schools (HPS) Accreditation, and also follows the <a className="anchor" id="mission" href="#iBMission"  >International Baccalaureate </a> Primary Years Programme (IB PYP) curriculum framework since 2015. The Spark certification and transdisciplinary PYP framework is a testament to the emphasis we place on updating our education landscape. Our preschool in Buona Vista also abides by the school health promotion best practices recommended by the Health Promotion Board in Singapore.
          <br/><br/>
          With a high value placed on the importance of inquiry-based learning, our <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> have received specialised IB training, with an aim to nurture a child’s mind and develop their social, emotional, and academic skills. Our childcare centre in Ghim Moh also features a tactfully designed <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a>, with well-equipped classrooms and an inquiry play zone that encourages exploration and the development of essential skills.
          <br/><br/>
          Little Skool-House aims to have a positive influence on preschool education with teaching strategies suitable for preschoolers. With a robust support system and optimal teacher student ratio, we nurture top learners by providing quality early childhood development services in our preschool in Buona Vista Singapore. Actively abiding by the education system, our accolades are a testament to our ability to mould the future generation.
          </p>`,
          programmesOffered: `<p>
          <br/>
          A full-day infant day care programme is offered at our learning centre in Ulu Pandan for children aged between 2 months and 17 months. Half-day and full-day programmes for those aged between 18 months and 6 years old are also part of its services. With an extensive activity schedule, the Little Skool-House 
           <a href="/our-curriculum/infant-3-years-relationships-based-curriculum" className="text-link">relationships-based curriculum</a> and <a id="primary" href="#iBPrimary">International Baccalaureate® (IB) Primary Years Programme </a> promotes self-confidence and independence.
          <br/><br/>
          The By-The-Vista childcare centre in Ulu Pandan Community Club is an accredited International Baccalaureate (IB) school that offers the Primary Year Programme (PYP) for Nursery 2 to Kindergarten 2 children. By offering a PYP programme, students of our preschool in Buona Vista are guided to become inquirers both in school and life in Singapore. The <a id="primary" href="#iBPrimary">PYP</a> also aims to develop inquiring, knowledgeable and caring young people who help to create a better and more peaceful world through intercultural understanding and respect. In concurrence with our comprehensive curriculum that uses stories as a catalyst to emphasise bilingualism and promotes contextualized learning, it has led to 93% of our students being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>.
          </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-by-the-vista-ulu-pandan-cc',
          title:
            'Preschool in Buona Vista Singapore (West) | The Little Skool-House',
          description:
            'Our Little Skool-House preschool in Buona Vista, Singapore offers an IB curriculum for N2 to K2 children. Schedule a visit to our learning centre.'
        }
      },
      {
        name: `At-Devan-Nair-Institute`,
        location: `Jurong East`,
        contact: `6566 5110`,
        slug: `the-little-skool-house-at-devan-nair-institute`,
        pics: null,
        defaultPic: null,
        awards: [],
        email: `lshdni01@littleskoolhouse.com`,
        address: [
          `Devan Nair Institute for Employment and Employability`,
          `80 Jurong East Street 21 #03-02`,
          `Singapore 609607`
        ],
        map:
          'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1994.3669900466687!2d103.74406366373003!3d1.3358376999332264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da100e3c55e9b5%3A0x237f5b9bb586953c!2s80+Jurong+East+Street+21%2C+Singapore+600201!5e0!3m2!1sen!2sus!4v1490061786826',
        principal: [
          {
            name: `Jocelyn Lim`,
            url: `jocelyn-lim-at-the-hub`,
            title: `Principal`,
            qualifications: [
              `Bachelor of Arts degree in Early Childhood Education`
            ],
            description: [
              `Starting as an intern from Ngee Ann Polytechnic, Jocelyn's 10-year journey at The Little Skool-House has been guided by her belief that all children are unique and have the desire to learn and acquire new skills. She strives for strong home-school partnerships to provide every child in the centre a holistic education.`
            ]
          },
          {
            name: `Lam Lee Lee`,
            url: `lam-lee-lee-at-hub`,
            title: `Vice Principal`,
            qualifications: [
              `Diploma in Early Childhood Care and Education - Chinese`
            ],
            description: [
              `Backed by 15 years of experience in early childhood education, Lee Lee was a Chinese Language teacher before she took on the role of Vice Principal. She believes that children are active, curious and eager learners, capable of constructing their own knowledge through discovery. By providing a rich environment for exploration and interactions, teachers can effectively extend children's understandings and help them make new connections in their learning.`
            ]
          }
        ],
        programmesOffered: [
          `Preschool (18 months to 6 years) - Full Day, Half Day (selected programmes)`,
          `Infant Care (2 months to 17 months) - Full Day, Half Day`
        ],
        operatingHours: [
          `Mondays to Fridays: 7am to 7pm`,
          `Saturdays: 7am to 2pm`
        ],
        faqs: [
          {
            q: `How do I get to the preschool in Jurong East Street?`,
            a: [
              `A walking distance away from Jurong East MRT station, the Little Skool-House infant care centre in Devan Nair Institute,
              can be conveniently accessed by families residing in Jurong East, Clementi, and Bukit Batok. To get to Jurong Gateway
              temporary interchange, a short walk away from Devan Nair Institute, bus service 99 can be taken from Clementi
              interchange, and 183 can be accessed from Clementi station exit A.`
            ]
          }
        ],
        contents: {
          heading: `What Sets our Preschool in Jurong East (Singapore) Apart`,
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> preschool and infant care at Devan
        Nair Institute, Jurong East Street comes equipped with a conducive and collaborative
        learning environment to help build self-confidence and encourage positive social
        behaviours. Our <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> strive to meet educational goals. They actively
        guide each child by nurturing and encouraging discovery and inquisitive thinking. When a
        child is enrolled in our kindergarten in Jurong East or other centres in Singapore, healthy
        eating habits will be fostered through the provision of <a href="/our-centres/our-nutritious-meals" className="text-link"> nutritious meals</a>. 
        </p>
        `,
          programmesOffered: `<p>
          <br/>
        A full-day infant care programme is offered at our learning centre at Devan Nair Institute for
        children aged between 2 months and 17 months. Full-day and selected half-day
        programmes for preschoolers aged between 18 months and 6 years old are also part of its
        services. With a <a href="/our-curriculum/infant–3-years-relationships-based-curriculum" className="text-link">relationships-based curriculum</a> that includes extensive reading
        schedules, the Little Skool-House pedagogy has led to 93% of our students being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly
        Proficient in English and Mandarin</a>.
        <br/>
        <br/>
        By integrating a vast array of <a href="/our-curriculum/enrichment-programme" className="text-link">enrichment programmes</a>, Little Skool-House preschools strive
        to support and foster all children to become active learners. Our
        <a href="/about-us/our-awards" className="text-link">accolades</a> are also a testament to the effectiveness of the Little Skool-House comprehensive
        <a href="/our-curriculum/curriculum-overview" className="text-link">curriculum</a> and <a href="/about-us/our-vision" className="text-link">vision</a>.
        </p>`
        },
        scheduleAVisit: true,
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-devan-nair-institute',
          title:
            'Preschool in Jurong East Street Devan Nair (West) | The Little Skool-House',
          description:
            'Our Devan Nair preschool located in Jurong East, Singapore follows a curriculum that has led to 93% of our kids gaining High Bilingual Proficiency. Schedule a visit today.'
        },
        newCenter: true
      },
      {
        name: `At-Ministry-Of-Education`,
        name2: `POP Centre`,
        location: `MOE Building - Buona Vista`,
        slug: `the-little-skool-house-at-ministry-of-education`,
        pics: 4,
        defaultPic: 3,
        awards: ['HEALTH'],
        address: [
          `#04-01 MOE Building Podium Block`,
          `1 North Buona Vista Drive`,
          `Singapore 138675`
        ],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7829789294456!2d103.78867001523642!3d1.3053057620793795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1a40abcf9e23%3A0x69a668fa0538fd31!2sMinistry%20of%20Education!5e0!3m2!1sen!2ssg!4v1585042463059!5m2!1sen!2ssg`,
        contact: `6908 1232`,
        email: `lshmoe01@littleskoolhouse.com`,
        operatingHours: [
          `Mondays to Fridays: 7am to 7pm`,
          `Saturdays: 7am to 2pm`
        ],
        programmesOffered: [`Preschool (18 months to 6 years) - Full Day`],
        principal: [
          {
            name: `Christine Lee`,
            url: `christine-lee-at-ministry-of-education`,
            title: `Executive Principal`,
            qualifications: [`Master of Education (Early Childhood)`],
            description: [
              `For someone who finds fulfillment in seeing children grow and learn about the world around them, it’s no wonder that Christine has been working with preschoolers for more than 20 years. She remains committed  to provide quality early childhood programmes and impart life-long learning skills to children.`
            ]
          }
        ],
        faqs: [
          {
            q: `How do I get to the childcare centre in North Buona Vista Drive?`,
            a: [
              `Located a short distance away from Buona Vista MRT station, the Little Skool-House childcare centre in
              North Buona Vista drive can be conveniently accessed by families residing in Ghim Moh, Queenstown, and
              Clementi. Bus service 48 is available from Holland Village MRT station exit A. `
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading:
            'What Sets our Workplace Preschool in the Singapore MOE Building Apart',
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> preschool in the Singapore Ministry of Education (MOE) building, is
          one of the <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link"> chosen preschool by top institutions</a>. Placing value on the importance of
          nurturing a child’s mind, our North Buona Vista Drive preschool features a conducive
          <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a> and outdoor play area where our <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> strive to
          meet educational goals set by the education ministry in Singapore.
          <br/><br/>
          The Little Skool-House campus in North Buona Vista Drive is the choice preschool for
          employees of our POP Centre partner in the west of Singapore. Established to provide
          working parents with childcare services for their children during working hours, our
          POP Centre in North Buona Vista Drive is a convenient option.
          </p>`,
          programmesOffered: `<p>
          <br/>
          At the Little Skool-House preschool in the Singapore MOE building, we offer full-day
          programmes for preschoolers aged between 18 months and 6 years old. Our
          comprehensive curriculum not only encourages holistic development but has also led to
          93% of Little Skool-House preschoolers being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>. In addition, the Little Skool-House childcare centre in North Buona Vista Drive
          offers a range of <a href="/our-curriculum/enrichment-programme" className="text-link">enrichment programmes</a> that aims to pique a child’s interest to become
          active learners.
          </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-ministry-of-education',
          title:
            'Workplace Preschool in Singapore MOE Building (West) | The Little Skool-House',
          description:
            'Being the chosen preschool by top institutions in Singapore, the Little Skool-House in the MOE building, nurtures bilingualism. Schedule a visit to our centre.'
        }
      },
      {
        name: `At-Alexandra`,
        name2: `POP Centre`,
        location: `Alexandra Hospital`,
        slug: `the-little-skool-house-at-alexandra`,
        pics: 3,
        defaultPic: 2,
        awards: ['SPARK', 'HEALTH'],
        address: [`378 Alexandra Road`, `Singapore 159964`],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.812449178366!2d103.79908621523643!3d1.2865935621326299!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1bcb19f367f5%3A0x8f256e299bf58c73!2sAlexandra%20Hospital!5e0!3m2!1sen!2ssg!4v1585042728709!5m2!1sen!2ssg`,
        contact: `6471 5135`,
        email: `lshalh@littleskoolhouse.com`,
        operatingHours: [
          `Mondays to Fridays: 7am to 7pm`
        ],
        programmesOffered: [`Preschool (18 months to 6 years) - Full Day`],
        principal: [
          {
            name: `Lim Cai Yan`,
            url: `lim-cai-yan-at-alexandra`,
            title: `Vice Principal`,
            qualifications: [
              `Bachelor of Science degree in Early Childhood Education and Leadership`
            ],
            description: [
              `A staunch advocate of learning through play, Cai Yan believes that children develop many essential skills, such as language, problem solving and social skills, through the power of play. Play nurtures a child’s imagination and most importantly, makes learning fun and meaningful.`
            ]
          }
        ],
        faqs: [
          {
            q: `How do I get to the childcare centre in Alexandra Hospital Singapore?`,
            a: [
              `Located a short distance away from Queenstown MRT station, Little Skool-House preschool in Alexandra
              Hospital Singapore can be conveniently accessed by families residing in Queenstown, Bukit Merah, and
              Alexandra. Bus service 195 is available from Queenstown MRT station Exit B and C.`
            ]
          }
        ],
        contents: {
          heading:
            'What Sets our Workplace Preschool in Alexandra Hospital Singapore Apart',
          description: `<p>
              <br/>
              The <a href="/" className="text-link">Little Skool-House</a> preschool in Alexandra Road, is located at Alexandra Hospital, a
              major institution in Singapore. This childcare centre in Alexandra Road has been awarded
              the Spark certification and the Healthy Pre-Schools (HPS) Accreditation by the ECDA in
              Singapore. At Little Skool-House, we place value on the importance of a collaborative
              <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a> that encourages positive social behaviours. Our preschool in
              Alexandra Hospital thus features a large outdoor play area where our <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> strive to meet educational goals set by the education ministry in Singapore.
              <br/><br/>
              Being the <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link"> chosen preschool by top institutions</a>, the Little Skool-House campus in Alexandra
              Road is the preferred kindergarten for employees of our POP Centre partner in
              Alexandra Road. Established to provide working parents with childcare services for their
              children during working hours, our POP Centre at Alexandra Hospital is a convenient
              option for those working in the West side of Singapore.
              </p>`,
          programmesOffered: `<p>
          <br/>
                  At the Little Skool-House kindergarten in Alexandra Road, we offer full-day programmes for
                  preschoolers aged between 18 months and 6 years old. Our comprehensive curriculum
                  coupled with the pedagogy based on clinical research, has led to 93% of Little Skool-House
                  preschoolers being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>. In addition, the Little
                  Skool-House childcare centre in Alexandra Road and all-around Singapore, offers a range of
                  <a href="/our-curriculum/enrichment-programme" className="text-link">enrichment programmes</a> for a more holistic development.
                  </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-alexandra',
          title:
            'Workplace Preschool in Alexandra Hospital Singapore (West) | The Little Skool-House',
          description:
            ' Being the chosen preschool by top institutions, the Little Skool-House in Alexandra Road ensures preparedness for primary school and life. Enrol today.'
        },
        scheduleAVisit: true
      },
      {
        name: `At-Kent-Vale`,
        name2: `POP Centre`,
        location: `National University of Singapore`,
        slug: `the-little-skool-house-at-kent-vale`,
        pics: 4,
        defaultPic: 3,
        awards: ['SPARK', 'HEALTH'],
        address: [
          `101B Clementi Road`,
          `Kent Vale Staff Housing`,
          `Singapore 129798`
        ],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.789103078515!2d103.76681021523642!3d1.301439362090421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1af1293e687f%3A0xbd47cdd21299bdee!2s101B%20Clementi%20Rd%2C%20Singapore%20129798!5e0!3m2!1sen!2ssg!4v1585122484589!5m2!1sen!2ssg`,
        contact: `6773 9361`,
        email: `lshnus@littleskoolhouse.com`,
        operatingHours: [
          `Mondays to Fridays: 7am to 7pm`,
          `Saturdays: 7am to 2pm`
        ],
        programmesOffered: [`Preschool (18 months to 6 years) - Full Day`],
        faqs: [
          {
            q: `Is there a school bus service for this centre?`,
            a: [
              `No, but there is an internal NUS shuttle bus service that drops off near our centre.`
            ]
          },
          {
            q: `How do I know if I am eligible for the LSH NUS workplace discount?`,
            a: [
              `Staff must produce their NUS staff card which the centre will verify with HR.`
            ]
          },
          {
            q: `How do I get to the Preschool in Clementi (Singapore)?`,
            a: [
              ` Located minutes away from Kent Ridge MRT station, Little Skool-House kindergarten at NUS Singapore can be conveniently accessed by families residing in Queenstown, Clementi, Jurong East, Ghim Moh, and Pandan Gardens. Bus service 963 is available from the National University Hospital, 95 from Kent Ridge Station exit A, and bus services 183 and 189 are available from Clementi Station Exit B. There is also an internal NUS shuttle bus service.`
            ]
          }
        ],
        principal: [
          {
            name: `Dawn Hu`,
            url: `dawn-hu-at-kent-vale`,
            title: `Executive Principal`,
            qualifications: [
              `Master’s degree in Early Childhood Education (M.SC. in ECE)`
            ],
            description: [
              `Inspired by her favorite book  ‘Life Lessons’ by Elizabeth Kubler-Ross and David Kessler, Dawn has been teaching children  to appreciate the little things in life.  She aims to infuse the extraordinary in her lessons, and give children opportunities to have fun and challenge themselves, every single day.`
            ]
          },
          {
            name: `Chen Heng Hong`,
            url: `chen-heng-hong-at-kent-vale`,
            title: `Vice-Principal`,
            qualifications: [
              `Diploma in early childhood teaching – Chinese (DECCE-T)`
            ],
            description: [
              `Heng Hong’s 20-year experience in Early Childhood Education has been guided by her belief in Swiss psychologist Jean Piaget, an advocate of learning through play.  Following his philosophy, she makes sure children enjoy their learning experiences as she believes that play can provide opportunities for teachers to observe each child’s needs and learning abilities.`
            ]
          }
        ],
        scheduleAVisit: true,
        contents: {
          heading: 'What Sets our Preschool in Clementi Road Apart',
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> preschool in Clementi, is located at the National University of
          Singapore, a major tertiary institution in Singapore. With a high value placed on the
          importance of both academics and fun activities, our <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> aim to
          nurture a child’s mind through activities that foster healthy social and emotional
          development. Our preschool in Clementi Road features well-equipped classrooms and an
          outdoor playground that encourages exploration and the development of motor skills.
          <br/><br/>
          Awarded the Spark certification and the Healthy Pre-Schools (HPS) Accreditation, our
          kindergarten at NUS abodes by the school health promotion practices recommended by the
          Health Promotion Board in Singapore. The Spark certification is also a testament to the
          emphasis the we place on updating the education landscape to keep up with the changing
          educational trends and pedagogies.
          <br/><br/>
          Being the <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link"> chosen preschool by top institutions</a>, the Little Skool-House campus at NUS is the
          preferred preschool for employees of our POP Centre partner in Clementi Road.
          Established to provide working parents childcare services during working hours, our
          POP Centre in NUS is a convenient option.
          </p>`,
          programmesOffered: `<p>
          <br/>
          At our preschool in Clementi (Singapore), we offer full-day
          programmes for preschoolers aged between 18 months and
          6-years old. Our comprehensive <a href="/our-curriculum/curriculum-overview" className="text-link">curriculums</a> coupled with
          Pedagogy for Enhanced Mandarin Acquisition programmes
          have led to 93% of Little Skool-House preschoolers being
          <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly Proficient in both English and Mandarin</a>.
          </p>`
        },
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-kent-vale',
          title:
            'Workplace Preschool in NUS Clementi Singapore (West) | The Little Skool-House',
          description:
            'Little Skool-House is the chosen preschool by the top institution in Clementi, the National University of Singapore. Schedule a visit to our POP Centre.'
        }
      },
      {
        name: `At-Ngee-Ann-Polytechnic`,
        name2: `POP Centre`,
        location: `Ngee Ann Polytechnic`,
        contact: `6464 5219`,
        slug: `the-little-skool-house-at-ngee-ann-polytechnic`,
        pics: null,
        defaultPic: null,
        awards: [],
        email: `lshnp01@littleskoolhouse.com`,
        address: [
          `535 Clementi Road Ngee Ann Polytechnic`,
          `Block 53 Level 3`,
          `Singapore 599489`
        ],
        map: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7400972112737!2d103.77290772729731!3d1.3320642519779304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da107de50e7f7d%3A0x1cb5f49628d9f77b!2s535%20Clementi%20Rd%2C%20Singapore%20599489!5e0!3m2!1sen!2sus!4v1598937697206!5m2!1sen!2sus`,
        principal: [
          {
            name: `Yvonne Lin`,
            url: `yvonne-lin-at-ngee-ann-polytechnic`,
            title: `Principal`,
            qualifications: [
              `Master of Science in Early Childhood Education (M.SC. in ECE)`
            ],
            description: [
              `Dr. Seuss once wrote: “The more that you read, the more things you will know. The more that you learn, the more places you’ll go.” Yvonne has put this into practice by teaching the little ones how words can make stories, encouraging them to use their imagination to create characters, and helping them develop a love for books. `
            ]
          }
        ],
        programmesOffered: [
          `Preschool (18 months to 6 years) - Full Day, Half Day (selected programmes)`,
          `Infant Care (2 months to 17 months) - Full Day`
        ],
        operatingHours: [
          `Mondays to Fridays: 7am to 7pm`,
          `Saturdays: 7am to 2pm (until 31 December 2021)`
        ],
        faqs: [
          {
            q: `How do I get to the preschool in Ngee Ann Polytechnic?`,
            a: [
              `Located near Clementi MRT station, the Little Skool-House childcare and infant care at Clementi road, can be conveniently acccessed by families residing in Toh Tuck, Bukit Timah, and Ghim Moh. Bus services 154 and 184 can be accessed from Clementi MRT Station exit B and 151, 154 and 74 from King Albert Park station.`
            ]
          }
        ],
        contents: {
          heading: `What Sets our Preschool in Ngee Ann Polytechnic Apart`,
          description: `<p>
          <br/>
          The <a href="/" className="text-link">Little Skool-House</a> preschool in Clementi Road, is located at Ngee Ann Polytechnic. Our
        <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> foster a supportive <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a>
         to educate all our
        preschoolers. With daily opportunities to play and exposure to <a href="/our-centres/our-nutritious-meals" className="text-link">nutritious meals</a>, our
        students including those in infant care, benefit from optimal health and academic
        excellence.
        <br/>
        <br/>
        Being the <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link">chosen preschool by top institutions</a>, the Little Skool-House POP Centre in
        Ngee Ann Polytechnic, is the preferred infant care centre for employees of our workplace
        partner in Clementi Road. Established to provide working parents with child and infant care
        services during working hours, our kindergarten in Ngee Ann Polytechnic (Singapore) is a
        convenient option. 
        </p>`,
          programmesOffered: `<p>
          <br/>
        A full-day infant care programme is offered at our learning centre in Ngee Ann Polytechnic
      for kids aged between 2 to 17 months. A full-day and half-day preschool programme is
      offered for preschoolers aged between 18 months and 6-years old. Our educators follow a
      comprehensive <a href="/our-curriculum/curriculum-overview" className="text-link">curriculum</a> that has led to 93% of Little Skool-House children being <a href="/our-difference/high-proficiency-in-english-and-mandarin" className="text-link">Highly
      Proficient in both English and Mandarin</a>. Little Skool-House preschools also offer a range of
      enrichment programmes that supplements the holistic development of every child. 
      </p>`
        },
        scheduleAVisit: true,
        metaData: {
          url:
            'https://www.littleskoolhouse.com/our-centres/the-little-skool-house-at-ngee-ann-polytechnic',
          title:
            ' Workplace Preschool in Ngee Ann Polytechnic (West) | The Little Skool-House',
          description:
            'As the chosen preschool by top institutions, the Little Skool-House preschool at Ngee Ann Polytechnic ensures preparedness for primary school and life. Enrol today.'
        },
        newCenter: true
      }
    ],
    data: {
      mainSection: {
        heading: 'Preschools in Singapore (West)',
        description: `<p>
          <br />
              There are several <a href="/" className="text-link">Little Skool-House</a> campuses throughout Singapore. Options for Little Skool-House kindergarten in the West coast of Singapore include ones located at <a href="/our-centres/the-little-skool-house-at-devan-nair-institute" className="text-link">  Devan Nair Institute</a> and <a href="/our-centres/the-little-skool-house-by-the-vista-ulu-pandan-cc" className="text-link"> Buona Vista</a>, as well as POP Centres at the <a href="/our-centres/the-little-skool-house-at-ministry-of-education" className="text-link">MOE building</a>, <a href="/our-centres/the-little-skool-house-at-alexandra" className="text-link">Alexandra Hospital</a>, <a href="/our-centres/the-little-skool-house-at-kent-vale" className="text-link">National University of Singapore</a> and <a href="/our-centres/the-little-skool-house-at-ngee-ann-polytechnic" className="text-link">  Ngee Ann Polytechnic</a>. Of these childcare centres in the West, our Devan Nair Institute, By-The-Vista and Ngee Ann Polytechnic centre offer an infant care programme for those living in the West Coast of Singapore in addition to the preschool programme.
              <br /> <br />
              These childcare centres in Singapore are easily accessible to families living in West around Ghim Moh, Queenstown, Jurong East, Clementi and Alexandra.
            </p>`
      },
      subSection: [
        {
          heading: 'Our Facilities and Curriculum',
          description: `<p>
          <br />
          The two core <a href="/our-curriculum/curriculum-overview" className="text-link">curriculums</a> followed by our educators were developed by pioneering ECDA fellows, and has led to 93% of Little Skool-House children being Highly Proficient in English and Mandarin. In addition, the By-The-Vista child and infant care in Ulu Pandan Community Club (Singapore) is also an accredited IB school in the West that offers the PYP programme for Nursery 2 to Kindergarten 2 children.
          <br /> <br/>
            Our <a href="/our-centres/our-learning-environment" className="text-link">learning environment</a> fosters a sense of collaboration and aid in improving key competencies. This collaborative environment at the Little Skool-House Kindergartens in Singapore, like the ones located in the West Coast, will create enriching experiences for your child.
        </p>`
        },
        {
          heading: 'Our Award-Winning Educators',
          description: `<p>
          <br />
          Our preschool and infant care <a href="/our-difference/our-award-winning-educators" className="text-link"> award-winning educators</a> in the West of Singapore go through rigorous training, continuous professional development, and one-to-one mentoring to achieve teaching mastery, ensuring that they are up to par with the educators’ standards set by MOE. Amongst the many <a href="/about-us/our-awards" className="text-link">awards</a>, The Little Skool-House has been awarded the ECDA Outstanding and Promising Early Childhood Educator Awards for 9 consecutive years.
        </p>`
        },
        {
          heading: 'Choice Preschool Amongst Parents',
          description: `<p>
          <br/>
          With the Primary School Formation Programme and Pedagogy for Enhanced Mandarin Acquisition, Little Skool-House early years centres and kindergartens in Singapore is the <a href="/our-difference/chosen-preschool-by-top-institutions" className="text-link">choice preschool by top institutions</a> and for parents looking for a bilingual preschool in the West.
        </p>`
        }
      ]
    }
  }
};
const centerLocations = [
  {
    label: 'All Centres',
    url: 'all-centres',
    title: 'all-centres'
  },
  {
    label: 'North',
    url: 'north',
    title: 'north'
  },
  {
    label: 'Central',
    url: 'central',
    title:'central'
  },
  {
    label: 'East',
    url: 'east',
    title: 'east'
  },
  {
    label: 'West',
    url: 'west',
    title:'west'
  }
];

const centerLocations_cn = [
  {
    label: '所有中心',
    title: 'all-centres',
    url: 'suoyouzhongxin'
  },
  {
    label: '北部',
    title: 'north',
    url: 'beibu'
  },
  {
    label: '中区',
    title:'central',
    url: 'zhongqu'
  },
  {
    label: '东部',
    title: 'east',
    url: 'dongbu'
  },
  {
    label: '西部',
    title:'west',
    url: 'xibu'
  }
];

const openHouseDetails = {
  active: false,
  details: {
    openHouseDate: `25 May 2020`,
    openHouseDay: `Monday`,
    lastDate: `25 May 2020`,
    promotion: `1,600`,
    uniformWorth: `50`
  }
};

module.exports = {
  promotions,
  press,
  events,
  videos,
  articlesCategories,
  centreDetails,
  centreDirectionContent,
  centreDirectionContentCn,
  centerIbFAQs,
  centerIbFAQsCn,
  centerLocations,
  centerLocations_cn,
  articleTypes,
  openHouseDetails
};
