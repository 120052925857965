import React, { useEffect, useState } from 'react';
import { useStaticQuery } from 'gatsby';
import Layout from '../../layout/layout';
import Accordion from '../../components/common/Accordion/Accordion';
import Slider from 'react-slick';
import { graphql, Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import FAQPage from '../../components/common/jsonld/FAQPage';
import Preschool from '../../components/common/jsonld/Preschool';
import { centerIbFAQs, centerIbFAQsCn } from '../../../static-data';
import './centre-details.scss';
import { gtmEventManager } from "../../components/common/utils/utils"
import PromoSection from '../../components/common/PromoSection/promoSection';
import { RECAPTCHA_KEY, schema } from '../../config/appConfig';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ScheduleVisitForm from '../../components/common/ScheduleVisitForm/ScheduleVisitForm';
import ContactUsForm from '../../components/common/ContactUsForm/ContactUsForm';
import ScheduleVisitFormCn from '../../components/common/ScheduleVisitForm/ScheduleVisitFormCn';
import PromoSectionCn from '../../components/common/PromoSectionCn/promoSectionCn';
import ContactUsCNForm from '../../components/common/ContactUsForm/ContactUsCnForm';
import Modal from 'react-modal';

const CentrePhotos = ({ pics, setModalOpen, slug }) => {
 const allowedSlugs = ['the-little-skool-house-at-tampines-junction',"the-little-skool-house-at-delta-house-alexandra-road","lidadashazhongxinyalishandafang","danbinnizhizongyingkangzhongxin"]
  const is360 = allowedSlugs.includes(slug);
  return (
  <Slider dots={true}>
    {pics.map((item, idx) => (
      <div className="img-holder" key={idx + 1}>
        {is360 && idx == 0 ?
        <img
          src={item.mediaItemUrl}
          alt="Centre photo"
          style={{cursor:"pointer"}}
          onClick={() => setModalOpen(true)}
        />
    : 
        <img
          src={item.mediaItemUrl}
          alt="Centre photo"
        />
    }
      </div>
    ))}
  </Slider>
)};

const PrincipalModalContent = ({
  name,
  designation,
  qualification,
  profileSummary,
  profileImage: { mediaItemUrl },

}) => {
  return (
    <div className="principal-section">
      <div className="principal-profile">
        <div
          style={{
            backgroundImage: `url(${mediaItemUrl})`,
            backgroundSize: `cover`
          }}
        />
      </div>
      <h2>{name}</h2>
      <h3>{designation}</h3>
      <p className="qualification">{qualification}</p>
      <p>{profileSummary}</p>
    </div>
  );
};

const CenterDetails = ({ data, pageContext: { center, language, preview } }) => {
  const {
    centreName,
    centreName2,
    location,
    operatingHours,
    email,
    contact,
    secondaryContact,
    address,
    map,
    awards,
    scheduleAVisit,
    contents,
    contents: { programmesOffered },
    metaData,
    temporaryLocation,
    cardinalDirection,
    faq,
    centrePhotos,
    centrePrincipals,
    showForm,
  } = center.centre;
  const { slug } = center;
  const [selectedPrincipal, setSelectedPrincipal] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [linkId, setLinkId] = useState('');

  const ibFaq = [];
  if (slug == 'wulubandanshequjulebuzhongxin' ||
    slug == 'the-little-skool-house-by-the-vista-ulu-pandan-cc') {
    const ibFaqList = language == "english" ? centerIbFAQs : centerIbFAQsCn;
    if (ibFaqList && ibFaqList.length) {
      ibFaqList.forEach(({ q, a, id }) => {
        ibFaq.push({
          id,
          q,
          a: a.map((ans, ansIndex) =>
            ans instanceof Array ? (
              <ul key={ansIndex}>
                {ans.map((ansList, i) => (
                  <li key={i}>{ansList}</li>
                ))}
              </ul>
            ) : (
              <p key={ansIndex}>{ans}</p>
            )
          )
        });
      });
    }
  }

  const addressArr = address?.trim()?.split(/\n/);
  const streetAddress = address?.trim()?.split(/\n/);

  const operatingHoursArr = operatingHours && operatingHours.split(/\n/);
  const programmesOfferedArr = programmesOffered?.split(/\n/);
  const [addressLocality, postalCode] = streetAddress.pop().split(' ');
  const direction_cn = {
    "北部": 'beibu',
    '中区': 'zhongqu',
    '东部': 'dongbu',
    '西部': 'xibu'
  };
  const custom = {
    english: {
      heading: 'Our Centres',
      breadCrumb: [
        { label: 'Our Centres', link: '/our-centres/centres/all-centres' },
        { label: cardinalDirection, link: `/our-centres/centres/${cardinalDirection}` },
        { label: centreName }
      ],
      programmesOffered: 'Programmes Offered',
      scheduleAVisitBtn: 'Schedule a visit',
      scheduleAVisitLink: '/enquiry/schedule-a-visit',
      findOutMoreBtn: 'Find out more',
      temporaryLocationLabel: 'Temporary Site Location',
      faqsLabel: 'FAQs',
    },
    chinese: {
      heading: '我们的中心',
      breadCrumb: [
        { label: '我们的中心', link: '/zh/womendezhongxin/suoyouzhongxin' },
        { label: cardinalDirection, link: `/zh/womendezhongxin/${direction_cn[cardinalDirection]}` },
        { label: centreName }
      ],
      programmesOffered: '我们提供的课程',
      scheduleAVisitBtn: '预约参观',
      scheduleAVisitLink: '/zh/xunwen/yuyuecanguan',
      findOutMoreBtn: '更多详情',
      temporaryLocationLabel: '临时中心位置',
      faqsLabel: '常见问题',
    }
  }
  
  useEffect(() => {
    window.onclick = e => {
      let id = e.srcElement.hash;
      if(id && id.charAt(0) === '#') {
        id = id.slice(1);
        if (id === 'iBLearner' || id === 'iBMission' || id === 'iBPrimary' || id === 'iBAgency') {
          setLinkId(id);
        }
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    let get360view = typeof window !== 'undefined' && window.location.search;
    if(get360view == '?true'){
      setModalOpen(true);
    }
  }, []);
  const showModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () =>{
    setModalOpen(false)
  }

  const dataPromo = useStaticQuery(graphql`
  query {
    cmsData: cms {
      pageBy(uri: "limited-time-promotion") {
        open_house {
          openhousestatus
          privacyPolicy
          promotionPrice
          promotionTitle
          subTitle
          title
          centres {
            centreName
            centreNameEn
            isParticipating
            location
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
        links
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      publicURL
    }
  }
`);
const { centres } = dataPromo.cmsData.pageBy.open_house;
let north = centres.filter(item => item.location === 'North');
north.unshift({ location: 'North' });
let central = centres.filter(item => item.location === 'Central');
central.unshift({ location: 'Central' });
let east = centres.filter(item => item.location === 'East');
east.unshift({ location: 'East' });
let west = centres.filter(item => item.location === 'West');
west.unshift({ location: 'West' });

const centersList = north.concat(central, east, west);

let northcn = centres.filter(item => item.location === '北部');
northcn.unshift({ location: '北部' });
let centralcn = centres.filter(item => item.location === '中区');
centralcn.unshift({ location: '中区' });
let eastcn = centres.filter(item => item.location === '东部');
eastcn.unshift({ location: '东部' });
let westcn = centres.filter(item => item.location === '西部');
westcn.unshift({ location: '西部' });

const centresWithPopupView = {
  "the-little-skool-house-at-delta-house-alexandra-road" : "https://kuula.co/share/collection/7qkfS?logo=-1&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=1",
  "lidadashazhongxinyalishandafang" : "https://kuula.co/share/collection/7qkfS?logo=-1&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=1",
  "the-little-skool-house-at-tampines-junction" : "https://kuula.co/share/collection/7vp9R?logo=-1&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=1",
  "danbinnizhizongyingkangzhongxin" : "https://kuula.co/share/collection/7vp9R?logo=-1&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=1"
}

const centerListCn = northcn.concat(centralcn, eastcn, westcn);

  const handlePreSchool = () => {
    const { site, logo } = dataPromo;
    const { siteUrl, title, links } = site?.siteMetadata;
    return (
      <Preschool
        name={title}
        url={`${siteUrl}/our-centres/${slug}`}
        logo={new URL(logo.publicURL, siteUrl).toString()}
        image={`${siteUrl}/centre-photos/${slug}/1.jpg`}
        streetAddress={streetAddress.join(', ')}
        addressLocality={addressLocality}
        postalCode={postalCode}
        telephone={'6570 8513'}
        email={email}
        openingHours={operatingHoursArr}
        hasMap={map}
        sameAs={links}
      />
    )
  }

  return (
    <Layout
      bannerClass="admissions"
      heading={custom[language].heading}
      breadCrumbs={custom[language].breadCrumb}
      metaData={metaData && metaData}
      language={language}
      noFooter={preview}
      noBanner={preview}
      noHeader={preview}
    >
      {/* SEO */}
      <FAQPage faqs={faq} />
      {data && data?.site && handlePreSchool()}
      {selectedPrincipal && (
        <div className="principal-modal">
          <div className="principal-modal-container">
            <div
              className="close-btn"
              role="button"
              onClick={() => setSelectedPrincipal(null)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <PrincipalModalContent {...selectedPrincipal} />
          </div>
        </div>
      )}

      <div className="center-details-container">
        <div className="top-section">
          {awards && awards.length > 0 && (
            <div className="logo-section">
              {awards.map((award, i) => (
                <img
                  key={i}
                  src={award.logo.mediaItemUrl}
                  alt={award.name}
                />
              ))}
            </div>
          )}
          <h2>
            <br className="mobile-only-strict" /> {centreName}{' '}
            {location && (<><br className="mobile-only-strict" />{language === "english" ? "|" : ""} <span>{location}&nbsp;</span></>)}
            <span>({cardinalDirection})</span>
            {centreName2 && <br />}
            <span style={{ color: '#fdb941' }}>{centreName2}</span>
          </h2>

          {slug === "the-little-skool-house-at-kent-vale" && <h3 style={{padding:"12px 0",fontWeight:"500"}}>The Little Skool-House At Kent-Vale is co-managed with NUS Multi-Purpose Co-Operative Society Ltd.</h3> }
          {slug === "guodakentegangzhongxin" && <h3 style={{padding:"12px 0",fontWeight:"500"}}>新加坡国立大学肯特岗中心是与新加坡国立大学合作社合作经营的</h3> }
          <a onClick={() => gtmEventManager(`Contact Link click- ${centreName}`, 'Click', `Telephone Link click- ${centreName}`)} href={`tel:${contact}`}>
            <div className="btn-like-style phone">{contact}</div>
          </a>
          {secondaryContact && 
          <a onClick={() => gtmEventManager(`Contact Link click- ${centreName}`, 'Click', `Telephone Link click- ${centreName}`)} href={`tel:${secondaryContact}`}>
            <div className="btn-like-style phone" style={{marginLeft:"6px"}}>{secondaryContact}</div>
          </a>
          }
          <br className="mobile-only-strict" />
          <a onClick={() => gtmEventManager(`Contact Link click- ${centreName}`, 'Click', `Email Link click- ${centreName}`)} href={`mailto:${email}`} target="_blank">
            <div className="btn-like-style email">{email}</div>
          </a>
          {slug == 'the-little-skool-house-at-delta-house-alexandra-road' && 
          <button className='btn-like-style rotate-view' onClick={() => showModal()}>
          360° view of centre
          </button>
          }
          {slug == 'the-little-skool-house-at-tampines-junction' && 
          <button className='btn-like-style rotate-view' onClick={() => showModal()}>
          360° view of centre
          </button>
          }
          {slug == 'lidadashazhongxinyalishandafang' && 
          <button className='btn-like-style rotate-view' onClick={() => showModal()}>
          360°导览
          </button>
          }
          {slug == 'danbinnizhizongyingkangzhongxin' && 
          <button className='btn-like-style rotate-view' onClick={() => showModal()}>
          360°导览
          </button>
          }
        </div>
        <span>
          
        </span>
        <div className="content-section">
          <div className="middle-section">
            {contents && (
              <div className="subtext content-text-center">
                <h2>{contents.heading}</h2>
                <br />
                <div
                  dangerouslySetInnerHTML={{ __html: contents.description }}
                />
                {!centrePrincipals && (
                  <div
                    className="subtext content-text-center"
                    style={{ marginTop: '15px' }}
                  >
                    <h2>{custom[language].programmesOffered}</h2>
                    {programmesOfferedArr?.length > 0 && (
                      <div className="programmes">
                        {programmesOfferedArr[0]},<br />
                        {programmesOfferedArr[1]}
                      </div>
                    )}
                    <br />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: contents.programmesOfferedDescription
                      }}
                    />
                    {scheduleAVisit && (
                      <Link
                        to={custom[language.scheduleAVisitLink]}
                        className="btn-section"
                      >
                        <button className="yellow-funky-button">
                          {custom[language].scheduleAVisitBtn}
                        </button>
                      </Link>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className={`right-section ${!contents ? 'no-contents' : ''}`}>
              <div
                className="map"
                style={{ marginBottom: temporaryLocation != 'no' ? '10px' : '' }}
              >
                <iframe
                  title="map"
                  src={map}
                  width="100%"
                  height="250"
                  frameBorder="0"
                  disabledefaultui="true"
                />
              </div>
              <div className="address-container">
                <div className="address">
                  {temporaryLocation != 'no' && (
                    <span>
                      <b>{custom[language].temporaryLocationLabel}</b>
                    </span>
                  )}
                  {addressArr?.length > 0 && addressArr.reduce((a, b, idx) => [...a, <br key={idx} />, b], [])}
                </div>
              </div>

              {programmesOfferedArr && !contents && (
                <div className="programmes">{programmesOfferedArr.join(', ')}</div>
              )}
              {!contents && scheduleAVisit && (
                <Link to={custom[language].scheduleAVisitLink} className="btn-section">
                  <button className="yellow-funky-button">
                    {custom[language].scheduleAVisitBtn}
                  </button>
                </Link>
              )}
            </div>
            {!contents &&
              centrePrincipals.length > 0 && centrePrincipals.map((p, idx) => {
                const { name, designation, qualification, profileImage: { mediaItemUrl } } = p;

                return (
                  <div className="principal-section" key={`${name}-${idx}`}>
                    <div className="principal-profile">
                      <div
                        style={{
                          backgroundImage: `url(${mediaItemUrl})`,
                          backgroundSize: `cover`
                        }}
                      />
                    </div>
                    <h2>{name}</h2>
                    <h3>{designation}</h3>
                    <p>{qualification}</p>
                    <div className="btn-section">
                      <button
                        className="yellow-funky-button"
                        onClick={() => setSelectedPrincipal(p)}
                      >
                        {custom[language].findOutMoreBtn}
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="middle-section">
            {centrePrincipals && contents && (
              <div className="subtext content-text-center">
                <h2>{custom[language].programmesOffered}</h2>
                {programmesOfferedArr &&
                  (programmesOfferedArr.length > 1 &&
                    (programmesOfferedArr[0].length > 70 || centrePrincipals.length > 1) ? (
                    <div className="programmes">
                      {programmesOfferedArr[0]},<br />
                      {programmesOfferedArr[1]}
                    </div>
                  ) : (
                    <div className="programmes">
                      {programmesOfferedArr.join(', ')}
                    </div>
                  ))}
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: contents.programmesOfferedDescription
                  }}
                />

                {scheduleAVisit && (
                  <Link to={custom[language].scheduleAVisitLink} className="btn-section">
                    <button
                      className="yellow-funky-button"
                      style={{ marginTop: '8px' }}
                    >
                      {custom[language].scheduleAVisitBtn}
                    </button>
                  </Link>
                )}
              </div>
            )}
           <div className="principal-section-div">
            {centrePrincipals.length > 0 && centrePrincipals.map((p, idx) => {
              const { name, designation, qualification, profileImage: { mediaItemUrl } } = p;
              return (
                <div className="principal-section" key={`${name}-${idx}`}>
                  <div className="principal-profile">
                    <div
                      style={{
                        backgroundImage: `url(${mediaItemUrl})`,
                        backgroundSize: `cover`
                      }}
                    />
                  </div>
                  <h2>{name}</h2>
                  <h3>{designation}</h3>
                  <p>{qualification}</p>
                  <div className="btn-section">
                    <button
                      className="yellow-funky-button"
                      onClick={() => setSelectedPrincipal(p)}
                    >
                      {custom[language].findOutMoreBtn}
                    </button>
                  </div>
                </div>
              );
            })}
            </div>
          </div>
        </div>
        {centrePhotos?.length > 0 && (
          <div className="slider-section">
            <CentrePhotos pics={centrePhotos} slug={slug} setModalOpen={setModalOpen} />
          </div>
        )}
        <Modal
         isOpen={modalOpen}
         overlayClassName="lsh-modal"
         className="lsh-modal-container"
         ariaHideApp={false}
        >
           <div
              className="close-btn"
              role="button"
              onClick={() => handleCloseModal()}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
        {
        <iframe width="100%" height="450" 
        frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer"
         allowfullscreen scrolling="no" src={centresWithPopupView[slug]}>
         </iframe>
         }
         {/* {slug == 'lidadashazhongxinyalishandafang' && 
        <iframe width="100%" height="450" 
        frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer"
         allowfullscreen scrolling="no" src="https://kuula.co/share/collection/7qkfS?logo=-1&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=1">
         </iframe>
         }
         {slug == 'the-little-skool-house-at-tampines-junction' && 
         <iframe width="100%" height="450" frameborder="0" 
         allow="xr-spatial-tracking; gyroscope; accelerometer" 
         allowfullscreen scrolling="no" src="https://kuula.co/share/collection/7vp9R?logo=-1&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=1">
         </iframe>
         }
         {slug == 'danbinnizhizongyingkangzhongxin' && 
         <iframe width="100%" height="450" frameborder="0" 
         allow="xr-spatial-tracking; gyroscope; accelerometer" 
         allowfullscreen scrolling="no" src="https://kuula.co/share/collection/7vp9R?logo=-1&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=1">
         </iframe>
         } */}
         </Modal>
          <>
            <div className="faq-catalog">
              <h2>{custom[language].faqsLabel}</h2>
              {faq &&
                faq.map(({ question, answer, id }) => {
                  const iBLeaner =
                    question === 'What is the IB Primary Years Programme?' ? true : false;
                  const iBMission =
                    question ===
                      'What are the objectives of the IB Primary Years Programme?'
                      ? true
                      : false;
                  return (
                    <Accordion
                      key={question}
                      title={question}
                      iBLeaner={iBLeaner}
                      iBMission={iBMission}
                      isOpen={id && id === linkId ? true : false}
                      linkId={id}
                      activeLang={language}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: answer
                        }}
                      />
                    </Accordion>
                  );
                })}
            </div>
            {ibFaq && ibFaq.length > 0 &&
              <div className="faq-catalog">
                <h2>
                  {language == 'english' ? 'International Baccalaureate (IB) FAQs'
                    : '国际文凭（IB）常见问题'}
                </h2>
                {ibFaq &&
                  ibFaq.map(({ q, a, id }, i) => {
                    const iBMission =
                      id === 'iBMission';
                    const iBLeaner =
                      id === 'iBLearner';
                    const iBPrimary =
                      id === 'iBPrimary';
                    const iBPyp =
                      id === 'iBPyp';
                    const iBEpyp =
                      id === 'iBEpyp';
                    const iBAgency =
                      id === 'iBAgency';

                    return (
                      <Accordion
                        key={`${q}_${Math.random()}`}
                        title={q}
                        iBLeaner={iBLeaner}
                        iBMission={iBMission}
                        iBPrimary={iBPrimary}
                        iBPyp={iBPyp}
                        iBEpyp={iBEpyp}
                        iBAgency={iBAgency}
                        isOpen={id && id === linkId ? `${linkId}` : false}
                        linkId={id}
                        activeLang={language}
                      >
                        {a}
                      </Accordion>
                    );
                  })}
              </div>
            }
            <div className="other-faqs">
              {language == 'english' ? (
                <>
                  For other FAQs please visit our{' '}
                  <Link to="/enquiry/faqs">Frequently Asked Questions</Link> section.
                </>
              ) : (
                <>
                  欲知更多详情，敬
                  <Link to="/zh/xunwen/changjianwenti">请参阅常见问题</Link>。
                </>
              )}
            </div>

        {
         language == "english" ? showForm === "schedule-visit" ?
          <div className="embed-form-section">
              <p>
              如需切换至中文表格，请单击&nbsp;
                <a style={{ color: '#4ec7e2', textDecoration: 'underline' }} href="/zh/xunwen/yuyuecanguan">
                此处
                </a>
                .
              </p>
              <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
                <ScheduleVisitForm type="normal" centerList={centres} />
              </GoogleReCaptchaProvider>
          </div> 
          : showForm === "limited-time-promo" ? 
              <div className="promo-wrap">
                <PromoSection />
                <div className="promo-right-section">
                <h2>SCHEDULE A ONE-TO-ONE CENTRE VISIT TODAY</h2>
                <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
                    <ScheduleVisitForm type="open-house" centerList={centersList} />
                </GoogleReCaptchaProvider>
                </div>
              </div> 
          : showForm === "contact-us" ?
              <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
                <ContactUsForm />
              </GoogleReCaptchaProvider> : ""
          : showForm === "schedule-visit" ?
              <div className="embed-form-section">
                <p>
                To switch to English web form, please click&nbsp;
                  <a style={{ color: '#4ec7e2', textDecoration: 'underline' }} href="/enquiry/schedule-a-visit">
                  here
                  </a>
                  .
                </p>
                <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
                  <ScheduleVisitFormCn type="normal-cn" />
                </GoogleReCaptchaProvider>
              </div> 
          : showForm === "limited-time-promo" ? 
              <div className="open-house-container">
               <div className="promo-wrap">
                <PromoSectionCn />
                <div className="promo-right-section">
                <h2>敬请登记预约</h2>
                <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
                      <ScheduleVisitFormCn type="open-house-cn" centerList={centerListCn} />
                </GoogleReCaptchaProvider>
                </div>
               </div>
              </div> : 
          showForm === "contact-us" ?
              <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
                <ContactUsCNForm />
              </GoogleReCaptchaProvider> : ""
        }
      </>
      </div>
    </Layout>
  );
};

export default CenterDetails;