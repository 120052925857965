import React from 'react';
import Logo from '../../images/logo.png';
import { Link } from 'gatsby';
import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faFacebook,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { gtmEventManager } from "../common/utils/utils";
import BHFLogo from '../../images/logos/footer-logos/BHF_Logo_Greyscale_RGB.png';
import LSHLogo from '../../images/logos/footer-logos/LSH_Logo_Greyscale_RGB.png';
import MFSLogo from '../../images/logos/footer-logos/MFS_Logo_Greyscale_RGB.png';
import NFCOutdoorLogo from '../../images/logos/footer-logos/NFC_outdoorschool_Logo_Greyscale_RGB.png';
import NFCSeedLogo from '../../images/logos/footer-logos/NFC_seed_Logo_Greyscale_RGB.png';
import FirstCampusLogo from '../../images/logos/footer-logos/NFC_Logo_Greyscale_RGB.png';
import { LSH_LOGO } from '../common/utils/utils';

const FooterCn = ({openStatus}) => {

  function loadScript( url, callback ) {
    var script = document.createElement( "script" )
    script.type = "text/javascript";
    if(script.readyState) {  // only required for IE <9
      script.onreadystatechange = function() {
        if ( script.readyState === "loaded" || script.readyState === "complete" ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {  //Others
      script.onload = function() {
        callback();
      };
    }
  
    script.src = url;
    document.getElementsByTagName( "head" )[0].appendChild( script );
  }
  
  
  // call the function...
  loadScript('https://js.hsforms.net/forms/v2.js?pre=1', function() {
    addHubspotFormToPage();
  });

  const addHubspotFormToPage = () => {
    const hbForm = document.getElementById('hs-form-iframe-0');
    if (!hbForm) {
      window && window.hbspt &&  window.hbspt.forms &&  window.hbspt.forms.create({
        region: "na1",
        portalId: process.env.GATSBY_API_BASE_URL == "https://api-staging.littleskoolhouse.com/" ? "22078937" : "21698896",
        formId: process.env.GATSBY_API_BASE_URL == "https://api-staging.littleskoolhouse.com/" ? "3b85101e-9b6b-46c4-abd5-df9295a99d05" : "d1d9c37f-cfaf-4262-9eeb-22ac8432da2b",
        target: '#ntuc-logo-container-cn',
        submitButtonClass: 'hs-button',
        cssRequired: 'hs-form-required no-list hs-error-msgs hs-input input hs-richtext actions',
        cssClass: 'labels'
      });
    }
  }

  const scheduleVisit = openStatus === 'on'? 'xianshicuxiao'
  : 'yuyuecanguan';
  return (
    <div>
    <div className="footer-container">
      <div className="footer-wrapper">
        <div className="footer-logo">
          {/* <img src={LSH_LOGO} alt="LSH Logo" /> */}
          <h3>联络我们</h3>
          <p style={{marginTop : "8px"}}>
          电话: <a onClick={() => gtmEventManager(`Footer contact us`, 'Click', 'Telephone Click')} className="cn-ga" href="tel:+6565097880">+65 6509 7880</a>
          </p>
          <p>
          电邮:{' '}
            <a onClick={() => gtmEventManager(`Footer contact us`, 'Click', 'Email Click')} href="mailto:info@littleskoolhouse.com" target="_blank">
              info@littleskoolhouse.com
            </a>
          </p>
        </div>

        <div className="footer-links">
          <div className="social-links">
            <a
              href="https://www.facebook.com/littleskoolhouse/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              href="https://www.instagram.com/littleskoolhousesg/?igshid=xmhx42c453e6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://www.youtube.com/channel/UChJdWcrtM5zRGKDUFLdb5hQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
          <div className="links">
            <Link to="/zh/guanyuwomen">关于我们</Link>
            <Link to="/zh/womendezhongxin/suoyouzhongxin">我们的中心</Link>
            <Link to="/zh/womendekecheng/kechengkuangjiagaishu">我们的课程</Link>
            <Link to={`/zh/xunwen/${scheduleVisit}`}>预约参观</Link>
            <Link to="/zh/xunwen/lianluowomen">联络我们</Link>
          </div>

          <div className="links-privacy-mobile">
    <Link to="/others/privacy-policy">隐私政策声明</Link>
           <a
              href="https://www.ntucenterprise.sg/contactus/vulnerability-disclosure-programme/"
              target="_blank"
              rel="noopener noreferrer"
            >
              报告漏洞{' '}
            </a>

    </div>
  </div>
        <div className='newsletter-frame-wrap'>
        <div className="ntuc-logo-container-cn" id='ntuc-logo-container-cn'></div>
        </div>

       
      </div>
      <div id="nfc-footer">
        <div className="flex-parent-wrapper">
            <div className="main-logo-wrapper">
                <a href="https://www.ntucfirstcampus.com" target="_blank"><img className="nfc-logo-cus-width" alt="NTUC First Campus" title="NTUC First Campus" src={FirstCampusLogo}></img></a> 
            </div>
            <div className="flex-wrapper">
                <div className="flex-item">
                    <a href="https://www.myfirstskool.com" target="_blank"><img className="nfc-mfs-logo-cus-width" alt="My First Skool" title="My First Skool" src={MFSLogo}/></a>
                </div>
                <div className="flex-item">
                    <a href="https://www.littleskoolhouse.com" target="_blank"><img className="nfc-lsh-logo-cus-width" alt="The Little Skool-House" title="The Little Skool-House" src={LSHLogo}/></a>
                </div>
                <div className="flex-item">
                    <a href="https://outdoorschool.sg/" target="_blank"><img className="nfc-outdoor-logo-cus-width" alt="Outdoor School Singapore" title="Outdoor School Singapore" src={NFCOutdoorLogo}/></a>
                </div>
                <div className="flex-item">
                    <a><img className="nfc-seed-logo-cus-width" alt="SEED Institute" title="SEED Institute" src={NFCSeedLogo}/></a> 
                </div>
                <div className="flex-item">
                    <a href="https://www.ntucfirstcampus.com/do-good/bright-horizons-fund" target="_blank"><img class="nfc-bhf-logo-cus-width" alt="Bright Horizons Fund" title="Bright Horizons Fund" src={BHFLogo}/></a>
                </div>
            </div>
        </div>
    </div>

    <div className="links-privacy">
    <Link to="/others/privacy-policy">隐私政策声明</Link>
           <a
              href="https://www.ntucenterprise.sg/contactus/vulnerability-disclosure-programme/"
              target="_blank"
              rel="noopener noreferrer"
            >
              报告漏洞{' '}
            </a>

    </div>
    </div>

    


    <div className="copyright-notice">
          © 2022年小小学庭国际版权所有 | 注册号码. UEN
            199406237Z
            {/* <a
              href="/others/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              隐私政策声明
            </a>
            {' '}|{' '}
            <a
              href="https://www.ntucenterprise.sg/contactus/vulnerability-disclosure-programme/"
              target="_blank"
              rel="noopener noreferrer"
            >
              报告漏洞
            </a> */}
          </div>
    </div>
  );
};

export default FooterCn;
