import React from 'react';
import './Banner.scss';
import { Link, graphql, useStaticQuery } from 'gatsby';
const Banner = ({openStatus}) => {
  const scheduleVisit = openStatus === 'on'? 'limited-time-promotion'
   : 'schedule-a-visit';
  return (
    <Link to={`enquiry/${scheduleVisit}`} id="footer-banner">
      <div className="background">
        <h2>
          93% of our graduates are Highly Proficient in English and Mandarin{' '}
          <br />
          Schedule a visit today to learn more
        </h2>
      </div>
    </Link>
  );
};
export default Banner;
