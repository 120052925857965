import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import './FlipBook.scss';
import RightArrow from '../../../images/icons/right-arrow.png';
import LeftArrow from '../../../images/icons/left-arrow.png';
import Bg1 from '../../../images/backgrounds/flip-book/bg-page-1.jpg';
import Bg2 from '../../../images/backgrounds/flip-book/bg-page-2.jpg';
import Bg3 from '../../../images/backgrounds/flip-book/bg-page-3.jpg';
import Bg4 from '../../../images/backgrounds/flip-book/bg-page-4.jpg';
import Bg5 from '../../../images/backgrounds/flip-book/bg-page-5.jpg';
import Bg6 from '../../../images/backgrounds/flip-book/bg-page-6.jpg';
import Bg7 from '../../../images/backgrounds/flip-book/bg-page-7.jpg';
import Bg8 from '../../../images/backgrounds/flip-book/bg-page-8.jpg';
import Bg10 from '../../../images/backgrounds/flip-book/bg-page-10.jpg';
import Bg11 from '../../../images/backgrounds/flip-book/bg-11.jpg';
import Bg12 from '../../../images/backgrounds/flip-book/bg-12.jpg';
import GWLBannerDesktop from '../../../images/backgrounds/flip-book/gwl-banner-desktop.jpg';
import './FlipBook.scss';
import { mandarinAcquisitionPageLink } from '../../../config/appConfig';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { getLanguage } from '../../common/utils/utils';

export const pages = [
  {
    content: (
      <>
        <h3>GOLDEN WINDOW OF LEARNING </h3>
        <h4>Your baby’s optimal window for foundational learning starts now </h4>
        <a href="https://gwl.littleskoolhouse.com/" target="blank">
          <button className="yellow-funky-button blue-text">Find Out More</button>
        </a>
      </>
    ),
  },
  {
    bgImage: Bg11
  },
  {
    content: (
      <>
        <h3>We nurture articulate thinkers for tomorrow.</h3>
        <a href="/tot-talks" target="blank">
          <button className="yellow-funky-button blue-text"> Find out more</button>
        </a>
      </>
    ),
  },

  {
    bgImage: Bg12
  },
  {
    content: (
      <>
        <h3>Exclusive Mid-Year Promotion </h3>
        <h4>Receive $2,000 in fee rebate </h4>
        <a href="/enquiry/limited-time-promotion" target="blank">
          <button className="yellow-funky-button blue-text">Register Now</button>
        </a>
      </>
    ),
  },

  {
    bgImage: Bg1
  },
  {
    content: (
      <>
        <h2>93%</h2>
        <h4>of Little Skool-House children are</h4>
        <h3>Highly Proficient in English &amp; Mandarin.</h3>
        <Link to={mandarinAcquisitionPageLink}>
          <button className="yellow-funky-button blue-text">
            Find out more
          </button>
        </Link>
      </>
    ),
    bgImage: Bg2
  },

  {
    bgImage: Bg3
  },
  {
    content: (
      <>
        <h3>CHOSEN PRESCHOOL</h3>
        <h4>by Singapore’s most prestigious institutions.</h4>
        <Link to="/our-difference/chosen-preschool-by-top-institutions">
          <button className="yellow-funky-button blue-text">
            Find out more
          </button>
        </Link>
      </>
    ),
    bgImage: Bg4
  },
  {
    bgImage: Bg5
  },
  {
    content: (
      <>
        <h3>AWARD-WINNING TEACHERS</h3>
        <h4>
          conferred by <span>Ministry of Education</span> and{' '}
          <span>Early Childhood Development Agency.</span>
        </h4>
        <Link to="/our-difference/our-award-winning-educators">
          <button className="yellow-funky-button blue-text">
            Find out more
          </button>
        </Link>
      </>
    ),
    bgImage: Bg6
  },
  {
    bgImage: Bg7
  },
  {
    content: (
      <>
        <h4>Driving Discoveries.</h4>
        <h3>BUILDING KNOWLEDGE BETTER.</h3>
        <Link to="/our-curriculum/curriculum-overview">
          <button className="yellow-funky-button blue-text">
            Find out more
          </button>
        </Link>
      </>
    ),
    bgImage: Bg8
  }
];

const FlipBook = ({language , data, initCurrentPage = 2, timeOutDuration = 3000 }) => {
  const [currentPage, setCurrentPage] = useState(initCurrentPage);
  const timerInstance = useRef(null);
  const [flipBookData, setFlipBookData] = useState(data.nodes);
  const [bgStyle, setBgStyle] = useState({});

  useEffect(() => {
    const lang = language ? language : getLanguage();
    let arr = [];
    data && data.nodes && data.nodes.forEach(ele => {
      if (ele.home_page.flipBook.language && ele.home_page.flipBook.language.toLowerCase() == lang) {
        arr.push(ele);
      }
    })

    const pages1 = []
    arr && arr.length > 0 && arr[0].home_page.flipBook.flipBookPage.forEach((item, i) => {
      if(i == 0){
        const styles = {};
        styles.backgroundImage = `url(${item.flipBookTile?.image?.mediaItemUrl})`;
        styles.backgroundSize = 'cover';
        styles.transform = 'rotateY(-180deg)';
        setBgStyle(styles);
      }
      if (i > 0 && item.flipBookTile?.image?.mediaItemUrl) {
        pages1.push(
          {
            bgImage: item.flipBookTile?.image?.mediaItemUrl
          }
        )
      }
      if (item.flipBookTile?.bgimage?.mediaItemUrl) {
        pages1.push(
          {
            content: (
              <>
                <ReactMarkdown
                  source={item.flipBookTile?.description}
                  key={`FlipBook_Paragraph${i}`}
                  plugins={[gfm]}
                />
                <a href={item.flipBookTile?.linkTo} target="blank">
                  <button className="yellow-funky-button blue-text">{item.flipBookTile?.buttonText}</button>
                </a>
              </>
            ),
            bgImage: item.flipBookTile?.bgimage?.mediaItemUrl
          }
        )
      }
      else {
        pages1.push(
          {
            content: (
              <>
                <ReactMarkdown
                  source={item.flipBookTile?.description}
                  key={`FlipBook_Paragraph${i}`}
                  plugins={[gfm]}
                />
                <a href={item.flipBookTile?.linkTo} target="blank">
                  <button className="yellow-funky-button blue-text">{item.flipBookTile?.buttonText}</button>
                </a>
              </>
            )
          }
        )
      }
    })
    setFlipBookData(pages1);
  }, []);



  const timerTick = () => {
    setNext();
    timerInstance.current = setTimeout(timerTick, timeOutDuration);
  };

  useEffect(() => {
    timerInstance.current = setTimeout(timerTick, timeOutDuration);
    // Cleanup
    return () => {
      if (clearTimeout) clearTimeout(timerInstance.current);
    };
  }, []);

  const deferTimerTick = () => {
    if (clearTimeout) {
      clearTimeout(timerInstance.current);
      timerInstance.current = setTimeout(timerTick, timeOutDuration * 3);
    }
  };

  const setNext = (slowdownTimer = false) => {
    setCurrentPage(current => {
      if (current < flipBookData?.length) {
        return current === 2 ? 3 : current + 2;
      } else {
        return initCurrentPage;
      }
    });
    if (slowdownTimer) deferTimerTick();
  };

  const setPrev = (slowdownTimer = false) => {
    setCurrentPage(current => (current === 3 ? 2 : current - 2));
    if (slowdownTimer) deferTimerTick();
  };

  const getStyles = (position, bgImage) => {
    const styles = {};

    if ((position + 2) % 2 === 0) {
      // styles.background = `url(${bgImage}) no-repeat #FFFFFF`;
      styles.background = `url(${bgImage}) 15% 20% / 50% no-repeat #FFFFFF`;
      // styles.backgroundPosition = `15% 20%`;
      // styles.backgroundSize = `50%`;
    } else {
      styles.backgroundImage = `url(${bgImage})`;
      styles.backgroundSize = 'cover';
    }

    if (currentPage === 2 && position === 0) {
      styles.zIndex = 10;
    }

    if (currentPage > 2) {
      if (position <= currentPage - 3 || position === currentPage - 2) {
        styles.transform = 'rotateY(-180deg)';
      }

      if (position === currentPage - 2 || position === currentPage - 1) {
        styles.zIndex = 10;
      }
    }

    return styles;
  };

  return (
    <div className="flipbook-container">
      <div className="left-page">
        <div className="left-page-content">
          <div className="left-page-image" style={bgStyle}/>
        </div>
        {currentPage > 2 && (
          <div className="left-arrow-container" onClick={setPrev}>
            <img src={LeftArrow} alt="Left arrow" />
          </div>
        )}
      </div>
      <div className="right-page">
        {currentPage < flipBookData?.length && (
          <div className="right-arrow-container" onClick={() => setNext(true)}>
            <img src={RightArrow} alt="Right arrow" />
          </div>
        )}
        <div className="right-page-content">
          {flipBookData &&
            flipBookData.map((page, i) => (
              <div
                className="right-page-text"
                style={getStyles(i, page.bgImage)}
                key={i}
              >
                {page.content && (
                  <div className="page-content">{page.content}</div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FlipBook;
