import React from 'react';
import Layout from '../../layout/layout';
import Graphic1 from '../../images/objects/enrichment-programme/graphic-1.png';
import Graphic2 from '../../images/objects/enrichment-programme/graphic-2.png';
import Graphic3 from '../../images/objects/enrichment-programme/graphic-3.png';
import Graphic4 from '../../images/objects/enrichment-programme/graphic-4.png';
import Graphic5 from '../../images/objects/enrichment-programme/graphic-5.png';
import Graphic6 from '../../images/objects/enrichment-programme/graphic-6.png';

const sections = [
  {
    text: {
      heading: `Arts Appreciation`,
      subHeading: `Inspire your child’s imagination by introducing different art forms`,
      content: [
        'We offer a wide variety of arts and craft programmes from drawing, painting, mosaics to collages and many more. Experiencing Arts and Crafts allow children learn to value and appreciate different culture and times. It is a way \n for them to express themselves while exploring, creating, and experimenting. Through this, children will learn to be more spontaneous, creative, and confident.'
      ]
    },
    graphic: Graphic1
  },
  {
    text: {
      heading: `Creative Movement and Dance Skills`,
      subHeading: `Enhance your child’s cognitive development, gross and fine motor skills by exposing them to various creative movements`,
      content: [
        `Creative movement and various dance forms provide stimulation for three of those five senses at once: touch, sight, and hearing. As such, this is an enjoyable way for young children to develop their physical skills, channel their energy, stimulate their imagination and promote their creativity. Through creative movement, children will learn that it is acceptable for everyone’s interpretation of music and movements is different and develop richer social and acceptance skills.`
      ]
    },
    graphic: Graphic2
  },
  {
    text: {
      heading: `Fencing`,
      subHeading: `A great mind and body workout that allows your child to develop habits of success`,
      content: [
        `Fencing is often referred to as "physical chess" and is useful in training a child’s ability to focus that can be carried over into academics and other areas of life. It is a physical activity that helps with development of hand-eye-leg coordination, balance, and quick reflexes. Coupled with a character-based curriculum that promotes honour, grace and chivalry along with focus, discipline and determination, this sport will allow your child to gain skills to manage future life challenges.`
      ]
    },
    graphic: Graphic3
  },
  {
    text: {
      heading: `Speech and Drama Programme`,
      subHeading: `Build your child’s confidence while helping them to grow a love for literature`,
      content: [
        `Speech and Drama is a powerful way for children to learn effective communication by equipping them with skills for voice, speech, and language. It empowers children and enables them to develop self-confidence, a creative imagination, empathy, and maturity in interpersonal skills.`
      ]
    },
    graphic: Graphic4
  },
  {
    text: {
      heading: `Preschool Sports(Sportball / Table Tennis)`,
      subHeading: `Encourage your child to get into an active and healthy lifestyle without the pressures of competition`,
      content: [
        `Engaging in athletic activity is important for your child's development beyond physical development.it can also impact a child mentally, emotionally, and socially.`,
        `Sportball is an internationally established non-competitive sports instruction program for children aged 20 months and up, which supports development of motor, social and physical literacy. Using creative instruction, it provides your child exposure to the concepts and sports skills of 8 popular sports: soccer, tennis, basketball, volleyball, baseball, golf, rugby, and hockey.`,
        `Working with MOE-certified coaches, table tennis classes are conducted in Mandarin and is suitable for aged 4 and up. This sport not only improves brain health, it also promotes enhanced hand-eye coordination, flexibility and agility.`
      ]
    },
    graphic: Graphic5
  },
  {
    text: {
      heading: `STEAM Programme`,
      subHeading: `STEAM education prepares the children for the upcoming MOE's Applied Framework in 2023`,
      content: [
        `STEAM is an educational approach to learning that uses Science, Technology, Engineering, the Arts and Mathematics. Based on process-based learning - emphasis, inquiry and collaboration, we aim to develop our pre-schoolers to take thoughtful risks, engage in experimental learning, build resilience through a creative problem-solving process. All activities allow children to be hands-on and be involved in every step of the learning process.`
      ]
    },
    graphic: Graphic6
  }
];

const EPSection = ({
  section: {
    text: { heading, subHeading, content },
    graphic
  },
  right = false
}) => (
  <div className={`top-section ${right ? 'top-section-right' : ''}`}>
    <div className={`img-section${right ? '-mobile' : ''}`}>
      <img src={graphic} alt={heading} />
    </div>
    <div className="text">
      <h3>{heading}</h3>
      <h4>{subHeading}</h4>

      {content &&
        content.map(item => {
          return <p>{item}</p>;
        })}
    </div>
    {right && (
      <div className="img-section">
        <img src={graphic} alt={heading} />
      </div>
    )}
  </div>
);

const EnrichmentProgramme = () => {
  return (
    <Layout
      bannerClass="curriculum-details"
      heading="Enrichment Programme"
      breadCrumbs={[
        { label: 'Our Curriculum' },
        { label: 'Enrichment Programme' }
      ]}
      metaData={{
        url:
          'https://www.littleskoolhouse.com/our-curriculum/enrichment-programme',
        title: 'Quality Enrichment Programmes | Little Skool-House',
        description: 'Little Skool-House provides a conducive learning environment that is safe for young children to grow in. Check out some of the enrichment programmes we offer.'
      }}
    >
      <div className="learning-environment-container">
        {sections.map((section, i) => (
          <EPSection section={section} right={i % 2 !== 0} key={i} />
        ))}
      </div>
    </Layout>
  );
};

export default EnrichmentProgramme;
