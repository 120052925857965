import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { getLanguage } from '../../common/utils/utils';
import './TestimonialsCarousel.scss';

const settings = {
  speed: 500,
  dots: true
};

const testimonials = [
  {
    text: `Anya represented her class and won first prize at Primary One level. All this would not have been possible without a solid foundation created at The Little Skool-House.`,
    singature: (
      <>
        Parents of Anya Wong,
        <br />
        <span>Age 8</span>
      </>
    )
  },
  {
    text: `Noah’s love of reading and creating stories came from inspirational storytelling by The Little Skool-House teachers.`,
    singature: (
      <>
        Parents of Noah Lim,
        <br />
        <span>Age 5</span>
      </>
    )
  },
  {
    text: `The Little Skool-House teachers have always made our children very comfortable. It is so heartwarming to see them rush to hug their teachers in the morning. They seem to have the magic fingers to handle the kids well. They prepare them well for Primary school.`,
    singature: (
      <>
        Parents of Janelle &amp; Jayden,
        <br />
        <span>Ages 6 &amp; 4</span>
      </>
    )
  },
  {
    text: `Eden didn't take to food textures very well. While initially picky with vegetables, the teachers now proudly update that he finishes whatever vege is in his bowl.`,
    singature: (
      <>
        Parents of Eden Toh,
        <br />
        <span>Age 3</span>
      </>
    )
  },
  {
    text: `Germaine shows significant improvement in her Chinese in terms of speech and expressions. She is able to communicate better with her grandmothers fluently.`,
    singature: (
      <>
        Parents of Germaine Foo,
        <br />
        <span>Age 4</span>
      </>
    )
  },
  {
    text: `Thank you teachers for teaching important life skills and building a strong sense of self-responsibility and independence in my girl. She has really grown!`,
    singature: (
      <>
        Parents of Charlotte Koh,
        <br />
        <span>Age 3</span>
      </>
    )
  },
  {
    text: `Janelle is always intrigued by how things work and react to changes. She enjoyed learning and understanding Science through many fun, interesting and hands-on experiments and projects with her classmates, teachers and even with us at home.`,
    singature: (
      <>
        Parents of Janelle Tan,
        <br />
        <span>Age 6</span>
      </>
    )
  },
  {
    text: `The number of Math games created by the teachers make the lessons interesting, fun and definitely easier for the children to absorb.  Whenever I’m working on Math with Sedric, I find that his number sense is really strong, and he can answer my questions immediately. Thank you for your hard work Teachers.`,
    singature: (
      <>
        Mother of Sedric Lee,
        <br />
        <span>Age 6</span>
      </>
    )
  }
];

const testimonialsCn = [
  {
    text: `Anya代表班级赢得小学第一名。这都归功于小小学庭为她打好扎实的基础。`,
    singature: (
      <>
        Anya Wong的家长
        <br />
        <span>8岁</span>
      </>
    )
  },
  {
    text: `Noah对阅读及创造故事的热爱来自于小小学庭老师鼓舞人心的故事教学和讲故事活动。`,
    singature: (
      <>
        Noah Lim的家长
        <br />
        <span>5岁</span>
      </>
    )
  },
  {
    text: `小小学庭的教师总是让孩子觉得很舒服。早上看他们冲上去拥抱老师，实在暖心。他们似乎有一根神奇的手指，能指点孩子们做得更好。他们也为孩子们进入小学做好准备。`,
    singature: (
      <>
        Janelle 和 Jayden的家长
        <br />
        <span>6岁和4岁</span>
      </>
    )
  },
  {
    text: `Eden以前不太适应许多食物的质感。初时，他对蔬菜很挑剔。现在，听到老师骄傲地说：“他能够吃光碗里所有的蔬菜！`,
    singature: (
      <>
        Eden Toh的家长
        <br />
        <span>3岁</span>
      </>
    )
  },
  {
    text: `Germaine的华语会话和表达能力有明显的进步。她能与祖母更有效地进行沟通。`,
    singature: (
      <>
        Germaine Foo的家长
        <br />
        <span>4岁</span>
      </>
    )
  },
  {
    text: `感谢老师们的教导，让我女儿学会重要的生活技巧，建立自我责任感，更加独立。她真的长大了！`,
    singature: (
      <>
        Charlotte Koh的家长
        <br />
        <span>3岁</span>
      </>
    )
  },
  {
    text: `Janelle总是对事物改变的反应和运作感到好奇。她喜欢跟同学、老师及家人进行好玩、有趣的实验活动，享受学习的过程，探究科学知识。`,
    singature: (
      <>
        Janelle Tan的家长
        <br />
        <span>6岁</span>
      </>
    )
  },
  {
    text: `老师们创作的数学游戏让教学有趣、好玩，且更容易让孩子吸收。当每次和Sedric做数学时，我发现他的数感很强，而且都能立即回答我的问题。感谢老师们的辛勤教导。`,
    singature: (
      <>
        Sedrice Lee的家长
        <br />
        <span>6岁</span>
      </>
    )
  }
];

// const testimonialContent =
//   testimonials &&
//   testimonials.map((testimonial, i) => (
//     <div className="slider-section" key={i}>
//       <div className="testimonial-wrapper">
//         <div className={`testimonial  ${(i + 1) % 2 === 0 && 'yellow'}`}>
//           <p className="quote">"</p>
//           <p>{testimonial.text}</p>
//           <p className="signature">{testimonial.singature}</p>
//         </div>
//       </div>
//     </div>
//   ));

const TestimonialsCarousel = () => {

  const [testimonialContent, setTestimonialContent] = useState([]);

  useEffect(() => {
    const lang = getLanguage();
    let arr = [];
    let data = [];
    if (lang == "english") {
      arr = testimonials;
    }
    else {
      arr = testimonialsCn;
    }
    data =
      arr &&
      arr.map((item, i) => (
        <div className="slider-section" key={i}>
          <div className="testimonial-wrapper">
            <div className={`testimonial  ${(i + 1) % 2 === 0 && 'yellow'}`}>
              <p className="quote">"</p>
              <p>{item.text}</p>
              <p className="signature">{item.singature}</p>
            </div>
          </div>
        </div>
      ));
    setTestimonialContent(data);
  }, []);

  return (
    <div className="testimonials-carousel">
      <div className="mobile-only-strict">
        <Slider
          {...settings}
          arrows={false}
          slidesToScroll={1}
          slidesToShow={1}
        >
          {testimonialContent}
        </Slider>
      </div>

      <div className="tablet-only">
        <Slider {...settings} slidesToShow={2} slidesToScroll={2}>
          {testimonialContent}
        </Slider>
      </div>

      <div className="desktop-only">
        <Slider {...settings} slidesToShow={3} slidesToScroll={3}>
          {testimonialContent}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialsCarousel;
